import React, { useEffect, useState } from 'react';

// STYLES
import {
  Modal,
  Box,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { StyledBox } from './styles';

// ICONES
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FloppyDiskBack } from 'phosphor-react';

// APIS
import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { getUserInfo } from 'js/library/utils/helpers';

export default function ModalNewModule({
  open,
  handleClose,
  valueSelectModule,
  onSave,
  moduleSelected,
  selectedClub,
  setSelectedClub,
  selectedClubEdit,
  setSelectedClubEdit,
  expanded,
  setExpanded,
  selectedFileTypes,
  setSelectedFileTypes,
  moduleData,
  setModuleData,
  clubId,
}) {
  const [loading, setLoading] = useState(false);

  const [dataClub, setDataClub] = useState([]);
  const [activeClubs, setActiveClubs] = useState([]);
  const [filteredClub, setFilteredClub] = useState(null);

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [required, setRequired] = useState('');

  const userData = getUserInfo();
  const uId = userData.uId;
  const isAdmin = userData.admin === true;

  // quando o admin for false atualiza o campo: club do clube aberto
  if (
    (!isAdmin || isAdmin === false) &&
    filteredClub !== null &&
    moduleData?.type === 'getUserFromClub' &&
    moduleData?.club === ''
  ) {
    setModuleData((prevData) => ({
      ...prevData,
      club: filteredClub?.partnerId,
    }));
  }

  useEffect(() => {
    if (moduleSelected && moduleSelected.required === true) {
      setRequired(1);
    } else if (moduleSelected && moduleSelected.required === false) setRequired(2);
    else {
      setRequired('');
    }
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const clubData = await getSeuClubList(uId);
        setDataClub(clubData);

        // Filtra o clube em que o usuário está, para exibir no select quando o admin for false
        const filteredClubData = clubData.find((club) => club.clubeId === clubId);
        setFilteredClub(filteredClubData);
      } catch (error) {
        console.error('Erro fetchData: ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // filtrando clubes ativos
  useEffect(() => {
    const filteredClubs = dataClub.filter((club) => club.status === true);
    setActiveClubs(filteredClubs);
  }, [dataClub]);

  // para sempre que o valor do campo obrigatório mudar
  useEffect(() => {
    setModuleData((prevData) => ({
      ...prevData,
      required: required === 1 ? true : false,
    }));
  }, [required]);

  // campo obrigatório
  const handleChangeRequired = (e) => {
    setRequired(e.target.value);
  };

  // seta o clube selecionado no moduleData
  useEffect(() => {
    if (selectedClub) {
      setModuleData((prevData) => ({
        ...prevData,
        club: isAdmin && selectedClub.partnerId,
      }));
    }
  }, [selectedClub]);

  // para quando for edição
  useEffect(() => {
    if (moduleSelected && moduleSelected.club) {
      const club = dataClub.find((item) => item.partnerId === moduleSelected.club);
      setSelectedClubEdit(club ? club.name : null);
    }
  }, [moduleSelected, dataClub]);

  // atualiza visualmente o valor selecionado do autocomplete de clubes
  useEffect(() => {
    if (selectedClub && selectedClub.name !== selectedClubEdit) {
      setSelectedClubEdit(selectedClub.name);
    }
  }, [selectedClub]);

  // para (checkbox) do tipo arquivo
  useEffect(() => {
    if (moduleSelected && moduleSelected.fileType) {
      const initialSelectedFileTypes = moduleSelected.fileType
        .split(',')
        .map((type) => type.trim());

      const expandedSelectedFileTypes = initialSelectedFileTypes.flatMap((type) =>
        fileTypes.filter((fileType) => fileType.includes(type) || type.includes(fileType))
      );
      setSelectedFileTypes([...new Set(expandedSelectedFileTypes)]);
    } else {
      setSelectedFileTypes([]);
    }
  }, [moduleSelected]);

  // para o accordion
  useEffect(() => {
    if (moduleSelected && moduleSelected.fileType) {
      setExpanded(!expanded);
    }
  }, [moduleSelected]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModuleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // para manipular o accordion
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  // para o clube selecionado
  const handleChangeAutocomplete = (event, value) => {
    if (value) {
      const selectedId = value.id;

      const filteredDataClub = dataClub.filter((item) => item.id === selectedId);
      setSelectedClub(filteredDataClub.length > 0 ? filteredDataClub[0] : null);
    } else {
      setSelectedClub(null);
    }
  };

  const fileTypes = [
    'application/pdf', // PDF
    'image/*', // All images
    'text/plain', // Plain text files
    'text/csv', // CSV files
    'text/html', // HTML files
    'video/*', // All video files
    'audio/*', // All audio files
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word documents
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel', // Excel files
    'application/zip, application/x-7z-compressed', // ZIP files
  ];

  const fileTypeLabels = {
    'application/pdf': 'PDF',
    'image/*': 'Imagens',
    'text/plain': 'Arquivos de texto',
    'text/csv': 'Arquivos CSV',
    'text/html': 'Arquivos HTML',
    'video/*': 'Arquivos de vídeo',
    'audio/*': 'Arquivos de áudio',
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'Documentos Word',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel':
      'Arquivos Excel',
    'application/zip, application/x-7z-compressed': 'Arquivos ZIP',
  };

  // para (checkbox) do tipo arquivo
  const handleFileTypeChange = (event) => {
    const { value } = event.target;
    const newSelectedFileTypes = selectedFileTypes?.includes(value)
      ? selectedFileTypes.filter((type) => type !== value)
      : [...(selectedFileTypes || []), value];

    setSelectedFileTypes(newSelectedFileTypes);
    setModuleData((prevData) => ({
      ...prevData,
      fileType: newSelectedFileTypes.join(', '),
    }));
  };

  // campos modal
  const renderContent = () => {
    return valueSelectModule === 1 || moduleSelected?.type === 'date' ? (
      <Box display="flex" flexDirection="column" gap="15px">
        <TextField
          fullWidth
          label="Informe o texto do campo"
          variant="outlined"
          name="label"
          onChange={handleChange}
          value={moduleData.label || ''}
        />
        <FormControl sx={{ width: '100%', maxWidth: '200px', marginTop: '2px' }}>
          <InputLabel id="demo-simple-select-label">Campo obrigatório?</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Campo obrigatório?"
            onChange={handleChangeRequired}
            value={required}
          >
            <MenuItem value={1}>Sim</MenuItem>
            <MenuItem value={2}>Não</MenuItem>
          </Select>
        </FormControl>
      </Box>
    ) : valueSelectModule === 2 || moduleSelected?.type === 'getUserFromClub' ? (
      <>
        {isAdmin || filteredClub !== null ? (
          <Box display="flex" flexDirection="column" gap="15px">
            {isAdmin ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={activeClubs}
                value={
                  selectedClubEdit
                    ? activeClubs.find((club) => club.name === selectedClubEdit)
                    : selectedClub?.name || null
                }
                getOptionLabel={(option) => option.name}
                sx={{ width: '100%', maxWidth: '400px' }}
                blurOnSelect
                noOptionsText={
                  loading ? <CircularProgress size={15} /> : 'Nenhum resultado encontrado...'
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione um clube"
                    onClick={() => setOptionsOpen(true)}
                    onFocus={() => setOptionsOpen(true)}
                    onBlur={() => setOptionsOpen(false)}
                  />
                )}
                ListboxProps={{ style: { maxHeight: '200px' } }}
                open={optionsOpen}
                onClose={() => setOptionsOpen(false)}
                onChange={handleChangeAutocomplete}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.clubeId}-${option.name}`}>
                    {option.name}
                  </li>
                )}
              />
            ) : (
              <FormControl sx={{ width: '400px' }} variant="outlined" disabled>
                <InputLabel id="disabled-select-label">Clube selecionado</InputLabel>
                <Select
                  labelId="disabled-select-label"
                  id="disabled-select"
                  value={filteredClub.name}
                  label="Clube selecionado"
                  disabled
                >
                  <MenuItem value={filteredClub.name}>{filteredClub.name}</MenuItem>
                </Select>
              </FormControl>
            )}

            <TextField
              fullWidth
              label="Informe o texto do campo"
              variant="outlined"
              name="label"
              onChange={handleChange}
              value={moduleData.label || ''}
            />

            <FormControl sx={{ width: '100%', maxWidth: '200px', marginTop: '2px' }}>
              <InputLabel id="demo-simple-select-label">Campo obrigatório?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Campo obrigatório?"
                onChange={handleChangeRequired}
                value={required}
              >
                <MenuItem value={1}>Sim</MenuItem>
                <MenuItem value={2}>Não</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </>
    ) : valueSelectModule === 3 || moduleSelected?.type === 'insertCode' ? (
      <Box display="flex" flexDirection="column" gap="15px">
        <Typography variant="subtitle2" sx={{ marginBottom: '20px' }}>
          <b style={{ display: 'block' }}>Instruções para máscara:</b> Para incluir qualquer número
          na máscara, digite o número "9". Para digitar qualquer letra, digite a letra "a"
          minúscula. Para qualquer dígito, insira *. Caso outras letras sejam inseridas, o valor na
          máscara ficará fixa.
          <br />
          <br /> Ex: <br /> celular: (99) 99999-9999 <br /> placa de carro: aaa-9a99 <br /> cpf:
          999.999.999-99
        </Typography>

        <TextField
          fullWidth
          label="Informe o texto do campo"
          variant="outlined"
          name="label"
          onChange={handleChange}
          value={moduleData.label || ''}
        />

        <TextField
          fullWidth
          label="Informe a máscara do campo"
          variant="outlined"
          name="mask"
          onChange={handleChange}
          value={moduleData.mask || ''}
        />

        <TextField
          fullWidth
          label="Informe o tipo da unidade"
          variant="outlined"
          name="unitType"
          onChange={handleChange}
          value={moduleData.unitType || ''}
        />

        <FormControl sx={{ width: '100%', maxWidth: '200px', marginTop: '2px' }}>
          <InputLabel id="demo-simple-select-label">Campo obrigatório?</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Campo obrigatório?"
            onChange={handleChangeRequired}
            value={required}
          >
            <MenuItem value={1}>Sim</MenuItem>
            <MenuItem value={2}>Não</MenuItem>
          </Select>
        </FormControl>
      </Box>
    ) : valueSelectModule === 4 || moduleSelected?.type === 'file' ? (
      <Box display="flex" flexDirection="column" gap="15px">
        <TextField
          fullWidth
          label="Informe o texto do campo"
          variant="outlined"
          name="label"
          onChange={handleChange}
          value={moduleData.label || ''}
        />
        <TextField
          fullWidth
          label="Informe o texto do botão"
          variant="outlined"
          name="buttonText"
          onChange={handleChange}
          value={moduleData.buttonText || ''}
        />

        <FormControl sx={{ width: '100%', maxWidth: '200px', marginTop: '2px' }}>
          <InputLabel id="demo-simple-select-label">Campo obrigatório?</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Campo obrigatório?"
            onChange={handleChangeRequired}
            value={required}
          >
            <MenuItem value={1}>Sim</MenuItem>
            <MenuItem value={2}>Não</MenuItem>
          </Select>
        </FormControl>

        <Accordion
          elevation={0}
          expanded={expanded}
          onChange={handleAccordionChange}
          sx={{ border: expanded ? '1px solid #ddd' : 'none' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Informe o tipo do arquivo
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup row>
              {fileTypes.map((item) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={item}
                  sx={{
                    borderRight: '1px solid #ddd',
                    padding: '0px',
                    marginRight: '8px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFileTypes?.includes(item) || false}
                        onChange={handleFileTypeChange}
                        value={item}
                        sx={{
                          color: '#6E3296',
                          '&.Mui-checked': {
                            color: '#6E3296',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '14px', padding: 0 }}>
                        {fileTypeLabels[item] || item}
                      </Typography>
                    }
                  />
                </Box>
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </Box>
    ) : null;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox>
        <Box
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="large" />
        </Box>

        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ marginTop: '20px' }}>{renderContent()}</Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: '25px' }}
                startIcon={<FloppyDiskBack size={20} color="#ffffff" weight="fill" />}
                onClick={() => onSave(moduleData)}
              >
                Salvar
              </Button>
            </Box>
          </>
        )}
      </StyledBox>
    </Modal>
  );
}
