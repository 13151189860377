import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { TriiboH4 } from '../styles';
import * as S from './styles';

export default function DropDown({
  selectedOption,
  setSelectedOption,
  data,
  placeholder,
  height,
  width,
  top,
  disabled,
  error,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const onOptionClicked = (value) => {
    if (typeof value === 'object') {
      setSelectedOption({ ...value });
    } else {
      setSelectedOption(value);
    }
    setIsSelectOpen(false);
  };

  const toggling = () => {
    !disabled && setIsSelectOpen(!isSelectOpen);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData =
    data &&
    data.filter((option) =>
      typeof option === 'object'
        ? option.label.toLowerCase().includes(searchTerm.toLowerCase())
        : option.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <S.DropDownContainer>
      <S.DropDown width={width} height={height}>
        <S.DropDownHeader onClick={() => toggling()} disabled={disabled} error={error}>
          <TriiboH4>
            {typeof selectedOption === 'object' && selectedOption.label
              ? selectedOption.label
              : typeof selectedOption !== 'object' && selectedOption
              ? selectedOption
              : placeholder ?? 'Selecionar...'}
          </TriiboH4>
          <IoIosArrowDown className={isSelectOpen ? 'iconActive' : ''} size={20} />
        </S.DropDownHeader>
        {isSelectOpen && (
          <S.DropDownListContainer top={top}>
            <S.SearchInput
              type="text"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <S.DropDownList>
              {filteredData?.map((option, index) => {
                return (
                  <S.ListItem onClick={() => onOptionClicked(option)} key={index}>
                    {typeof option === 'object' ? option.label : option}
                  </S.ListItem>
                );
              })}
            </S.DropDownList>
          </S.DropDownListContainer>
        )}
      </S.DropDown>
    </S.DropDownContainer>
  );
}
