import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export function updateHasPromo(establishmentId) {
  return new Promise((resolve, reject) => {
    if (establishmentId !== null) {
      authToken()
        .then((token) => {
          axios
            .patch(
              `${cfac22(
                'API_HOST_V2'
              )}/establishments/admin/updateHasPromo?establishmentId=${establishmentId}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Full-Url': window.location.href,
                },
              }
            )
            .then((result) => {
              resolve(result.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve(null);
    }
  });
}
