import React from 'react';

//styles
import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';

//icons
import { MdModeEditOutline } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';

//components
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import { CircularProgress } from '@mui/material';

export default function FieldsList({
  formsFields,
  setFormsFields,
  fieldIndexKey,
  loading,
  handleSubmit,
  setFieldToEdit,
  setIsModalOpen,
  indexKeyAlreadyExists,
  fieldTypes,
}) {
  const handleRemoveField = (fieldName, isIndexKey) => {
    const newFormsFields = Array.from(formsFields).filter((field) => field.fieldName !== fieldName);

    if (isIndexKey) {
      const findCellPhone = Array.from(formsFields).find(
        (field) => field.fieldName === 'cellPhone'
      );

      if (!findCellPhone) {
        setFormsFields(
          new Set([
            ...formsFields,
            {
              id: formsFields.size.toString(),
              fieldName: 'cellPhone',
              label: 'Insira seu celular',
              fieldType: 'cellPhone',
              inputType: 'text',
              inputMask: 'cellPhone',
              allowEdition: true,
              required: true,
              type: 'contactList',
              indexKey: false,
            },
          ])
        );
      } else {
        setFormsFields(new Set(newFormsFields));
      }
    } else {
      setFormsFields(new Set(newFormsFields));
    }
  };

  const handleOpenEditField = (fieldName) => {
    setFieldToEdit(fieldName);
    setIsModalOpen(true);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(formsFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormsFields(new Set(items));
  }

  const getItemStyle = (draggableStyle) => ({
    ...draggableStyle,
  });

  return (
    <>
      <S.AddNewFieldContainer>
        <DashedButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setIsModalOpen(true);
            setFieldToEdit('');
          }}
        >
          + Adicionar novo campo
        </DashedButton>
      </S.AddNewFieldContainer>
      <S.DragContainer>
        {fieldIndexKey && (
          <>
            <T.TriiboH4>
              <strong>Campos fixos</strong>
            </T.TriiboH4>

            <S.FieldIndexKey>
              {!indexKeyAlreadyExists ? (
                <S.Actions>
                  <S.ButtonAction
                    onClick={() => handleRemoveField(fieldIndexKey.fieldName, true)}
                    bgColor={'#F13A3A'}
                  >
                    <FiTrash2 color="#fff" />
                  </S.ButtonAction>
                </S.Actions>
              ) : (
                <S.Actions>
                  <S.ButtonAction
                    onClick={() => handleOpenEditField(fieldIndexKey.fieldName, true)}
                    bgColor={'#3A63F1'}
                  >
                    <MdModeEditOutline color="#fff" />
                  </S.ButtonAction>
                </S.Actions>
              )}
              <S.CardItemInfos>
                <div style={{ width: '100%' }}>
                  <T.TriiboH2>{fieldIndexKey.label}</T.TriiboH2>
                  <T.TriiboH4>Tipo: {fieldIndexKey.inputType}</T.TriiboH4>
                  <T.TriiboH4>Identificador: {fieldIndexKey.fieldName}</T.TriiboH4>
                </div>
                Obrigatório
              </S.CardItemInfos>
            </S.FieldIndexKey>

            {Array.from(formsFields)
              .filter((field) => field.fieldName === 'aliasName')
              .map(({ label, fieldType, fieldName }) => (
                <S.FieldCard key={fieldName}>
                  <S.Actions>
                    <S.ButtonAction
                      onClick={() => handleOpenEditField(fieldName)}
                      bgColor={'#3A63F1'}
                    >
                      <MdModeEditOutline color="#fff" />
                    </S.ButtonAction>
                  </S.Actions>

                  <S.CardItemInfos>
                    <div>
                      <T.TriiboH2>{label}</T.TriiboH2>
                      <T.TriiboH4>Tipo: {fieldType}</T.TriiboH4>
                      <T.TriiboH4>Identificador: {fieldName}</T.TriiboH4>
                    </div>
                    Obrigatório
                  </S.CardItemInfos>
                </S.FieldCard>
              ))}

            {fieldIndexKey.fieldName !== 'cellPhone' &&
              Array.from(formsFields)
                .filter((field) => field.fieldName === 'cellPhone')
                .map(({ label, fieldType, fieldName }) => (
                  <S.FieldCard key={fieldName}>
                    <S.Actions>
                      <S.ButtonAction
                        onClick={() => handleOpenEditField(fieldName)}
                        bgColor={'#3A63F1'}
                      >
                        <MdModeEditOutline color="#fff" />
                      </S.ButtonAction>
                    </S.Actions>

                    <S.CardItemInfos>
                      <div>
                        <T.TriiboH2>{label}</T.TriiboH2>
                        <T.TriiboH4>Tipo: {fieldType}</T.TriiboH4>
                        <T.TriiboH4>Identificador: {fieldName}</T.TriiboH4>
                      </div>
                      Obrigatório
                    </S.CardItemInfos>
                  </S.FieldCard>
                ))}
          </>
        )}

        <hr />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <T.TriiboH4>
              <strong>Campos editáveis</strong>
            </T.TriiboH4>
            <T.TriiboH5>
              Para alterar a ordem de exibição dos campos no formulário, clique a arraste.
            </T.TriiboH5>
          </div>
          <PrimaryButton onClick={handleSubmit} disabled={loading} width={'100px'}>
            {loading ? <CircularProgress size={20} /> : 'Salvar'}
          </PrimaryButton>
        </div>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="formsFields">
            {(provided, snapshot) => (
              <S.DragContainerList
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDraggingOver}
              >
                {
                  /* Array.from(formsFields)
                  .filter(
                    (field) =>
                      !field.indexKey &&
                      field.fieldName !== 'cellPhone' &&
                      field.fieldName !== 'aliasName'
                  ) */

                  Array.from(formsFields).map(
                    ({ label, id, fieldName, fieldType, placeholder, indexKey }, index) => {
                      if (indexKey || fieldName === 'cellPhone' || fieldName === 'aliasName')
                        return null;

                      const example = fieldTypes.find(({ type }) => type === fieldType)
                        ? fieldTypes.find(({ type }) => type === fieldType).example
                        : '';
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided, snapshot) => (
                            <S.DragItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              style={getItemStyle(provided.draggableProps.style)}
                            >
                              <S.Actions>
                                <S.ButtonAction
                                  onClick={() => handleOpenEditField(fieldName)}
                                  bgColor={'#3A63F1'}
                                >
                                  <MdModeEditOutline color="#fff" />
                                </S.ButtonAction>

                                {fieldName !== 'cellPhone' && fieldName !== 'aliasName' && (
                                  <S.ButtonAction
                                    onClick={() => handleRemoveField(fieldName)}
                                    bgColor={'#F13A3A'}
                                  >
                                    <FiTrash2 color="#fff" />
                                  </S.ButtonAction>
                                )}
                              </S.Actions>

                              <S.CardItemInfos>
                                <div>
                                  <T.TriiboH2>{label}</T.TriiboH2>
                                  <T.TriiboH4>Tipo: {fieldType}</T.TriiboH4>
                                  <T.TriiboH4>Identificador: {fieldName}</T.TriiboH4>
                                  {fieldName !== 'aliasName' && (
                                    <T.TriiboH4>Exemplo: {placeholder || example}</T.TriiboH4>
                                  )}
                                </div>
                                {(fieldName === 'cellPhone' || fieldName === 'aliasName') &&
                                  'Obrigatório'}
                              </S.CardItemInfos>
                            </S.DragItem>
                          )}
                        </Draggable>
                      );
                    }
                  )
                }
                {provided.placeholder}
              </S.DragContainerList>
            )}
          </Droppable>
        </DragDropContext>
      </S.DragContainer>
    </>
  );
}
