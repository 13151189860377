import React, { useEffect, useState } from 'react';

// LIBS
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// STYLES
import * as S from '../../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';

// ICONES
import { MdArrowBackIosNew } from 'react-icons/md';
import { FloppyDiskBack } from 'phosphor-react';

// COMPONENTES
import ModalNewModule from './ModalNewModule';
import DragDropModules from './DragDropModules';

// API
import getInvoiceModules from 'js/library/utils/API/CampaignModules/getInvoiceModules';
import { createInvoiceModules } from 'js/library/utils/API/CampaignModules/createInvoiceModules';
import { updateInvoiceModules } from 'js/library/utils/API/CampaignModules/updateInvoiceModules';

export default function Modules(props) {
  const [loading, setLoading] = useState(false);
  const [valueSelectModule, setValueSelectModule] = useState('');
  const [open, setOpen] = useState(false);
  const [blurTrigger, setBlurTrigger] = useState(false);
  const [form, setForm] = useState({
    title: '',
    description: '',
    modules: [],
  });

  // para caso já exista modulos dessa campanha
  const [invoiceModules, setInvoiceModules] = useState({});
  const [loadingInvoiceModules, setLoadingInvoiceModules] = useState(true);

  const currentUrl = window.location.href;
  const params = currentUrl.split('/');
  const campaignId = params[6].split('=')[1];

  const [moduleSelected, setModuleSelected] = useState({});
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedClubEdit, setSelectedClubEdit] = useState(null);

  const [expanded, setExpanded] = useState(false);
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [moduleData, setModuleData] = useState({});

  // para edição dos dados do modal
  const isEdit = localStorage.getItem('Edição');

  const history = useHistory();

  const clubeId = props.location.state?.clubeId;

  // trás os modulos da campanha
  useEffect(() => {
    try {
      getInvoiceModules(campaignId).then((res) => {
        setInvoiceModules(res);
      });
    } catch (e) {
      console.error('error getInvoiceModules', e);
    } finally {
      setTimeout(() => {
        setLoadingInvoiceModules(false);
      }, 500);
    }
  }, []);

  // se existir modulos seta pra dentro do form
  useEffect(() => {
    if (Object.keys(invoiceModules).length > 0) {
      setForm((prevState) => ({
        ...prevState,
        title: invoiceModules.title,
        description: invoiceModules.description,
        modules: invoiceModules.modules,
      }));
    }
  }, [invoiceModules]);

  const initialData =
    valueSelectModule === 1
      ? {
          required: null,
          label: '',
          type: 'date',
        }
      : valueSelectModule === 2
      ? {
          required: null,
          label: '',
          type: 'getUserFromClub',
          club: '',
        }
      : valueSelectModule === 3
      ? {
          required: null,
          label: '',
          type: 'insertCode',
          mask: '',
          unitType: '',
        }
      : valueSelectModule === 4
      ? {
          required: null,
          label: '',
          type: 'file',
          buttonText: '',
          fileType: '',
        }
      : {};

  useEffect(() => {
    if (isEdit && moduleSelected) {
      setModuleData(moduleSelected);
    } else {
      setModuleData(initialData);
    }
  }, [open, moduleSelected]);

  const handleClose = () => {
    setOpen(false);
    setValueSelectModule('');
    setBlurTrigger(true);
    setModuleSelected(null);
    setSelectedClub(null);
    setSelectedClubEdit(null);
    setExpanded(false);
    setSelectedFileTypes([]);
    localStorage.removeItem('Edição');
  };

  const handleChange = (event) => {
    setValueSelectModule(event.target.value);
    setOpen(true);
  };

  useEffect(() => {
    if (!open && blurTrigger) {
      document.activeElement.blur();
      setBlurTrigger(false);
    }
  }, [open, blurTrigger]);

  // para o select (Selecione um módulo)
  const optionsModules = [
    { id: 1, value: 'Data' },
    { id: 2, value: 'Buscar usuário no clube' },
    { id: 3, value: 'Inserir código' },
    { id: 4, value: 'Arquivo' },
  ];

  const handleChangeFields = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  // seta os modulos no form
  const handleSaveModule = (moduleData) => {
    // validações do modal
    if (moduleData.type === 'date' && (moduleData.label === '' || moduleData.required === null)) {
      toast.error('Preencha todos os campos!', { autoClose: 2000 });
    } else if (
      moduleData.type === 'getUserFromClub' &&
      (moduleData.club === '' || moduleData.label === '' || moduleData.required === null)
    ) {
      toast.error('Preencha todos os campos!', { autoClose: 2000 });
    } else if (
      moduleData.type === 'insertCode' &&
      (moduleData.label === '' ||
        moduleData.mask === '' ||
        moduleData.unitType === '' ||
        moduleData.required === null)
    ) {
      toast.error('Preencha todos os campos!', { autoClose: 2000 });
    } else if (
      moduleData.type === 'file' &&
      (moduleData.label === '' ||
        moduleData.buttonText === '' ||
        moduleData.required === null ||
        moduleData.fileType === '')
    ) {
      toast.error('Preencha todos os campos!', { autoClose: 2000 });
    } else {
      // se o usuário preencher os campos corretamente seta o módulo no state form
      setForm((prevForm) => {
        const moduleExists = prevForm.modules.some(
          (module) => module.priority === moduleData.priority
        );

        if (moduleExists) {
          const updatedModules = prevForm.modules.map((module) =>
            module.priority === moduleData.priority ? moduleData : module
          );
          return {
            ...prevForm,
            modules: updatedModules,
          };
        } else {
          const newModule = {
            ...moduleData,
            priority: prevForm.modules.length,
          };
          return {
            ...prevForm,
            modules: [...prevForm.modules, newModule],
          };
        }
      });

      toast.success('Modulo salvo!', { autoClose: 1500 });
      handleClose();
    }
  };

  // atualiza o priority
  const handleModulesUpdate = (updatedModules) => {
    setForm((prevForm) => ({
      ...prevForm,
      modules: updatedModules,
    }));
  };

  const handleSubmit = async () => {
    const payload = {
      campaignId: campaignId,
      title: form.title,
      description: form.description,
      modules: form.modules,
    };

    if (form.title === '') {
      toast.error('Preencha o título.', { autoClose: 2000 });
      return;
    }
    if (form.description === '') {
      toast.error('Preencha a descrição.', { autoClose: 2000 });
      return;
    }

    try {
      setLoading(true);

      // para edição
      if (Object.keys(invoiceModules).length > 0) {
        await updateInvoiceModules(payload);
        toast.success('Modulo atualizado.', { autoClose: 1500 });
      } else {
        //para criação
        await createInvoiceModules(payload);
        toast.success('Modulo criado.', { autoClose: 1500 });
      }
      setTimeout(() => {
        history.goBack();
      }, 1500);
    } catch (error) {
      if (error.response.data.error.includes('campaignId não localizado')) {
        toast.error(`Não foi possivel adicionar um modulo para essa campanha!`, {
          autoClose: 2000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Container style={{ paddingBottom: '200px' }}>
      {loadingInvoiceModules ? (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '65vh' }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <ToastContainer />
          <AreaHeader
            style={{
              color: '#08bad0',
              justifyContent: 'end',
              padding: '1rem',
              marginLeft: '3rem',
            }}
          >
            <GoBack
              onClick={() => {
                window.history.back();
              }}
            >
              <MdArrowBackIosNew /> Voltar
            </GoBack>
          </AreaHeader>
          <D.TriiboH1
            style={{
              textAlign: 'center',
            }}
          >
            Gestão de módulos
          </D.TriiboH1>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Título"
                variant="outlined"
                name="title"
                value={form.title}
                onChange={handleChangeFields}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                variant="outlined"
                name="description"
                value={form.description}
                onChange={handleChangeFields}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">Selecione um módulo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={valueSelectModule}
                  onChange={handleChange}
                  label="Selecione um módulo"
                  sx={{ maxWidth: '300px' }}
                >
                  {optionsModules.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ModalNewModule
                open={open}
                handleClose={handleClose}
                valueSelectModule={valueSelectModule}
                onSave={handleSaveModule}
                moduleSelected={moduleSelected}
                selectedClub={selectedClub}
                setSelectedClub={setSelectedClub}
                selectedClubEdit={selectedClubEdit}
                setSelectedClubEdit={setSelectedClubEdit}
                expanded={expanded}
                setExpanded={setExpanded}
                selectedFileTypes={selectedFileTypes}
                setSelectedFileTypes={setSelectedFileTypes}
                moduleData={moduleData}
                setModuleData={setModuleData}
                clubId={clubeId}
              />

              {form.modules.length > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginTop: '40px' }}
                >
                  <D.TriiboH3>Ordem dos módulos</D.TriiboH3>
                </Box>
              )}
              <DragDropModules
                modules={form.modules}
                onModulesUpdate={handleModulesUpdate}
                setOpen={setOpen}
                setModuleSelected={setModuleSelected}
              />

              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || form.modules.length === 0}
                  sx={{ marginTop: '25px' }}
                  startIcon={!loading && <FloppyDiskBack size={20} color="#ffffff" weight="fill" />}
                  onClick={handleSubmit}
                >
                  {loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : 'Salvar'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </S.Container>
  );
}
