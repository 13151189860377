import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function GenerateLuckyNumbers(data) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/luckyNumbers/new',
          {
            ...data,
          },
          { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
        )
        .then((result) => {
          // console.log("RESULTADO GERAR LUCKY NUMBERS ==>", result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // console.log("ERROR NA GERAR LUCKY NUMBERS ==>", error);
          return reject(error);
        });
    });
  });
}
