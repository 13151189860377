import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { listPostHistory } from 'js/library/utils/API/Posts/listPostHistory';
import { getSeuClubList, getSeuClube } from 'js/library/utils/API/seuClube';
import { store } from 'js/core/configureStore';

import { Alert, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { TriiboComplete } from 'js/components/DesignSystem/TriiboAutocomplete/styles';
import { TableTD, TableTH } from 'js/components/DesignSystem/Table/styles';
import { CircularProgress, TextField } from '@mui/material';
import { cloneDeep } from 'lodash';
import { AreaHeader, GoBack } from '../../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { toast } from 'react-toastify';

function addSendDate(arrayOfObjects) {
  return arrayOfObjects.map((obj) => {
    const timestamp = obj.publishDate;
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return {
      ...obj,
      sendDate: formattedDate,
    };
  });
}

export default function ListPosts() {
  const isGestorPage = window.location.href.split('/').includes('admin') ? false : true;

  const params = useParams();

  const [notifications, setNotifications] = useState([]);
  const [clubeInfo, setClubeInfo] = useState(null);

  const [selectedClubOrEstablishment, setSelectedClubOrEstablishment] = useState(false);
  const [inputSearchPost, setInputSearchPost] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchId, setSearchId] = useState(params.id ? params.id : '');
  const [queryType, setQueryType] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [blankPost, setBlankPost] = useState({
    sender: {
      id: '',
      name: '',
      type: 'establishment',
      logo: '',
    },
    appFilter: {
      itemId: '',
      query: '',
      url: '',
    },
    title: '',
    message: '',
    image: '',
    orderType: 'alphabetic',
    action: {
      label: '',
      type: 'establishment_list',
    },
    keywords: [],
    platform: ['web', 'android', 'iOS'],
    orgs: [],
    receiverPlaces: [],
    receiverOrgs: [],
  });

  const sortBySendDate = (a, b) => {
    const dateA = new Date(a.publishDate);
    const dateB = new Date(b.publishDate);
    return dateB - dateA;
  };

  useEffect(() => {
    if (queryType === 'establishment' && searchId) {
      getEstablishmentId(searchId).then((res) => {
        let newObj = cloneDeep(blankPost);

        newObj.sender = {
          name: res.nome ? res.nome : '',
          id: res.id ? res.id : '',
          logo: res.fotoThumb ? 'estabelecimento/' + res.fotoThumb : '/triibo',
          type: 'establishment',
        };
        setBlankPost(newObj);
      });
    } else {
      if (searchId) {
        getSeuClube(searchId)
          .then((res) => {
            let newObj = cloneDeep(blankPost);
            newObj.sender = {
              name: res.name ? res.name : '',
              id: res.clubeId ? res.clubeId : '',
              logo: res.logo ? 'seuClube/' + res.logo : '/seuClube',
              type: 'club',
            };
            setClubeInfo(res);
            setBlankPost(newObj);
          })
          .catch((err) => {
            console.error('erro de busca do seu clube', err);
            err && toast.error(err.error);
          });
      }
    }
    if (searchId) {
      const typelist =
        window.location.pathname.indexOf('seu-clube') !== -1
          ? 'clubeId'
          : queryType === 'club'
          ? 'clubeId'
          : 'establishmentId';
      listPostHistory(searchId, typelist).then((res) => {
        if (res.result) {
          let newArr = addSendDate(res.result);

          newArr.sort(sortBySendDate);
          setNotifications(newArr);
        }
      });
    }
  }, [searchId]);

  useEffect(() => {
    if (queryType === 'establishment') {
      setLoadingOptions(true);
      const timeoutId = setTimeout(() => {
        apiListEstablishment(inputValue, 0, 10)
          .then((res) => {
            setOptions(res);
            setLoadingOptions(false);
          })
          .catch((err) => {
            console.error(err);
            setLoadingOptions(false);
          });
      }, 800);
      return () => clearTimeout(timeoutId);
    } else if (queryType === 'club' && options.length === 0) {
      if (inputValue.length !== 0) {
        setLoadingOptions(true);
        const timeoutId = setTimeout(() => {
          getSeuClubList(store.getState().codeInputModel.userInfo.id)
            .then((res) => {
              let tempList = [];
              res.forEach((item) => {
                item.status && tempList.push(item);
              });
              setOptions(tempList);
              setLoadingOptions(false);
            })
            .catch((err) => {
              console.error(err);
              setLoadingOptions(false);
            });
        }, 800);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [inputValue, queryType]);

  const column = [
    { heading: 'Titulo', value: 'title' },
    { heading: 'Data do Envio', value: 'sendDate' },
  ];

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <TableTH>
          <Link
            to={{
              pathname: isGestorPage
                ? `/estabelecimento/relacionamento/envio-postagem/${searchId}`
                : `/admin/configuracoes/enviaPostagem/${searchId}`,
              state: { ...blankPost, clubeInfo },
            }}
          >
            <S.AddNotificationButton style={{ marginLeft: 'auto' }}>
              <span>+</span> Criar postagem.
            </S.AddNotificationButton>
          </Link>
        </TableTH>
      ),
      childrenComponent: (
        <TableTD style={{ textAlign: 'right' }}>
          <Link
            to={{
              pathname: childrens
                ? isGestorPage
                  ? `/admin/seu-clube/relacionamento/envio-postagem/${searchId}`
                  : `/admin/configuracoes/enviaPostagem/${searchId}`
                : '/',
              state: { ...childrens, clubeInfo },
            }}
          >
            <S.StatusButton>Editar Post</S.StatusButton>
          </Link>
        </TableTD>
      ),
    };
  };

  const handleChangeType = (event) => {
    setInputSearchPost(null);
    setQueryType(event.target.value);
    setOptions([]);
  };

  const loadingPostOptions = (
    <div style={{ display: 'flex', gap: '1rem' }}>
      Buscando estabelecimentos/clubes
      {<CircularProgress size={30} />}
    </div>
  );

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container>
        <S.Header>
          <D.TriiboH1>Gerenciar Postagens</D.TriiboH1>
        </S.Header>
        {!params.id ? (
          <S.SearchBox>
            <div>
              <D.TriiboH4>
                Escolha o tipo da busca
                <b style={{ color: 'red', paddingLeft: '5px' }}>*</b>
              </D.TriiboH4>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  checked={queryType === 'establishment' ? true : false}
                  onClick={handleChangeType}
                  value="establishment"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6E3296',
                        },
                      }}
                    />
                  }
                  label="Estabelecimento"
                />
                <FormControlLabel
                  value="club"
                  checked={queryType === 'club' ? true : false}
                  onClick={handleChangeType}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6E3296',
                        },
                      }}
                    />
                  }
                  label="Clube"
                />
              </RadioGroup>
            </div>
            <TriiboComplete
              disabled={!queryType ? true : false}
              id="asynchronous-demo"
              fullWidth={true}
              open={open}
              onOpen={() => {
                inputValue.length > 0 && setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              value={inputSearchPost}
              onChange={(e, newValue) => {
                setInputSearchPost(newValue);
                setSelectedClubOrEstablishment(true);
                if (newValue) {
                  if (queryType === 'establishment') {
                    setSearchId(newValue.id);
                  } else if (queryType === 'club') {
                    setSearchId(newValue.clubeId);
                  }
                }
              }}
              inputValue={inputValue}
              loading={loadingOptions}
              loadingText={loadingPostOptions}
              noOptionsText={
                inputValue.length > 0 && <Alert severity="error">Nenhum resultado</Alert>
              }
              onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={options}
              getOptionLabel={(options) => (queryType === 'club' ? options.name : options.nome)}
              renderInput={(params) => (
                <TextField
                  sx={{ cursor: 'pointer' }}
                  {...params}
                  label="Nome"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </S.SearchBox>
        ) : (
          <></>
        )}

        <CustomButtonsTable
          data={notifications}
          setSelected={setNotifications}
          perPage={10}
          column={column}
          componentReceiver={componentReceiver}
          selectedItem={selectedClubOrEstablishment}
        />
        {notifications.length < 1 && (
          <Link
            to={{
              pathname: isGestorPage
                ? `/estabelecimento/relacionamento/envio-postagem/${searchId}`
                : `/admin/configuracoes/enviaPostagem/${searchId}`,
              state: { ...blankPost, clubeInfo },
            }}
          >
            <S.AddNotificationButton
              style={{
                opacity:
                  window.location.pathname.indexOf('seu-clube') === -1 && !inputSearchPost
                    ? 0.5
                    : 1,
                cursor:
                  window.location.pathname.indexOf('seu-clube') === -1 && !inputSearchPost
                    ? 'not-allowed'
                    : 'pointer',
              }}
              disabled={window.location.pathname.indexOf('seu-clube') === -1 && !inputSearchPost}
            >
              <span>+</span> Criar postagem
            </S.AddNotificationButton>
          </Link>
        )}
      </S.Container>
    </div>
  );
}

