import React from 'react';

export const ColumnViewer = ({ numberOfColumns }) => {
  // Define a single column JSX
  const column = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <div
        style={{
          width: '2rem',
          height: '2.6rem',
          border: '1px solid transparent',
          borderRadius: '6px',
          background: '#aaaaaa',
        }}
      ></div>
      <div
        style={{
          width: '2rem',
          height: '2.6rem',
          border: '1px solid transparent',
          borderRadius: '6px',
          background: '#aaaaaa',
        }}
      ></div>
    </div>
  );

  const columns = Array.from({ length: numberOfColumns }, (_, index) => (
    <div key={index}>{column}</div>
  ));

  return <>{columns}</>;
};
