import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loading = ({ children, tickness, sizeLoading, state }) => {
  return state ? (
    <div style={{ margin: '0 auto' }}>
      <CircularProgress tickness={tickness} size={sizeLoading} />
    </div>
  ) : (
    <>{children}</>
  );
};
