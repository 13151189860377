import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function getUserSegment(data) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/userSegment/getUserSegment',
          {
            ...data,
          },
          {
            headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
          }
        )
        .then((result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          return reject(error.response.data);
        });
    });
  });
}
