import React from 'react';

// STYLES
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export const ImportantInformation = ({
  dataCustomization,
  setDataCustomization,
  segments,
  customizationsAlready,
}) => {
  const { name, clientName, stripeUrl, text } = dataCustomization;
  const isDisabled = (segmentId) => customizationsAlready.includes(segmentId);

  const setInformantions = (event, field) => {
    setDataCustomization((prevCustomization) => ({
      ...prevCustomization,
      [field]: event.target.value,
    }));
  };

  return (
    <Grid container spacing={2} sx={{ border: '1px solid #aaa', padding: '1rem 2rem 2rem 1rem' }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 600, paddingLeft: '1rem' }}>
        Informações importantes:
      </Typography>

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '1rem' }}
      >
        <TextField
          label="Nome da customização*"
          fullWidth
          onChange={(event) => setInformantions(event, 'name')}
          value={name}
          variant="outlined"
        />

        <TextField
          fullWidth
          label="Tipo do cliente*"
          onChange={(event) => setInformantions(event, 'clientName')}
          value={clientName}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          label="Texto*"
          multiline
          fullWidth
          onChange={(event) => setInformantions(event, 'text')}
          value={text}
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '1rem' }}
      >
        <TextField
          label="Stripe URL*"
          onChange={(event) => setInformantions(event, 'stripeUrl')}
          sx={{ width: '70%' }}
          value={stripeUrl}
          variant="outlined"
        />
        <FormControl sx={{ width: 300 }}>
          <InputLabel id="segmentId">Selecione o segmento*</InputLabel>
          <Select
            labelId="segmentId"
            input={<OutlinedInput label="Selecione o segmento*" />}
            onChange={(event) => setInformantions(event, 'segmentId')}
            value={dataCustomization.segmentId}
          >
            {segments?.map((segment) => (
              <MenuItem disabled={isDisabled} key={segment.id} value={segment.id}>
                {segment.segmentName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
