import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// STYLES
import {
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tab,
  Avatar,
  useMediaQuery,
} from '@mui/material';
import useStyles from './styles';

// COMPONENTS
import Header from '../Header';
import { PromotionCard } from './Cards';
import InputSearchTab from './InputSearchTab';

// SERVICES
import getEstablishmentWithPromotions from 'js/library/utils/API/Establishment/getEstablishmentWithPromotions';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export default function AttendanceScreen() {
  const [userData, setUserData] = useState({
    user: null,
    uId: '',
    triiboId: '',
    aliasName: '',
    keywords: [],
  });

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [promotionsData, setPromotionsData] = useState([]);
  const [walletData, setWalletData] = useState(null);
  const [couponList, setCouponList] = useState([]);

  const [filteredPromotions, setFilteredPromotions] = useState([]);
  const [filteredCouponsList, setFilteredCouponsList] = useState([]);

  const [messageUser, setMessageUser] = useState(false);

  const [value, setValue] = useState(0);

  // para a pesquisa de usuario
  const [searchInputText, setSearchInputText] = useState('');

  // para a pesquisa dentro de tab
  const [searchTerm, setSearchTerm] = useState('');

  // id do estabelecimento
  const { id } = useParams();

  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(600));

  // sempre que mudar de tab, limpa os valores de pesquisa
  useEffect(() => {
    setSearchTerm('');
    setFilteredPromotions([]);
    setFilteredCouponsList([]);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (searchInputText.length < 14) {
      setMessageUser(false);
    }
  }, [searchInputText]);

  useEffect(() => {
    if (userData?.user && userData?.uId !== '' && userData?.triiboId !== '') {
      getWalletData(userData.uId, userData.triiboId);
    }
  }, [userData]);

  const getWalletData = async (uId, triiboId) => {
    try {
      const wallet = await getWallet_v1(uId, triiboId);
      setWalletData(wallet || null);
    } catch (error) {
      console.error('Erro na getWallet', error);
      setWalletData(null);
    }
  };

  useEffect(() => {
    if (walletData?.coupons) {
      const filteredCoupons = Object.entries(walletData.coupons)
        .filter(
          ([key, coupon]) =>
            coupon.establishmentId === id &&
            (coupon.state === 'ongoing' || coupon.status === 'enviado')
        )
        .map(([key, coupon]) => ({
          ...coupon,
          voucherId: key,
        }));

      setCouponList(filteredCoupons);
    }
  }, [walletData, id]);

  const getUser = async () => {
    const payload = {
      contactList: [
        {
          type: 'cellPhone',
          value: searchInputText,
        },
      ],
    };

    try {
      if (searchInputText.length === 14) {
        setLoading(true);
        setLoadingButton(true);

        setUserData({
          user: null,
          uId: '',
          triiboId: '',
          aliasName: '',
          keywords: [],
        });

        const response = await getUserInfo_v1(['getUserInfo'], payload);
        const userInfo = response.data.success.userInfo;

        if (!userInfo) {
          setMessageUser(true);
        }

        setUserData({
          user: !!userInfo.uId,
          uId: userInfo.uId || '',
          triiboId: userInfo.triiboId || '',
          aliasName: userInfo.aliasName || '',
          keywords: userInfo.keyWordsSetConsumption || [],
        });

        // Aguarda o estado ser atualizado antes de chamar getPromotions()
        setTimeout(() => {
          getPromotions(userInfo.uId);
          setLoadingButton(false);
        }, 100);
      }
    } catch (error) {
      console.error('erro na userInfo', error);
      setLoadingButton(false);
    }
  };

  const getPromotions = async (uid) => {
    const payload = {
      systemQuery: {
        grupo1: [
          {
            field: 'establishmentId',
            type: '==',
            value: id,
            apiType: 'promotion',
          },
          {
            field: 'type',
            type: '==',
            value: 'promotion',
            apiType: 'promotion',
          },
        ],
      },
      establishmentType: ['digital', 'physical'],
      orderType: 'relevance',
      hasPromo: true,
      plataform: 'web',
      from: 0,
      size: 1,
      fromPromotion: 0,
      sizePromotion: 100,
    };

    try {
      // id = id do estabelecimento
      if (id) {
        setLoading(true);
        const response = await getEstablishmentWithPromotions(uid, payload);
        const promotions = response.result[0]?.promotions || [];

        setPromotionsData(promotions);
      }
    } catch (error) {
      console.error('Erro ao buscar promoções:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    getUser();
  };

  return (
    <Container maxWidth={false} classes={{ root: classes.root }}>
      <Header
        title="Atendimento"
        action={handleSearch}
        searchInputText={searchInputText}
        setSearchInputText={setSearchInputText}
        loadingButton={loadingButton}
      />

      {!userData.user ? (
        <Box width="100%" display="flex" justifyContent="center" sx={{ marginTop: '20px' }}>
          {messageUser && <Typography variant="body1">Usuário não encontrado!</Typography>}
        </Box>
      ) : loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: '100%', marginTop: isMobile ? '10px' : '0px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <br />
              <Box display="flex" alignItems="center" gap={2} sx={{ marginBottom: '20px' }}>
                <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    background: '#a8a8a8',
                  }}
                >
                  <Typography variant="h6">
                    {userData?.aliasName?.[0]?.toUpperCase() || 'T'}
                  </Typography>
                </Avatar>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      padding: '0px !important',
                    }}
                  >
                    {userData?.aliasName || 'Usuário'}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Pontos: {walletData?.totalPoints || 0}
                  </Typography>
                </Box>
              </Box>
              <hr />
              <br />
              <br />
            </Grid>
          </Grid>
          <TabContext value={value}>
            <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Enviar cupom" value={0} />
              <Tab label="Cupons do usuário" value={1} />
            </TabList>

            <TabPanel value={0}>
              {promotionsData.length > 0 ? (
                <>
                  <InputSearchTab
                    data={promotionsData}
                    setFilteredPromotions={setFilteredPromotions}
                    type="promotion"
                    setSearchTerm={setSearchTerm}
                  />

                  {searchTerm && filteredPromotions.length === 0 ? (
                    <Box display="flex" justifyContent="center">
                      <Typography variant="subtitle1">Nenhum resultado encontrado.</Typography>
                    </Box>
                  ) : (
                    <Grid container spacing={3}>
                      {(filteredPromotions.length > 0 ? filteredPromotions : promotionsData).map(
                        (promotion) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={promotion.id}>
                            <PromotionCard
                              classes={classes}
                              promotion={promotion}
                              tabValue={value}
                              userData={userData}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  )}
                </>
              ) : (
                <Typography variant="subtitle1">Nenhuma promoção encontrada!</Typography>
              )}
            </TabPanel>

            <TabPanel value={1}>
              {couponList.length > 0 ? (
                <>
                  <InputSearchTab
                    data={couponList}
                    setFilteredCouponsList={setFilteredCouponsList}
                    type="coupon"
                    setSearchTerm={setSearchTerm}
                  />

                  {searchTerm && filteredCouponsList.length === 0 ? (
                    <Box display="flex" justifyContent="center">
                      <Typography variant="subtitle1">Nenhum resultado encontrado.</Typography>
                    </Box>
                  ) : (
                    <Grid container spacing={3}>
                      {(filteredCouponsList.length > 0 ? filteredCouponsList : couponList).map(
                        (coupon, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                            <PromotionCard
                              classes={classes}
                              promotion={coupon}
                              tabValue={value}
                              userData={userData}
                              setFilteredCouponsList={setFilteredCouponsList}
                              setCouponList={setCouponList}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  )}
                </>
              ) : (
                <Typography variant="subtitle1">Este usuário não possui cupons.</Typography>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </Container>
  );
}
