import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { validateRegistrationDate } from '../../utils/helpers';
import cfac22 from '../cfac22';

// API REQUISIÇÃO DADOS GRAFICOS
export default function dataGraphics(establishmentID, repTable) {
  return new Promise((resolve, reject) => {
    const dataGraphics = JSON.parse(localStorage.getItem(`${establishmentID}_${repTable}`));

    const valideDate = validateRegistrationDate(dataGraphics?.uptadeDate, 20);
    if (dataGraphics === null || dataGraphics === undefined || !valideDate) {
      authToken()
        .then((token) => {
          axios
            .get(`${cfac22('API_HOST_V2')}/redshiftGraphs`, {
              headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
              params: {
                table: repTable,
                merchantId: establishmentID,
              },
            })
            .then((result) => {
              const data = {
                data: result.data.data,
                uptadeDate: Date.now(),
              };

              localStorage.setItem(`${establishmentID}_${repTable}`, JSON.stringify(data));
              resolve(result.data.data);
            })
            .catch((error) => {
              // console.log("GRAPHICS_ERROR: ", error);
              return reject(error);
            });
        })
        .catch((error) => {
          // console.log("ERROR_TOKEN===>", error);
          reject(error);
        });
    } else {
      resolve(dataGraphics.data);
    }
  });
}
