import React, { useEffect, useState } from 'react';
import StepOneCuration from './StepOneCuration/index';
import StepTwoCuration from './StepTwoCuration/StepTwoCuration';
import StepThreeCuration from './StepThreeCuration';
import { getEstablishmentChangesRequest } from 'js/library/utils/API/Curation/getEstablishmentChangesRequest';
import { LoadingTableClube } from 'js/components/Clubs/clubeTableStyles';
import { CircularProgress } from '@mui/material';
import { downloadImage } from 'js/library/services/StorageManager';
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import { IoMdSave } from 'react-icons/io';
import { LoadingButton } from '@mui/lab';
import { AreaHeader } from '../RelationshipScreen/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { confirmEstablishmentChangeRequest } from 'js/library/utils/API/Curation/confirmEstablishmentChangeRequest';
import { toast } from 'react-toastify';
import { getBase64FromUrl, getUserInfo, separateBase64String } from 'js/library/utils/helpers';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

const EstablishmentCuration = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [estThumb, setEstThumb] = useState('');
  const [galleryArr, setGalleryArr] = useState([]);
  const [checked, setChecked] = useState(false);
  const [errorGettingData, setErrorGettingData] = useState(false);
  const [galleryArrExcluded, setGalleryArrExcluded] = useState([]);

  const [formState, setFormState] = useState({
    nome: '',
    descricao: '',
    endereco: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    tags: [],
    disableDate: new Date(),
    email: '',
    site: '',
    fotoThumb: '',
    listaFotos: [],
    contact: '',
    contato: [],
    contatosExcluido: [],
    redesSociais: [],
    redesSociaisExcluidas: [],
    funcionamento: [],
    cashbackDefault: null,
  });
  const [newFormState, setNewFormState] = useState({
    nome: '',
    descricao: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    tags: [],
    disableDate: new Date(),
    email: '',
    site: '',
    contact: '',
    fotoThumb: '',
    contatosExcluido: [],
    redesSociaisExcluidas: [],
    funcionamentoAntigos: [],
    cashbackDefault: null,
  });
  const [finalFormState, setFinalFormState] = useState({
    nome: '',
    descricao: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    tags: [],
    disableDate: '',
    email: '',
    site: '',
    contatos: '',
    fotoThumb: '',
    contatoTriibo: [],
    redesSociais: [],
    funcionamento: [],
    cashbackDefault: null,
  });
  const id = props.location.state;

  const checkForm = async (e) => {
    e.preventDefault();

    setLoadingSave(true);

    let finalFormStateBody = { ...finalFormState };

    finalFormStateBody.listaFotos = [];

    try {
      if (checked) {
        if (
          finalFormStateBody.cep.length !== 0 &&
          finalFormStateBody.logradouro.length !== 0 &&
          finalFormStateBody.numero.length !== 0 &&
          finalFormStateBody.bairro.length !== 0 &&
          finalFormStateBody.cidade.length !== 0 &&
          finalFormStateBody.estado.length !== 0
        ) {
        } else {
          toast.error('Endereço incompleto!');
        }
      } else {
        finalFormStateBody.endereco = 'Brasil';
        finalFormStateBody.cep = '';
        finalFormStateBody.logradouro = '';
        finalFormStateBody.numero = '';
        finalFormStateBody.complemento = '';
        finalFormStateBody.bairro = '';
        finalFormStateBody.cidade = '';
        finalFormStateBody.estado = '';
      }

      if (finalFormStateBody.nome) {
        if (finalFormStateBody.nome.length < 2) {
          toast.error('Nome precisa ter no mínimo 2 caracteres!');
        }
      } else {
        toast.error('Nome é obrigatório!');
      }

      if (finalFormStateBody.descricao.length !== 0) {
        if (finalFormStateBody.descricao.length < 5) {
          toast.error('Descrição precisa ter no mínimo 5 caracteres!');
        }
      } else {
        toast.error('Descrição é obrigatório!');
      }

      if (finalFormStateBody.email) {
        if (finalFormStateBody.email.length !== 0) {
          const emailRegex = new RegExp(
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
            'gm'
          );
          const isValidEmail = emailRegex.test(finalFormStateBody.email);
          if (isValidEmail) {
            setFinalFormState((prevState) => {
              return {
                ...prevState,
                email: finalFormStateBody.email,
              };
            });
          } else {
            toast.error('Email inválido');
          }
        } else {
          toast.error('Email é obrigatório!');
        }
      } else {
        toast.error('Email é obrigatório!');
      }

      if (!finalFormStateBody.contatos) {
        toast.error('Telefone Privado é obrigatório!');
      }

      if (!finalFormStateBody.contatoTriibo[0]) {
        toast.error('Contatos para os clientes precisa no mínuno ter 1 contato');
      }

      if (galleryArr.length < 1) {
        toast.error('Lista de fotos precisa no mínimo ter 1 foto');
      }

      // // Upload das imagens da galeria
      for (let i = 0; i < galleryArr.length; i++) {
        console.log(galleryArr[i]);
        if (galleryArr[i].indexOf('https') === 0) {
          let tempname = galleryArr[i];
          tempname = tempname.split('%2F');
          tempname = tempname[1].split('?');

          finalFormStateBody.listaFotos[i] = tempname[0];
        } else {
          try {
            const newGalleryFile = separateBase64String(await getBase64FromUrl(galleryArr[i]));

            const res = await uploadFiles('estabelecimento', {
              mimeType: newGalleryFile.mimeType,
              fileName: 'gal' + i + '_' + id,
              buffer: newGalleryFile.buffer,
            });

            const response = res.split('/');
            const galleryImgWithTimestamp = response[response.length - 1].split('?')[0];

            finalFormStateBody.listaFotos[i] = galleryImgWithTimestamp;
          } catch (error) {
            console.log(error);
            throw new Error('Erro no upload de uma imagem da galeria.');
          }
        }
      }

      finalFormStateBody.contatoTriibo = formState.contato;
      finalFormStateBody.redesSociais = formState.redesSociais;

      // Remoção de propriedades desnecessárias
      delete finalFormStateBody.telefone;
      delete finalFormStateBody.data;
      delete finalFormStateBody.fotoThumbFile;

      const loggedUid = getUserInfo().uId;

      // console.log('finalFormStateBody', finalFormStateBody);
      // Chamada à API
      confirmEstablishmentChangeRequest(loggedUid, id, true, '', finalFormStateBody)
        .then((result) => {
          setLoadingSave(false);
          toast.success('Informações enviadas com sucesso!');
          window.history.back();
        })
        .catch((err) => {
          setLoadingSave(false);
        });
    } catch (error) {
      setLoadingSave(false);
      toast.error('Não foi possível salvar as alterações');
    }
  };

  // console.log('galleryArr', galleryArr);

  useEffect(() => {
    setLoading(true);
    (async function fetchData() {
      try {
        const data = await getEstablishmentChangesRequest(id);
        const { establishmentInfo, establishmentEdits } = data.response;

        const mergeFields = (info, edits) => {
          return {
            ...info,
            ...Object.keys(edits).reduce((acc, key) => {
              acc[key] = edits[key];
              return acc;
            }, {}),
          };
        };

        const initialFormState = mergeFields(establishmentInfo, {});
        const finalFormStateData = mergeFields(establishmentInfo, establishmentEdits.changedFields);

        let fotoThumb = '';

        if (initialFormState.fotoThumb) {
          fotoThumb = await downloadImage('estabelecimento', initialFormState.fotoThumb)
            .then((result) => result)
            .catch(() => '');
        }

        setFormState({
          ...formState,
          fotoThumbFile: fotoThumb ? fotoThumb : '',
          fotoThumb: fotoThumb ? initialFormState.fotoThumb : '',
          listaFotos: initialFormState.listaFotos,
          nome: initialFormState.nome,
          descricao: initialFormState.descricao,
          tags: initialFormState.tags,
          email: initialFormState.email,
          cep: initialFormState.cep ? initialFormState.cep : '',
          logradouro: initialFormState.logradouro ? initialFormState.logradouro : '',
          numero: initialFormState.numero ? initialFormState.numero : '',
          complemento: initialFormState.complemento ? initialFormState.complemento : '',
          bairro: initialFormState.bairro ? initialFormState.bairro : '',
          cidade: initialFormState.cidade ? initialFormState.cidade : '',
          estado: initialFormState.estado ? initialFormState.estado : '',
          endereco: initialFormState.endereco ? initialFormState.endereco : '',
          lat: initialFormState.lat ? initialFormState.lat : 0,
          long: initialFormState.long ? initialFormState.long : 0,
          funcionamento: initialFormState.funcionamento ? initialFormState.funcionamento : [],
          disableDate: initialFormState.disableDate,
          site: initialFormState.site,
          contact: initialFormState.contatos ? initialFormState.contatos : [],
          contato: initialFormState.contatoTriibo ? initialFormState.contatoTriibo : [],
          contatosExcluido: initialFormState.contatoTriibo ? initialFormState.contatoTriibo : [],
          redesSociais: initialFormState.redesSociais ? initialFormState.redesSociais : [],
          redesSociaisExcluidas: initialFormState.redesSociais ? initialFormState.redesSociais : [],
          cashbackDefault: initialFormState.cashbackDefault
            ? typeof finalFormStateData.cashbackDefault === 'number'
              ? finalFormStateData.cashbackDefault
              : parseFloat(finalFormStateData.cashbackDefault.replace(',', '.'))
            : null,
        });

        let fotoThumbNew = '';

        if (finalFormStateData.fotoThumb) {
          fotoThumbNew = await downloadImage('estabelecimento', finalFormStateData.fotoThumb)
            .then((result) => result)
            .catch(() => '');
        }

        setNewFormState({
          ...newFormState,
          fotoThumbFile: fotoThumbNew ? fotoThumbNew : '',
          fotoThumb: fotoThumbNew ? finalFormStateData.fotoThumb : '',
          listaFotos: finalFormStateData.listaFotos,
          nome: finalFormStateData.nome,
          descricao: finalFormStateData.descricao,
          tags: finalFormStateData.tags,
          cep: finalFormStateData.cep ? finalFormStateData.cep : '',
          logradouro: finalFormStateData.logradouro ? finalFormStateData.logradouro : '',
          numero: finalFormStateData.numero ? finalFormStateData.numero : '',
          complemento: finalFormStateData.complemento ? finalFormStateData.complemento : '',
          bairro: finalFormStateData.bairro ? finalFormStateData.bairro : '',
          cidade: finalFormStateData.cidade ? finalFormStateData.cidade : '',
          estado: finalFormStateData.estado ? finalFormStateData.estado : '',
          endereco: finalFormStateData.endereco ? finalFormStateData.endereco : '',
          lat: finalFormStateData.lat ? finalFormStateData.lat : 0,
          long: finalFormStateData.long ? finalFormStateData.long : 0,
          funcionamentoAntigos: finalFormStateData.funcionamento
            ? finalFormStateData.funcionamento
            : [],
          email: finalFormStateData.email,
          disableDate: finalFormStateData.disableDate,
          contact: finalFormStateData.contatos ? finalFormStateData.contatos : [],
          site: finalFormStateData.site,
          cashbackDefault: finalFormStateData.cashbackDefault
            ? typeof finalFormStateData.cashbackDefault === 'number'
              ? finalFormStateData.cashbackDefault
              : parseFloat(finalFormStateData.cashbackDefault.replace(',', '.'))
            : null,
        });

        setFinalFormState({
          ...finalFormState,
          fotoThumbFile: fotoThumbNew ? fotoThumbNew : '',
          fotoThumb: fotoThumbNew ? finalFormStateData.fotoThumb : '',
          listaFotos: finalFormStateData.listaFotos,
          nome: finalFormStateData.nome,
          descricao: finalFormStateData.descricao,
          tags: finalFormStateData.tags,
          cep: finalFormStateData.cep ? finalFormStateData.cep : '',
          logradouro: finalFormStateData.logradouro ? finalFormStateData.logradouro : '',
          numero: finalFormStateData.numero ? finalFormStateData.numero : '',
          complemento: finalFormStateData.complemento ? finalFormStateData.complemento : '',
          bairro: finalFormStateData.bairro ? finalFormStateData.bairro : '',
          cidade: finalFormStateData.cidade ? finalFormStateData.cidade : '',
          estado: finalFormStateData.estado ? finalFormStateData.estado : '',
          endereco: finalFormStateData.endereco ? finalFormStateData.endereco : '',
          lat: finalFormStateData.lat ? finalFormStateData.lat : 0,
          long: finalFormStateData.long ? finalFormStateData.long : 0,
          funcionamento: finalFormStateData.funcionamento ? finalFormStateData.funcionamento : [],
          email: finalFormStateData.email,
          disableDate: finalFormStateData.disableDate,
          contatos: finalFormStateData.contatos ? finalFormStateData.contatos : [],
          site: finalFormStateData.site ? finalFormStateData.site : '',
          redesSociais: finalFormStateData.redesSociais ? finalFormStateData.redesSociais : [],
          contatoTriibo: finalFormStateData.contatoTriibo ? finalFormStateData.contatoTriibo : [],
          cashbackDefault: finalFormStateData.cashbackDefault
            ? typeof finalFormStateData.cashbackDefault === 'number'
              ? finalFormStateData.cashbackDefault
              : parseFloat(finalFormStateData.cashbackDefault.replace(',', '.'))
            : null,
        });

        let loadedPhotoList = [];

        if (initialFormState.listaFotos) {
          const photoPromises = initialFormState.listaFotos.map(async (item) => {
            const downloadedImage = await downloadImage('estabelecimento', item);
            return downloadedImage;
          });
          const photos = await Promise.allSettled(photoPromises);
          photos.forEach((photo) => {
            if (photo.status === 'fulfilled') {
              loadedPhotoList.push(photo.value);
            }
          });
        }
        setGalleryArrExcluded(loadedPhotoList);

        let loadedPhotoListExcluded = [];

        if (finalFormStateData.listaFotos) {
          const photoPromisesExcluded = finalFormStateData.listaFotos.map(async (item) => {
            const downloadedImage = await downloadImage('estabelecimento', item);
            return downloadedImage;
          });
          const photosExcluded = await Promise.allSettled(photoPromisesExcluded);
          photosExcluded.forEach((photo) => {
            if (photo.status === 'fulfilled') {
              console.log(photo.value);
              loadedPhotoListExcluded.push(photo.value);
            }
          });
        }
        setGalleryArr(loadedPhotoListExcluded);

        const sameGallery = JSON.stringify(galleryArr) === JSON.stringify(galleryArrExcluded);
        sameGallery && setGalleryArrExcluded(['']);

        console.log('finnalFormStateData', finalFormStateData);

        if (finalFormStateData.endereco === 'Brasil') {
          setChecked(false);
          setFinalFormState((prevState) => ({
            ...prevState,
            endereco: 'Brasil',
          }));
        } else {
          setChecked(true);
        }

        setLoading(false);
      } catch (error) {
        console.error('Erro ao obter dados do estabelecimento:', error);
        setLoading(false);
        setErrorGettingData(true);
      }
    })();
  }, []);

  // console.log('formState', formState);
  // console.log('newFormState', newFormState);
  // console.log('finalFormState', finalFormState);

  if (loading) {
    return (
      <>
        <LoadingTableClube>
          <CircularProgress size="120px" />
        </LoadingTableClube>
      </>
    );
  }
  return (
    <>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
        }}
      >
        <S.GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </S.GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        Editar Estabelecimento
      </D.TriiboH1>
      {errorGettingData ? (
        <D.TriiboH4
          style={{
            textAlign: 'center',
            paddingTop: '17rem',
          }}
        >
          Erro ao obter dados do estabelecimento
        </D.TriiboH4>
      ) : (
        <>
          <StepOneCuration
            checked={checked}
            formState={formState}
            setChecked={setChecked}
            newFormState={newFormState}
            finalFormState={finalFormState}
            setFinalFormState={setFinalFormState}
          />

          <StepTwoCuration
            formState={formState}
            setFormState={setFormState}
            newFormState={newFormState}
            setNewFormState={setNewFormState}
            finalFormState={finalFormState}
            setFinalFormState={setFinalFormState}
          />

          <StepThreeCuration
            id={id}
            estThumb={estThumb}
            formState={formState}
            galleryArr={galleryArr}
            setEstThumb={setEstThumb}
            newFormState={newFormState}
            setGalleryArr={setGalleryArr}
            finalFormState={finalFormState}
            setFinalFormState={setFinalFormState}
            galleryArrExcluded={galleryArrExcluded}
            setGalleryArrExcluded={setGalleryArrExcluded}
          />
          <S.SaveButton>
            <LoadingButton
              sx={{ backgroundColor: '#328796' }}
              onClick={(e) => checkForm(e)}
              loading={loadingSave}
              loadingPosition="end"
              endIcon={<IoMdSave />}
              variant="contained"
            >
              <span>Salvar</span>
            </LoadingButton>
          </S.SaveButton>
        </>
      )}
    </>
  );
};

export default EstablishmentCuration;

