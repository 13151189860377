import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Menu,
  MenuItem,
  Modal,
  IconButton,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  CircularProgress,
  Box,
  Button,
  Alert,
  Container,
} from '@mui/material';
import { toast } from 'react-toastify';

import { Delete, Edit, Cancel, Sms } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import InputMask from 'react-input-mask';

import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';
import { cellPhoneMask2, randomizeNumbersString, getUserInfo } from 'js/library/utils/helpers';

import ValidatorsInformation from 'js/components/Validators/ValidatorsInformation';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import addAdminEstablishment from 'js/library/utils/API/Establishment/apiAddAdminEstablishment';
import addSmsDispatch from 'js/library/utils/API/Establishment/apiSendSmsDispatch';
import deleteAdminEstablishment from 'js/library/utils/API/Establishment/deleteAdminEstablishment';
import editAdminEstablishment from 'js/library/utils/API/Establishment/apiEditAdminEstablishment';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { RegisterUserModal } from '../Establishment/RelationshipScreen/RegisterUserModal';

function getModalStyle(theme) {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    backgroundColor: 'white',
    width: 800,
    maxWidth: '90%',
    maxHeight: '90%',
    padding: '50px 20px 30px 20px',
    boxSizing: 'border-box',
    overflowY: 'auto',
  };
}

const modalStyles = {
  display: 'flex',
  width: '100%',
  gap: '20px',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '3rem 2rem',
};

export default function AdminConfigurationExternal(props) {
  const superUser = useSelector((state) => state.authModel.superUser);
  const triiboId = getUserInfo().triiboId;

  const [admins, setAdmins] = useState(props.location.state.admins ?? []);
  const establishmentId = props.match.params.id;
  const establishmentName = props.location.state.nome;
  const idGestor = props.location.state.idGestor;
  const [administrador, setAdministrador] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [uid, setUid] = useState('');
  const [userFound, setUserFound] = useState(null);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');
  const [openModalRegisterUser, setOpenModalRegisterUser] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [openValidatorModal, setOpenValidatorModal] = useState(false);
  const [validatorName, setValidatorName] = useState('');

  const [validatorEmail, setValidatorEmail] = useState('');

  useEffect(() => {
    getEstablishmentId(establishmentId)
      .then((resp) => {
        setAdmins(resp.admins ?? []);
      })
      .catch((error) => {
        // console.log('Erro ao obter lista de administradores:', error);
      });
  }, []);

  const searchUser = (cellphone) => {
    setLoadingSearchUser(true);
    setUserFound(null);
    setNome('');
    setTelefone('');
    setUid('');

    if (cellphone.length > 3) {
      const formattedCellphone = currentDDD + cellphone.replace(/\D+/g, '');

      if (admins.length > 0 && admins.find((i) => i.telefone === formattedCellphone)) {
        setLoadingSearchUser(false);
        toast.error('Telefone digitado já está na equipe.');
      } else {
        userReadCellphone_v3(formattedCellphone)
          .then((verification) => {
            if (verification && verification.userInfo) {
              // Existing user found
              setNome(verification.userInfo.name);
              setTelefone(formattedCellphone);
              setUid(verification.userInfo.uId);
              setUserFound(true);
              setLoadingSearchUser(false);
            } else {
              setLoadingSearchUser(true);
              setOpenModalRegisterUser(true);
            }
          })
          .catch((error) => {
            console.error('Erro ao buscar informação do usuário:', error);
            setLoadingSearchUser(false);
            toast.error('Erro ao buscar informação do usuário.');
          });
      }
    } else {
      setLoadingSearchUser(false);
      toast.error('Digite o telefone do usuário.');
    }
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
    setOpenModal(false);
  };

  const handleSendSms = async (cell, uid) => {
    setSendingSMS(`true-${cell}`);
    const admin = {
      telefone: cell,
      uid: uid,
    };

    try {
      await addSmsDispatch(admin, establishmentId);

      setSendingSMS(false);
      setLoading(false);
      toast.success('SMS enviado com sucesso.');
    } catch (error) {
      // Handle error
      setLoading(false);
      toast.error('Erro ao tentar enviar SMS.');
    }
  };

  const addAdmin = (type) => {
    setLoading(true);

    const admin = {
      cargo: type === 'atendente' ? 'attendant' : type,
      nome: nome,
      telefone: telefone,
      uid: uid,
    };

    addAdminEstablishment(admin, establishmentId)
      .then(() => {
        setAdmins((prevAdmins) => [...prevAdmins, admin]);
        setLoading(false);
        setNome('');
        setTelefone('');
        setUid('');
        toast.success('Usuário adicionado com sucesso.');
        setUserFound(null);
        setOpenModal(false);
        setAdministrador('');
      })
      .catch((error) => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Erro ao tentar adicionar o tipo de cargo';

        setLoading(false);

        toast.error(message);
      });
  };

  const changeAdmin = async (idx, type) => {
    try {
      setAnchorEl(false);
      setLoading(true);

      let newAdmins = admins;
      newAdmins[idx].cargo = type;

      await editAdminEstablishment(newAdmins[idx], establishmentId);

      setAdmins(newAdmins);
      setLoading(false);
    } catch (error) {
      console.error('Erro em changeAdmin:', error);

      setLoading(false);
      toast.error('Erro ao tentar alterar o cargo do administrador.');
    }
  };

  const deleteAdmin = (idx) => {
    setLoading(true);

    let newAdmins = admins;
    let oldAdmin = newAdmins.splice(idx, 1);

    deleteAdminEstablishment(oldAdmin[0], establishmentId)
      .then(() => {
        setLoading(false);
        setAdmins(newAdmins);
        setSelectedIndex(null);

        toast.success('Usuário removido com sucesso.');
      })
      .catch((error) => {
        console.error('Erro em deleteAdmin (updateDatabase):', error);
        setLoading(false);

        toast.error('Erro ao tentar remover informação na lista de validadores.');
      });
  };

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');

    setCellphone('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    // setFullMask(valuesArr[2]);
    setCurrentCountry(valuesArr[3]);
  };

  if (loading)
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  else {
    return (
      <div style={{ padding: '4rem 32px' }}>
        <GoBackHeader />
        <HeaderTitle pageInfo="Gerenciar Equipe" />
        <Box
          sx={{
            padding: '2.336rem 2rem',
            maxWidth: '100%',
          }}
        >
          <Grid container>
            <Grid item container xs={12} direction="row" justifyContent="right">
              <Button
                title="Adicionar Usuário"
                variant="contained"
                color="primary"
                onClick={() => {
                  setUserFound(null);
                  setOpenModal(true);
                }}
              >
                Adicionar Usuário
              </Button>
            </Grid>
          </Grid>

          {admins.length > 0 ? (
            <div>
              <Table style={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead
                  style={{
                    marginBottom: '2em',
                    backgroundColor: '#fafafa',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  <TableRow style={{ minWidth: '100%' }}>
                    <TableCell
                      style={{
                        fontWeight: '550',
                        borderBottom: 0,
                      }}
                      align="left"
                    >
                      Nome
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: '550',
                        borderBottom: 0,
                      }}
                      align="center"
                    >
                      Telefone
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: '550',
                        borderBottom: 0,
                      }}
                      align="center"
                    >
                      Cargo
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: '550',
                        borderBottom: 0,
                      }}
                      align="center"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins?.map((item, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell align="left" style={{ textTransform: 'capitalize' }}>
                          {item.nome}
                        </TableCell>

                        <TableCell align="center">{cellPhoneMask2(item.telefone)}</TableCell>

                        <TableCell align="center" style={{ textTransform: 'capitalize' }}>
                          {item.cargo}
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            title="Enviar SMS"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={() => handleSendSms(item.telefone, item.uid)}
                            disabled={sendingSMS === `true-${item.telefone}`}
                          >
                            {sendingSMS === `true-${item.telefone}` ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Sms />
                            )}
                          </Button>
                          <Button
                            title="QRCode e editar cracha"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={() => {
                              setValidatorName(item.nome);
                              setValidatorEmail(`${item.telefone}@sms,triibo,com,br`);
                              setOpenValidatorModal(true);
                            }}
                          >
                            <svg
                              viewBox="0 0 448 512"
                              style={{
                                height: '1.5em',
                                width: '1.5em',
                                padding: '1.5px 0px',
                              }}
                            >
                              <path
                                fill="currentColor"
                                d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"
                              />
                            </svg>
                          </Button>

                          <Button
                            title="Editar Cargo"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                              setSelectedIndex(idx);
                              setAnchorEl(event.currentTarget);
                            }}
                          >
                            <Edit />
                          </Button>

                          {superUser || idGestor === triiboId ? (
                            <Button
                              title="Excluir"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={() => deleteAdmin(idx)}
                            >
                              <Delete />
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(false)}
                  >
                    {selectedIndex !== null &&
                      admins[selectedIndex].cargo !== 'gestor' &&
                      superUser &&
                      admins.some((el) => el.cargo === 'gestor') === false && (
                        <MenuItem
                          onClick={() => changeAdmin(selectedIndex, 'gestor')}
                          disabled={admins && admins.some((el) => el.cargo === 'gestor')}
                        >
                          Tornar Gestor
                        </MenuItem>
                      )}

                    {selectedIndex !== null && admins[selectedIndex].cargo !== 'administrador' && (
                      <MenuItem onClick={() => changeAdmin(selectedIndex, 'administrador')}>
                        Tornar Administrador
                      </MenuItem>
                    )}

                    {selectedIndex !== null && admins[selectedIndex].cargo !== 'validador' && (
                      <MenuItem onClick={() => changeAdmin(selectedIndex, 'validador')}>
                        Tornar Atendente
                      </MenuItem>
                    )}
                  </Menu>
                </TableBody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                marginTop: '150px',
                marginBottom: '50px',
                textAlign: 'center',
              }}
            >
              Não há administradores cadastrados
            </div>
          )}

          {openRegisterModal && (
            <RegisterUserModal
              isModalRegisterUserOpen={openRegisterModal}
              dataCellphone={cellphone}
              handleCloseModalRegisterUser={() => {
                setOpenModalRegisterUser(false);
                setOpenRegisterModal(false);
                setCellphone('');
              }}
            />
          )}

          {openValidatorModal && (
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={openValidatorModal}
              onClose={() => {
                setOpenValidatorModal(false);
                setValidatorName('');
                setValidatorEmail('');
              }}
            >
              <div
                style={Object.assign(getModalStyle(), {
                  width: '75%',
                  overflow: 'auto',
                })}
              >
                <ValidatorsInformation
                  state={{
                    nome: validatorName,
                    identificacao1: validatorName,
                    email: validatorEmail,
                    idEstabelecimento: establishmentId,
                    nomeEstabelecimento: establishmentName,
                    idGestor: idGestor,
                  }}
                />
              </div>
            </Modal>
          )}

          {/* MODAL DE ADIÇÃO DE USUÁRIO */}
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openModal}
            onClose={() => {
              setOpenModal(false);
              setUserFound(null);
              setNome('');
              setTelefone('');
              setUid('');
              setAdministrador('');
            }}
          >
            <div style={getModalStyle()}>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                  setUserFound(null);
                  setNome('');
                  setTelefone('');
                  setUid('');
                  setAdministrador('');
                  setCellphone('');
                  setOpenModalRegisterUser(false);
                }}
                edge="end"
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  padding: '0',
                  margin: '0.5em',
                }}
              >
                <Cancel />
              </IconButton>

              {/* Busca de usuário */}
              {userFound !== false && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: '0' }}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="primary"
                      style={{ fontWeight: '600' }}
                    >
                      Adicionar Usuário
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '1em' }}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        searchUser(administrador);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: '1rem',
                          border: ' 1px solid #afafaf',
                          borderRadius: '4px',
                          height: '42px',
                          width: '100%',
                        }}
                      >
                        <select
                          style={{
                            fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                            height: '100%',
                            border: 'none',
                            background: 'none',
                            outline: 'none',
                            fontSize: '17px',
                            cursor: 'pointer',
                          }}
                          value={currentCountry}
                          onChange={handleCountryChange}
                        >
                          {phoneMaskArray.map((country, index) => (
                            <option
                              style={{
                                fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                              }}
                              key={country.id}
                              value={[country.ddd, country.mask, country.fullMask]}
                            >
                              {country.emoji} {country.initials} {country.ddd}
                            </option>
                          ))}
                        </select>
                        <InputMask
                          style={{
                            fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                            alignContent: 'center',
                            height: '100%',

                            border: 'none',
                            outline: 'none',
                            fontSize: '17px',
                            color: '#383838d6',
                            borderRadius: '0px',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            width: '100%',
                          }}
                          mask={inputMask}
                          value={administrador}
                          placeholder={randomizeNumbersString(inputMask)}
                          onChange={(e) => setAdministrador(e.target.value)}
                        />
                      </div>
                      <br />

                      {loadingSearchUser ? (
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </Grid>
                      ) : (
                        userFound === null && (
                          <Grid item xs={12} style={{ marginTop: '1em' }}>
                            <Button
                              style={{
                                display: 'block',
                                margin: '0 auto',
                                color: 'white',
                                textTransform: 'capitalize',
                                fontWeight: '550',
                              }}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                searchUser(administrador);
                                setCellphone(administrador);
                              }}
                              disabled={loadingSearchUser}
                            >
                              {loadingSearchUser ? <CircularProgress /> : 'Adicionar'}
                            </Button>
                          </Grid>
                        )
                      )}
                    </form>
                  </Grid>
                  {openModalRegisterUser ? (
                    <Container sx={modalStyles}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ fontWeight: '600', color: '#FF0000' }}
                      >
                        Telefone digitado não possui cadastro!
                      </Typography>
                      <Button
                        style={{
                          display: 'block',
                          margin: '0 auto',
                          color: 'white',
                          textTransform: 'capitalize',
                          fontWeight: '550',
                          width: '50%',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenRegisterModal()}
                      >
                        Criar Cadastro
                      </Button>
                    </Container>
                  ) : (
                    <>
                      <Grid item xs={12} md={superUser ? 4 : 6} style={{ marginTop: '1em' }}>
                        <Grid container>
                          {userFound === true && (
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="secondary"
                                style={{
                                  fontWeight: '600',
                                  textAlign: 'center',
                                }}
                              >
                                Nome
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="secondary"
                                style={{ textAlign: 'center' }}
                              >
                                {nome}
                              </Typography>
                            </Grid>
                          )}

                          {userFound === true && (
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="secondary"
                                style={{
                                  fontWeight: '600',
                                  textAlign: 'center',
                                }}
                              >
                                Telefone
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="secondary"
                                style={{ textAlign: 'center' }}
                              >
                                {telefone}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={superUser ? 8 : 6} style={{ marginTop: '1em' }}>
                        <Grid container justify="space-between" spacing={2}>
                          {userFound === true && (
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              md={superUser ? 4 : 6}
                              style={{ marginTop: '1em' }}
                            >
                              <Button
                                style={{
                                  display: 'block',
                                  width: '100%',
                                  margin: '0 auto',
                                  color: 'white',
                                  textTransform: 'capitalize',
                                  fontWeight: '550',
                                }}
                                variant="contained"
                                color="primary"
                                //atualiza type de usuário administrador
                                onClick={() => addAdmin('administrador')}
                              >
                                Administrador
                              </Button>
                            </Grid>
                          )}

                          {userFound === true && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={superUser ? 4 : 6}
                              style={{ marginTop: '1em' }}
                            >
                              <Button
                                style={{
                                  display: 'block',
                                  width: '100%',
                                  margin: 'auto',
                                  color: 'white',
                                  textTransform: 'capitalize',
                                  fontWeight: '550',
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => addAdmin('validador')}
                              >
                                Atendente
                              </Button>
                            </Grid>
                          )}

                          {userFound === true &&
                            (admins.length === 0 || !admins.find((i) => i.cargo === 'Gestor')) && (
                              <Grid item xs={12} sm={6} md={4} style={{ marginTop: '1em' }}>
                                <Button
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    margin: '0 auto',
                                    color: 'white',
                                    textTransform: 'capitalize',
                                    fontWeight: '550',
                                  }}
                                  variant="contained"
                                  color="primary"
                                  disabled={admins && admins.some((el) => el.cargo === 'gestor')}
                                  onClick={() => addAdmin('gestor')}
                                >
                                  Gestor
                                </Button>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </div>
          </Modal>
        </Box>
      </div>
    );
  }
}

