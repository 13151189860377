import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LPModel from './model.js';
import routes from './routes.js';

// STYLES
import { Box, Button, CircularProgress, Snackbar, Tooltip } from '@mui/material';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import * as E from 'js/components/DesignSystem/Table/styles';

// ICONS
import searchIcon from 'styles/assets/SearchUser/search.svg';
import { MdArrowBackIosNew } from 'react-icons/md';
import { AreaHeader, GoBack } from '../../styles';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// COMPONENTS
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import CustomButtonsTableApiPaginated from 'js/components/TriiboComponents/Tables/CustomButtonsTable/CustomButtonsTableApiPaginated';

// APIS
import { getSeuClube } from 'js/library/utils/API/seuClube';

export const LandingPageList = () => {
  const [partnerId, setPartnerId] = useState('');
  const [loading, setLoading] = useState(true);
  const [clubInfo, setClubInfo] = useState([]);
  const [landingPageList, setLandingPageList] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  const params = useParams();
  const history = useHistory();

  const clubId = params?.id;

  const [data, setData] = useState({
    size: 20,
    from: 0,
  });

  const [itemsPerPage, setItemsPerPage] = useState(0);

  const column = [
    {
      heading: 'Título',
      value: 'title',
    },
    {
      heading: 'ID da campanha',
      value: 'campaignId',
    },
    {
      heading: 'Nome Público',
      value: 'publicName',
    },
  ];

  const getPartnerId = async () => {
    const clubInfos = await getSeuClube(clubId);

    setClubInfo(clubInfos);
    setPartnerId(clubInfos?.partnerId);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (partnerId) {
        try {
          const landingPages = await LPModel.getLandingPages(
            partnerId,
            searchTerm,
            data.from,
            data.size
          );
          setLandingPageList((prevState) => [...prevState, ...landingPages]);
          setItemsPerPage(landingPages.length);
        } catch (e) {
          console.error('Erro ao buscar landing pages:', e);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [partnerId, data]);

  const handleSearch = async (term) => {
    if (term.length > 1) {
      setLoading(true);
      try {
        const response = await LPModel.getLandingPages(partnerId, term, 0, 1000);
        setFilteredCampaigns(response);
      } catch (e) {
        console.error('Erro ao buscar dados:', e);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (searchTerm.length <= 3) {
      setFilteredCampaigns([]);
    }
  }, [searchTerm]);

  // Atualiza o estado `debouncedSearchTerm` após 1 segundo de inatividade
  useEffect(() => {
    if (searchTerm.length > 1) {
      const handler = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm);
      }, 1000); // Aguarda 1 segundo

      return () => {
        clearTimeout(handler); // Limpa o timeout se o usuário continuar digitando
      };
    }
  }, [searchTerm]);

  // Chama a API quando o termo com debounce é atualizado
  useEffect(() => {
    if (debouncedSearchTerm && searchTerm.length > 1) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleCopyUrl = async (publicName) => {
    const linkToCopy =
      clubId === 'clubmapfre'
        ? `https://club.mapfre.com.br/landing-page/?id=${publicName}`
        : `https://${clubInfo.clubeId}${clubInfo.url}/lp/${publicName}`;
    try {
      await navigator.clipboard.writeText(linkToCopy);
      setOpenSnackbar(true);
    } catch (err) {
      console.error('Falha ao copiar: ', err);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const goToLPSignUp = (LP) =>
    history?.push(`${routes.LPSignUpPage}/${clubId}`, LP.id ? LP : { partnerId });

  useEffect(() => {
    if (clubId) getPartnerId();
  }, [clubId]);

  useEffect(() => {
    setTimeout(() => {
      setMessage(true);
    }, 2000);
  }, []);

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <E.TableTH>Ações</E.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <E.TableTD>
              <Tooltip title="Editar" placement="top" style={{ marginRight: '1.2rem' }}>
                <Button
                  variant="contained"
                  onClick={() => goToLPSignUp(childrens)}
                  style={{ backgroundColor: '#6e3296', marginRight: '10px' }}
                >
                  <EditRoundedIcon size={25} />
                </Button>
              </Tooltip>

              <Tooltip title="Copiar Link" placement="top" style={{ marginRight: '1.2rem' }}>
                <Button variant="contained" onClick={() => handleCopyUrl(childrens.publicName)}>
                  <ContentCopyRoundedIcon size={25} />
                </Button>
              </Tooltip>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message="Link copiado!"
                autoHideDuration={2000}
              />
            </E.TableTD>
          </>
        ),
    };
  };

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{
          alignItems: 'flex-end',
          display: 'flex',
          rowGap: '60px',
          flexDirection: 'column',
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container style={{ marginTop: '2rem' }}>
        <D.TriiboH1>Lista de Landing Pages</D.TriiboH1>
        <S.BoxSearch>
          <S.InputBox>
            <S.Input
              placeholder="Pesquise por uma landing page..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
            <S.SearchIconButton type="button" onClick={(e) => e.preventDefault()}>
              <img src={searchIcon} alt="" />
            </S.SearchIconButton>
          </S.InputBox>
        </S.BoxSearch>
        <Box display={'flex'} justifyContent={'right'} width={'100%'}>
          <DashedButton width={'200px'} onClick={goToLPSignUp}>
            + Adicionar LP
          </DashedButton>
        </Box>

        {loading ? (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : landingPageList?.length > 0 || (searchTerm && filteredCampaigns?.length > 0) ? (
          <CustomButtonsTableApiPaginated
            data={searchTerm.length > 1 ? filteredCampaigns : landingPageList}
            column={column}
            perPage={10}
            setData={setData}
            itemsPerPage={itemsPerPage}
            size={data.size}
            componentReceiver={componentReceiver}
            loadingTableDisabled={true}
            searchTerm={searchTerm}
          />
        ) : (
          message && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <p>Nenhuma campanha encontrada.</p>
            </Box>
          )
        )}
      </S.Container>
    </div>
  );
};
