const textAlignOptions = [
  {
    option: 'Esquerda',
    align: 'left',
  },
  {
    option: 'Centro',
    align: 'center',
  },
  {
    option: 'Direita',
    align: 'right',
  },
];

export default { textAlignOptions };
