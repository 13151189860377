import axios from 'axios';
import authToken from './authToken';
import cfac22 from '../cfac22';

export function getEstablishment(establishmentId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/establishments/${establishmentId}`, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          resolve(result.data.establishmentInfo);
        })
        .catch((error) => {
          return reject(error.response.data);
        });
    });
  });
}
