import axios from 'axios';

import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import { getUserInfo } from '../helpers';
import cfac22 from '../cfac22';

export function getStorePlaces_v2(
  uId,
  objectType,
  lat,
  long,
  userQuery,
  establishmentId,
  query,
  from,
  size
) {
  //OBTER INFORMAÇÕES DO USUÁRIO
  return new Promise((resolve, reject) => {
    const loggedUser = getUserInfo().uId;
    getChannelToken_v1(loggedUser)
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);
        axios
          .post(
            cfac22('API_HOST_V1') + 'getStorePlaces_v2',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId: loggedUser,
                channelTokenId: result,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              objectType: objectType,
              order: true,
              geoFocus: {
                lat: lat,
                long: long,
              },
              flagGoogle: false,
              geoRadius: 10000,
              platform: 'web',
              from: from,
              size: size,
              userQuery: userQuery,
              establishmentId: establishmentId,
              systemQuery: query,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then((result) => {
            //console.log('RESULTADO STORE PLACES ===>', result);
            resolve(result.data.success);
          })
          .catch((error) => {
            //console.log('ERROR STORE PLACES ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
