import React, { useState, useEffect } from 'react';

import { createFeedContent } from 'js/library/utils/API/apiCreateFeedContent';
import { updateFeedContent } from 'js/library/utils/API/apiUpdateFeedContent';

import {
  convertDateToTimestamp,
  getBase64FromUrl,
  separateBase64String,
} from 'js/library/utils/helpers';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import ListTemplate from '../ListTemplate';
import TagsDisplay from 'js/components/DesignSystem/TagsDisplay';
import TagsAdd from 'js/components/DesignSystem/TagsAdd';
import { useHistory } from 'react-router-dom';
import TagsAddUF from 'js/components/DesignSystem/TagsAddUF';
import cfac22 from 'js/library/utils/cfac22';
import {
  Switch,
  FormControlLabel,
  CircularProgress,
  Chip,
  MenuItem,
  FormControl,
  Select,
  Box,
  OutlinedInput,
} from '@mui/material';
import { getSegmentCustomization } from 'js/library/utils/API/Segmentation/getSegmentCustomization';
import calendarIcon from 'styles/assets/communications/calendarPurple.svg';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { UF } from '../uf';
import EditPreview from '../EditPreview';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { downloadImage } from 'js/library/services/StorageManager';

function addProtocolIfNeeded(url) {
  if (!url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}

export default function StripeBanner(props) {
  const { preForm } = props;

  const [formData, setFormData] = useState({
    disableDate: preForm ? convertDateToTimestamp(preForm.disableDate) : '',
    enableDate: preForm ? convertDateToTimestamp(preForm.enableDate) : '',
    stripeBannerDesktop: preForm ? (preForm.segmentStripe ? '' : preForm.images.desktop) : null,
    stripeBannerDesktopBlob: '',
    stripeBannerMobile: preForm ? (preForm.segmentStripe ? '' : preForm.images.mobile) : null,
    stripeBannerMobileBlob: '',
    url: preForm ? preForm.url : '',
    regionFilter: preForm ? (preForm.regionFilter ? preForm.regionFilter : false) : false,
    filterType: preForm ? (preForm.filterType ? preForm.filterType : 'city') : 'city',
    filterValue: preForm ? (preForm.filterValue ? preForm.filterValue : []) : [],
    segmentIds: preForm ? preForm.segmentIds : [],
    status: preForm ? preForm.status : false,
    trackingId: preForm ? preForm.trackingId : '',
    segmentStripe: preForm ? preForm.segmentStripe : false,
    isExternalLink: preForm ? preForm.isExternalLink : false,
  });

  const [data, setData] = useState([]);
  const [indexItem, setIndexItem] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const partnerId = cfac22('PARTNER_ID_MAPFRE');
  const [segments, setSegments] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (preForm && !formData.segmentStripe) {
        try {
          const storageInfoDesktop = formData.stripeBannerDesktop.split('/');
          const storageInfoMobile = formData.stripeBannerMobile.split('/');

          const [desktopImageBlob, mobileImageBlob] = await Promise.all([
            downloadImage(storageInfoDesktop[0], storageInfoDesktop[1]),
            downloadImage(storageInfoMobile[0], storageInfoMobile[1]),
          ]);

          const [desktopBase64, mobileBase64] = await Promise.all([
            desktopImageBlob ? getBase64FromUrl(desktopImageBlob) : null,
            mobileImageBlob ? getBase64FromUrl(mobileImageBlob) : null,
          ]);

          setFormData((prev) => ({
            ...prev,
            stripeBannerDesktopBlob: desktopBase64,
            stripeBannerMobileBlob: mobileBase64,
          }));
        } catch (err) {
          console.error('Erro ao carregar imagens:', err);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        if (!partnerId) return;

        const segmentData = await getSegmentCustomization({ partnerId });

        const segmentList = Object.values(segmentData.response || {});

        setSegments(
          segmentList.map((segment) => ({
            id: segment.segmentId || '',
            name: segment.name || '',
          }))
        );
      } catch (error) {
        console.error('Erro ao carregar segmentos:', error.message || error);
      }
    };
    fetchSegments();
  }, [partnerId]);

  const handleChangeSegment = (event) => {
    const selectedId = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      segmentIds: selectedId,
    }));
  };

  const handleChangeTrackingId = (e) => {
    setFormData({ ...formData, trackingId: e.target.value.replace(/\s/g, '') });
  };

  const handleChangeUrl = (e) => {
    setFormData({ ...formData, url: e.target.value });
  };

  const handleChangeListType = (e) => {
    setFormData({ ...formData, filterType: e.target.value, filterValue: [] });
  };

  const handleChangeEnableDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, enableDate: date });
  };

  const handleChangeDisableDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, disableDate: date });
  };

  const uploadingImages = async (file, fileName) => {
    return new Promise(async (resolve, reject) => {
      const newFile = separateBase64String(file);

      uploadFiles('homeMapfre', {
        mimeType: newFile.mimeType,
        fileName: fileName,
        buffer: newFile.buffer,
      })
        .then((res) => {
          const response = res.split('/');
          const path = 'homeMapfre/' + response[response.length - 1].split('?')[0];
          resolve(path);
        })
        .catch(() => {
          reject('');
        });
    });
  };

  const handleAddStripeBanner = () => {
    if (
      !formData.enableDate ||
      !formData.disableDate ||
      (!formData.segmentStripe &&
        (!formData.stripeBannerDesktopBlob ||
          !formData.stripeBannerMobileBlob ||
          !formData.url ||
          !formData.trackingId))
    ) {
      return toast.error('Preencha todos os dados obrigatórios.');
    }

    if (formData.enableDate > formData.disableDate) {
      return toast.error('Data de ativação é maior que a de desativação.');
    }

    setData([...data, formData]);
    setFormData({
      disableDate: '',
      enableDate: '',
      stripeBannerDesktop: '',
      stripeBannerDesktopBlob: '',
      stripeBannerMobile: null,
      stripeBannerMobileBlob: '',
      segmentIds: [],
      url: '',
      status: false,
      regionFilter: false,
      trackingId: '',
      segmentStripe: false,
      isExternalLink: false,
    });
  };

  const handleUpdateStripeBanner = async () => {
    setIsUpdating(true);
    let newObj = cloneDeep(formData);

    for (let key in newObj) {
      if (!newObj[key] || (Array.isArray(newObj[key]) && newObj[key].length <= 0)) {
        delete newObj[key];
      }
    }

    if (formData.isExternalLink && formData.url) {
      newObj.url = addProtocolIfNeeded(newObj.url);
    }

    const resolved = new Promise(async (resolved, rejected) => {
      let stripeBannerDesktopPath;
      let stripeBannerMobilePath;

      if (!formData.segmentStripe) {
        stripeBannerDesktopPath = await uploadingImages(
          newObj.stripeBannerDesktopBlob,
          'stripeBannerDesktop'
        );
        stripeBannerMobilePath = await uploadingImages(
          newObj.stripeBannerMobileBlob,
          'stripeBannerMobile'
        );
      }

      if (stripeBannerDesktopPath && stripeBannerMobilePath) {
        const {
          stripeBannerDesktopBlob,
          stripeBannerMobileBlob,
          stripeBannerDesktop,
          stripeBannerMobile,
          ...rest
        } = newObj;

        return resolved({
          ...rest,
          images: {
            desktop: stripeBannerDesktopBlob ? stripeBannerDesktopPath : stripeBannerDesktop,
            mobile: stripeBannerMobileBlob ? stripeBannerMobilePath : stripeBannerMobile,
          },
          id: preForm.id,
          type: preForm.type,
          status: formData.status,
          segmentStripe: formData.segmentStripe,
        });
      } else {
        const { ...rest } = newObj;
        return resolved({
          ...rest,
          id: preForm.id,
          type: preForm.type,
          status: formData.status,
          segmentStripe: formData.segmentStripe,
        });
      }
    });

    const res = await resolved;
    const filtered = (element) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      if (element.url === '') {
        delete element.url;
      }

      if (element.segmentStripe) {
        delete element.images;
        delete element.segmentIds;
        delete element.stripeBannerDesktop;
        delete element.stripeBannerMobile;
        delete element.stripeBannerMobileBlob;
        delete element.stripeBannerDesktopBlob;
      }

      return element;
    };

    updateFeedContent([filtered(res)])
      .then(() => {
        toast.success('Feed atualizado com sucesso!');
        setIsUpdating(false);
        setTimeout(() => {
          history.goBack();
        }, 1000);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  const handleEditStripeBanner = () => {
    if (
      !formData.enableDate ||
      !formData.disableDate ||
      (!formData.segmentStripe &&
        (!formData.stripeBannerDesktopBlob ||
          !formData.stripeBannerMobileBlob ||
          !formData.url ||
          !formData.trackingId))
    ) {
      return toast.error('Preencha todos os dados obrigatórios.');
    }

    if (formData.enableDate > formData.disableDate) {
      return toast.error('Data de ativação é maior que a de desativação.');
    }

    setData((prevData) => {
      const copyData = [...prevData];
      copyData[indexItem] = formData;

      return copyData;
    });
    setFormData({
      disableDate: '',
      enableDate: '',
      stripeBannerDesktop: '',
      stripeBannerDesktopBlob: '',
      stripeBannerMobile: null,
      stripeBannerMobileBlob: '',
      segmentIds: [],
      url: '',
      status: false,
      regionFilter: false,
      trackingId: '',
      segmentStripe: false,
      isExternalLink: false,
    });

    setIndexItem(null);
  };

  const handleRemoveStripeBanner = (index) => {
    setData((prevData) => {
      const copyData = [...prevData];
      copyData.splice(index, 1);
      return copyData;
    });
  };

  const handleSubmitStripeBanners = async () => {
    setIsUpdating(true);

    let resolved = await Promise.all(
      data.map(async (item) => {
        let stripeBannerMobilePath;
        let stripeBannerDesktopPath;

        if (!item.segmentStripe) {
          try {
            stripeBannerDesktopPath = await uploadingImages(
              item.stripeBannerDesktopBlob,
              'stripeBannerDesktop'
            );

            stripeBannerMobilePath = await uploadingImages(
              item.stripeBannerMobileBlob,
              'stripeBannerMobile'
            );
          } catch (error) {
            console.error('Erro ao processar imagens:', error);
            return null;
          }
        }

        if (stripeBannerDesktopPath && stripeBannerMobilePath) {
          const {
            stripeBannerDesktopBlob,
            stripeBannerMobileBlob,
            stripeBannerDesktop,
            stripeBannerMobile,
            ...rest
          } = item;

          return {
            ...rest,
            images: {
              desktop: stripeBannerDesktopPath,
              mobile: stripeBannerMobilePath,
            },
          };
        } else {
          return {
            trackingId: item.trackingId,
            type: item.type,
            status: item.status,
            segmentStripe: item.segmentStripe,
            enableDate: item.enableDate,
            disableDate: item.disableDate,
            regionFilter: item.regionFilter,
          };
        }
      })
    );

    //enviar para api o resolved
    const segmentItem = resolved.map((element) => {
      if (!element?.segmentIds || element?.segmentIds.length === 0) {
        delete element.segmentIds;
      }
      return element;
    });

    const filtered = resolved.map((element) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      if (element.url === '') {
        delete element.url;
      }
      if (element.segmentStripe) {
        delete element.images;
      }
      return element;
    });
    createFeedContent('stripeBanner', filtered, segmentItem)
      .then((res) => {
        toast.success('Feed atualizado com sucesso');
        setIsUpdating(false);
        setTimeout(() => {
          history.goBack();
        }, 1000);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  return (
    <S.Container>
      {preForm && (
        <AreaHeader
          style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>
      )}
      <S.Content>
        <S.AddBannerContainer>
          <D.TriiboH3>
            <strong>Banner de Tira</strong>
          </D.TriiboH3>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Nome identificador*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.trackingId}
              placeholder={'Nome da campanha'}
              onChange={(e) => handleChangeTrackingId(e)}
            />
          </S.FormElement>

          <S.FormElement>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.segmentStripe}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      segmentStripe: !formData.segmentStripe,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Utilizar banner do segmento?"
            />
          </S.FormElement>
          {!formData.segmentStripe && (
            <>
              <S.FormElement>
                <D.TriiboH4>
                  <strong>Banner Desktop*</strong>
                </D.TriiboH4>
                <Box sx={{ maxWidth: 400, height: 'auto' }}>
                  <ImportImage
                    imageDefault={true}
                    autoWidth={true}
                    image={formData.stripeBannerDesktopBlob}
                    setImage={(image) =>
                      setFormData({ ...formData, stripeBannerDesktopBlob: image })
                    }
                    ratio={16 / 1}
                  />
                </Box>
              </S.FormElement>

              <S.FormElement>
                <D.TriiboH4>
                  <strong>Banner Mobile*</strong>
                </D.TriiboH4>
                <Box sx={{ maxWidth: 400, height: 'auto' }}>
                  <ImportImage
                    imageDefault={true}
                    autoWidth={true}
                    image={formData.stripeBannerMobileBlob}
                    setImage={(image) =>
                      setFormData({ ...formData, stripeBannerMobileBlob: image })
                    }
                    ratio={4.875 / 1}
                  />
                </Box>
              </S.FormElement>

              <S.FormElement>
                <D.TriiboH4>
                  <strong>Link de Redirecionamento*</strong>
                </D.TriiboH4>

                <D.TriiboTextField
                  rows={1}
                  value={formData.url}
                  placeholder={'https://www.triibo.com.br'}
                  onChange={(e) => handleChangeUrl(e)}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isExternalLink}
                      onChange={() => {
                        setFormData({
                          ...formData,
                          isExternalLink: !formData.isExternalLink,
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Redireciona para link externo"
                />
              </S.FormElement>

              <S.FormElement>
                <D.TriiboH4>
                  <strong>Filtro regional</strong>
                </D.TriiboH4>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.regionFilter}
                      onChange={() => {
                        setFormData({
                          ...formData,
                          regionFilter: !formData.regionFilter,
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Filtrar por região"
                />
              </S.FormElement>

              {formData.regionFilter && (
                <>
                  <S.FormElement>
                    <D.TriiboH4>
                      <strong>Tipo de Listagem</strong>
                    </D.TriiboH4>

                    <S.RadioSelectContainer>
                      <S.Label>
                        <S.InputRadio
                          value={'city'}
                          checked={formData.filterType === 'city'}
                          onChange={(e) => handleChangeListType(e)}
                        />
                        Cidade
                      </S.Label>

                      <S.Label>
                        <S.InputRadio
                          value={'state'}
                          checked={formData.filterType === 'state'}
                          onChange={(e) => handleChangeListType(e)}
                        />
                        Estado
                      </S.Label>
                    </S.RadioSelectContainer>
                  </S.FormElement>

                  <S.FormElement>
                    <D.TriiboH4>
                      <strong>Adicionar Tags da filtragem</strong>
                    </D.TriiboH4>

                    {formData.filterType === 'city' ? (
                      <TagsAdd form={formData} formKey={'filterValue'} setForm={setFormData} />
                    ) : (
                      <TagsAddUF
                        form={formData}
                        formKey={'filterValue'}
                        setForm={setFormData}
                        listArr={UF}
                        listKeys={['nome', 'sigla']}
                      />
                    )}
                    <TagsDisplay form={formData} formKey={'filterValue'} setForm={setFormData} />
                  </S.FormElement>
                </>
              )}

              <S.FormElement>
                <D.TriiboH4>
                  <strong>Segmentos</strong>
                </D.TriiboH4>
                <FormControl>
                  <Select
                    multiple
                    value={formData.segmentIds || []}
                    onChange={handleChangeSegment}
                    input={<OutlinedInput />}
                    sx={{ borderRadius: '10px', minHeight: '1.5rem' }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return 'Nenhum segmento selecionado';
                      }
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {segments
                            .filter((segment) => selected.includes(segment.id))
                            .map((segment) => (
                              <Chip
                                key={segment.id}
                                label={segment.name}
                                sx={{
                                  color: '#fff',
                                  backgroundColor: '#6e3296',
                                  margin: '4px',
                                }}
                              />
                            ))}
                        </Box>
                      );
                    }}
                  >
                    {segments.map((segment) => (
                      <MenuItem key={segment.id} value={segment.id}>
                        {segment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </S.FormElement>
            </>
          )}

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Ativação*</strong>
            </D.TriiboH4>

            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.enableDate &&
                  new Date(formData.enableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeEnableDate(e)}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Desativação*</strong>
            </D.TriiboH4>
            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.disableDate &&
                  new Date(formData.disableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeDisableDate(e)}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Status da publicação*</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.status}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      status: !formData.status,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Publicado"
            />
          </S.FormElement>

          {preForm ? (
            <S.ButtonsContainer>
              <PrimaryButton
                width={'100%'}
                onClick={() => {
                  handleUpdateStripeBanner();
                }}
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <CircularProgress size={30} color={'inherit'} />
                ) : (
                  'Atualizar Banner de Tira'
                )}
              </PrimaryButton>
            </S.ButtonsContainer>
          ) : (
            <S.ButtonsContainer>
              {indexItem !== null ? (
                <>
                  <PrimaryButton
                    bgColor={'#f13a3a'}
                    width={'100%'}
                    onClick={() => {
                      setFormData({
                        disableDate: '',
                        enableDate: '',
                        stripeBannerDesktop: null,
                        stripeBannerDesktopBlob: '',
                        stripeBannerMobile: null,
                        stripeBannerMobileBlob: '',
                        segmentIds: [],
                        url: '',
                        status: false,
                        trackingId: '',
                        segmentStripe: false,
                        isExternalLink: false,
                      });
                      setIndexItem(null);
                    }}
                  >
                    Cancelar
                  </PrimaryButton>
                  <PrimaryButton
                    width={'100%'}
                    onClick={() => {
                      if (indexItem !== null) {
                        handleEditStripeBanner();
                      } else {
                        handleAddStripeBanner();
                      }
                    }}
                  >
                    + Adicionar Banner de Tira
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton width={'100%'} onClick={handleAddStripeBanner}>
                  + Adicionar Banner de Tira
                </PrimaryButton>
              )}
            </S.ButtonsContainer>
          )}
        </S.AddBannerContainer>

        {preForm ? (
          <>
            <S.ListBannersContainer>
              <EditPreview title={'Imagens atuais'} data={preForm} />
            </S.ListBannersContainer>
          </>
        ) : (
          <S.ListBannersContainer>
            <ListTemplate
              title={'Lista de Banners de Tira'}
              data={data}
              type={'Banner de Tira'}
              setFormData={setFormData}
              setIndexItem={setIndexItem}
              handleRemoveItem={handleRemoveStripeBanner}
            />
          </S.ListBannersContainer>
        )}
      </S.Content>

      {preForm ? (
        <></>
      ) : (
        <PrimaryButton onClick={handleSubmitStripeBanners} disabled={data.length < 1 || isUpdating}>
          {isUpdating ? (
            <CircularProgress size={30} color={'inherit'} />
          ) : (
            'Cadastrar Banner de Tira'
          )}
        </PrimaryButton>
      )}
    </S.Container>
  );
}
