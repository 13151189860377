import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import * as E from 'js/components/DesignSystem/Table/styles';

import { PageStructure } from 'js/components/TriiboComponents/PageStructure/PageStructure';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import { getSeuClube, updateSeuClube } from 'js/library/utils/API/seuClube';
import { useParams } from 'react-router-dom';
import AddAdmins from './AddAdmins';
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { Trash, UserSwitch } from 'phosphor-react';
import { GetUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { cellPhoneMask2, findValueInArray, getUserInfo } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';
import ChangeGestor from './ChangeGestor';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function ManageAdmins() {
  const userInfo = getUserInfo();
  const params = useParams();
  const clubeId = params.id;
  const roleOrder = { gestor: 1, financeiro: 2, administrador: 3 };

  const column = [
    { heading: 'uId', value: 'uId' },
    { heading: 'Apelido', value: 'aliasName' },
    { heading: 'Cargo', value: 'role' },
    { heading: 'Telefone', value: 'cellPhone' },
  ];

  const [loadingPage, setLoadingPage] = useState(true);
  const [fieldKey, setFieldKey] = useState({});
  const [clubeData, setClubeData] = useState({});
  const [adminsList, setAdminsList] = useState({});
  const [adminsArray, setAdminsArray] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [modalOpenUpdateGestor, setModalOpenUpdateGestor] = useState(false);
  const [openModalDeleteAdmin, setOpenModalDeleteAdmin] = useState(false);
  const [userRole, setUserRole] = useState(userInfo.admin ? 'superAdmin' : '');
  const [loadingAction, setLoadingAction] = useState('');
  const [adminsNotOnClub, setAdminsNotOnClub] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const clubeData = await getSeuClube(clubeId);
      setClubeData(clubeData);
      setAdminsList(clubeData.admins ?? {});
      const partnerUserForms = await GetPartnerUserForms(clubeData.partnerId);
      const fieldK = partnerUserForms.formFields.filter((item) => item.indexKey);

      setFieldKey(fieldK[0]);
      setLoadingPage(false);
    };

    fetch();
  }, []);

  useEffect(() => {
    setLoadingTable(true);

    if (Object.keys(adminsList).length !== 0) {
      const promises = Object.keys(adminsList).map(async (uid) => {
        const res = await GetUserPartner(clubeData.partnerId, uid);
        if (res.response) {
          const cellPhone = findValueInArray(res.response.contactList, 'type', 'cellPhone');

          return {
            ...adminsList[uid],
            uId: uid,
            aliasName: res.response.aliasName,
            cellPhone: cellPhoneMask2(cellPhone.value),
          };
        } else {
          return {
            ...adminsList[uid],
            uId: uid,
            aliasName: 'USUÁRIO NÃO CADASTRADO',
            cellPhone: 'N/A',
            userNotInClub: true,
          };
        }
      });

      // Filtra os valores undefined antes de usar `Promise.all`
      Promise.all(promises)
        .then((updatedAdmins) => {
          // Filtra os `undefined` após a execução das promessas
          // const filteredAdmins = updatedAdmins.filter((admin) => !admin.userNotInClub);
          const adminsNotExistInClub = updatedAdmins
            .filter((admin) => admin.userNotInClub)
            .map((admin) => admin.uId);

          setAdminsNotOnClub(adminsNotExistInClub);

          if (userRole !== 'superAdmin') {
            setUserRole(updatedAdmins.find((admin) => admin.uId === userInfo.uId)?.role);
          }
          setAdminsArray(updatedAdmins.sort((a, b) => roleOrder[a.role] - roleOrder[b.role]));
        })
        .finally(() => {
          setLoadingTable(false);
        });
    } else {
      setLoadingTable(false);
    }
  }, [adminsList]);

  const componentReceiver = (_, childrens) => {
    const isGestor = childrens?.role === 'gestor';
    const buttonColor = isGestor ? 'primary' : 'error';
    const buttonTitle = isGestor ? 'Atualizar gestor' : 'Remover administrador';
    const isLoading = loadingAction === childrens?.uId;
    const Icon = isLoading ? CircularProgress : isGestor ? UserSwitch : Trash;

    return {
      columnComponent: <E.TableTH>Ação</E.TableTH>,
      childrenComponent: (
        <td style={{ padding: '1rem 0.5rem' }}>
          <Button
            variant="contained"
            disabled={isLoading}
            color={buttonColor}
            title={buttonTitle}
            sx={{ minWidth: 0, p: 0.6 }}
            onClick={() => {
              setLoadingAction(childrens.uId);
              if (!isGestor) {
                return setOpenModalDeleteAdmin(childrens);
              }
              manageUserRole(childrens);
            }}
          >
            <Icon size={25} color={isLoading ? 'primary' : '#f7f7f7'} />
          </Button>
        </td>
      ),
    };
  };

  const manageUserRole = async (childrens) => {
    const newAdmins = { ...adminsList };
    const removedUser = { [childrens.uId]: newAdmins[childrens.uId] };

    if (childrens.role === 'gestor' && !userInfo.admin) {
      setLoadingAction('');
      return toast.error('Você não tem permissão para editar este cargo.');
    } else if (childrens.role === 'gestor') {
      setLoadingAction('');
      return setModalOpenUpdateGestor(true);
    } else if (roleOrder[userRole] > roleOrder[childrens.role] && !userInfo.admin) {
      setLoadingAction('');
      return toast.error('Você não tem permissão para editar este cargo.');
    } else {
      removedUser[childrens.uId] = null;
      delete newAdmins[childrens.uId];
      const payload = {
        clubeId: clubeData.clubeId,
        admins: removedUser,
      };
      try {
        const updateClube = await updateSeuClube(payload);

        if (updateClube.status === 200) {
          setAdminsList(newAdmins);
          toast.success('Usuário removido com sucesso!');
        }
        setLoadingAction('');
      } catch (error) {
        console.error('Erro ao atualizar o clube:', error);
        toast.error('Erro ao atualizar os administradores.');
        setLoadingAction('');
      }
    }
  };

  return (
    <PageStructure title="Gerenciar administradores">
      {loadingPage ? (
        <CircularProgress />
      ) : (
        <Grid container rowSpacing={5}>
          <AddAdmins
            clubeData={clubeData}
            fieldKey={fieldKey}
            adminsList={adminsList}
            setAdminsList={setAdminsList}
            userRole={userRole}
            roleOrder={roleOrder}
          />
          {adminsNotOnClub.length > 0 && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: '#FF0000',
                  p: 2,
                  borderRadius: 1,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <b>ATENÇÃO: </b>Existem administradores que não estão mais cadastrados no clube,
                eles estão identificados como "USUÁRIO NÃO CADASTRADO" na lista abaixo.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            {loadingTable ? (
              <CircularProgress />
            ) : adminsArray.length !== 0 ? (
              <CustomButtonsTable
                data={adminsArray}
                perPage={10}
                column={column}
                componentReceiver={componentReceiver}
                loadingTableDisabled={true}
              />
            ) : (
              <Typography variant="h6">
                Adicione um gestor para continuar a gerenciar outros cargos do clube.
              </Typography>
            )}
          </Grid>

          <ChangeGestor
            open={modalOpenUpdateGestor}
            setOpen={setModalOpenUpdateGestor}
            clubeData={clubeData}
            fieldKey={fieldKey}
            adminsArray={adminsArray}
            setAdminsList={setAdminsList}
          />

          <Dialog
            open={Boolean(openModalDeleteAdmin)}
            onClose={() => setOpenModalDeleteAdmin(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirmar Exclusão</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza que deseja remover o usuário selecionado do cargo?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button onClick={() => setOpenModalDeleteAdmin(false)} color="primary">
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  manageUserRole(openModalDeleteAdmin);
                  setOpenModalDeleteAdmin(false);
                }}
                color="primary"
                autoFocus
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </PageStructure>
  );
}
