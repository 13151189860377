import React, { useEffect, useState } from 'react';

// STYLES
import { Grid, Typography } from '@mui/material';

// COMPONENTS
import ImportImage from 'js/components/TriiboComponents/ImportImage';

export const ImagesInformation = ({ dataCustomization, setDataCustomization }) => {
  const {
    featuredImageDesktop,
    featuredImageMobile,
    logo,
    pin,
    stamp,
    stripeDesktop,
    stripeMobile,
  } = dataCustomization;

  const imageFields = [
    {
      label: 'Desktop',
      field: 'featuredImageDesktop',
      image: featuredImageDesktop,
      ratio: 1157 / 122,
    },
    { label: 'Mobile', field: 'featuredImageMobile', image: featuredImageMobile, ratio: 1.08 },
    { label: 'Logo', field: 'logo', image: logo, ratio: 1.4 },
    { label: 'Pin', field: 'pin', image: pin, ratio: 1 },
    { label: 'Stamp', field: 'stamp', image: stamp, ratio: 1 },
    { label: 'Stripe Desktop', field: 'stripeDesktop', image: stripeDesktop, ratio: 9.5 },
    { label: 'Stripe Mobile', field: 'stripeMobile', image: stripeMobile, ratio: 4.87 },
  ];

  const [images, setImages] = useState({
    featuredImageDesktop,
    featuredImageMobile,
    logo,
    pin,
    stamp,
    stripeDesktop,
    stripeMobile,
  });

  useEffect(() => {
    // Atualiza dataCustomization com todas as imagens ao mesmo tempo
    setDataCustomization((prevCustomization) => ({
      ...prevCustomization,
      ...images,
    }));
  }, [images, setDataCustomization]);

  const handleImageChange = (field, image) => {
    setImages((prev) => ({
      ...prev,
      [field]: image,
    }));
  };

  return (
    <Grid container spacing={2} sx={{ border: '1px solid #aaa', padding: '1rem 2rem 2rem 1rem' }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 600, paddingLeft: '1rem' }}>
        Imagens:
      </Typography>

      {imageFields.map((field, index) => {
        if (index % 2 === 0) {
          return (
            <Grid container item xs={12} spacing={2} key={field.field}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
              >
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {field.label}*:
                </Typography>
                <ImportImage
                  imageDefault={null}
                  autoWidth={true}
                  image={field.image}
                  setImage={(img) => handleImageChange(field.field, img)}
                  ratio={field.ratio}
                />
              </Grid>

              {imageFields[index + 1] && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {imageFields[index + 1].label}*:
                  </Typography>
                  <ImportImage
                    imageDefault={null}
                    autoWidth={true}
                    image={imageFields[index + 1].image}
                    setImage={(img) => handleImageChange(imageFields[index + 1].field, img)}
                    ratio={field.ratio}
                  />
                </Grid>
              )}
            </Grid>
          );
        }

        return null;
      })}
    </Grid>
  );
};
