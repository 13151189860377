import React, { useState, useRef, useEffect } from 'react';

import { createFeedContent } from 'js/library/utils/API/apiCreateFeedContent';
import { updateFeedContent } from 'js/library/utils/API/apiUpdateFeedContent';
import { getSegmentCustomization } from 'js/library/utils/API/Segmentation/getSegmentCustomization';
import { useHistory } from 'react-router-dom';

import { convertDateToTimestamp, getFileFromUrl } from 'js/library/utils/helpers';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import ListTemplate from '../ListTemplate';
import TagsDisplay from 'js/components/DesignSystem/TagsDisplay';
import TagsAdd from 'js/components/DesignSystem/TagsAdd';
import TagsAddUF from 'js/components/DesignSystem/TagsAddUF';
import EditPreview from '../EditPreview';
import {
  Switch,
  FormControlLabel,
  CircularProgress,
  Chip,
  Select,
  MenuItem,
  FormControl,
  Box,
  OutlinedInput,
} from '@mui/material';
import iconQR from 'styles/assets/icone_QRCODE.svg';
import iconPercentage from 'styles/assets/icone_Porcentagem.svg';
import iconTicket from 'styles/assets/icone_Ticket.svg';
import calendarIcon from 'styles/assets/communications/calendarPurple.svg';
import Moldura_1 from 'styles/assets/ConfigHomeMapfre/Moldura_1.png';
import Moldura_2 from 'styles/assets/ConfigHomeMapfre/Moldura_2.png';
import { firebaseStorage } from 'js/library/utils/firebaseUtils';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { UF } from '../uf';
import cfac22 from 'js/library/utils/cfac22';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

export default function Carousels(props) {
  const { preForm } = props;
  const [formData, setFormData] = useState({
    disableDate: preForm ? convertDateToTimestamp(preForm.disableDate) : '',
    enableDate: preForm ? convertDateToTimestamp(preForm.enableDate) : '',
    image: preForm ? preForm.image : '',
    imageBlob: null,
    title: preForm ? preForm.title : '',
    listType: preForm ? preForm.listType : 'establishment',
    frameType: preForm ? preForm.frameType : 'ticket',
    query: preForm ? preForm.query : '',
    status: preForm ? preForm.status : false,
    segmentIds: preForm ? preForm.segmentIds : [],
    regionFilter: preForm ? (preForm.regionFilter ? preForm.regionFilter : false) : false,
    filterType: preForm ? (preForm.filterType ? preForm.filterType : 'city') : 'city',
    filterValue: preForm ? (preForm.filterValue ? preForm.filterValue : []) : [],
    trackingId: preForm ? preForm.trackingId : '',
    hasVoucher: preForm ? (preForm.hasVoucher !== undefined ? preForm.hasVoucher : false) : false,
    geoRadius: preForm ? (preForm.geoRadius !== undefined ? preForm.geoRadius : 1000) : 1000,
    orderType: preForm
      ? preForm.orderType !== undefined
        ? preForm.orderType
        : 'relevance'
      : 'relevance',
  });

  const history = useHistory();
  const [indexItem, setIndexItem] = useState(null);
  const [data, setData] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const partnerId = cfac22('PARTNER_ID_MAPFRE');
  const [segments, setSegments] = useState([]);
  const [presetIcons] = useState([
    {
      name: 'icone_Porcentagem.svg',
      url: iconPercentage,
    },
    {
      name: 'icone_QRCODE.svg',
      url: iconQR,
    },
    {
      name: 'icone_Ticket.svg',
      url: iconTicket,
    },
  ]);
  const [isUsingCustomIcon, setIsUsingCustomIcon] = useState(false);
  const [currentPresetIcon, setCurrentPresetIcon] = useState('');

  const imageRef = useRef(null);

  const imageUploader = async (event) => {
    let file;
    if (isUsingCustomIcon) {
      file = event.target.files[0];
    } else {
      file = getFileFromUrl(event, 'icone.svg');
    }
    const reader = new FileReader();
    reader.readAsDataURL(await file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        // For SVG files, draw the image directly without using canvas
        if ((await file.type) === 'image/svg+xml') {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const svgUrl = event.target.result;
          const svgImg = new Image();
          svgImg.onload = () => {
            ctx.drawImage(svgImg, 0, 0, img.width, img.height);
            canvas.toBlob((blob) => {
              const fileConvert = new File(
                [blob],
                file.name ? `${file.name.split('.')[0]}.svg` : 'preset.svg',
                {
                  type: 'image/svg+xml',
                }
              );
              setFormData({
                ...formData,
                imageBlob: blob,
                image: fileConvert,
              });
            }, 'image/svg+xml');
          };
          svgImg.src = svgUrl;
        } else {
          ctx.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob(
            (blob) => {
              const fileConvert = new File(
                [blob],
                file.name ? `${file.name.split('.')[0]}.png` : 'preset.png',
                {
                  type: 'image/png',
                }
              );
              setFormData({
                ...formData,
                imageBlob: blob,
                image: fileConvert,
              });
            },
            'image/png',
            1
          );
        }
      };
    };
  };

  const uploadingImages = async (image, type) => {
    let imagePath = '';

    const newStorageRef = firebaseStorage.ref('homeMapfre').child(type + Date.now());

    await newStorageRef.put(image).then((result) => {
      imagePath = result._delegate.ref._location.path_;
    });
    return imagePath;
  };

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        if (!partnerId) return;

        const segmentData = await getSegmentCustomization({ partnerId });

        const segmentList = Object.values(segmentData.response || {});

        setSegments(
          segmentList.map((segment) => ({
            id: segment.segmentId || '',
            name: segment.name || '',
          }))
        );
      } catch (error) {
        console.error('Erro ao carregar segmentos:', error.message || error);
      }
    };

    fetchSegments();
  }, [partnerId]);

  const handleRemoveCarousel = (index) => {
    setData((prevData) => {
      const copyData = [...prevData];
      copyData.splice(index, 1);
      return copyData;
    });
  };

  const handleChange = (e, field) => {
    if (field === 'image') {
      setFormData({ ...formData, [field]: { name: e.target.value } });
      return;
    }

    if (field === 'trackingId') {
      setFormData({ ...formData, [field]: e.target.value.replace(/\s/g, '') });
      return;
    }

    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleChangeDate = (e, field) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, [field]: date });
  };

  const handleChangeFilterType = (e) => {
    setFormData({ ...formData, filterType: e.target.value, filterValue: [] });
  };

  const handleChangeSegment = (event) => {
    const selectedId = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      segmentIds: selectedId,
    }));
  };

  const handleSubmitCarousel = async () => {
    setIsUpdating(true);
    const resolved = await Promise.all(
      data.map(async (item) => {
        const imagePath = await uploadingImages(item.imageBlob, 'carouselImage');
        if (imagePath) {
          const { image, imageBlob, ...rest } = item;

          return { ...rest, frameIcon: imagePath };
        }
      })
    );

    const segmentItem = resolved.map((element, index) => {
      if (!element.segmentIds || element.segmentIds.length === 0) {
        delete element.segmentIds;
      }
      return element;
    });

    const filtered = resolved.map((element, index) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      if (element.url === '') {
        delete element.url;
      }
      return element;
    });

    createFeedContent('carousels', filtered, segmentItem)
      .then((res) => {
        setIsUpdating(false);
        toast.success('Feed atualizado com sucesso');
        setTimeout(() => {
          history.goBack();
        }, 1000);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  const handleUpdateCarousels = async () => {
    setIsUpdating(true);
    let newObj = cloneDeep(formData);

    for (let key in newObj) {
      if (!newObj[key] || (Array.isArray(newObj[key]) && newObj[key].length <= 0)) {
        delete newObj[key];
      }
    }

    const resolved = new Promise(async (resolved, rejected) => {
      const imagePath = await uploadingImages(newObj.imageBlob, 'carouselImage');
      if (imagePath) {
        const { image, imageBlob, ...rest } = newObj;

        return resolved({
          ...rest,
          frameIcon: imageBlob ? imagePath : image,
          id: preForm.id,
          type: preForm.type,
          status: preForm.status,
        });
      }
    });
    const res = await resolved;
    const filtered = (element) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      return element;
    };
    updateFeedContent([filtered(res)])
      .then(() => {
        toast.success('Feed atualizado com sucesso');
        setIsUpdating(false);
        history.goBack();
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  const handleEditHighlights = () => {
    setData((prevData) => {
      const copyData = [...prevData];
      copyData[indexItem] = formData;

      return copyData;
    });

    setFormData({
      disableDate: '',
      enableDate: '',
      image: null,
      imageBlob: null,
      title: '',
      listType: 'establishment',
      frameType: 'ticket',
      query: '',
      segmentIds: [],
      status: false,
      regionFilter: false,
      trackingId: preForm ? preForm.trackingId : '',
    });

    setIndexItem(null);
  };

  const handleAddCarousels = () => {
    if (
      !formData.disableDate ||
      !formData.enableDate ||
      !formData.image ||
      // !formData.imageBlob ||
      !formData.title ||
      !formData.listType ||
      !formData.frameType ||
      !formData.query
    ) {
      return toast.error('Preencha todos os dados obrigatórios.');
    }

    if (formData.enableDate > formData.disableDate) {
      return toast.error('Data de ativação é maior que a de desativação.');
    }

    setData([...data, formData]);
    setFormData({
      disableDate: '',
      enableDate: '',
      image: null,
      imageBlob: null,
      title: '',
      listType: 'establishment',
      frameType: 'ticket',
      query: '',
      segmentIds: [],
      status: false,
      regionFilter: false,
      trackingId: preForm ? preForm.trackingId : '',
    });
  };

  return (
    <S.Container>
      {preForm && (
        <AreaHeader
          style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>
      )}
      <S.Content>
        <S.AddCarousel>
          <D.TriiboH3>
            <strong>Carrosséis</strong>
          </D.TriiboH3>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Nome identificador*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.trackingId}
              placeholder={'Nome da campanha'}
              onChange={(e) => handleChange(e, 'trackingId')}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Tipo*</strong>
            </D.TriiboH4>

            <S.RadioSelectContainer>
              <S.Label>
                <S.InputRadio
                  value={'establishment'}
                  checked={formData.listType === 'establishment'}
                  onChange={(e) => handleChange(e, 'listType')}
                />
                Estabelecimentos
              </S.Label>

              <S.Label>
                <S.InputRadio
                  value={'promotion'}
                  checked={formData.listType === 'promotion'}
                  onChange={(e) => handleChange(e, 'listType')}
                />
                Promoções
              </S.Label>
            </S.RadioSelectContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Título*</strong>
            </D.TriiboH4>

            <S.InputContainer>
              <D.TriiboTextField
                rows={1}
                maxLength={28}
                value={formData.title}
                onChange={(e) => {
                  handleChange(e, 'title');
                }}
              />
              <S.InputCounter>{formData.title.length}/28</S.InputCounter>
            </S.InputContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Ícones padrão*</strong>
            </D.TriiboH4>

            <S.RadioSelectContainer>
              {presetIcons.map((element, index) => (
                <S.Label key={`label-${index}`}>
                  <S.InputRadio
                    value={element.name}
                    checked={currentPresetIcon === element.name}
                    onChange={(e) => {
                      e.preventDefault();
                      setCurrentPresetIcon(e.target.value);
                      setIsUsingCustomIcon(false);
                      if (!isUsingCustomIcon) {
                        imageUploader(element.url);
                      }
                    }}
                    key={`input-radio-${index}`}
                  />
                  <S.OptionImgContainer>
                    <img
                      src={element.url}
                      alt=""
                      key={`img-${index}`}
                      style={
                        {
                          // filter: "drop-shadow(0px 3px 6px rgb(0 0 0 / 0.6))",
                        }
                      }
                    />
                  </S.OptionImgContainer>
                </S.Label>
              ))}
              <S.Label>
                <S.InputRadio
                  value={'Upload'}
                  checked={currentPresetIcon === 'Upload'}
                  onChange={(e) => {
                    e.preventDefault();
                    setCurrentPresetIcon(e.target.value);
                    setIsUsingCustomIcon(true);
                  }}
                />
                Upload
              </S.Label>
            </S.RadioSelectContainer>
          </S.FormElement>

          {isUsingCustomIcon ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>ícone*</strong>
              </D.TriiboH4>

              {formData.image ? (
                <S.FileContainer>
                  <PrimaryButton
                    width={'200px'}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        image: null,
                        imageBlob: null,
                      })
                    }
                  >
                    Limpar seleção
                  </PrimaryButton>
                  <D.TriiboH5>{formData.image.name}</D.TriiboH5>
                </S.FileContainer>
              ) : (
                <>
                  <DashedButton width={'100%'} onClick={() => imageRef.current.click()}>
                    + Adicionar imagem
                  </DashedButton>
                  <input
                    type="file"
                    className="inputFile"
                    ref={imageRef}
                    onChange={(e) => imageUploader(e)}
                    accept=".svg"
                  />
                </>
              )}
            </S.FormElement>
          ) : (
            <></>
          )}

          <S.FormElement>
            <D.TriiboH4>
              <strong>Tipo da moldura*</strong>
            </D.TriiboH4>

            <S.RadioSelectContainer>
              <S.Label>
                <S.InputRadio
                  value={'ticket'}
                  checked={formData.frameType === 'ticket'}
                  onChange={(e) => handleChange(e, 'frameType')}
                />
                <img src={Moldura_1} alt="" />
              </S.Label>

              <S.Label>
                <S.InputRadio
                  value={'flat'}
                  checked={formData.frameType === 'flat'}
                  onChange={(e) => handleChange(e, 'frameType')}
                />
                <img src={Moldura_2} alt="" />
              </S.Label>
            </S.RadioSelectContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Tag*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.query}
              placeholder={'Query para listagem das promoções/estabelecimentos'}
              onChange={(e) => {
                handleChange(e, 'query');
              }}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Ativação*</strong>
            </D.TriiboH4>

            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.enableDate &&
                  new Date(formData.enableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeDate(e, 'enableDate')}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Desativação*</strong>
            </D.TriiboH4>

            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.disableDate &&
                  new Date(formData.disableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeDate(e, 'disableDate')}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Segmentos</strong>
            </D.TriiboH4>
            <FormControl>
              <Select
                multiple
                value={formData.segmentIds || []}
                onChange={handleChangeSegment}
                input={<OutlinedInput />}
                sx={{ borderRadius: '10px', minHeight: '1.5rem' }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return 'Nenhum segmento selecionado';
                  }
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {segments
                        .filter((segment) => selected.includes(segment.id))
                        .map((segment) => (
                          <Chip
                            key={segment.id}
                            label={segment.name}
                            sx={{
                              color: '#fff',
                              backgroundColor: '#6e3296',
                              margin: '4px',
                            }}
                          />
                        ))}
                    </Box>
                  );
                }}
              >
                {segments.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Status da publicação*</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.status}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      status: !formData.status,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Publicado"
            />
          </S.FormElement>
          <S.FormElement>
            <D.TriiboH4>
              <strong>Filtro regional</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.regionFilter}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      regionFilter: !formData.regionFilter,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Filtrar por região"
            />
          </S.FormElement>
          {formData.regionFilter ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Tipo de Listagem</strong>
              </D.TriiboH4>

              <S.RadioSelectContainer>
                <S.Label>
                  <S.InputRadio
                    value={'city'}
                    checked={formData.filterType === 'city'}
                    onChange={(e) => handleChangeFilterType(e)}
                  />
                  Cidade
                </S.Label>

                <S.Label>
                  <S.InputRadio
                    value={'state'}
                    checked={formData.filterType === 'state'}
                    onChange={(e) => handleChangeFilterType(e)}
                  />
                  Estado
                </S.Label>
              </S.RadioSelectContainer>
            </S.FormElement>
          ) : (
            <></>
          )}
          {formData.regionFilter ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Adicionar Tags da filtragem</strong>
              </D.TriiboH4>

              {formData.filterType === 'city' ? (
                <TagsAdd form={formData} formKey={'filterValue'} setForm={setFormData} />
              ) : (
                <TagsAddUF
                  form={formData}
                  formKey={'filterValue'}
                  setForm={setFormData}
                  listArr={UF}
                  listKeys={['nome', 'sigla']}
                />
              )}
              <TagsDisplay form={formData} formKey={'filterValue'} setForm={setFormData} />
            </S.FormElement>
          ) : (
            <></>
          )}

          {formData.listType === 'establishment' ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Exibir apenas estabelecimentos com ofertas</strong>
              </D.TriiboH4>

              <FormControlLabel
                control={
                  <Switch
                    checked={formData.hasVoucher}
                    onChange={() => {
                      setFormData({
                        ...formData,
                        hasVoucher: !formData.hasVoucher,
                      });
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Exibir apenas estabelecimentos com ofertas"
              />
            </S.FormElement>
          ) : (
            <></>
          )}

          <S.FormElement>
            <D.TriiboH4>
              <strong>Raio de exibição(em km)</strong>
            </D.TriiboH4>

            <S.InputContainer>
              <D.TriiboField
                type="number"
                value={formData.geoRadius}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    geoRadius: e.target.value,
                  });
                }}
              />
            </S.InputContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Ordenação da lista</strong>
            </D.TriiboH4>

            <S.RadioSelectContainer>
              <S.Label>
                <S.InputRadio
                  value={'relevance'}
                  checked={formData.orderType === 'relevance'}
                  onChange={(e) => handleChange(e, 'orderType')}
                />
                Prioridade
              </S.Label>

              <S.Label>
                <S.InputRadio
                  value={'alphabetic'}
                  checked={formData.orderType === 'alphabetic'}
                  onChange={(e) => handleChange(e, 'orderType')}
                />
                Alfabética
              </S.Label>

              <S.Label>
                <S.InputRadio
                  value={'location'}
                  checked={formData.orderType === 'location'}
                  onChange={(e) => handleChange(e, 'orderType')}
                />
                Localização
              </S.Label>
            </S.RadioSelectContainer>
          </S.FormElement>

          {preForm ? (
            <S.ButtonsContainer>
              <PrimaryButton
                width={'100%'}
                onClick={() => {
                  handleUpdateCarousels();
                }}
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <CircularProgress size={30} color={'inherit'} />
                ) : (
                  'Atualizar Carrossel'
                )}
              </PrimaryButton>
            </S.ButtonsContainer>
          ) : (
            <S.ButtonsContainer>
              {indexItem !== null ? (
                <>
                  <PrimaryButton
                    bgColor={'#f13a3a'}
                    width={'100%'}
                    onClick={() => {
                      setFormData({
                        disableDate: '',
                        enableDate: '',
                        image: null,
                        imageBlob: null,
                        title: '',
                        segmentIds: [],
                        listType: 'establishment',
                        frameType: 'ticket',
                        query: '',
                        trackingId: '',
                      });
                      setIndexItem(null);
                    }}
                  >
                    Cancelar
                  </PrimaryButton>
                  <PrimaryButton
                    width={'100%'}
                    onClick={() => {
                      if (indexItem !== null) {
                        handleEditHighlights();
                      } else {
                        handleAddCarousels();
                      }
                    }}
                  >
                    + Adicionar Carrossel
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton width={'100%'} onClick={handleAddCarousels}>
                  + Adicionar Carrossel
                </PrimaryButton>
              )}
            </S.ButtonsContainer>
          )}
        </S.AddCarousel>
        {preForm ? (
          <S.ListBannersContainer>
            <EditPreview title={'Preview de imagens'} data={preForm} />
          </S.ListBannersContainer>
        ) : (
          <S.ListCarousels>
            <ListTemplate
              title={'Lista de Promoções'}
              data={data}
              type={'Carrossel'}
              setFormData={setFormData}
              setIndexItem={setIndexItem}
              handleRemoveItem={handleRemoveCarousel}
            />
          </S.ListCarousels>
        )}
      </S.Content>

      {preForm ? (
        <></>
      ) : (
        <PrimaryButton
          onClick={() => handleSubmitCarousel()}
          disabled={data.length < 1 || isUpdating}
        >
          {isUpdating ? <CircularProgress size={30} color={'inherit'} /> : 'Cadastrar Carrossel'}
        </PrimaryButton>
      )}
    </S.Container>
  );
}
