import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export const deletePartnerCode = (partnerCode) => {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'POST',
        url: `${cfac22('API_HOST_V2')}/campaign/delete/partnerCode`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Full-Url': window.location.href,
        },

        data: {
          partnerCodes: partnerCode,
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};
