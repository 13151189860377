import React, { useState, useEffect } from 'react';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';

// STYLES, ICONS AND COMPONENTS
import * as S from './styles';
import { TextField, Button, Typography, Box, Grid, CircularProgress, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

// APIS
import { postPartnerCode } from 'js/library/utils/API/Campaigns/apiPostPartnerCode';
import { getPartnerCodesByCampaign } from 'js/library/utils/API/Campaigns/apiGetPartnerCode';
import { deletePartnerCode } from 'js/library/utils/API/Campaigns/apiDeletePartnerCode';

export default function PartnerCode(props) {
  // STATES
  const [fields, setFields] = useState([]);
  const [removedFields, setRemovedFields] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [duplicateIndexes, setDuplicateIndexes] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});

  // ID'S
  const campaignId = props.match.params.id;
  const ownerId = localStorage.getItem('partnerIdClube');

  // BUSCA INFORMAÇÕES DE CÓDIGOS EXISTENTES
  useEffect(() => {
    getPartnerCodesByCampaign(campaignId)
      .then((res) => {
        const formattedFields = Object.entries(res)
          .filter(([key, value]) => key !== 'success' && typeof value === 'object')
          .map(([key, value]) => ({
            partnerCode: value.partnerCode,
            relatedPromotion: value.store,
            codeExists: true,
          }));

        setFields(formattedFields);
      })
      .catch((err) => {
        toast.error('Erro ao carregar códigos de parceiro existentes.');
        console.error(err);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [campaignId]);

  const handleAddField = () => {
    const newField = { partnerCode: '', relatedPromotion: '', codeExists: false };
    setFields([...fields, newField]);
  };

  const handleChangeField = (index, event) => {
    const { name, value } = event.target;
    const newFields = fields.slice();
    newFields[index][name] = value;
    setFields(newFields);

    if (name === 'partnerCode') {
      const duplicate = fields.some(
        (field, fieldIndex) => fieldIndex !== index && field.partnerCode === value
      );

      if (duplicate) {
        toast.error(`O código de parceiro ${value} já existe. Por favor, tente outro.`);
        setDuplicateIndexes((prevIndexes) => [...prevIndexes, index]);
      } else {
        setDuplicateIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));
      }
    }
  };

  const handleDelete = async (index) => {
    setLoadingStates((prev) => ({ ...prev, [index]: { delete: true } }));
    const fieldToRemove = fields[index];

    try {
      if (fieldToRemove.codeExists) {
        await deletePartnerCode(fieldToRemove.partnerCode);
        toast.success('Código do parceiro deletado.');

        setFields((prevFields) => prevFields.filter((_, i) => i !== index));
      } else {
        setRemovedFields([...removedFields, fieldToRemove]);
        setFields((prevFields) => prevFields.filter((_, i) => i !== index));
      }
    } catch (err) {
      let errorMessage =
        err.response?.data?.error || 'Erro desconhecido ao deletar código do parceiro.';

      // se for a mensagem de erro que o back não tratou
      if (
        errorMessage.includes('path argument was an invalid path') &&
        errorMessage.includes('Paths must be non-empty strings')
      ) {
        errorMessage = 'Erro desconhecido ao deletar código do parceiro.';
      }

      toast.error(errorMessage);
      console.error('error:', err.response?.data?.error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: { delete: false } }));
    }
  };

  const saveCodePartner = async (index) => {
    setLoadingStates((prev) => ({ ...prev, [index]: { save: true } }));

    const data = fields[index];

    if (data.partnerCode === '') {
      toast.error('O código do parceiro é obrigatório.');
      setLoadingStates((prev) => ({ ...prev, [index]: { save: false } }));
      return;
    }

    if (data.relatedPromotion === '') {
      toast.error('A promoção relacionada é obrigatória.');
      setLoadingStates((prev) => ({ ...prev, [index]: { save: false } }));
      return;
    }

    try {
      const partnerCodesData = {
        campaign: campaignId,
        partnerCode: data.partnerCode,
        partnerId: ownerId,
        store: data.relatedPromotion,
      };

      await postPartnerCode(partnerCodesData);
      toast.success('Código do parceiro adicionado com sucesso.');

      // Atualiza o campo para refletir que o código agora existe
      setFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index] = { ...updatedFields[index], codeExists: true };
        return updatedFields;
      });
    } catch (err) {
      console.error('error:', err);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: { save: false } }));
    }
  };

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <S.Container>
      <GoBackHeader />
      <Typography variant="h4" component="h1" gutterBottom sx={{ marginBottom: '60px' }}>
        Códigos do Parceiro
      </Typography>
      {fields.map((field, index) => (
        <Grid container spacing={2} key={index} sx={{ marginBottom: '15px', padding: '0px 80px' }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Código do parceiro"
              name="partnerCode"
              value={field.partnerCode}
              onChange={(event) => handleChangeField(index, event)}
              error={duplicateIndexes.includes(index)}
              fullWidth
              disabled={field.codeExists}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', position: 'relative', alignItems: 'center' }}
          >
            <TextField
              label="Promoção relacionada"
              name="relatedPromotion"
              value={field.relatedPromotion}
              onChange={(event) => handleChangeField(index, event)}
              error={duplicateIndexes.includes(index)}
              fullWidth
              disabled={field.codeExists}
            />
            <div style={{ display: 'flex', position: 'absolute', left: '100%' }}>
              {!field.codeExists && (
                <Tooltip title="Salvar" placement="top">
                  <S.IconButton onClick={() => saveCodePartner(index)} $bgcolor="#28a745">
                    {loadingStates[index]?.save ? (
                      <CircularProgress size={22} thickness={5} style={{ color: '#fff' }} />
                    ) : (
                      <SaveRoundedIcon color="primary" size={25} style={{ color: '#fff' }} />
                    )}
                  </S.IconButton>
                </Tooltip>
              )}
              <Tooltip title="Apagar" placement="top">
                <S.IconButton onClick={() => handleDelete(index)} $bgcolor="#ff0000">
                  {loadingStates[index]?.delete ? (
                    <CircularProgress size={22} thickness={5} style={{ color: '#fff' }} />
                  ) : (
                    <DeleteIcon size={25} style={{ color: '#fff' }} />
                  )}
                </S.IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      ))}
      <Box mt={4} display="flex" justifyContent="center" sx={{ width: '100%' }}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddField}>
          Adicionar Novo
        </Button>
      </Box>
    </S.Container>
  );
}
