import React, { useEffect, useRef, useState } from 'react';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import readXlsxFile from 'read-excel-file';
import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';
import { HeaderTitle } from '../../RelationshipClub/HeaderTitle';
import usePagination from '../../RelationshipClub/usePagination';

import { toast } from 'react-toastify';

import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
} from '@mui/material';

import { Pagination } from '@mui/material';

import { useStyles } from '../../RelationshipClub/useStyles';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { GetPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';
import { GoBackHeader } from '../../RelationshipClub/GoBackHeader';
import { ListAllFieldTypes } from 'js/library/utils/API/apiListAllTypes';
import { addAccessList } from 'js/library/utils/API/apiAddAccessList';

export default function AttachWhitelist({ history, ...props }) {
  const [clubeId] = useState(window.location.href.split('/')[7]);
  const partnerId = localStorage.getItem('partnerIdClube');
  const [clientList, setClientList] = useState([]);
  const [columnsTable, setColumnsTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [formInfos, setFormInfos] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [seuClubeInfos, setSeuClubeInfos] = useState({});
  const [page, setPage] = useState(1);

  const fileUploader = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId)
        .then((response) => {
          setSeuClubeInfos(response);
          GetPartnerUserForms(response.partnerId).then((result) => {
            setFormInfos(result);
            setLoading(false);
            setColumnsTable([result.tokenType]);
          });
          ListAllFieldTypes().then((result) => {
            setFieldTypes(result);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [clubeId]);

  const handleExportExcelFile = () => {
    const data = [[formInfos.tokenType]];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

    worksheet['!cols'] = [{ width: 15 }];

    // Conversão do arquivo para o formato binário
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Criação do Blob para salvar o arquivo
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Nome do arquivo
    const fileName = 'template.xlsx';

    // Salva o arquivo
    saveAs(blob, fileName);
  };

  const validateField = (fieldName, value) => {
    if (!value) return { value: '', valid: false };

    switch (fieldName) {
      case 'cellPhone': {
        const phoneRegex = /^\+55\d{11}$/;
        return { value: value.toString(), valid: phoneRegex.test(value.toString()) };
      }
      case 'cpf': {
        const cpf = value.toString().replace(/\D+/g, '');
        const isValidCpf = cpf.length === 11 && !/^(\d)\1{10}$/.test(cpf);
        return { value: cpf, valid: isValidCpf };
      }
      default:
        return { value: value.toString(), valid: true };
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const fileExt = file.name.split('.').find((item) => item === 'xlsx');

    if (fileExt === 'xlsx' && fileExt !== undefined) {
      const fileXlsx = await readXlsxFile(event.target.files[0]);

      const columns = fileXlsx[0];
      const data = fileXlsx.slice(1);

      const formattedData = data.map((row) => {
        const obj = {};
        columns.forEach((column, columnIndex) => {
          const value = row[columnIndex];

          obj[column] = validateField(column, value);
        });
        return obj;
      });
      setClientList(formattedData);
    } else {
      toast.error('Arquivo não é no formato .xlsx');
    }
  };

  const PER_PAGE = 10;

  const count = Math.ceil(clientList.length / PER_PAGE);
  const _DATA = usePagination(clientList, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingRegister(true);

    const filteredUserInfo = clientList.filter((row) => row[formInfos.tokenType]);

    const verifyingFieldsInvalids = clientList.filter((row) => {
      let fields = [];
      for (let key in row) {
        if (row[key].valid === false) fields.push(row[key]);
      }
      return fields.length > 0;
    });

    if (verifyingFieldsInvalids.length > 0) {
      toast.error(
        `${verifyingFieldsInvalids.length} usuário(s) com dados inválidos! Por favor, corrija para enviar novamente!`
      );
      setLoadingRegister(false);
      return;
    }

    if (filteredUserInfo.length === 0) {
      toast.error(
        `O ${
          formInfos.tokenType === 'cellPhone' ? 'número de celular' : formInfos.tokenType
        } é obrigatório`
      );
      setLoadingRegister(false);
      return;
    }

    try {
      const usersData = clientList.map((row) => {
        const userPartnerId = row[formInfos.tokenType].value;
        return {
          partnerInfo: {
            partnerId: seuClubeInfos.partnerId,
          },
          userPartnerId: userPartnerId,
        };
      });

      const payload = { usersPartnersData: usersData };

      const response = await addAccessList(payload);
      if (response.message === 'Usuários adicionados com sucesso') {
        toast.success('Permissões cadastradas com sucesso.');
        setClientList([]);
      } else {
        toast.error('Erro ao cadastrar usuários.');
      }
    } catch (error) {
      toast.error('Ops, usuários não foram cadastrados.');
    } finally {
      setLoadingRegister(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
          width: '100%',
        }}
      >
        <CircularProgress size="6rem" />
      </div>
    );
  }
  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <Container maxWidth={false} className={classes.root}>
        <HeaderTitle pageInfo="Cadastro em lote de permissões" />

        <Grid item xs={12} className={classes.boxInportFiles}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                Carregue um arquivo no formato Excel com o seguinte padrão:
                <br />
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleExportExcelFile}
                >
                  Exportar template
                </Button>
              </Typography>
              <br />
              <Typography variant="subtitle1" style={{ fontWeight: 600, marginTop: '22px' }}>
                <div>
                  {formInfos.tokenType === 'cellPhone'
                    ? '1: O dado "cellphone" é obrigatório para inclusão do usuário na lista de permissão.'
                    : `1: O dado "${formInfos.tokenType}" é obrigatório para inclusão do usuário na lista de permissão.`}
                </div>
                <div>
                  2: O número de telefone deve seguir obrigatoriamente o formato: (DDD) + telefone
                  (exemplo: +5511970697706)
                </div>
              </Typography>{' '}
              <br />
              <div>
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => fileUploader.current.click()}
                >
                  Carregar arquivo
                </Button>
                <input
                  type="file"
                  accept=".xlsx"
                  ref={fileUploader}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.boxListUsers}>
          {clientList.length > 0 && (
            <>
              <strong>Usuários Listados: </strong>
              {clientList.length}
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columnsTable.map((column, index) => (
                        <TableCell align="left" key={index}>
                          <strong>{column}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_DATA.currentData().map((client, index) => (
                      <TableRow key={index}>
                        {columnsTable.map((item, indexColumns) => (
                          <TableCell
                            key={indexColumns}
                            style={{
                              backgroundColor:
                                client[item] && !client[item].valid ? 'red' : '#ffff',
                            }}
                            align="left"
                          >
                            {client[item] ? client[item].value : ''}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Pagination
                sx={{ mt: 3 }}
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />

              <div align="center" style={{ marginTop: 15 }}>
                <Button
                  style={{
                    fontWeight: '550',
                    color: loadingRegister ? '#383838' : '#fff',
                    textTransform: 'capitalize',
                    marginBottom: '2rem',
                    background: loadingRegister ? '#dbdbdb' : undefined,
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleSubmit}
                  disabled={loadingRegister}
                >
                  {loadingRegister && (
                    <CircularProgress size="23px" style={{ marginRight: '0.5rem' }} />
                  )}
                  Cadastrar todos
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
