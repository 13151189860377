import { findStoreByProximity } from 'js/library/utils/API/CampaignLandinPage/apiFindStoreByProximity';

const getListPromotions = async (title, isPublished, from, size) => {
  const data = await findStoreByProximity(title, isPublished, from, size);
  if (data?.success) {
    return data.response;
  }
};

export default { getListPromotions };
