import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../../cfac22';

export function establishmentApproval(uId, establishmentId, comment, status) {
  return new Promise(async (resolve, reject) => {
    authToken(uId).then((token) => {
      const config = {
        method: 'patch',
        url: `${cfac22('API_HOST_V2')}/establishments/pending/${establishmentId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: {
          payload: {
            comment: comment.length !== 0 ? comment : ' ',
            status: status,
          },
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
