import React from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { AreaHeader, GoBack } from '../styles';
import { useModal } from 'js/components/Establishment/Context/Attendance';

export function GoBackHeader({ history }) {
  // para o modal de quando clica na opção 'dar cupom', que só aparece no hover das promoções, na tela de atendimento.
  const { setOpenModal } = useModal();

  return (
    <>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '1rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
            setOpenModal(false);
          }}
        >
          <MdArrowBackIosNew style={{ justifyContent: 'center' }} /> Voltar
        </GoBack>
      </AreaHeader>
    </>
  );
}
