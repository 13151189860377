import React, { useEffect, useState } from 'react';

import Switch from 'react-switch';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import * as E from 'js/components/DesignSystem/Table/styles';
import { Add } from '@mui/icons-material';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { Button, Tooltip } from '@mui/material';
import { ContainerButtons, ContentSwitchSegment, InvisbleButton } from './segmentStyles';
import {
  MdArrowBackIosNew,
  MdEditDocument,
  MdPeopleAlt,
  MdOutlineAssignment,
  MdAddLink,
} from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';

// COMPONENTS
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import CustomButtonsTableApiPaginated from 'js/components/TriiboComponents/Tables/CustomButtonsTable/CustomButtonsTableApiPaginated';

// APIS
import { getAllResourceSegment } from 'js/library/utils/API/Segmentation/getAllResourceSegment';
import { getUserSegment } from 'js/library/utils/API/Segmentation/getUserSegment';

export function SegmentationList() {
  let isSuperAdmin = useSelector((state) => state.authModel.superUser);
  const partnerId = localStorage.getItem('partnerIdClube');

  const [isEmpty, setIsEmpty] = useState(false);
  const [peopleSegmentList, setPeopleSegmentList] = useState([]);
  const [resourceSegmentList, setResourceSegmentList] = useState([]);
  const [segmentType, setSegmentType] = useState(false);
  const [resourceData, setResourceData] = useState({});
  const [userData, setUserData] = useState({});

  const [data, setData] = useState({
    size: 21,
    from: 0,
  });

  const [itemsPerPage, setItemsPerPage] = useState(0);

  // BUSCA AS INFORMAÇÔES DA GETUSERSEGMENT E GETALLRESOURCESEGMENT
  useEffect(() => {
    const payloadResource = {
      partnerId: partnerId,
    };

    const payloadPeople = {
      ...data,
      partnerId: partnerId,
    };

    segmentType
      ? getAllResourceSegment(payloadResource).then((res) => {
          // Limpa a variavel ao alternar o switch para recursos
          setPeopleSegmentList([]);
          setResourceData(res);
          const resourceSegment = Object.keys(res).map((id) => {
            const resource = res[id];
            const quantity = resource.id && Object.values(resource.id).length;
            const numberOfMembers = Object.keys(resource.partnerId).length;
            return {
              ...resource,
              id: id,
              active: resource.active ? 'Ativo' : 'Inativo',
              quantity: quantity ?? 'N/A',
              numberOfMembers,
            };
          });

          resourceSegment?.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
          setResourceSegmentList(resourceSegment);
        })
      : getUserSegment(payloadPeople)
          .then((res) => {
            setUserData(res);
            setItemsPerPage(res.length);
            const peopleSegment = res.map((people) => {
              const quantity = people.uid && Object.values(people.uid).length;
              return {
                ...people,
                active: people.active ? 'Ativo' : 'Inativo',
                quantity: quantity ?? 'N/A',
              };
            });

            peopleSegment?.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
            setPeopleSegmentList((prevList) => [
              ...prevList, // Mantém os valores antigos
              ...peopleSegment, // Adiciona os novos
            ]);
          })
          .catch((error) => {
            toast.error('Ocorreu um erro ao buscar os segmentos.');
          });
  }, [segmentType, data]);

  const column = [
    {
      heading: 'Nome',
      value: 'name',
    },
    {
      heading: 'Quantidade',
      value: 'quantity',
    },
    {
      heading: 'Status',
      value: 'active',
    },
  ];

  // RENDERIZA AS AÇÕES DA TABELA
  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <E.TableTH>Ação</E.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <E.TableTD>
              {segmentType ? (
                (isSuperAdmin || childrens.numberOfMembers === 1) && (
                  <>
                    <Tooltip
                      title="Editar segmentação"
                      placement="top"
                      style={{ marginRight: '1.2rem' }}
                    >
                      <InvisbleButton
                        $color="#08bad0"
                        to={`${
                          !segmentType ? 'editar-segmento-pessoa/' : 'editar-segmento-recurso/'
                        }${childrens.id}`}
                      >
                        <FaEdit size={25} />
                      </InvisbleButton>
                    </Tooltip>
                    <Tooltip
                      title="Lista de recursos"
                      placement="top"
                      style={{ marginRight: '1.2rem' }}
                    >
                      <InvisbleButton
                        $color="#08bad0"
                        to={{
                          pathname: `lista-recursos/${childrens.id}`,
                          state: { type: segmentType },
                        }}
                      >
                        <MdOutlineAssignment size={25} />
                      </InvisbleButton>
                    </Tooltip>
                  </>
                )
              ) : (
                <>
                  <Tooltip
                    title="Editar segmentação"
                    placement="top"
                    style={{ marginRight: '1.2rem' }}
                  >
                    <InvisbleButton
                      $color="#08bad0"
                      to={`${
                        !segmentType ? 'editar-segmento-pessoa/' : 'editar-segmento-recurso/'
                      }${childrens.id}`}
                    >
                      <FaEdit size={25} />
                    </InvisbleButton>
                  </Tooltip>
                  <Tooltip
                    title="Lista de usuários"
                    placement="top"
                    style={{ marginRight: '1.2rem' }}
                  >
                    <InvisbleButton
                      $color="#08bad0"
                      to={{
                        pathname: `lista-usuarios/${childrens.id}`,
                        state: { type: segmentType },
                      }}
                    >
                      <MdPeopleAlt size={25} />
                    </InvisbleButton>
                  </Tooltip>
                </>
              )}

              <Tooltip title="Gerenciar regras" placement="top" style={{ marginRight: '1.2rem' }}>
                <InvisbleButton
                  $color="#08bad0"
                  to={{
                    pathname: `${
                      !segmentType ? 'regras-segmento-pessoa/' : 'regras-segmento-recurso/'
                    }${childrens.id}`,
                    state: segmentType
                      ? resourceData[childrens.id]
                      : userData.find((item) => item.id === childrens.id),
                  }}
                >
                  <MdEditDocument size={25} />
                </InvisbleButton>
              </Tooltip>

              {!segmentType && (
                <Tooltip title="Linkar recursos" placement="top" style={{ marginRight: '1.2rem' }}>
                  <InvisbleButton
                    $color="#08bad0"
                    to={{
                      pathname: `linkar-tipo/${childrens.id}`,
                    }}
                  >
                    <MdAddLink size={25} />
                  </InvisbleButton>
                </Tooltip>
              )}
            </E.TableTD>
          </>
        ),
    };
  };

  return (
    <S.Container style={{ paddingBottom: '200px' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        Segmentação
      </D.TriiboH1>

      <ContainerButtons>
        <ContentSwitchSegment>
          Pessoas
          <Switch
            checked={segmentType}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor="#08BAD0"
            onColor="#08BAD0"
            onChange={() => setSegmentType(!segmentType)}
          />
          Recursos
        </ContentSwitchSegment>
        <InvisbleButton to={`adicionar-segmento/${partnerId}`}>
          <Button variant="contained" color="primary" startIcon={<Add />}>
            <strong>Adicionar segmentação</strong>
          </Button>
        </InvisbleButton>
      </ContainerButtons>
      {isEmpty ? (
        <strong>Ainda não existem informações cadastradas.</strong>
      ) : segmentType ? (
        // RENDERIZA TABELA DE RECURSOS
        <CustomButtonsTable
          data={resourceSegmentList}
          column={column}
          perPage={10}
          componentReceiver={componentReceiver}
        />
      ) : (
        // RENDERIZA A TABELA DE PESSOAS
        <CustomButtonsTableApiPaginated
          data={peopleSegmentList}
          column={column}
          perPage={10}
          setData={setData}
          itemsPerPage={itemsPerPage}
          size={data.size}
          componentReceiver={componentReceiver}
        />
      )}
    </S.Container>
  );
}

