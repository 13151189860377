import React from 'react';
import { ChromePicker } from 'react-color';

import { Box, Button, TextField, Typography } from '@mui/material';

export const ColorSelector = ({
  form,
  setForm,
  displayColorPickerButton,
  setDisplayColorPickerButton,
  displayColorPickerBg,
  setDisplayColorPickerBg,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: '1px solid #aaaaaa',
        padding: '1rem',
        width: '100%',
      }}
    >
      <span style={{ width: '100%', marginBottom: '0.8rem' }}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Cores:
        </Typography>
      </span>

      <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
        <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
          <TextField
            fullWidth
            label="Cor do botão"
            id="buttonColor"
            margin="normal"
            value={form?.buttonColor?.value || ''}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            sx={{
              backgroundColor: form.buttonColor?.value || '',
              width: '304px',
              height: 'inherit',
            }}
            variant="contained"
            onClick={() => setDisplayColorPickerButton((prevState) => !prevState)}
          >
            {displayColorPickerButton ? 'Salvar' : 'Selecione a cor'}
          </Button>
        </Box>
        {displayColorPickerButton && (
          <ChromePicker
            color={form?.buttonColor?.value}
            onChange={(event) =>
              setForm((prevState) => ({
                ...prevState,
                buttonColor: { ...prevState.buttonColor, value: event.hex },
              }))
            }
          />
        )}
      </Box>

      <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
        <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
          <TextField
            fullWidth
            label="Cor de fundo"
            id="cardsBackground"
            margin="normal"
            value={form?.cardsBackground?.value || ''}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            sx={{
              backgroundColor: form.cardsBackground?.value || '',
              width: '304px',
              height: 'inherit',
            }}
            variant="contained"
            onClick={() => setDisplayColorPickerBg((prevState) => !prevState)}
          >
            {displayColorPickerBg ? 'Salvar' : 'Selecione a cor'}
          </Button>
        </Box>
        {displayColorPickerBg && (
          <ChromePicker
            color={form?.cardsBackground?.value}
            onChange={(event) =>
              setForm((prevState) => ({
                ...prevState,
                cardsBackground: { ...prevState.cardsBackground, value: event.hex },
              }))
            }
          />
        )}
      </Box>
    </div>
  );
};
