import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import ModelLPSignUp from '../../LandingPageSignUp/model';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const CampaignEnrollmentModal = ({
  isActiveModals,
  closeModal,
  setPayload,
  selectedModalInfos,
}) => {
  const [displayColorPickerBgMessage, setDisplayColorPickerBgMessage] = useState(false);
  const [displayColorPickerText, setDisplayColorPickerText] = useState(false);
  const [displayColorPickerButton, setDisplayColorPickerButton] = useState(false);
  const { form, setForm, handleChange, validateForm, clearForm } = useForm({
    optin: {
      value: '',
      required: false,
    },
    backgroundColor: {
      value: '',
      required: false,
    },
    textColor: {
      value: '',
      required: false,
    },
    buttonColor: {
      value: '',
      required: false,
    },
    buttonLabel: {
      value: '',
      required: false,
    },
    active: {
      value: false,
      required: false,
    },
    messages: {
      value: [
        {
          isUserEnrolledInCampaign: { value: '', required: false },
          isUserLoggedOut: { value: '', required: false },
          isUserIneligible: { value: '', required: false },
          isCampaignClosed: { value: '', required: false },
        },
      ],
      required: false,
    },
  });

  const currentModal = ModelLPSignUp.modalsList.campaignEnrollment;

  useEffect(() => {
    if (isActiveModals) {
      const { optin, backgroundColor, textColor, buttonColor, buttonLabel, active, messages } =
        selectedModalInfos?.modalInfos || {};

      setForm((prevState) => ({
        ...prevState,
        optin: {
          value: optin || '',
          required: true,
        },
        backgroundColor: {
          value: backgroundColor || '#000000',
          required: true,
        },
        textColor: {
          value: textColor || '#000000',
          required: true,
        },
        buttonColor: {
          value: buttonColor || '#000000',
          required: optin === 'explicit',
        },
        buttonLabel: {
          value: buttonLabel || '',
          required: optin === 'explicit',
        },
        active: {
          value: active || false,
          required: true,
        },
        messages: {
          value: messages
            ? Object.entries(messages).reduce(
                (acc, [key, value]) => ({
                  ...acc,
                  [key]: {
                    value: value || '',
                    required: false,
                  },
                }),
                {}
              )
            : {},
          required: false,
        },
      }));
    }
  }, [isActiveModals, selectedModalInfos, setForm]);

  const handleSave = () => {
    const { optin, backgroundColor, textColor, buttonColor, buttonLabel, active, messages } = form;

    if (validateForm()) {
      setPayload((prevState) => {
        const modules = prevState.campaignLandpage.modules;

        const transformedMessages = Object.entries(messages.value).reduce(
          (acc, [key, { value }]) => {
            acc[key] = value;
            return acc;
          },
          {}
        );

        const moduleData = {
          ...selectedModalInfos.modalInfos,
          optin: optin?.value,
          backgroundColor: backgroundColor?.value,
          textColor: textColor?.value,
          active: active?.value,
          messages: transformedMessages,
          ...(optin?.value === 'explicit' && {
            buttonColor: buttonColor?.value,
            buttonLabel: buttonLabel?.value,
          }),
        };

        if (optin?.value === 'implicit') {
          delete moduleData.buttonColor;
          delete moduleData.buttonLabel;
        }

        modules[selectedModalInfos.currentIndex] = moduleData;

        return {
          campaignLandpage: {
            ...prevState?.campaignLandpage,
            modules,
          },
        };
      });

      toast.success('Adesão de campanha salvo com sucesso!');
      clearForm();
      closeModal(currentModal);
    } else {
      toast.error('Preencha todos os campos obrigatórios!');
    }
  };

  const handleCloseModal = () => {
    clearForm();
    closeModal(currentModal);
  };

  const handleChangeSelectType = (event) => {
    const { value } = event.target;

    setForm((prevForm) => ({
      ...prevForm,
      optin: {
        ...prevForm.optin,
        value: value,
      },
    }));
  };

  useEffect(() => {
    if (form.optin.value === 'implicit') {
      setForm((prevForm) => ({
        ...prevForm,
        buttonColor: {
          value: '#000000',
          required: false,
        },
        buttonLabel: {
          value: '',
          required: false,
        },
      }));
    }
  }, [form.optin.value]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Selecione um tipo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form?.optin.value}
              label="Selecione um tipo"
              onChange={handleChangeSelectType}
            >
              <MenuItem value="implicit">Implícito</MenuItem>
              <MenuItem value="explicit">Explícito</MenuItem>
            </Select>
          </FormControl>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor do fundo da mensagem"
              id="backgroundSummary"
              margin="normal"
              value={form?.backgroundColor?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.backgroundColor?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPickerBgMessage((prevState) => !prevState)}
            >
              {displayColorPickerBgMessage ? 'Salvar' : 'Selecione a cor'}
            </Button>
          </Box>
          {displayColorPickerBgMessage && (
            <ChromePicker
              color={form?.backgroundColor?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  backgroundColor: { ...prevState.backgroundColor, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor do texto da mensagem"
              id="backgroundDetails"
              margin="normal"
              value={form?.textColor?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.textColor?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPickerText((prevState) => !prevState)}
            >
              {displayColorPickerText ? 'Salvar' : 'Selecione a cor'}
            </Button>
          </Box>
          {displayColorPickerText && (
            <ChromePicker
              color={form?.textColor?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  textColor: { ...prevState.textColor, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        {form?.optin.value === 'explicit' && (
          <>
            <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
              <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
                <TextField
                  fullWidth
                  label="Cor do botão"
                  id="buttonColor"
                  margin="normal"
                  value={form?.buttonColor?.value || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Button
                  sx={{
                    backgroundColor: form.buttonColor?.value || '',
                    width: '304px',
                    height: 'inherit',
                  }}
                  variant="contained"
                  onClick={() => setDisplayColorPickerButton((prevState) => !prevState)}
                >
                  {displayColorPickerButton ? 'Salvar' : 'Selecione a cor'}
                </Button>
              </Box>
              {displayColorPickerButton && (
                <ChromePicker
                  color={form?.buttonColor?.value}
                  onChange={(event) =>
                    setForm((prevState) => ({
                      ...prevState,
                      buttonColor: { ...prevState.buttonColor, value: event.hex },
                    }))
                  }
                />
              )}
            </Box>

            <TextField
              fullWidth
              label="Texto do botão"
              id="buttonLabel"
              value={form?.buttonLabel?.value || ''}
              onChange={handleChange}
              margin="normal"
            />
          </>
        )}

        <Box sx={{ width: '100%' }}>
          <FormControlLabel
            control={<Switch id="active" checked={form.active.value} onChange={handleChange} />}
            label="Ativo"
          />
        </Box>

        <Box sx={{ width: '100%', marginTop: '20px' }}>
          <Typography sx={{ padding: 0 }}>Mensagens:</Typography>
        </Box>

        {[
          { id: 'isUserEnrolledInCampaign', label: 'Usuário já participando da campanha' },
          { id: 'isUserLoggedOut', label: 'Usuário deslogado' },
          { id: 'isUserIneligible', label: 'Usuário não elegível' },
          { id: 'isCampaignClosed', label: 'Campanha encerrada' },
        ].map(({ id, label }) => (
          <TextField
            key={id}
            fullWidth
            label={label}
            value={form.messages?.value?.[id]?.value || ''}
            onChange={(event) =>
              setForm((prevForm) => ({
                ...prevForm,
                messages: {
                  ...prevForm.messages,
                  value: {
                    ...prevForm.messages.value,
                    [id]: {
                      value: event.target.value,
                      required: prevForm.messages.value[id]?.required || false,
                    },
                  },
                },
              }))
            }
            margin="normal"
          />
        ))}
        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CampaignEnrollmentModal;
