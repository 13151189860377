import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Box,
  IconButton,
  FormControl,
  Grid,
  Popover,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import { toast } from 'react-toastify';
import { inputCpfInBatch } from 'js/library/utils/API/Campaigns/apiInputCpfInBatch';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import {
  blobToBase64,
  cellPhoneMask2,
  getUserInfo,
  separateBase64String,
} from 'js/library/utils/helpers';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';
import { getAllUsersByCampaignId } from 'js/library/utils/API/Campaigns/apiGetAllUsersByCampaignId';
import Pagination from '@mui/material/Pagination';
// ASSETS
import csvModel from '../../../../../styles/assets/usersCampaign/modelo-adicionar-membros-campanha.csv';
import txtModel from '../../../../../styles/assets/usersCampaign/modelo-adicionar-membros-campanha.txt';
//ICONES
import {
  Paperclip,
  FileText,
  Question,
  Trash,
  PencilSimpleLine,
  UserCirclePlus,
} from 'phosphor-react';
import { Send } from '@mui/icons-material';
import RenderInputDynamic from '../../SearchUser/RenderInputDynamic';
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';
import { IoIosClose } from 'react-icons/io';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import { updateTargetStatus } from 'js/library/utils/API/Campaigns/apiUpdateTargetStatus';
import { inputCpfStatus } from 'js/library/utils/API/Campaigns/apiInputCpfStatus';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { useLocation } from 'react-router-dom';

export default function ListUsersCampaign() {
  const location = useLocation();
  const [clubeId] = useState(location.state.owner);
  const { id: campaignId } = useParams();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataUser, setDataUser] = useState({ fileThumbnail: null });
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const fileInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const rowsPerPage = 21;
  const [sendMode, setSendMode] = useState(1);
  const [userSearch, setUserSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const [accessField, setAccessField] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const uId = getUserInfo().uId;

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId).then((result) => {
        localStorage.setItem('partnerIdClube', result.partnerId);
        setPartnerId(result.partnerId);
      });
    }
  }, [clubeId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const partner = await GetPartnerUserForms(partnerId);
        const indexKey = partner.formFields.filter((field) => field.indexKey);
        if (indexKey.length > 0) {
          setAccessField(indexKey[0]);
        }
      } catch (error) {
        console.error('Ocorreu um erro:', error);
      }
    };

    if (partnerId) fetchData();
  }, [partnerId]);

  useEffect(() => {
    if (campaignId) fetchUsers(page);
  }, [campaignId, page]);

  const fetchUsers = async (currentPage) => {
    try {
      setLoading(true);
      const from = (currentPage - 1) * rowsPerPage;
      const response = await getAllUsersByCampaignId(campaignId, from, rowsPerPage);

      if (response && response.result) {
        const fetchedUsers = response.result.map((user) => {
          const userKey = Object.keys(user).find((key) => key !== 'id' && key !== 'campaignId');
          return {
            id: user.id,
            userKey: userKey,
            status: user[userKey] || 'Status não disponível',
          };
        });

        setUsers(fetchedUsers);
        setHasMore(fetchedUsers.length === rowsPerPage);
      }
    } catch (error) {
      toast.error('Erro ao carregar usuários.');
    } finally {
      setLoading(false);
    }
  };

  const searchUnitary = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setUserData(null);
    setUsersList([]);
    try {
      const user = await ValidateUserPartner(userSearch, partnerId);
      const isDuplicate = usersList.some((obj) => obj.uId === user.uId);
      setLoadingSearchUser(false);

      if (!user.user) {
        setUserSearch('');
        setLoadingSearch(false);
        return toast.error('Usuário não encontrado.', { autoClose: 2000 });
      } else if (isDuplicate) {
        setUserSearch('');
        setLoadingSearch(false);
        return toast.error('Usuário já adicionado.', { autoClose: 2000 });
      } else {
        setUserData(user);
        setLoadingSearch(false);
        setUsersList((prevUser) => {
          return [...prevUser, { ...user, key: userSearch }];
        });

        setUserSearch('');
        setLoadingSearch(false);
      }
    } catch (err) {
      toast.error('Erro ao buscar usuário.');
      setLoadingSearch(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!dataUser.fileThumbnail) {
      newErrors.fileThumbnail = 'Selecionar um arquivo é obrigatório';
    }
    return newErrors;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return toast.error('Nenhum arquivo selecionado.');

    const validFileTypes = ['text/plain', 'text/csv'];
    if (!validFileTypes.includes(file.type)) {
      return toast.error('Tipo de arquivo inválido. Selecione um arquivo .txt ou .csv.');
    }

    setDataUser({ fileThumbnail: file });
    setFileName(file.name);
    setErrors({});
  };
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
  };
  const openHelper = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // useEffect(() => {
  //   handleSearch();
  // }, [searchTerm, users]);

  // const handleSearch = () => {
  //   const searchLower = searchTerm.toLowerCase();

  //   const filtered = users.filter((user) => {
  //     const userId = user.id ? user.id.toString().toLowerCase() : '';
  //     return userId.includes(searchLower);
  //   });

  //   setFilteredUsers(filtered);
  //   setPage(1);
  // };

  const handleUpdateStatus = async () => {
    if (!selectedUser || !selectedUser.status) {
      return toast.error('Por favor, selecione um status.');
    }

    try {
      setLoading(true);

      const userKey = selectedUser.userKey;
      const status = selectedUser.status;

      await updateTargetStatus(campaignId, status, userKey);
      toast.success('Status do usuário atualizado com sucesso!');

      setIsEditModalOpen(false);

      setUsers([]);
      setTimeout(() => fetchUsers(1), 500);
    } catch (error) {
      toast.error('Erro ao atualizar status.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitUser = async () => {
    if (usersList.length === 0) {
      return toast.error('Nenhum usuário disponível para cadastro.');
    }
    const userKey = usersList[0].key;

    try {
      await inputCpfStatus(userKey, 'eligible', campaignId);
      toast.success('Usuário cadastrado na campanha com sucesso!');
      setTimeout(() => fetchUsers(1), 500);
    } catch {
      toast.error('Erro ao cadastrar usuário na campanha.');
    }
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Por favor, selecione um arquivo válido.');
      return;
    }

    try {
      setLoading(true);
      const base64 = await blobToBase64(dataUser.fileThumbnail);
      const newFile = separateBase64String(base64);

      const uploadedFileName = await uploadFiles('campaignUserCpf', {
        mimeType: newFile.mimeType,
        fileName: `${campaignId}.${newFile.mimeType === 'text/csv' ? 'csv' : 'txt'}`,
        buffer: newFile.buffer,
      });

      const realFileName = uploadedFileName.split('/').pop().split('?')[0];

      const response = await inputCpfInBatch({
        fileName: `campaignUserCpf/${realFileName}`,
        campaignId: campaignId,
      });

      const quantityLinesNotImported = response?.result?.quantityLinesNotImported || 0;

      if (quantityLinesNotImported > 0) {
        toast.error(
          `${quantityLinesNotImported} usuário(s) não seguem o padrão de cadastro. Verifique o arquivo e tente novamente.`
        );

        console.warn('Linhas não importadas:', response?.result?.linesNotImported);
        return;
      }

      toast.success('Arquivo enviado com sucesso!');
      fetchUsers(1);
      setIsModalOpen(false);
      setDataUser({ fileThumbnail: null });
      setFileName('');
    } catch (error) {
      toast.error(error.message || 'Erro ao enviar o arquivo.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <S.ContainerUserCampaign>
      <S.Container>
        <GoBackHeader />
        <D.TriiboH1 style={{ textAlign: 'center' }}>Membros da Campanha</D.TriiboH1>
        <S.SearchSection>
          {/* <S.InputBox>
            <S.Input
              placeholder="Procurar membro da campanha por chave"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <S.SearchIconButton onClick={handleSearch}>
              <MagnifyingGlass size={25} weight="bold" />
            </S.SearchIconButton>
          </S.InputBox> */}

          <S.AddUserButton onClick={() => setIsModalOpen(true)}>
            <span>+</span>
            <D.TriiboH4>Adicionar Membro</D.TriiboH4>
          </S.AddUserButton>
        </S.SearchSection>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <strong>Chave do Membro</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Ação</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((user) => (
                    <TableRow key={user.userKey}>
                      <TableCell align="left">{user.userKey}</TableCell>
                      <TableCell align="left">{user.status}</TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleEditUser(user);
                          }}
                          sx={{ marginTop: '0.4rem' }}
                        >
                          <PencilSimpleLine size={25} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="body2">Nenhum usuário encontrado.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          sx={{ mt: 3 }}
          count={hasMore ? page + 1 : page}
          page={page}
          size="large"
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
        {/* Modal Editar Status*/}
        <Modal open={isEditModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box
            sx={{
              outline: 'none',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 300, md: 400 },
              maxWidth: 600,
              borderRadius: '15px',
              bgcolor: 'background.paper',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <S.Header>
              <S.CloseModalButton onClick={handleCloseModal}>
                <IoIosClose size={45} />
              </S.CloseModalButton>
            </S.Header>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  mb: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <D.TriiboH3>Editar status do usuário</D.TriiboH3>
              </Box>
              <Box>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel>Status</InputLabel>

                  <Select
                    value={selectedUser ? selectedUser.status : ''}
                    label="Status"
                    onChange={(e) => setSelectedUser({ ...selectedUser, status: e.target.value })}
                  >
                    <MenuItem value={'eligible'}>Eligível</MenuItem>
                    <MenuItem value={'notEligible'}>Não eligível</MenuItem>
                    <MenuItem value={'rescued'}>Resgatado</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize',
                fontWeight: '550',
                width: '100px',
                mt: 2,
              }}
              variant="contained"
              color="primary"
              startIcon={!loading && <Send />}
              disabled={loading}
              onClick={handleUpdateStatus}
            >
              {loading ? (
                <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
              ) : (
                'Enviar'.toUpperCase()
              )}
            </Button>
          </Box>
        </Modal>
        {/* Modal Adicionar Membro*/}
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box
            sx={{
              outline: 'none',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 400, md: 500 },
              maxWidth: 600,
              borderRadius: '15px',
              bgcolor: 'background.paper',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <S.Header>
              <S.CloseModalButton onClick={handleCloseModal}>
                <IoIosClose size={45} />
              </S.CloseModalButton>
            </S.Header>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <D.TriiboH3>Adicionar novo membro</D.TriiboH3>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', my: 2, gap: '25px' }}
            >
              <Button
                variant="contained"
                onClick={() => setSendMode(1)}
                color={sendMode === 1 ? 'primary' : 'secondary'}
                sx={{
                  transition: '1000ms',
                  transform: sendMode === 1 ? 'scale(1.0)' : 'scale(0.9)',
                  opacity: sendMode === 1 ? 1 : 0.25,
                }}
              >
                Envio por arquivo
              </Button>
              <Button
                variant="contained"
                onClick={() => setSendMode(2)}
                color={sendMode === 2 ? 'primary' : 'secondary'}
                sx={{
                  transition: '1000ms',
                  transform: sendMode === 2 ? 'scale(1.0)' : 'scale(0.9)',
                  opacity: sendMode === 2 ? 1 : 0.25,
                }}
              >
                Selecionar usuários
              </Button>
            </Box>

            {sendMode === 1 && (
              <>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Carregue um arquivo nos formatos <strong>.txt</strong> ou <strong>.csv</strong>{' '}
                  contendo a chave e o status do(s) membro(s) da campanha. Para simplificar, baixe o
                  template disponível, edite-o conforme necessário e faça o upload.
                </Typography>
                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                  <Typography variant="subtitle2">
                    <a href={txtModel} download>
                      baixar template .txt
                    </a>{' '}
                    |{' '}
                    <a href={csvModel} download>
                      baixar template .csv
                    </a>
                  </Typography>
                  <IconButton color="secondary" onClick={openHelper}>
                    <Question size={30} weight="fill" />
                  </IconButton>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<Paperclip size={20} />}
                  onClick={() => fileInputRef.current.click()}
                  fullWidth
                >
                  Anexar arquivo
                </Button>
                {fileName && (
                  <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                    <FileText size={13} /> {fileName}
                  </Typography>
                )}
                <input
                  type="file"
                  accept=".txt, .csv"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Button
                  sx={{
                    mt: 2,
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: '550',
                    backgroundColor: '#6E3296',
                    '&:hover': {
                      backgroundColor: '#8740B3',
                    },
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  startIcon={!loading && <Send />}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
                  ) : (
                    'Enviar'.toUpperCase()
                  )}
                </Button>

                {/* Popover da Question */}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <Typography
                      variant="body1"
                      style={{ paddingLeft: '0px', marginBottom: '10px' }}
                    >
                      <ul>
                        <li> • No arquivo do tipo .txt, não coloque espaço entre os campos.</li>
                        <li> • Separe os campos por ponto e vírgula.</li>
                      </ul>
                    </Typography>
                  </div>
                </Popover>
              </>
            )}

            {sendMode === 2 && (
              <FormControl fullWidth margin="normal">
                <Grid sx={{ padding: '1rem' }}>
                  {loadingSearchUser ? (
                    <CircularProgress />
                  ) : (
                    <RenderInputDynamic
                      fieldInfos={accessField}
                      inputValue={userSearch}
                      setInputValue={setUserSearch}
                      handleSubmit={searchUnitary}
                      loadingSearch={loadingSearch}
                      customIcon={<UserCirclePlus size={35} weight="fill" color="#6E3296" />}
                    />
                  )}
                  {usersList.length > 0 && (
                    <Grid item xs={12} sx={{ marginTop: '25px' }}>
                      {usersList.map((item, index) => (
                        <Box
                          key={item.uId}
                          component="span"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              const tempUL = [...usersList];
                              tempUL.splice(index, 1);
                              setUsersList([...tempUL]);
                            }}
                          >
                            <Trash color="#da0000" />
                          </IconButton>
                          <Typography variant="body1" sx={{ pl: '0px' }}>
                            {item.cellPhone !== item.key
                              ? 'Chave: ' +
                                item.key +
                                ` || Telefone do usuário: ${cellPhoneMask2(item.cellPhone)}`
                              : `Telefone do usuário: ${cellPhoneMask2(item.key)}`}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Button
                  sx={{
                    m: 2,
                    width: '100px',
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: '550',
                    backgroundColor: '#6E3296',
                    '&:hover': {
                      backgroundColor: '#8740B3',
                    },
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={!loading && <Send />}
                  disabled={loading}
                  onClick={handleSubmitUser}
                >
                  {loading ? (
                    <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
                  ) : (
                    'Enviar'.toUpperCase()
                  )}
                </Button>
              </FormControl>
            )}
          </Box>
        </Modal>
      </S.Container>
    </S.ContainerUserCampaign>
  );
}
