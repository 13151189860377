import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function LoginHistory(data) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/login/history',
          {
            ...data,
          },
          { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  });
}
