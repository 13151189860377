import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

import { getStoreByEstablishment } from './apiGetStoreByEstablishment';
import cfac22 from '../../cfac22';

let status;

// Função para verificar o valor de 'Lista de promoções' no localStorage e atualizar o status
function updateStatusFromLocalStorage() {
  const promotionTypeStorage = localStorage.getItem('Lista de promoções');
  const promotionType = Number(promotionTypeStorage);

  // Atualiza o status de acordo com o valor
  if (promotionType === 0) {
    status = true; // Promoção ativa
  } else {
    status = false; // Promoção inativa
  }
}

// Inicializa o status no carregamento da página
updateStatusFromLocalStorage();

// Verifica continuamente o valor de 'Lista de promoções' a cada 1 segundo
setInterval(() => {
  updateStatusFromLocalStorage();
}, 1000);

function getListPromotion(establishmentId, establishmentName, promotionId) {
  return new Promise((resolve, reject) => {
    getStoreByEstablishment(establishmentId, 0, 1000, status)
      .then(async (dataReceived) => {
        const dataPromotions = [];

        await Promise.all(
          dataReceived.map((data) => {
            const [, item] = Object.entries(data)[0];
            item.storeInfo.key = item.storeInfo.id;
            item.storeInfo['downloadedThumb'] = placeholder;

            if (!item.storeInfo?.id) {
              const establishmentIdKey = Object.keys(data)[0];
              item.storeInfo.id = establishmentIdKey;
            }

            if (item.storeApprovals) {
              const firstApproval = Object.values(item.storeApprovals)[0];
              item.storeInfo.aprovacao = firstApproval;
            }

            if (item.storeEdits) {
              const dataStoreEdits = Object.values(item.storeEdits)[0];
              item.storeInfo.edicao = dataStoreEdits;
            }

            dataPromotions.push(item.storeInfo);
          })
        );

        const result = dataPromotions.filter((promo) => promo.id === promotionId);

        resolve(result[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateStoreApprovals(uId, storeId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (storeId !== null) {
      authToken(uId)
        .then((token) => {
          axios
            .patch(
              `${cfac22('API_HOST_V2')}/stores/pending/${storeId}`,
              { payload },
              {
                headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
              }
            )
            .then((result) => {
              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.storeInfos.id = storeId;

              resolve(result.data.storeInfos);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

export function updateStore(storeId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (storeId !== null) {
      authToken()
        .then((token) => {
          axios
            .patch(
              `${cfac22('API_HOST_V2')}/stores/${storeId}`,
              { payload },
              { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
            )
            .then(async (result) => {
              if (window?.promoListOrganized) {
                const promotionId = result.data.storeInfo.id;
                const originalPromotionList = window.promoListOrganized?.originalPromotionList;
                const promotionListActive = window.promoListOrganized?.promotionListActive;
                const newFilteredPromotionList =
                  window.promoListOrganized?.newFilteredPromotionList;

                try {
                  const updatedPromotion = await getListPromotion(
                    result.data.storeInfo.establishmentId,
                    result.data.storeInfo.establishmentName,
                    promotionId
                  );

                  if (originalPromotionList) {
                    originalPromotionList.forEach((promo, index) => {
                      if (promo.id === promotionId) {
                        originalPromotionList.splice(index, 1, updatedPromotion);
                      }
                    });
                  }

                  if (promotionListActive) {
                    promotionListActive.forEach((promo, index) => {
                      if (promo.id === promotionId) {
                        promotionListActive.splice(index, 1, updatedPromotion);
                      }
                    });
                  }

                  if (window.promoListOrganized?.newFilteredPromotionList) {
                    newFilteredPromotionList.forEach((promo, index) => {
                      if (promo.id === promotionId) {
                        const isActive =
                          promo.isPublished === true &&
                          promo.enableDate <= Date.now() &&
                          promo.disableDate >= Date.now();
                        const isAwaiting = promo.aprovacao?.status === 'aberto';

                        if (isActive || isAwaiting) {
                          newFilteredPromotionList.splice(index, 1, updatedPromotion);
                        } else {
                          newFilteredPromotionList.splice(index, 1);
                        }
                      }
                    });
                  }
                } catch (error) {
                  console.log('***Error get', error);
                }
              }

              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.storeInfo.id = storeId;

              resolve(result.data.storeInfo);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

export function createStoreChange(uId, establishmentId, establishmentName, storeId, data, dueDate) {
  return new Promise(async (resolve, reject) => {
    authToken(uId).then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/storeChanges/createStoreChange`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          payload: {
            establishmentId: establishmentId,
            establishmentName: establishmentName,
            storeId: storeId,
            dueDate: dueDate,
            changedFields: data,
          },
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data.storeInfo);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
