import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import useForm from '../../Hooks/useForm';
import { useParams } from 'react-router';

import { Box, Button, CircularProgress, Modal } from '@mui/material';

// COMPONENTS
import { CardItem } from './CardItem';
import { ColorSelector } from './ColorSelector';
import { EdgeSelector } from './EdgeSelector';
import ModelCardsGridModal from './model';
import ModelLPSignUp from '../../LandingPageSignUp/model';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const CardsGridModal = ({ isActiveModals, closeModal, setPayload, selectedModalInfos }) => {
  const [displayColorPickerButton, setDisplayColorPickerButton] = useState(false);
  const [displayColorPickerBg, setDisplayColorPickerBg] = useState(false);
  const [displayColorPickerBorder, setDisplayColorPickerBorder] = useState(false);
  const [loading, setLoading] = useState(false);

  const { form, setForm, validateForm, clearForm } = useForm({
    border: {
      tickness: {
        value: '',
        required: false,
      },
      style: {
        value: '',
        required: true,
      },
      color: {
        value: '',
        required: false,
      },
    },
    buttonColor: {
      value: '',
      required: false,
    },
    cardVariant: {
      value: 'icon',
      required: true,
    },
    cardsBackground: {
      value: '',
      required: true,
    },
    columnsNumber: {
      value: 1,
      required: true,
    },
    cardsList: [
      {
        image: { value: '', required: true },
        text: { value: '', required: true },
        title: { value: '', required: true },
        actionButton: {
          buttonLabel: { value: '', required: false },
          target: { value: '', required: false },
          url: { value: '', required: false },
        },
      },
    ],
  });

  const currentModal = ModelLPSignUp.modalsList.cardsGrid;
  const modalRef = useRef();
  const params = useParams();

  const handleSave = async () => {
    setLoading(true);
    const { border, buttonColor, cardVariant, cardsBackground, columnsNumber } = form;
    const borderStyle = `${border?.tickness?.value}px ${border?.style?.value} ${border?.color?.value}`;

    if (validateForm()) {
      if (form?.cardsList && form.cardsList.length > 0) {
        const updatedCardsList = await Promise.all(
          form.cardsList.map(async (card) => {
            if (card?.image?.value && card.image.value.includes('base64')) {
              const imgPath = await ModelCardsGridModal.uploadImg(card.image.value, params?.id);
              return {
                ...card,
                image: { value: imgPath, required: card.image.required },
              };
            }
            return card;
          })
        );

        setForm((prevState) => ({
          ...prevState,
          cardsList: updatedCardsList,
        }));

        setPayload((prevState) => {
          const modules = prevState.campaignLandpage.modules;

          const extractedValues = updatedCardsList.map((card) => ({
            image: card.image.value,
            text: card.text.value,
            title: card.title.value,
            actionButton: {
              buttonLabel: card.actionButton.buttonLabel.value,
              target: card.actionButton.target.value,
              url: card.actionButton.url.value,
            },
          }));

          modules[selectedModalInfos.currentIndex] = {
            ...selectedModalInfos.modalInfos,
            border: border?.style.value === 'none' ? 'none' : borderStyle,
            buttonColor: buttonColor?.value,
            cardVariant: cardVariant?.value,
            cardsBackground: cardsBackground?.value,
            type: 'cardsGrid',
            columnsNumber: +columnsNumber?.value,
            cardsList: extractedValues,
          };

          return {
            campaignLandpage: {
              ...prevState?.campaignLandpage,
              modules,
            },
          };
        });

        toast.success('Card salvo com sucesso!');
        setLoading(false);
        clearForm();
        closeModal(currentModal);
      }
    } else {
      toast.error('Preencha todos os campos obrigatórios!');
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    clearForm();
    closeModal(currentModal);
    setDisplayColorPickerBorder(false);
  };

  const handleChangeSlider = ({ target: { name, value } }) => {
    setForm((prevForm) => {
      const keys = name.split('.');
      let updatedForm = { ...prevForm };

      keys.reduce((acc, key, idx) => {
        if (idx === keys.length - 1) {
          acc[key].value = value;
        }
        return acc[key];
      }, updatedForm);

      return updatedForm;
    });
  };

  useEffect(() => {
    if (isActiveModals) {
      const { border, buttonColor, cardVariant, cardsBackground, cardsList, columnsNumber } =
        selectedModalInfos?.modalInfos;

      const borderDestructured = border?.split(' ');

      const ticknessValue =
        borderDestructured && borderDestructured[0] ? borderDestructured[0].replace('px', '') : 0;

      const processedCardsList = cardsList?.map((card) => ({
        image: {
          value: card?.image || '',
          required: true,
        },
        text: {
          value: card?.text || '',
          required: true,
        },
        title: {
          value: card?.title || '',
          required: true,
        },
        actionButton: {
          buttonLabel: {
            value: card?.actionButton?.buttonLabel || '',
            required: false,
          },
          target: {
            value: card?.actionButton?.target || '',
            required: false,
          },
          url: {
            value: card?.actionButton?.url || '',
            required: false,
          },
        },
      }));

      setForm((prevState) => ({
        ...prevState,
        border: {
          tickness: {
            value: ticknessValue || 0, // Remover 'px' de tickness
            required: false,
          },
          style: {
            value: (borderDestructured && borderDestructured[1]) || 'none',
            required: false,
          },
          color: {
            value: (borderDestructured && borderDestructured[2]) || '#000000',
            required: false,
          },
        },
        buttonColor: {
          value: buttonColor || '#000000',
          required: false,
        },
        cardVariant: {
          value: cardVariant || 'icon',
          required: true,
        },
        cardsBackground: {
          value: cardsBackground || '#000000',
          required: true,
        },
        columnsNumber: {
          value: columnsNumber || 1,
          required: true,
        },
        cardsList: processedCardsList,
      }));
    }
  }, [isActiveModals, selectedModalInfos]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        gap={'1rem'}
        sx={style}
      >
        <EdgeSelector
          form={form}
          setForm={setForm}
          handleChangeSlider={handleChangeSlider}
          ModelCardsGridModal={ModelCardsGridModal}
          displayColorPickerBorder={displayColorPickerBorder}
          setDisplayColorPickerBorder={setDisplayColorPickerBorder}
        />

        <ColorSelector
          form={form}
          setForm={setForm}
          displayColorPickerButton={displayColorPickerButton}
          setDisplayColorPickerButton={setDisplayColorPickerButton}
          displayColorPickerBg={displayColorPickerBg}
          setDisplayColorPickerBg={setDisplayColorPickerBg}
        />

        <CardItem form={form} setForm={setForm} modalRef={modalRef} />

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ minWidth: '6rem' }}
          >
            {loading ? <CircularProgress size={25} tickness={5} /> : 'Salvar'}
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CardsGridModal;
