import dispatcher from 'js/core/dispatcher';

import { updateDatabase } from 'js/library/services/DatabaseManager';

import { uploadImage } from 'js/library/services/StorageManager';
import { GenerateLuckyNumbers } from 'js/library/utils/API/apiLuckyNumbers';

import { convertToMillis, b64toBlob } from 'js/library/utils/helpers';

export const updateCampaingTriiboAction = async (
  dispatch,
  walletTitle,
  walletDetailsTitle,
  walletDescription,
  walletDetailsDescription,
  walletThumbnail,
  walletPhoto,
  thumbnailFile,
  photoFile,
  lotterySerie,
  notificationMessage,
  quizzes,
  events,
  voucherEach,
  voucherQuantity,
  activationDate,
  dueDate,
  establishmentId,
  establishmentName,
  campaingId,
  campaingName,
  numbersLucky,
  serieStart,
  serieEnd,
  isUpdateCampaign
) => {
  if (thumbnailFile !== null && thumbnailFile !== undefined) {
    walletThumbnail = 'thumb_' + lotterySerie;
    thumbnailFile = b64toBlob(thumbnailFile);
  }

  if (photoFile !== null && photoFile !== undefined) {
    walletPhoto = 'photo_' + lotterySerie;
    photoFile = b64toBlob(photoFile);
  }

  activationDate = convertToMillis(activationDate);
  dueDate = convertToMillis(dueDate);

  const storageFolder = 'voucher';

  let pathname = '/admin/configuracoes/sorteios';

  // Verifica se os valores já estão no formato correto
  const startsAt = String(serieStart).endsWith('00000')
    ? Number(serieStart)
    : Number(serieStart) * 100000;
  const endsAt = String(serieEnd).endsWith('99999')
    ? Number(serieEnd)
    : (Number(serieEnd) + 1) * 100000 - 1;

  const data = {
    startsAt,
    endsAt,
    serieName: lotterySerie,
  };

  const dataCampanha = {
    walletTitle,
    walletDetailsTitle,
    walletDescription,
    walletDetailsDescription,
    walletThumbnail,
    walletPhoto,
    lotterySerie,
    notificationMessage,
    quizzes,
    events,
    voucherEach,
    voucherQuantity,
    activationDate,
    dueDate,
    establishmentId,
    establishmentName,
    campaingId,
    campaingName,
    numbersLucky,
    serieStart: startsAt,
    serieEnd: endsAt,
  };

  return dispatcher(
    dispatch,
    'CAMPAING_TRIIBO',
    updateDatabase('lottery-serie', dataCampanha, lotterySerie, pathname),
    !isUpdateCampaign && GenerateLuckyNumbers(data),
    uploadImage(storageFolder, thumbnailFile, walletThumbnail),
    uploadImage(storageFolder, photoFile, walletPhoto)
  );
};
