import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { TriiboH1 } from '../../DesignSystem/styles.js';
import { StyledButton } from '../AdminFunctions/styledButton';
import { MdArrowBackIosNew } from 'react-icons/md';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { getUserInfo } from 'js/library/utils/helpers';

const ClubMenu = ({ match }) => {
  const userInfo = getUserInfo();
  const clubeId = document.location.pathname.split('/')[4];
  const [userRole, setUserRole] = useState(userInfo.admin ? 'superAdmin' : '');

  const customizationSegment = {
    value: 'Customização de segmentos',
    url: '/admin/seu-clube/customizacao-segmentos/',
  };

  const initialCardsList = [
    { value: 'Gerenciar membros', url: '/admin/seu-clube/relacionamento/cadastro-usuarios/' },
    { value: 'Gerenciar cards', url: '/admin/seu-clube/gerenciar-cards/' },
    { value: 'Estilizar clube', url: '/admin/seu-clube/' },
    { value: 'Gerenciar administradores', url: '/admin/seu-clube/gerir-admin/' },
    { value: 'Gerenciar postagens', url: '/admin/seu-clube/relacionamento/lista-postagem/' },
    { value: 'Gerenciar formulario de cadastro', url: '/admin/seu-clube/formulario/' },
    { value: 'Permissões de acesso', url: '/admin/seu-clube/permissoes-acesso/' },
    { value: 'Gerenciar palavras proibidas', url: '/admin/seu-clube/palavras-proibidas/' },
    { value: 'Segmentação', url: '/admin/seu-clube/segmentacao/' },
    { value: 'Listagem e upload de arquivos', url: `/admin/seu-clube/listagem-arquivos/` },
    { value: 'Gerenciar Formulário NPS', url: '/admin/seu-clube/formulario-nps/' },
    { value: 'Campanhas', url: '/admin/configuracoes/campanhas/listarCampanhas/' },
    { value: 'Landing Page', url: '/admin/seu-clube/landing-page/' },
    {
      value: 'Gestão de pontos',
      url: '/admin/seu-clube/gestao-pontos/',
      roles: ['gestor', 'financeiro'],
    },
  ];

  const [cardsList, setCardsList] = useState(initialCardsList);

  useEffect(() => {
    if (clubeId) {
      getSeuClube(clubeId).then((result) => {
        localStorage.setItem('partnerIdClube', result.partnerId);
        if (userRole !== 'superAdmin') {
          setUserRole(result.admins[userInfo.uId].role);
        }
      });
    }
  }, [clubeId]);

  useEffect(() => {
    if (clubeId === 'clubmapfre') {
      setCardsList((prevCardsList) => [...prevCardsList, customizationSegment]);
    }
  }, [clubeId]);

  const cardsListOrdered = cardsList.sort((a, b) => {
    let x = a.value.toUpperCase(),
      y = b.value.toUpperCase();
    return x === y ? 0 : x > y ? 1 : -1;
  });

  return (
    <div style={{ padding: '4rem' }}>
      <S.AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <S.GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </S.GoBack>
      </S.AreaHeader>
      <S.Container>
        <TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3rem',
            marginTop: '-5rem',
          }}
        >
          Painel - Seu Clube
        </TriiboH1>
        <S.Grid>
          {cardsListOrdered.map((card, index) => {
            // if (userRole !== 'superAdmin' && card.roles && !card.roles.includes(userRole)) {
            //   return null;
            // }
            return (
              <StyledButton
                value={card.value}
                url={card.url + match.params.id}
                index={index}
                key={index + card.value}
                roles={card.roles}
                block={userRole !== 'superAdmin' && card.roles && !card.roles.includes(userRole)}
              />
            );
          })}
        </S.Grid>
      </S.Container>
    </div>
  );
};

export default ClubMenu;
