import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep, toNumber } from 'lodash';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

import { Input } from '../styles';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import DropDown from 'js/components/DesignSystem/DropDown';
import ModalPromotion from './ModalPromotion';
import itemIDList from './placeholderAppScreenItemID.json';
import { getAppActions } from 'js/library/utils/API/apiGetAppActions';
import cfac22 from 'js/library/utils/cfac22';

const getObjectByKey = (id, array, key) => array.find((obj) => obj[key] === id);

const extractValuesByKey = (arrayOfObjects, key) => arrayOfObjects.map((obj) => obj[key]);

const checkKeysForSubstring = (obj) => {
  let hasSubstring = false;
  const keysWithSubstring = [];

  const searchKeys = (object, parentKey = '') => {
    Object.entries(object).forEach(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === 'string' && value.includes('{') && value.includes('}')) {
        hasSubstring = true;
        keysWithSubstring.push(currentKey);
      }

      if (typeof value === 'object' && value !== null) {
        searchKeys(value, currentKey);
      }
    });
  };

  searchKeys(obj);

  return { hasSubstring, keysWithSubstring };
};

export default function ActionsButtons({
  formCommunication,
  setFormCommunication,
  selectedOptionActionButton,
  setSelectedOptionActionButton,
  senderType,
  typeLink,
  setTypeLink,
}) {
  const [isSelectedActionButtonOpen, setIsSelectedActionButtonOpen] = useState(
    Boolean(selectedOptionActionButton)
  );

  const tagsliced = !!(
    formCommunication.appFilter.query.length > 0 &&
    formCommunication.appFilter.query.indexOf('') >= 0
  )
    ? formCommunication.appFilter.query.split(':')
    : null;
  const [promotion, setPromotion] = useState('');
  const [tag, setTag] = useState();
  const [query, setQuery] = useState(tagsliced ? tagsliced[2] : '');
  const [isModalPromotionOpen, setIsModalPromotionOpen] = useState(false);
  const establishmentList = useSelector((state) => state.authModel.establishmentList);
  const currentEst = useSelector((state) => state.currentEstModel.currentEst);
  const [custom, setCustom] = useState('');
  const [modalSubtype, setModalSubtype] = useState('');
  const [selectedAppAction, setSelectedAppAction] = useState('');
  const [actionOptions, setActionOptions] = useState([]);
  const [geoRadius, setGeoRadius] = useState(formCommunication.appFilter.geoRadius || 10000);
  const [orderType, setOrderType] = useState(formCommunication.appFilter.orderType || 'location');
  const [hasVoucher, setHasVoucher] = useState(formCommunication.appFilter.hasVoucher || true);

  const handleCloseModalPromotion = () => setIsModalPromotionOpen(false);

  const handleGenerateLink = (type, value) => {
    const dynamicLinkKey = cfac22('DYNAMIC_LINK_KEY');
    const dynamicLinkApi = `${cfac22('DYNAMIC_LINK_API')}${dynamicLinkKey}`;
    const dynamicLinkDomain = cfac22('DYNAMIC_LINK_DOMAIN');
    const androidPackageName = cfac22('ANDROID_PACKAGE_NAME');
    const iosPackageName = cfac22('IOS_PACKAGE_NAME');
    const iosStoreId = cfac22('IOS_STORE_ID');
    const baseLink = cfac22('BASE_LINK');

    const params =
      type === 'establishment' ? `app/estabelecimento/?id=${value}` : `app/oferta/?id=${value}`;

    const finalLink = `${baseLink}${params}`;

    const linkRequest = {
      dynamicLinkInfo: {
        dynamicLinkDomain: dynamicLinkDomain,
        link: finalLink,
        androidInfo: {
          androidPackageName: androidPackageName,
        },
        iosInfo: {
          iosBundleId: iosPackageName,
          iosAppStoreId: iosStoreId,
        },
      },
    };

    return axios.post(dynamicLinkApi, linkRequest).then((result) => result.data.shortLink);
  };

  useEffect(() => {
    if (selectedOptionActionButton === 'Enviar para link externo') {
      const cloneForm = cloneDeep(formCommunication);

      cloneForm.appFilter = { itemId: '', query: '', url: '' };
      cloneForm.action.type = 'browser';
      setFormCommunication(cloneForm);
    }

    if (selectedOptionActionButton === 'Promoções próximas') {
      const cloneForm = cloneDeep(formCommunication);

      cloneForm.appFilter = {
        itemId: '',
        query: '',
        url: '',
        hasVoucher: true,
        geoRadius: 10000,
        orderType: 'location',
      };
      cloneForm.action.type = 'browser';
      setFormCommunication(cloneForm);
    }

    if (selectedOptionActionButton === 'Abrir uma promoção/produto') {
      const cloneForm = cloneDeep(formCommunication);

      cloneForm.action.type = 'promotion_item';
      setFormCommunication(cloneForm);
      setIsModalPromotionOpen(true);
    }

    if (selectedOptionActionButton === 'Acessar perfil do Negócio') {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.action.type = 'establishment_item';

      const establishmentId =
        Object.keys(currentEst).length !== 0 ? currentEst.id : establishmentList[0].id;

      handleGenerateLink('establishment', establishmentId).then((res) => {
        cloneForm.appFilter.itemId = res;
        cloneForm.appFilter.query = '';
        cloneForm.appFilter.url = '';
        setFormCommunication(cloneForm);
      });
    }

    const obj = getObjectByKey(selectedOptionActionButton, actionOptions, 'actionLabel');

    if (obj) {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.action = {
        label: obj.buttonLabel || 'Ver mais',
        type: obj.actionSubtype || '',
      };
      cloneForm.appFilter = {
        itemId: obj.itemId || '',
        query: obj.query || '',
        url: obj.url || '',
      };
      setCustom(obj.app_screen ? 'app_screen' : '');

      const hasSubstring = checkKeysForSubstring(cloneForm);
      if (hasSubstring.hasSubstring) {
        setCustom(hasSubstring.keysWithSubstring[0].split('.')[1]);
        if (cloneForm.appFilter.itemId === '{itemId}') {
          setModalSubtype(obj.actionSubtype.includes('product') ? 'product' : 'promotion');
        }
        if (cloneForm.appFilter.url === '{url}') {
          cloneForm.appFilter.url = '';
        }
      } else if (
        obj.actionSubtype === 'promotion_list' ||
        obj.actionSubtype === 'establishment_list'
      ) {
        cloneForm.appFilter = {
          itemId: obj.itemId || '',
          query: obj.query || '',
          url: obj.url || '',
          geoRadius: obj.geoRadius || 10000,
          hasVoucher: obj.hasVoucher || true,
          orderType: obj.orderType || 'location',
        };
        setCustom('storePlaces');
      }
      setFormCommunication(cloneForm);
    }
  }, [selectedOptionActionButton]);

  useEffect(() => {
    if (selectedAppAction) {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter.itemId = getObjectByKey(selectedAppAction, itemIDList, 'label').value;
      setFormCommunication(cloneForm);
    }
  }, [selectedAppAction]);

  useEffect(() => {
    if (promotion) {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter.itemId = Array.from(promotion)[0];
      setFormCommunication(cloneForm);
    }
  }, [promotion]);

  useEffect(() => {
    if (custom === 'itemId') {
      setIsModalPromotionOpen(true);
    }
  }, [custom]);

  useEffect(() => {
    if (custom === 'query') {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter = { itemId: '', query, url: '' };
      setFormCommunication(cloneForm);
    }
  }, [query]);

  useEffect(() => {
    if (custom === 'query' || custom === 'storePlaces') {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter.geoRadius = toNumber(geoRadius);
      setFormCommunication(cloneForm);
    }
  }, [geoRadius]);

  useEffect(() => {
    if (custom === 'query' || custom === 'storePlaces') {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter.orderType = orderType;
      setFormCommunication(cloneForm);
      setGeoRadius(orderType !== 'location' ? 0 : 10000);
    }
  }, [orderType]);

  useEffect(() => {
    if (custom === 'query' || custom === 'storePlaces') {
      const cloneForm = cloneDeep(formCommunication);
      cloneForm.appFilter.hasVoucher = hasVoucher;
      setFormCommunication(cloneForm);
    }
  }, [hasVoucher]);

  useEffect(() => {
    setQuery(`[(tags:LIKE:${tag})]`);
  }, [tag]);

  useEffect(() => {
    getAppActions(senderType === 'org' ? 'club' : senderType).then((res) => {
      setActionOptions(res.result);

      if (formCommunication.action.type) {
        const actionType = formCommunication.action.type;
        const appFilter = formCommunication.appFilter;

        const itemsFilteredByType = res.result.filter((item) => item.actionSubtype === actionType);

        if (actionType === 'promotion_list' || actionType === 'establishment_list') {
          const query = appFilter.query.length === 0 ? '' : appFilter.query.split(':');
          const final = itemsFilteredByType.filter(
            (item) => item.query.indexOf(`${query[0]}:${query[1]}`) >= 0
          );
          setSelectedOptionActionButton(final[0].actionLabel);
        } else {
          setSelectedOptionActionButton(itemsFilteredByType[0].actionLabel);
        }
      }
    });
  }, []);
  return (
    <>
      <S.Container>
        <div>
          <D.TriiboH4>
            <b>Botão de Ação*</b>
          </D.TriiboH4>
          <D.TriiboH5>Defina a ação que será realizada ao clicar no Botão de Ação.</D.TriiboH5>
        </div>
        {actionOptions.length === 0 ? (
          <CircularProgress />
        ) : (
          <>
            <S.ContentActionContainer>
              <div style={{ flex: 1, width: '100%' }}>
                <DropDown
                  height="48px"
                  width="100%"
                  top="3.5rem"
                  selectedOption={selectedOptionActionButton}
                  setSelectedOption={setSelectedOptionActionButton}
                  isSelectOpen={isSelectedActionButtonOpen}
                  setIsSelectOpen={setIsSelectedActionButtonOpen}
                  data={extractValuesByKey(actionOptions, 'actionLabel')}
                />
              </div>

              <S.ContentAction
                visible={
                  Array.from(promotion).length === 1 &&
                  selectedOptionActionButton === 'Abrir uma promoção/produto'
                }
              >
                <D.TriiboH5 style={{ textAlign: 'center' }}>
                  <strong>Promoção selecionada</strong>
                </D.TriiboH5>
                <S.EditInfoButton onClick={() => setIsModalPromotionOpen(true)}>
                  clique para editar
                </S.EditInfoButton>
              </S.ContentAction>
            </S.ContentActionContainer>

            {custom === 'url' && (
              <>
                <D.TriiboH4>
                  <b>Endereço URL de destino</b>
                </D.TriiboH4>
                <Input
                  placeholder="www.triibo.com.br"
                  value={formCommunication.appFilter.url}
                  onChange={(e) => {
                    e.preventDefault();
                    const newObj = cloneDeep(formCommunication);
                    newObj.appFilter = { itemId: '', query: '', url: e.target.value };
                    setFormCommunication(newObj);
                  }}
                />

                {(senderType === 'org' || senderType === 'club') && (
                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel>Abrir link em:</InputLabel>
                    <Select
                      value={typeLink}
                      label="Abrir link em:"
                      onChange={(e) => setTypeLink(e.target.value)}
                    >
                      <MenuItem value="new-guide">Nova guia</MenuItem>
                      <MenuItem value="inside-frame">Conteúdo interno</MenuItem>
                      <MenuItem value="full-frame">Conteúdo completo</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </>
            )}

            {custom === 'query' && (
              <>
                <TextField
                  value={tag}
                  placeholder="Insira a tag da query"
                  onChange={(e) => {
                    e.preventDefault();
                    setTag(e.target.value);
                  }}
                />
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel>Ordenação</InputLabel>
                  <Select
                    value={orderType}
                    label="Ordenação"
                    onChange={(e) => setOrderType(e.target.value)}
                  >
                    <MenuItem value="location">Distância</MenuItem>
                    <MenuItem value="alphabetical">Alfabética</MenuItem>
                    <MenuItem value="relevance">Relevância</MenuItem>
                  </Select>
                </FormControl>
                {orderType === 'location' && (
                  <TextField
                    type="number"
                    value={geoRadius}
                    label="Distância máxima (em metros)"
                    placeholder="Ex: 5000"
                    onChange={(e) => {
                      e.preventDefault();
                      setGeoRadius(e.target.value);
                    }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasVoucher}
                      value={hasVoucher}
                      onChange={() => setHasVoucher(!hasVoucher)}
                    />
                  }
                  label="hasVoucher"
                />
              </>
            )}
            {custom === 'storePlaces' && (
              <>
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel>Ordenação</InputLabel>
                  <Select
                    value={orderType}
                    label="Ordenação"
                    onChange={(e) => setOrderType(e.target.value)}
                  >
                    <MenuItem value="location">Distância</MenuItem>
                    <MenuItem value="alphabetical">Alfabética</MenuItem>
                    <MenuItem value="relevance">Relevância</MenuItem>
                  </Select>
                </FormControl>
                {orderType === 'location' && (
                  <TextField
                    type="number"
                    value={geoRadius}
                    label="Distância máxima (em metros)"
                    placeholder="Ex: 5000"
                    onChange={(e) => {
                      e.preventDefault();
                      setGeoRadius(e.target.value);
                    }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasVoucher}
                      value={hasVoucher}
                      onChange={() => setHasVoucher(!hasVoucher)}
                    />
                  }
                  label="hasVoucher"
                />
              </>
            )}

            {custom === 'app_screen' && (
              <DropDown
                height="48px"
                width="100%"
                top="3.5rem"
                selectedOption={selectedAppAction}
                setSelectedOption={setSelectedAppAction}
                data={extractValuesByKey(itemIDList, 'label')}
              />
            )}
          </>
        )}
      </S.Container>

      <ModalPromotion
        handleCloseModal={handleCloseModalPromotion}
        isModalPromotionOpen={isModalPromotionOpen}
        setPromotion={setPromotion}
        promotion={promotion}
        setSelectedOption={setSelectedOptionActionButton}
        form={formCommunication}
        modalSubtype={modalSubtype}
      />
    </>
  );
}
