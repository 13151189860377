import { uploadImage } from 'js/library/services/StorageManager';
import { createCard, updateCard } from 'js/library/utils/API/apiCards';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { convertToMillis, b64toBlob } from 'js/library/utils/helpers';

export async function updateCardsStoreAction(data) {
  data.cardId = data.cardId === null ? firebaseDatabase.ref('Cards').push().key : data.cardId;

  const currentDate = Date.now();

  const url = window.location.pathname;
  const isCreatingCard = url.includes('cadastrarCards');

  //Mobile images
  if (data.loadedMobileFile !== null) {
    data.oldImageMobile = data.imageMobile.replace('cards/', '');
    data.imageMobile = 'storage:' + data.cardId + ':mobile:' + currentDate;
    data.loadedMobileFile = b64toBlob(data.loadedMobileFile);
  }

  //Details images
  if (data.loadedDetailsFile !== null) {
    data.oldImageDetails = data.imageMobileDetails.replace('cards/', '');
    data.imageMobileDetails = 'storage:' + data.cardId + ':mobileDetails:' + currentDate;
    data.loadedDetailsFile = b64toBlob(data.loadedDetailsFile);
  }

  //Web images
  if (data.loadedWebFile !== null) {
    data.oldImageWeb = data.imageWeb.replace('cards/', '');
    data.imageWeb = 'storage:' + data.cardId + ':web:' + currentDate;
    data.loadedWebFile = b64toBlob(data.loadedWebFile);
  }

  // obj para criar card
  let createData = {
    action: data.action,
    appFilter: {},
    description: data.description,
    disableDate: convertToMillis(data.disableDate),
    enableDate: convertToMillis(data.enableDate),
    images: {
      icon: data.icon ? data.icon : ' ',
      web: data.loadedWebFile !== null ? data.imageWeb : data.webFile,
      mobile: data.loadedMobileFile !== null ? 'cards/' + data.imageMobile : data.mobileFile,
      mobileDetails:
        data.loadedDetailsFile !== null ? 'cards/' + data.imageMobileDetails : data.detailsFile,
    },
    isPublished: data.isPublished,
    keyWordsSet: data.keywordsList,
    location: data.location,
    orgs: data.orgs,
    platform: data.platform,
    priority: parseInt(data.priority, 10) || null,
    status: data.isPublished ? 1 : 0,
    subtitle: data.subtitle,
    title: data.title,
    type: data.cardType,
  };

  if (data.itemId !== '') {
    createData.appFilter.itemId = data.itemId;
  } else {
    delete createData.appFilter.itemId;
  }

  if (data.query !== '') {
    createData.appFilter.query = data.query;

    createData.orderType = data.orderType;
    createData.geoRadius = data.geoRadius;
    createData.hasVoucher = data.hasVoucher;
  } else {
    delete createData.appFilter.query;
  }

  if (data.url !== '') {
    createData.appFilter.url = data.url;
  } else {
    delete createData.appFilter.url;
  }

  // obj para editar card
  let updateData = {
    action: data.action,
    appFilter: {},
    description: data.description,
    disableDate: convertToMillis(data.disableDate),
    enableDate: convertToMillis(data.enableDate),
    images: {
      icon: data.icon ? data.icon : ' ',
      web: data.imageWeb,
      mobile: data.imageMobile.includes('cards/') ? data.imageMobile : 'cards/' + data.imageMobile,
      mobileDetails: data.imageMobileDetails.includes('cards/')
        ? data.imageMobileDetails
        : 'cards/' + data.imageMobileDetails,
    },
    isPublished: data.isPublished,
    keyWordsSet: data.keywordsList,
    location: data.location,
    orgs: data.orgs,
    platform: data.platform,
    priority: parseInt(data.priority, 10) || null,
    status: data.isPublished ? 1 : 0,
    subtitle: data.subtitle,
    title: data.title,
    type: data.cardType,
  };

  if (data.itemId !== '') {
    updateData.appFilter.itemId = data.itemId;
  } else {
    delete updateData.appFilter.itemId;
  }

  if (data.query !== '') {
    updateData.appFilter.query = data.query;

    updateData.orderType = data.orderType;
    updateData.geoRadius = data.geoRadius;
    updateData.hasVoucher = data.hasVoucher;
  } else {
    delete updateData.appFilter.query;
  }

  if (data.url !== '') {
    updateData.appFilter.url = data.url;
  } else {
    delete updateData.appFilter.url;
  }

  const storageFolder = 'cards';

  const commonImageUploadPromises = [
    uploadImage(storageFolder, data.loadedWebFile, data.imageWeb),
    uploadImage(storageFolder, data.loadedMobileFile, data.imageMobile),
    uploadImage(storageFolder, data.loadedDetailsFile, data.imageMobileDetails),
  ];

  const specificPromise = isCreatingCard
    ? createCard(createData)
    : updateCard(data.cardId, updateData);

  const resultPromise = await Promise.all([specificPromise, ...commonImageUploadPromises]);

  return resultPromise;
  // deleteImage(storageFolder, data.oldImageWeb);
  // deleteImage(storageFolder, data.oldImageMobile),
  // deleteImage(storageFolder, data.oldImageDetails)
}

