import React, { useState } from 'react';

//LIBS
import { Modal, Box, Button, Typography, CircularProgress } from '@mui/material';
import { UserSwitch } from 'phosphor-react';
import { toast } from 'react-toastify';

//COMPONENTS & HELPERS
import { cellPhoneMask2, findValueInArray } from 'js/library/utils/helpers';
import RenderInputDynamic from '../../SearchUser/RenderInputDynamic';

//API
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';
import { GetUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { updateSeuClube } from 'js/library/utils/API/seuClube';

export default function ChangeGestor({
  open,
  setOpen,
  clubeData,
  fieldKey,
  adminsArray,
  setAdminsList,
}) {
  const [userSearch, setUserSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [userSearchedData, setUserSearchedData] = useState({});

  const [step, setStep] = useState(1);
  const gestor = adminsArray.find((adm) => adm.role === 'gestor');

  const handleSearchUser = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);

    const userAlreadyHaveRole = adminsArray.some(
      (user) => user[fieldKey.fieldName].replace(/\D/g, '') === userSearch.replace(/\D/g, '')
    );

    if (userAlreadyHaveRole) {
      setLoadingSearch(false);
      return toast.error(
        'Usuário já possui cargo no clube. Remova-o do cargo atual para tornar gestor.'
      );
    } else {
      const basicValidateUser = await ValidateUserPartner(userSearch, clubeData.partnerId);

      if (basicValidateUser.user) {
        const res = await GetUserPartner(clubeData.partnerId, basicValidateUser.uId);
        setUserSearchedData(res.response);
        console.log(res.response);

        setStep(2);
        setLoadingSearch(false);
      } else {
        setLoadingSearch(false);
        return toast.error('Usuário não encontrado.');
      }
    }
  };

  const handleSubmitChangeGestor = async () => {
    setLoadingSearch(true);
    console.log(gestor);
    const newAdminsList = {};
    newAdminsList[userSearchedData.uId] = { role: 'gestor' };
    newAdminsList[gestor.uId] = { role: 'administrador' };

    console.log(newAdminsList);

    const payload = {
      clubeId: clubeData.clubeId,
      admins: newAdminsList,
    };
    try {
      const updateClube = await updateSeuClube(payload);

      if (updateClube.status === 200) {
        setAdminsList(newAdminsList);
        setOpen(false);
        setLoadingSearch(false);
        setStep(1);
        toast.success('Gestor atualizado com sucesso! O antigo gestor agora é um administrador.');
      }
    } catch (error) {
      console.error('Erro ao atualizar o clube:', error);
      toast.error('Erro ao atualizar o gestor.');
    }
  };

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 6,
            gap: 6,
          }}
        >
          {step === 1 && (
            <>
              <Typography variant="h6" gutterBottom>
                Informe o telefone do novo gestor
              </Typography>
              <RenderInputDynamic
                fieldInfos={fieldKey}
                inputValue={userSearch}
                setInputValue={setUserSearch}
                handleSubmit={handleSearchUser}
                loadingSearch={loadingSearch}
                customIcon={<UserSwitch weight="fill" size={35} color="#6E3296" />}
              />
              <Button variant="text" color="primary" onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <Typography variant="h6" gutterBottom>
                Confirme a troca de Gestor
              </Typography>
              <Box
                sx={{ minWidth: '500px', display: 'flex', justifyContent: 'space-around', mb: 2 }}
              >
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Gestor Anterior
                  </Typography>
                  <Typography variant="body2">Usuário: {gestor.aliasName}</Typography>
                  <Typography variant="body2">Telefone: {gestor.cellPhone}</Typography>
                </Box>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Novo Gestor
                  </Typography>
                  <Typography variant="body2">Usuário: {userSearchedData.aliasName}</Typography>
                  <Typography variant="body2">
                    Telefone:{' '}
                    {cellPhoneMask2(
                      findValueInArray(userSearchedData.contactList, 'type', 'cellPhone').value
                    )}
                  </Typography>
                </Box>
              </Box>
              {loadingSearch ? (
                <CircularProgress />
              ) : (
                <Box
                  sx={{ minWidth: '500px', display: 'flex', justifyContent: 'space-around', mb: 2 }}
                >
                  <Button variant="text" color="primary" onClick={() => setStep(1)}>
                    Voltar
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleSubmitChangeGestor}>
                    Confirmar
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
