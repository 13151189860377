import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// STYLES
import { Pagination } from '@mui/material';
import { SearchBox } from 'js/components/Establishment/ListEstablishment/styles';

// COMPONENTS
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import InputSearch from 'js/components/TriiboComponents/InputSearch';
import { Loading } from 'js/components/TriiboComponents/Loading/Loading';
import { PageStructure } from 'js/components/TriiboComponents/PageStructure/PageStructure';
import SimpleTable from 'js/components/TriiboComponents/Tables/SimpleTable';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

// APIS
import { getSegmentCustomization } from 'js/library/utils/API/getSegmentCustomization';
import { getUserSegment } from 'js/library/utils/API/Segmentation/getUserSegment';

export function Customization() {
  const partnerId = localStorage.getItem('partnerIdClube');
  const navigate = useHistory();
  const { pathname } = document.location;
  const clubeId = pathname.split('/')[4];

  const [customizations, setCustomizations] = useState([]);
  const [customizationsSearch, setCustomizationsSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [segments, setSegments] = useState([]);
  const [customizationsAlready, setCustomizationsAlready] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const PER_PAGE = 10;

  const count =
    inputSearch.length > 2
      ? Math.ceil(customizationsSearch.length / PER_PAGE)
      : Math.ceil(customizations.length / PER_PAGE);
  const _DATA_SEARCH = usePagination(customizationsSearch, PER_PAGE);
  const _DATA = usePagination(customizations, PER_PAGE);

  const column = [
    { heading: 'Nome', value: 'name' },
    { heading: 'Tipo', value: 'clientName' },
    { heading: 'Id do segmento', value: 'segmentId' },
  ];

  // PEGA OS SEGMENTOS
  useEffect(() => {
    const data = { from: 0, size: 100, partnerId: partnerId };
    partnerId &&
      getUserSegment(data).then((res) => {
        const segments = res.map((segment) => {
          return {
            id: segment.id,
            segmentName: segment.name,
          };
        });
        setSegments(segments);
      });
  }, [partnerId]);

  useEffect(() => {
    partnerId &&
      getSegmentCustomization(partnerId)
        .then((res) => {
          const pushIds = Object.keys(res);
          const customizations = Object.values(res);
          // REMOVE OS ITENS QUE NÃO SÃO ENVIADOS NAS REQUISIÇÕES DE UPDATE E CREATE
          const updatedCustomizations = customizations.map((customization, index) => {
            const { creationDate, updateDate, ...restCustomization } = customization;

            return {
              ...restCustomization,
              pushId: pushIds[index],
            };
          });

          const segmentIds = customizations.map((segment) => segment.segmentId);

          setCustomizationsAlready(segmentIds);
          setCustomizations(updatedCustomizations);
          setCustomizationsSearch(updatedCustomizations);
        })
        .catch((e) => {
          if (e.includes('Registro não encontrado no banco de dados')) {
            toast.warning('Ainda não existem customizações para esse segmento.');
          } else {
            toast.error(e);
          }
        })
        .finally(() => {
          setLoadingPage(false);
        });
  }, [partnerId]);

  useEffect(() => {
    if (inputSearch.length > 2) {
      const filteredCustomizations = customizations.filter((customization) => {
        return (
          customization.name.toLowerCase().includes(inputSearch.toLowerCase()) ||
          customization.clientName.toLowerCase().includes(inputSearch.toLowerCase())
        );
      });

      setCustomizationsSearch(filteredCustomizations);
    } else {
      setCustomizationsSearch(customizations); // Reset to all customizations if search is cleared
    }
  }, [inputSearch, customizations]);

  const handleChangePage = (e, p) => {
    inputSearch.length > 2 ? _DATA_SEARCH.jump(p) : _DATA.jump(p);
    inputSearch.length > 2 ? setPageSearch(p) : setPage(p);
  };

  const redirectOnClick = (info) => {
    navigate.push({
      pathname: `/admin/seu-clube/customizacao-segmentos/${clubeId}/${info.name}/editar`,
      state: { info, segments, clubeId, customizationsAlready },
    });
  };

  return (
    <PageStructure title="Customização de segmento">
      <SearchBox>
        <InputSearch
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          disabled={false}
          placeholder="Pesquisar customização de seguimento"
        />
        <Link
          to={{
            pathname: `/admin/seu-clube/customizacao-segmentos/${clubeId}/adicionar`,
            state: { segments, partnerId, clubeId, customizationsAlready },
          }}
        >
          <DashedButton width={'250px'}>Adicionar Customização</DashedButton>
        </Link>
      </SearchBox>

      <Loading tickness="5" size="50px" state={loadingPage}>
        <>
          <SimpleTable
            data={inputSearch.length > 2 ? _DATA_SEARCH.currentData() : _DATA.currentData()}
            column={column}
            usePaginate={false}
            redirectOnClick={redirectOnClick}
          />
          <Pagination
            sx={{ margin: '0 auto' }}
            count={count}
            size="large"
            page={inputSearch.length > 2 ? pageSearch : page}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={handleChangePage}
          />
        </>
      </Loading>
    </PageStructure>
  );
}
