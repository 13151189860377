import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

export default function apiQRCode(data) {
  return new Promise(function (resolve, reject) {
    getChannelTokenUidNull()
      .then((token) => {
        let dataQRCode = {
          triiboHeader: {
            apiToken: cfac22('keyapiv1triibowebadmin'),
            channelGroup: cfac22('channelGroup'),
            channelName: cfac22('channelName'),
            channelTokenId: token,
            channelId: cfac22('channelId'),
            sessionId: '001',
            transactionId: '002',
          },
          title: data.title,
          action: data.action,
          active: data.active,
          availableHours: data.availableHours,
          data: data.data,
          dueDate: Number(data.dueDate),
          keyWordsSet: data.keyWordsSet,
          owner: data.owner,
          qrCodeId: data.qrCodeId,
          lat: data.lat ? Number(data.lat) : undefined,
          long: data.long ? Number(data.long) : undefined,
          radius: data.radius ? Number(data.radius) : undefined,
        };

        axios
          .post(cfac22('API_HOST_V1') + 'insertQRCode', JSON.parse(JSON.stringify(dataQRCode)), {
            headers: {
              'Content-Type': 'application/json',
              'Full-Url': window.location.href,
            },
          })
          .then((result) => {
            if (result) {
              resolve(result);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            // console.log("ERROR", error)
            reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}
