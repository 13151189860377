import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function getSeuClube(clubeId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + '/seuClube/getSeuClube?clubeId=' + clubeId, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          // console.log('RESP ==>', result);
          resolve(result.data.result);
        })
        .catch((error) => {
          console.log('ERRO SEU CLUBE ==>', error);
          return reject(error.response.data);
        });
    });
  });
}

export function setSeuClube(clubeId, url, name, status, whatsapp) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/seuClube/postSeuClube',
          {
            clubeId,
            url,
            name,
            status,
            whatsapp,
            accessMethod: 'public',
          },
          { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
        )
        .then((result) => {
          // console.log("RESULTADO POST SEU CLUBE ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR POST SEU CLUBE ==>", error);
          return reject(error);
        });
    });
  });
}

export function updateSeuClube(payload) {
  // console.log(payload);
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios

        .put(
          cfac22('API_HOST_V2') + '/seuClube/updateSeuClube',
          {
            payload,
          },
          { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
        )
        .then((result) => {
          // console.log("RESULTADO UPDATE SEU CLUBE ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR UPDATE SEU CLUBE ==>", error);
          return reject(error);
        });
    });
  });
}

export function getSeuClubList(uId) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + '/seuClube/getSeuClubeList', {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          // console.log("RESULTADO SEU CLUBELIST ==>", result.data.result);
          resolve(result.data.result);
        })
        .catch((error) => {
          // console.log('ERRO SEU CLUBELIST ==>', error);
          return reject(error);
        });
    });
  });
}

export function getClubByNamePaginatedSearch(clubName, from, size) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/seuClube/getClubByNamePaginatedSearch',
          {
            payload: {
              clubName,
              from,
              size,
            },
          },

          { headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href } }
        )
        .then((result) => {
          // console.log("RESULTADO SEU CLUBELIST ==>", result.data.result);
          resolve(result.data.result.results);
        })
        .catch((error) => {
          // console.log('ERRO SEU CLUBELIST ==>', error);
          return reject(error);
        });
    });
  });
}
