import axios from 'axios';

import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import cfac22 from '../../cfac22';

export function BrasilCap_createUser(partnerList, uId) {
  return new Promise(function async(resolve, reject) {
    getChannelToken_v1(uId)
      .then((channelTokenId) => {
        axios
          .post(
            cfac22('API_HOST_V1') + 'brasilCap_createUser',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId: uId,
                channelTokenId: channelTokenId,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              userInfo: {
                partnerList: [partnerList],
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then(async (result) => {
            // console.log("OLA", result);
            return resolve(result.data);
          })
          .catch((error) => {
            // console.log("ERROR2-> ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log("ERROR1-> ", error);
        return reject(error);
      });
  });
}
