import axios from 'axios';

import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function EditUserPartner(uid, partnerList) {
  return new Promise((resolve, reject) => {
    authToken(uid).then((token) => {
      axios
        .put(
          cfac22('API_HOST_V2') + '/userPartner/editUserPartner',
          {
            uid: uid,
            partnerList: partnerList,
          },
          {
            headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
          }
        )
        .then(async (result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          console.log(error);
          return reject(error.response);
        });
    });
  });
}
