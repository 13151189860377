import axios from 'axios';

import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import cfac22 from '../cfac22';

export function getWallet_v1(userId, triiboId) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1(userId).then((channelTokenId) => {
      //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);

      axios
        .post(
          cfac22('API_HOST_V1') + 'getWallet_v1',
          {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: userId,
              channelTokenId: channelTokenId,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            triiboId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Full-Url': window.location.href,
            },
          }
        )
        .then((result) => {
          var wallet = {
            coupons: result.data.success.wallet,
            totalPoints: result.data.success.totalPoints,
          };

          resolve(wallet);
        })
        .catch((error) => {
          // console.log('ERROR GET WALLET ===>', error);

          reject(error);
        });
    });
  }).catch((error) => {
    // console.log('ERROR GET CHANNEL TOKEN ===>', error);
  });
}
