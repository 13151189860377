import authToken from 'js/library/utils/API/authToken';
import axios from 'axios';
import cfac22 from '../../cfac22';

export default async function getEstablishmentWithPromotions(uId, payload) {
  const token = await authToken(uId);
  var config = {
    method: 'post',
    url: cfac22('API_HOST_V2') + '/getStorePlace/establishments/withPromotions',
    headers: {
      Authorization: 'Bearer ' + token,
      'Full-Url': window.location.href,
    },
    data: { ...payload },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
}

// exemplo de payload
// {
//   "systemQuery": {
//     "grupo1": [
//       {
//         "type": "like",
//         "field": "nome",
// 		  "apiType": "establishment",
//         "value": "cashback"
//       },
//       {
//         "type": "like",
//         "field": "descricao",
// 		  "apiType": "promotion"
//         "value": "cashback"
//       }
//     ]
//   },
//   "orderType": "location",
//   "hasPromo": false,
//   "geoFocus": {
//     "lat": "-23.5983184",
//     "long": "-46.641581"
//   },
//   "geoRadius": 10000,
//   "plataform": "web",
//   "from": 0,
//   "size": 1,
// 	  "fromPromotion": 0,
//   "sizePromotion": 5
// }
