import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../cfac22';

export function addDeniedWords(clubId, data) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/deniedWords/addDeniedWords`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Full-Url': window.location.href,
        },
        data: {
          clubId: clubId,
          deniedWords: [...data],
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function getDeniedWordsByClub(clubId) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'get',
        url: `${cfac22('API_HOST_V2')}/deniedWords/getDeniedWordsByClub/${clubId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function inactiveDeniedWords(clubId, data) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/deniedWords/inactiveDeniedWords`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          clubId: clubId,
          deniedWords: [...data],
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
