import { useState } from 'react';

const useForm = (initialValues) => {
  const [form, setForm] = useState(initialValues);

  const handleChange = ({ target }) => {
    const { id, name, type, value } = target;
    const fieldName = id || name;
    let newValue = type === 'checkbox' ? target.checked : value;

    if (fieldName === 'publicName') {
      newValue = newValue.replace(/[^a-zA-Z0-9-]/g, '');
    }

    const fieldParts = fieldName.split('.');

    // Verifica se o campo é dentro de cardsList e lida com isso corretamente
    if (fieldName.startsWith('cardsList')) {
      const [field, index, subField] = fieldName.split('.'); // Exemplo: cardsList[0].title

      // Atualiza o valor dentro de cardsList de forma imutável
      const updatedCardsList = [...form.cardsList];
      updatedCardsList[index] = {
        ...updatedCardsList[index],
        [subField]: {
          ...updatedCardsList[index][subField],
          value: newValue,
        },
      };

      setForm({
        ...form,
        cardsList: updatedCardsList,
      });
    } else {
      // Caso não seja dentro de cardsList, trata normalmente
      setForm((prevForm) => {
        if (fieldParts.length === 1) {
          // Atualização simples (nível superior)
          return {
            ...prevForm,
            [fieldName]: {
              ...prevForm[fieldName],
              value: newValue,
            },
          };
        } else {
          // Atualização de campos aninhados
          const [parentKey, childKey] = fieldParts;
          return {
            ...prevForm,
            [parentKey]: {
              ...prevForm[parentKey],
              [childKey]: {
                ...prevForm[parentKey][childKey],
                value: newValue,
              },
            },
          };
        }
      });
    }
  };

  const validateForm = () => {
    const validateFields = (formObject) => {
      for (const key in formObject) {
        const field = formObject[key];

        // Verifica se o campo é um objeto e possui a propriedade 'value'
        if (typeof field === 'object' && field !== null && 'value' in field) {
          // Valida o campo atual
          if (
            field.required &&
            (field.value === undefined || field.value === null || field.value === '')
          ) {
            return false;
          }
        }

        // Se o campo for um objeto, valida recursivamente
        if (typeof field === 'object' && field !== null && !('value' in field)) {
          if (!validateFields(field)) {
            return false;
          }
        }
      }
      return true;
    };

    const isValid = validateFields(form);

    // Validação específica para questions
    if (isValid && form.questions?.value?.length) {
      for (const question of form.questions.value) {
        if (!question.title.trim() || !question.answer.trim()) {
          return false; // Se título ou resposta estiver vazio, retorna falso
        }
      }
    }

    return isValid;
  };

  const clearForm = () => {
    const clearedForm = {};
    for (const key in form) {
      if (Array.isArray(form[key].value)) {
        clearedForm[key] = { ...form[key], value: [] };
      } else if (typeof form[key].value === 'boolean') {
        clearedForm[key] = { ...form[key], value: false };
      } else {
        clearedForm[key] = { ...form[key], value: '' };
      }
    }
    setForm(clearedForm);
  };

  return {
    form,
    handleChange,
    setForm,
    validateForm,
    clearForm,
  };
};

export default useForm;
