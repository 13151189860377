import React, { useEffect, useState } from 'react';

import { Modal } from '@mui/material';
import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';
import DropDown from 'js/components/DesignSystem/DropDown';
import { toast } from 'react-toastify';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import { PiWarningCircleLight } from 'react-icons/pi';

export default function ModalAddField({
  open,
  handleClose,
  formsFields,
  setFormsFields,
  fieldToEdit,
  setFieldToEdit,
  fieldTypes,
}) {
  const [field, setField] = useState({
    id: formsFields.size.toString(),
    fieldName: '',
    label: '',
    inputType: '',
    allowEdition: true,
    required: true,
    type: '',
    indexKey: false,
    inputMask: '',
  });

  const [typeSelected, setTypeSelected] = useState({ label: '', value: '', inputMask: '' });
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [errorFields, setErrorFields] = useState([]);

  const fieldTypesFiltered = fieldTypes
    .map((field) => {
      return {
        label: field.name === 'Email' ? 'E-mail de contato' : field.name,
        value: field.type,
        inputMask: field.mask ?? '',
        helpText: field.helpText ?? null,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (fieldToEdit) {
      const filterField = Array.from(formsFields).find((field) => field.fieldName === fieldToEdit);
      const typeFiltered = fieldTypes.find(({ type }) => type === filterField.fieldType);
      setField(filterField);
      setTypeSelected({
        label: typeFiltered.name,
        value: typeFiltered.type,
        inputMask: typeFiltered.mask ?? '',
        helpText: typeFiltered.helpText ?? null,
      });

      if (filterField.fieldType === typeFiltered.type) {
        const inputMask = typeFiltered.mask ?? '';

        setField({ ...filterField, inputMask });
      }
    } else {
      setField({
        id: formsFields.size.toString(),
        fieldName: '',
        label: '',
        inputType: '',
        allowEdition: true,
        required: true,
        type: '',
        indexKey: false,
      });

      setTypeSelected({
        label: '',
        value: '',
        inputMask: '',
        helpText: null,
      });
    }
  }, [fieldToEdit, formsFields, fieldTypes]);

  const isUrlValid = (url) => {
    try {
      const validateUrl = new URL(url);
      const splitUrl = validateUrl.origin.split('.');

      if (splitUrl.length < 3 || splitUrl[0].length < 2 || splitUrl[1].length < 2) {
        return false;
      }
      return true;
    } catch (error) {
      return;
    }
  };

  const handleSubmit = () => {
    const errors = [];
    let newField = {};

    if (
      field.fieldName !== 'cellPhone' &&
      field.fieldName !== 'aliasName' &&
      !field.indexKey &&
      !typeSelected.value
    ) {
      errors.push('fieldType');
    }

    if (!field.label) {
      errors.push('fieldLabel');
    }

    if (!field.fieldName) {
      errors.push('fieldName');
    }

    if (typeSelected.value === 'text' && field.fieldName !== 'aliasName' && !field.placeholder) {
      errors.push('placeholder');
      // return toast.warn('Informe um placeholder para esse campo');
    }

    const fieldExists = Array.from(formsFields).filter(
      (fieldItem) => fieldItem.fieldName === field.fieldName
    );

    if (
      !fieldToEdit &&
      field.fieldName !== 'cellPhone' &&
      field.fieldName !== 'aliasName' &&
      !field.indexKey &&
      fieldExists.length > 0
    ) {
      return toast.warn('Já existe um campo com esse nome encurtado inserido.');
    }

    if (typeSelected.value === 'optIn') {
      if (!field.link) {
        errors.push('optInUrl');
        // return toast.warn('Informe o link dos termos de uso');
      }

      if (!isUrlValid(field.link)) {
        errors.push('optInUrl');
        // return toast.warn('Link informado não é válida.');
      }

      newField.optInId = field.fieldName;
    }

    if (errors.length > 0) {
      return setErrorFields(errors);
    }

    newField = {
      ...field,
      ...newField,
      label: field.label.trim(),
      fieldType: typeSelected.value,
      inputType: typeSelected.value === 'optIn' ? 'checkbox' : typeSelected.value,
      inputMask: typeSelected.value === 'text' ? '' : typeSelected.inputMask,
      type: fieldTypes.find(({ type }) => type === typeSelected.value).block,
    };

    if (fieldToEdit) {
      const copyFields = Array.from(formsFields);
      let index = copyFields.findIndex((fieldItem) => fieldItem.fieldName === fieldToEdit);

      if (index !== -1) {
        copyFields[index] = newField;
      }
      setFieldToEdit('');
      setFormsFields(new Set(copyFields));
    } else {
      setFormsFields((fieldItem) => {
        return new Set([...fieldItem, newField]);
      });
    }

    setField({
      id: formsFields.size.toString(),
      fieldName: '',
      label: '',
      inputType: '',
      allowEdition: true,
      required: true,
      type: '',
      indexKey: false,
    });
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <S.Container>
          <T.TriiboH2>Configurar campo</T.TriiboH2>

          <S.Content>
            {field.fieldName !== 'cellPhone' &&
            field.fieldName !== 'aliasName' &&
            !field.indexKey ? (
              <div>
                <T.TriiboH4>Tipo do campo*</T.TriiboH4>
                <DropDown
                  selectedOption={typeSelected}
                  setSelectedOption={setTypeSelected}
                  isSelectOpen={isTypeOpen}
                  setIsSelectOpen={setIsTypeOpen}
                  data={fieldTypesFiltered}
                  top={true}
                  height={'42px'}
                  error={errorFields.includes('fieldType')}
                />
                {errorFields.includes('fieldType') && (
                  <S.ErrorText>Informe o tipo do campo</S.ErrorText>
                )}

                {typeSelected.helpText && (
                  <S.HelpText>
                    <PiWarningCircleLight size={30} />
                    {typeSelected.helpText}
                  </S.HelpText>
                )}
              </div>
            ) : null}

            <div>
              <T.TriiboH4>Nome do campo*</T.TriiboH4>
              <S.InputContainer error={errorFields.includes('fieldLabel')}>
                <S.Input
                  placeholder={
                    typeSelected.value === 'checkbox'
                      ? 'Exemplo - Aceito receber e-mail marketing.'
                      : ''
                  }
                  type="text"
                  maxLength="45"
                  value={field.label}
                  onChange={(e) => {
                    setField({ ...field, label: e.target.value });
                  }}
                />
                <S.TextCounter>{field.label.length}/45</S.TextCounter>
              </S.InputContainer>
              {errorFields.includes('fieldLabel') && (
                <S.ErrorText>Informe o nome do campo</S.ErrorText>
              )}
            </div>

            {!field.indexKey && (
              <div>
                <T.TriiboH4>Nome encurtado*</T.TriiboH4>
                <S.InputContainer error={errorFields.includes('fieldName')}>
                  <S.Input
                    type="text"
                    maxLength="20"
                    value={field.fieldName}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Remove acentos e caracteres especiais
                      const sanitizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                      // Substitui espaços por "_"
                      const formattedValue = sanitizedValue.replace(/\s/g, '_').toLowerCase();

                      setField({ ...field, fieldName: formattedValue });
                    }}
                  />
                  <S.TextCounter>{field.fieldName.length}/20</S.TextCounter>
                </S.InputContainer>
                {errorFields.includes('fieldName') && (
                  <S.ErrorText>Informe o nome encurtado do campo</S.ErrorText>
                )}
              </div>
            )}

            {typeSelected.value === 'optIn' && (
              <div>
                <T.TriiboH4>Link do termo de uso*</T.TriiboH4>
                <T.TriiboField
                  value={field.link}
                  placeholder={'Exemplo - https://triibo.com.br'}
                  onFocus={(e) => {
                    let url = e.target.value;

                    if (!e.target.value.startsWith('https://www.')) {
                      url = 'https://www.';
                    }

                    setField({ ...field, link: url });
                  }}
                  onChange={(e) => {
                    let url;

                    if (!e.target.value.startsWith('https://www.')) {
                      url = 'https://www.';
                    } else {
                      url = e.target.value;
                    }

                    setField({ ...field, link: url });
                  }}
                  error={errorFields.includes('optInUrl')}
                />
                {errorFields.includes('optInUrl') && (
                  <S.ErrorText>Link informado não é válido</S.ErrorText>
                )}
              </div>
            )}

            {typeSelected.value === 'text' && field.fieldName !== 'aliasName' && (
              <div>
                <T.TriiboH4>Texto de exemplo*</T.TriiboH4>
                <S.InputContainer error={errorFields.includes('placeholder')}>
                  <S.Input
                    type="text"
                    maxLength="20"
                    value={field.placeholder}
                    onChange={(e) => {
                      setField({ ...field, placeholder: e.target.value });
                    }}
                  />
                  {field.placeholder && (
                    <S.TextCounter>{field.placeholder.length}/20</S.TextCounter>
                  )}
                </S.InputContainer>
                {errorFields.includes('placeholder') && (
                  <S.ErrorText>Informe um placeholder para esse campo</S.ErrorText>
                )}
              </div>
            )}

            {field.fieldName !== 'cellPhone' && !field.indexKey ? (
              <div>
                <T.TriiboH4>O campo será editável no perfil do usuário?*</T.TriiboH4>
                <S.RadioSelectContainer>
                  <S.Label>
                    <S.InputRadio
                      checked={field.allowEdition === true}
                      onChange={(e) => setField({ ...field, allowEdition: true })}
                    />
                    Sim
                  </S.Label>

                  <S.Label>
                    <S.InputRadio
                      checked={field.allowEdition === false}
                      onChange={(e) => setField({ ...field, allowEdition: false })}
                    />
                    Não
                  </S.Label>
                </S.RadioSelectContainer>
              </div>
            ) : null}

            {field.fieldName !== 'cellPhone' &&
            field.fieldName !== 'aliasName' &&
            !field.indexKey ? (
              <div>
                <T.TriiboH4>O campo será obrigatório?*</T.TriiboH4>
                <S.RadioSelectContainer>
                  <S.Label>
                    <S.InputRadio
                      checked={field.required === true}
                      onChange={(e) => setField({ ...field, required: true })}
                    />
                    Sim
                  </S.Label>

                  <S.Label>
                    <S.InputRadio
                      checked={field.required === false}
                      onChange={(e) => setField({ ...field, required: false })}
                    />
                    Não
                  </S.Label>
                </S.RadioSelectContainer>
              </div>
            ) : null}
          </S.Content>

          <S.ActionsButtons>
            <PrimaryButton
              bgColor={'#F13A3A'}
              onClick={() => {
                setField({
                  id: formsFields.size.toString(),
                  fieldName: '',
                  label: '',
                  inputType: '',
                  allowEdition: true,
                  required: true,
                  type: '',
                });
                setFieldToEdit('');
                handleClose();
              }}
            >
              Cancelar
            </PrimaryButton>
            <PrimaryButton onClick={handleSubmit}>
              {fieldToEdit ? 'Atualizar' : 'Adicionar'}
            </PrimaryButton>
          </S.ActionsButtons>
        </S.Container>
      </>
    </Modal>
  );
}
