import axios from 'axios';

import getSecurityCode from './getSecurityCode';
import cfac22 from '../cfac22';

export default async function authTokenWithScAndUid(uId) {
  return new Promise(async (resolve, reject) => {
    //PEGA O TOKEN DO LOCAL_STORAGE
    const auth = window.btoa(cfac22('USERNAME') + ':' + cfac22('PASSWORD'));
    const sc = await getSecurityCode();

    const config = {
      method: 'post',
      url: cfac22('API_HOST_V2') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        securitycode: sc,
        uId: uId,
        'Full-Url': window.location.href,
      },
    };

    axios(config)
      .then(function (response) {
        resolve(response.data.token);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
