import axios from 'axios';
import authToken from './authToken';
import cfac22 from '../cfac22';

export function getListClients(segmentId, payload) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(
          cfac22('API_HOST_V2') +
            `/userSegment/listClients?segmentId=${segmentId}&from=${payload.from}&size=${payload.size}`,
          {
            headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
          }
        )
        .then((result) => {
          resolve(result.data.result);
        })
        .catch((error) => {
          return reject(error.data.result);
        });
    });
  });
}
