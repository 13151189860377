import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

export const InputGroupContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
`;

export const InputContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const Input = styled.input`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid #afafaf;
  width: 100%;
  resize: none;
  background-color: white;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
`;

export const SpinnerContainer = styled.span`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  visibility: hidden;

  ${({ isLoading }) =>
    isLoading &&
    `
      visibility: visible;
      display: flex;
    `}
`;

export const OptionsDisplayContainer = styled.span`
  position: absolute;
  box-shadow: 3px 3px 6px #00000029;
  background: white;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 6rem;
  z-index: 300;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
      visibility: visible;
      min-height: 18px;
    `}
`;

export const OptionSelectable = styled.span`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  transition: 0.2s ease-in-out;
  background: white;

  &:hover {
    cursor: pointer;
    background: #dbdbdb;
  }
`;

export const InputEstablishment = styled.input`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid #afafaf;
  width: 100%;
  resize: none;
  background-color: white;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
`;

export const OptionsDisplayContainerEstablishment = styled.span`
  position: absolute;
  box-shadow: 3px 3px 6px #00000029;
  background: white;
  width: 100%;
  min-height: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 300;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
  display: flex;

      visibility: visible;
      min-height: 18px;
    `}
`;

export const InputOrganization = styled.input`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid #afafaf;
  width: 100%;
  resize: none;
  background-color: white;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
`;

export const OptionsDisplayContainerOrganization = styled.span`
  position: absolute;
  box-shadow: 3px 3px 6px #00000029;
  background: white;
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 300;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
      visibility: visible;
      min-height: 18px;
    `}
`;
