import React from 'react';
import * as D from 'js/components/DesignSystem/styles';
import { Link } from 'react-router-dom';
import { Cursor } from 'phosphor-react';
import { Tooltip } from '@mui/material';

export function StyledButton(props) {
  const { value, index, url, onClick, roles, block = false } = props;

  //Array de escolha de cores para o StyledButton
  const cor = ['#08BAD0', '#6E3296', '#6c63ff'];

  let newColor = cor[index % cor.length];

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (block) {
    return (
      <Tooltip
        title={'Apenas o(s) seguinte(s) cargo(s) tem acesso á este módulo: ' + roles.join(', ')}
        arrow
      >
        <div style={{ textDecoration: 'none', width: '100%', cursor: 'not-allowed' }}>
          <D.CardButton disabled={block} newcolor={newColor}>
            {value}
          </D.CardButton>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Link
        to={block ? null : url}
        onClick={handleClick}
        style={{ textDecoration: 'none', width: '100%' }}
      >
        <D.CardButton disabled={block} newcolor={newColor}>
          {value}
        </D.CardButton>
      </Link>
    );
  }
}
