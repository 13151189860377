import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { separateBase64String } from 'js/library/utils/helpers';

const borderStylesMap = {
  none: 'Nenhuma',
  solid: 'Sólida',
  dashed: 'Tracejada',
  dotted: 'Pontilhada',
  double: 'Dupla',
  groove: 'Entalhada',
  ridge: 'Serrilhada',
  inset: 'Interna',
  outset: 'Externa',
};

const uploadImg = async (image, clubeId) => {
  const firstPart = image.split('/');
  const [typeImg] = firstPart[1].split(';');
  const newImage = separateBase64String(image);

  const response = await uploadFiles('campaignLp', {
    mimeType: newImage.mimeType,
    fileName: `cardsGrid_${typeImg}_${clubeId}`,
    buffer: newImage.buffer,
  });

  if (response) return response;
};

export default { borderStylesMap, uploadImg };
