import authToken from 'js/library/utils/API/authToken';
import axios from 'axios';
import cfac22 from '../../cfac22';

export default function getInvoiceModules(campaignId) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        const config = {
          method: 'get',
          url: `${cfac22('API_HOST_V2')}/campaignInvoiceModules?campaignId=${campaignId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Full-Url': window.location.href,
          },
        };

        axios(config)
          .then((result) => {
            return resolve(result.data.result);
          })
          .catch((error) => {
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}
