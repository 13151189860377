import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

// STYLES
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

export const ColorInformation = ({ dataCustomization, setDataCustomization }) => {
  const { backgroundColor, textsColor } = dataCustomization;

  const [displayColorPicker, setDisplayColorPicker] = useState({
    pickerBackground: false,
    pickerText: false,
  });

  return (
    <Grid container spacing={2} sx={{ border: '1px solid #aaa', padding: '1rem 2rem 2rem 1rem' }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 600, paddingLeft: '1rem' }}>
        Cores:
      </Typography>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '1rem',
        }}
      >
        <Box width="100%" display="flex" flexDirection="column" rowGap="8px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            columnGap="12px"
          >
            <TextField
              fullWidth
              label="Cor do background*"
              id="textColor"
              margin="normal"
              value={backgroundColor}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: backgroundColor,
                width: '360px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() =>
                setDisplayColorPicker((prevState) => ({
                  ...prevState,
                  pickerBackground: !prevState.pickerBackground,
                }))
              }
            >
              {displayColorPicker.pickerBackground ? 'Salvar' : 'Selecione a cor do background'}
            </Button>
          </Box>
          {displayColorPicker.pickerBackground && (
            <ChromePicker
              color={backgroundColor}
              onChange={(event) =>
                setDataCustomization((prevCustomization) => ({
                  ...prevCustomization,
                  backgroundColor: event.hex,
                }))
              }
            />
          )}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '1rem' }}
      >
        <Box width="100%" display="flex" flexDirection="column" rowGap="8px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            columnGap="12px"
          >
            <TextField
              fullWidth
              label="Cor do texto*"
              id="textColor"
              margin="normal"
              value={textsColor}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: textsColor,
                width: '360px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() =>
                setDisplayColorPicker((prevState) => ({
                  ...prevState,
                  pickerText: !prevState.pickerText,
                }))
              }
            >
              {displayColorPicker.pickerText ? 'Salvar' : 'Selecione a cor do texto'}
            </Button>
          </Box>
          {displayColorPicker.pickerText && (
            <ChromePicker
              color={textsColor}
              onChange={(event) =>
                setDataCustomization((prevCustomization) => ({
                  ...prevCustomization,
                  textsColor: event.hex,
                }))
              }
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
