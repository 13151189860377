import axios from 'axios';

import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { hashTriibo1, validateRegistrationDate } from 'js/library/utils/helpers.js';
import cfac22 from '../cfac22';

export function getChannelToken_v1(uId) {
  return new Promise((resolve, reject) => {
    //capturando dados do Storage
    const channelObj = JSON.parse(localStorage.getItem('channelToken'));

    const valideDate = validateRegistrationDate(
      channelObj === null ? null : channelObj.registrationDate,
      0.25
    );
    const channelUId = channelObj === null ? null : channelObj.uId;

    //token não existe no storage
    if (
      channelObj === null ||
      channelObj.id === null ||
      channelObj.id === '' ||
      channelObj.id === undefined ||
      !valideDate ||
      channelUId === undefined ||
      channelUId === null ||
      channelUId !== uId
    ) {
      const passPhrase = firebaseDatabase.ref('triibo-auth-api-channel').push().key;
      const challenge = hashTriibo1(
        cfac22('keyapiv1triibowebadmin'),
        passPhrase,
        cfac22('channelToken')
      );

      axios
        .post(
          cfac22('API_HOST_V1') + 'getChannelToken_v1',
          {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: uId,
              passPhrase: passPhrase,
              challenge: challenge,
              channelId: cfac22('channelId'),
            },
          },
          {
            headers: {
              'Full-Url': window.location.href,
            },
          }
        )
        .then((result) => {
          //console.log('RESULTADO GET CHANNEL TOKEN', result);
          localStorage.setItem(
            'channelToken',
            '{ "id":"' +
              challenge +
              '", "registrationDate":' +
              Date.now() +
              ', "uId":"' +
              uId +
              '" }'
          );

          resolve(challenge);
        })
        .catch((error) => {
          //console.log('ERRO GET CHANNEL TOKEN', error);
          reject(error);
        });
    }
    //token existe no storage
    else {
      resolve(channelObj.id);
    }
  });
}
