import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import { ColumnViewer } from './ColumnViewer';

export const CardItem = ({ form, setForm, modalRef }) => {
  const cardsList =
    Array.isArray(form.cardsList) && form.cardsList.length > 0 ? form.cardsList : [];

  const [isRemovingCard, setIsRemovingCard] = useState(null);

  useEffect(() => {
    if (cardsList.length === 0) handleAddCard();
  }, [cardsList]);

  const handleAddCard = () => {
    const currentCardsList = Array.isArray(form.cardsList) ? form.cardsList : [];

    setForm((prevState) => ({
      ...prevState,
      cardsList: [
        ...currentCardsList,
        {
          image: { value: '', required: true },
          text: { value: '', required: true },
          title: { value: '', required: true },
          actionButton: {
            buttonLabel: { value: '', required: false },
            target: { value: '', required: false },
            url: { value: '', required: false },
          },
        },
      ],
    }));
  };

  const handleRemoveCard = (index, event) => {
    event.stopPropagation();
    setIsRemovingCard(index);
  };

  const cancelRemoveCard = (event) => {
    event.stopPropagation();
    setIsRemovingCard(null);
  };

  const confirmRemoveCard = () => {
    const newCardsList = [...form.cardsList];
    newCardsList.splice(isRemovingCard, 1);
    setForm((prevState) => ({
      ...prevState,
      cardsList: newCardsList,
    }));
    setIsRemovingCard(null);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '1px solid #aaaaaa',
        padding: '1rem',
        width: '100%',
        gap: '1rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Cards:
        </Typography>

        <Button
          variant="outlined"
          sx={{ fontSize: '1.3rem', fontWeight: 900, minWidth: '40px', padding: 0 }}
          onClick={handleAddCard}
        >
          +
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Escolha uma variante:</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={form.cardVariant.value || ''}
            onChange={(event) =>
              setForm((prevState) => ({
                ...prevState,
                cardVariant: {
                  value: event.target.value,
                },
              }))
            }
          >
            <FormControlLabel value="image" control={<Radio />} label="Imagem" />
            <FormControlLabel value="icon" control={<Radio />} label="Ícone" />
          </RadioGroup>
        </FormControl>

        <TextField
          label="Número de colunas"
          id="outlined-number"
          type="number"
          onChange={(event) =>
            setForm((prevState) => ({
              ...prevState,
              columnsNumber: {
                value: event.target.value,
                required: true,
              },
            }))
          }
          value={form.columnsNumber.value}
          inputProps={{
            min: 1,
            max: 6,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: '30%' }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="20px"
      >
        <Typography variant="body1" paddingLeft="0px !important">
          Visualização:
        </Typography>

        <Box sx={{ display: 'flex', gap: '0.5rem', margin: '0 auto' }}>
          <ColumnViewer numberOfColumns={form.columnsNumber.value} />
        </Box>
      </Box>

      {cardsList.map((card, index) => (
        <Accordion key={index} defaultExpanded={index === 0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{ fontWeight: 700 }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '98%',
              }}
            >
              {card.title.value === '' ? `Card - ${index + 1}` : `Card - ${card.title.value}`}
              {index > 0 &&
                (isRemovingCard === index ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                      flexDirection: 'column',
                      gap: '0.5rem',
                    }}
                  >
                    <Typography variant="body2">Deseja remover esse card?</Typography>
                    <span
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        marginRight: '0.5rem',
                      }}
                    >
                      <Button variant="outlined" color="error" onClick={confirmRemoveCard}>
                        Sim
                      </Button>
                      <Button variant="outlined" onClick={(event) => cancelRemoveCard(event)}>
                        Não
                      </Button>
                    </span>
                  </Box>
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ marginLeft: '1rem' }}
                    onClick={(event) => handleRemoveCard(index, event)}
                  >
                    Remover
                  </Button>
                ))}
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <ImportImage
                imageDefault={null}
                autoWidth={false}
                image={card.image.value}
                setImage={(newImage) => {
                  const newCardsList = [...form.cardsList];
                  newCardsList[index].image.value = newImage;
                  setForm((prevState) => ({
                    ...prevState,
                    cardsList: newCardsList,
                  }));
                }}
                ratio={4 / 3}
                refScroll={modalRef}
              />
            </Box>

            <TextField
              fullWidth
              label="Título"
              id={`cardsList[${index}].title`}
              margin="normal"
              value={card.title.value || ''}
              onChange={(event) => {
                const newCardsList = [...form.cardsList];
                newCardsList[index].title.value = event.target.value;
                setForm((prevState) => ({
                  ...prevState,
                  cardsList: newCardsList,
                }));
              }}
            />

            <TextField
              fullWidth
              multiline
              minRows={2}
              label="Texto"
              id={`cardsList[${index}].text`}
              margin="normal"
              value={card.text.value || ''}
              onChange={(event) => {
                const newCardsList = [...form.cardsList];
                newCardsList[index].text.value = event.target.value;
                setForm((prevState) => ({
                  ...prevState,
                  cardsList: newCardsList,
                }));
              }}
            />

            <Box width="100%" mt={2} mb={1}>
              <FormControl fullWidth>
                <InputLabel id={`target-select-label-${index}`}>Ação ao clicar no botão</InputLabel>
                <Select
                  label="Ação ao clicar no botão"
                  labelId={`target-select-label-${index}`}
                  id={`target-select-${index}`}
                  value={card.actionButton.target.value || ''}
                  onChange={(event) => {
                    const newCardsList = [...form.cardsList];
                    newCardsList[index].actionButton.target.value = event.target.value;
                    setForm((prevState) => ({
                      ...prevState,
                      cardsList: newCardsList,
                    }));
                  }}
                >
                  <MenuItem value="_self">Abrir na mesma janela (_self)</MenuItem>
                  <MenuItem value="_blank">Abrir em nova aba/janela (_blank)</MenuItem>
                  <MenuItem value="_parent">Abrir no frame pai (_parent)</MenuItem>
                  <MenuItem value="_top">Abrir na janela principal (_top)</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <TextField
              fullWidth
              label="Texto do botão"
              id={`cardsList[${index}].actionButton.buttonLabel`}
              margin="normal"
              value={card.actionButton.buttonLabel.value || ''}
              onChange={(event) => {
                const newCardsList = [...form.cardsList];
                newCardsList[index].actionButton.buttonLabel.value = event.target.value;
                setForm((prevState) => ({
                  ...prevState,
                  cardsList: newCardsList,
                }));
              }}
            />

            <TextField
              fullWidth
              label="URL de redirecionamento"
              id={`cardsList[${index}].actionButton.url`}
              margin="normal"
              value={card.actionButton.url.value || ''}
              onChange={(event) => {
                const newCardsList = [...form.cardsList];
                newCardsList[index].actionButton.url.value = event.target.value;
                setForm((prevState) => ({
                  ...prevState,
                  cardsList: newCardsList,
                }));
              }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
