import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export function GetOrgsPaginatedSearch({ organizationName, from, size, accessMethod, visibility }) {
  return new Promise(function (resolve, reject) {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/organizations/getOrgsPaginatedSearch`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: {
          payload: {
            organizationName,
            from,
            size,
            accessMethod,
            visibility,
          },
        },
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response.data);
        });
    });
  });
}
