import React, { useState, useEffect } from 'react';

import { cloneDeep } from 'lodash';

import { SearchTags } from './styles';

const TagsAddUF = (props) => {
  const { form, formKey, setForm, listArr, listKeys } = props;
  const [current, setCurrent] = useState(listArr);

  useEffect(() => {
    if (!form[formKey]) return;

    let newCategories = cloneDeep(listArr);

    form[formKey].forEach((element) => {
      newCategories = newCategories.filter(function (obj) {
        return obj[listKeys[1]] !== element;
      });
    });

    setCurrent(newCategories);
  }, [form]);

  return (
    <>
      <SearchTags
        defaultValue={'Selecione'}
        onChange={(e) => {
          e.preventDefault();
          if (e.target.value === 'Selecione') {
            return null;
          }
          const newArr = cloneDeep(form[formKey]);
          newArr.push(e.target.value);
          setForm({ ...form, [formKey]: newArr });
        }}
      >
        <option value={'Selecione'}>Categorias</option>
        {current.map((query, index) => {
          return (
            <option key={query[listKeys[1]] + '--' + index} value={query[listKeys[1]]}>
              {query[listKeys[0]]}
            </option>
          );
        })}
      </SearchTags>
    </>
  );
};

export default TagsAddUF;
