import React, { useState, useRef, useEffect } from 'react';

//styles
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import { SketchPicker } from 'react-color';
import { Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import whatsappIcon from 'styles/assets/whatsapp.png';

//Apis
import { getSeuClube, updateSeuClube } from 'js/library/utils/API/seuClube';

//Libs
import { toast } from 'react-toastify';

//Componentes
import { EditClubImages } from 'js/components/Establishment/EstablishmentSteps/createFunctions';
import { ModalUpdateClub } from './ModalUpdateClub';
import { TooltipEnvolveElement } from './Tooltips';
import PreviewData from './PreviewData';
import { Display } from './BasicRegistration/styles';
import { GoBackHeader } from './RelationshipClub/GoBackHeader';
import { HeaderTitle } from './RelationshipClub/HeaderTitle';
import { isValidURL } from '../../../library/utils/helpers';
import CropperModal from 'js/components/DesignSystem/Cropper/CropperModal';

const EditClub = () => {
  const clubeIdUrl = document.location.pathname.split('/')[3];

  const hiddenFileInputLogo = useRef(null);
  const hiddenFileInputFavicon = useRef(null);
  const handleFileInputLeft = useRef(null);
  const handleFileInputRight = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [cropImg, setCropImg] = useState('');
  const [cropPrevImg, setCropPrevImg] = useState('');
  const [newNameClube, setNewNameClube] = useState('');
  const [typeLinkbannerLeft, setTypeLinkbannerLeft] = useState('new-guide');
  const [typeLinkbannerRight, setTypeLinkbannerRight] = useState('new-guide');

  const [clubForm, setClubForm] = useState({
    bannerLeft: '', //imagens
    bannerRight: '', //imagens
    colorPrimary: '',
    colorSecondary: '#FFFFFF',
    clubeId: '',
    createDate: '',
    favicon: '', //imagem
    logo: '', // imagem
    linkBannerLeft: '',
    linkBannerRight: '',
    keywords: [],
    name: '',
    push: '',
    orgs: [],
    status: false,
    showScore: false,
    updateDate: '',
    url: '',
    whatsapp: {
      active: false,
      cellphone: 0,
    },
  });

  const createDateField = useRef(new Date(clubForm.createDate));
  const updateDateField = useRef(new Date(clubForm.updateDate));
  const [filesForUpload, setFilesForUpload] = useState({
    logo: '',
    favicon: '',
    bannerLeft: '',
    bannerRight: '',
  });

  useEffect(() => {
    getSeuClube(clubeIdUrl).then((response) => {
      //tratando url no frame
      if (response.linkBannerLeft)
        if (response?.linkBannerLeft?.indexOf('/pagina-completa/') > 0) {
          setTypeLinkbannerLeft('full-frame');
          response.linkBannerLeft =
            decodeURIComponent(new URL(response.linkBannerLeft).searchParams.get('url')) ?? '';
        } else if (response?.linkBannerLeft.indexOf('/pagina-interna/') > 0) {
          setTypeLinkbannerLeft('inside-frame');
          response.linkBannerLeft =
            decodeURIComponent(new URL(response.linkBannerLeft).searchParams.get('url')) ?? '';
        }

      if (response.linkBannerRight)
        if (response.linkBannerRight?.indexOf('/pagina-completa/') > 0) {
          setTypeLinkbannerRight('full-frame');
          response.linkBannerRight =
            decodeURIComponent(new URL(response.linkBannerRight).searchParams.get('url')) ?? '';
        } else if (response.linkBannerRight.indexOf('/pagina-interna/') > 0) {
          setTypeLinkbannerRight('inside-frame');
          response.linkBannerRight =
            decodeURIComponent(new URL(response.linkBannerRight).searchParams.get('url')) ?? '';
        }

      setNewNameClube(response.clubeId);
      setClubForm({
        ...clubForm,
        bannerLeft: response.bannerLeft,
        bannerRight: response.bannerRight,
        clubeId: response.clubeId,
        name: response.name,
        createDate: response.createDate,
        colorPrimary: response.hasOwnProperty('colorPrimary') ? response.colorPrimary : '#6E3296',
        colorSecondary: response.hasOwnProperty('colorSecondary')
          ? response.colorSecondary
          : '#FFF',
        favicon: response.favicon,
        linkBannerLeft: response.linkBannerLeft,
        linkBannerRight: response.linkBannerRight,
        logo: response.logo,
        keywords: response.keywords,
        orgs: response.orgs,
        push: response.id,
        showScore: response.showScore !== undefined ? response.showScore : false,
        status: response.status,
        updateDate: response.updateDate,
        url: response.url,
        whatsapp:
          response.whatsapp === undefined ? { active: false, cellphone: 0 } : response.whatsapp,
      });
    });
  }, [clubeIdUrl]);

  useEffect(() => {
    if (cropImg && cropImg.includes('base64')) {
      setFilesForUpload({
        ...filesForUpload,
        logo: cropImg,
      });
    }
  }, [cropImg]);

  function updateInfoClub() {
    const data = { ...clubForm };

    delete data.createDate;
    delete data.updateDate;
    delete data.push;
    delete data.keywords;

    if (!data.whatsapp.active) {
      delete data.whatsapp.cellphone;
    }
    if (data.name === '') {
      return toast.warning('O nome do clube deve ser preenchido.');
    }
    if (data.whatsapp.active) {
      if (data.whatsapp.hasOwnProperty('cellphone') === false || data.whatsapp.cellphone === '+') {
        return toast.warning(
          'Se a opção whatsapp estiver ativa, é obrigatório inserir um número de telefone válido.'
        );
      }
    }
    if (!isValidURL(data.linkBannerLeft)) {
      return toast.warning('URL do banner esquerdo inválida.');
    }
    if (!isValidURL(data.linkBannerRight)) {
      return toast.warning('URL do banner direito inválida.');
    }

    //tratando url do banner para inserir frame
    if (typeLinkbannerLeft === 'inside-frame') {
      data.linkBannerLeft =
        'https://' +
        data.clubeId +
        data.url +
        '/pagina-interna/?url=' +
        encodeURIComponent(data.linkBannerLeft);
    } else if (typeLinkbannerLeft === 'full-frame') {
      data.linkBannerLeft =
        'https://' +
        data.clubeId +
        data.url +
        '/pagina-completa/?url=' +
        encodeURIComponent(data.linkBannerLeft);
    }

    if (typeLinkbannerRight === 'inside-frame') {
      data.linkBannerRight =
        'https://' +
        data.clubeId +
        data.url +
        '/pagina-interna/?url=' +
        encodeURIComponent(data.linkBannerRight);
    } else if (typeLinkbannerRight === 'full-frame') {
      data.linkBannerRight =
        'https://' +
        data.clubeId +
        data.url +
        '/pagina-completa/?url=' +
        encodeURIComponent(data.linkBannerRight);
    }

    updateSeuClube(data)
      .then((result) => {
        if (result.data.success) {
          const listPromises = [];
          if (filesForUpload.logo !== '') {
            listPromises.push(EditClubImages(filesForUpload.logo, clubForm.logo));
          }
          if (filesForUpload.bannerLeft !== '') {
            listPromises.push(EditClubImages(filesForUpload.bannerLeft, clubForm.bannerLeft));
          }
          if (filesForUpload.bannerRight !== '') {
            listPromises.push(EditClubImages(filesForUpload.bannerRight, clubForm.bannerRight));
          }
          if (filesForUpload.favicon !== '') {
            listPromises.push(EditClubImages(filesForUpload.favicon, clubForm.favicon));
          }

          Promise.all(listPromises);

          toast.success('Clube editado com sucesso !');
          setOpenModal(true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const handleClick = (uploadType) => {
    if (uploadType === 'logo') {
      hiddenFileInputLogo.current.click();
    }
    if (uploadType === 'favicon') {
      hiddenFileInputFavicon.current.click();
    }
    if (uploadType === 'left') {
      handleFileInputLeft.current.click();
    }
    if (uploadType === 'right') {
      handleFileInputRight.current.click();
    }
  };

  const handleChange = (e, uploadType) => {
    e.preventDefault();
    // props.handleFile(fileUploaded);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (uploadType === 'logo') {
      setClubForm({
        ...clubForm,
        logo: clubForm.clubeId + '-logo',
      });
    }

    if (uploadType === 'favicon') {
      setClubForm({
        ...clubForm,
        favicon: clubForm.clubeId + '-favicon',
      });
    }
    if (uploadType === 'left') {
      setClubForm({
        ...clubForm,
        bannerLeft: clubForm.clubeId + '-banner-esquerdo',
      });
    }
    if (uploadType === 'right') {
      setClubForm({
        ...clubForm,
        bannerRight: clubForm.clubeId + '-banner-direito',
      });
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (uploadType === 'logo') {
        setOpenCropModal(true);
        setCropPrevImg(reader.result);
      }
      if (uploadType === 'left') {
        setFilesForUpload({
          ...filesForUpload,
          bannerLeft: reader.result,
        });
      }
      if (uploadType === 'right') {
        setFilesForUpload({
          ...filesForUpload,
          bannerRight: reader.result,
        });
      }
      if (uploadType === 'favicon') {
        setFilesForUpload({
          ...filesForUpload,
          favicon: reader.result,
        });
      }
    };
    e.target.value = null;
  };

  const handleLinkBannerLeft = (e) => {
    setClubForm({
      ...clubForm,
      linkBannerLeft: e.target.value,
    });
  };

  const handleLinkBannerRight = (e) => {
    setClubForm({
      ...clubForm,
      linkBannerRight: e.target.value,
    });
  };

  const handleChangeCompletePrimary = (color) => {
    setClubForm({
      ...clubForm,
      colorPrimary: color,
    });
  };

  const handleChangeCompleteSecondary = (color) => {
    setClubForm({
      ...clubForm,
      colorSecondary: color,
    });
  };

  const handleChangeShowScore = () => {
    setClubForm({
      ...clubForm,
      showScore: !clubForm.showScore,
    });
  };

  const activeWhatsapp = () => {
    setClubForm({
      ...clubForm,
      whatsapp: {
        active: !clubForm.whatsapp.active,
      },
    });
  };

  const whatsappValue = (e) => {
    let numberAjust = e.target.value.replace(/[^\d]+/g, '');
    setClubForm({
      ...clubForm,
      whatsapp: {
        active: clubForm.whatsapp.active,
        cellphone: '+' + numberAjust,
      },
    });
  };

  function handleCloseModal() {
    setOpenModal(false);
  }

  return (
    <S.CreateClubContainer>
      <CropperModal
        open={openCropModal}
        setOpen={setOpenCropModal}
        img={cropPrevImg}
        setImg={setCropImg}
        ratio={4 / 1}
      />
      <ModalUpdateClub closeModal={handleCloseModal} openModal={openModal} />
      <GoBackHeader />
      <HeaderTitle pageInfo="Estilização do clube" />

      <S.EditContainer>
        <S.FormCard>
          <S.NameGroup>
            <S.FormLabel>Nome do clube:</S.FormLabel>
            <D.TriiboTextField
              rows={1}
              maxLength="30"
              value={clubForm.name}
              onChange={(e) => setClubForm({ ...clubForm, name: e.target.value })}
            />
          </S.NameGroup>
          <S.NameGroup>
            <S.FormLabel>Clube</S.FormLabel>
            <D.TriiboTextField rows={1} value={clubForm.clubeId + clubForm.url} disabled />
          </S.NameGroup>

          <Display>
            <TooltipEnvolveElement
              title="Como devo enviar a logo ?"
              description="Essa imagem deve estar em um dos formatos png, jpeg, jpg e ter no máximo 10mb."
              label="infoLogo"
            >
              <D.ButtonsEditClub
                onClick={(e) => {
                  e.preventDefault();
                  handleClick('logo');
                }}
                purple={true}
              >
                Upload do logo
              </D.ButtonsEditClub>
            </TooltipEnvolveElement>
            <TooltipEnvolveElement
              title="Como devo enviar o favicon ?"
              description="Essa imagem deve está no formato .ico no tamanho 16px x 16px."
              label="infoFavicon"
            >
              <D.ButtonsEditClub
                onClick={(e) => {
                  e.preventDefault();
                  handleClick('favicon');
                }}
                purple={true}
              >
                Upload do favicon
              </D.ButtonsEditClub>
            </TooltipEnvolveElement>
          </Display>
          <S.PickerGroup>
            <S.PickerContainer style={{ display: 'flex', alignItems: 'center' }}>
              <S.FormLabel>Cor primária</S.FormLabel>
              <SketchPicker
                color={clubForm.colorPrimary}
                onChange={(e) => handleChangeCompletePrimary(e.hex)}
                disableAlpha={true}
                width={'75%'}
              />
            </S.PickerContainer>
            <S.PickerContainer style={{ display: 'flex', alignItems: 'center' }}>
              <S.FormLabel>Cor secundária</S.FormLabel>
              <SketchPicker
                color={clubForm.colorSecondary}
                onChange={(e) => handleChangeCompleteSecondary(e.hex)}
                disableAlpha={true}
                width={'75%'}
              />
            </S.PickerContainer>
          </S.PickerGroup>
          <S.UploadButtonContainer>
            <Display>
              <S.FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                <D.ButtonsEditClub
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick('left');
                  }}
                  blue={true}
                >
                  Banner esquerdo
                </D.ButtonsEditClub>
                <S.FormLabel>Link banner esquerdo:</S.FormLabel>

                <FormControl
                  fullWidth
                  style={{
                    marginRight: `${window.screen.width <= 1140 ? 0 : '1.5rem'}`,
                  }}
                >
                  <InputLabel>Abrir link em:</InputLabel>
                  <Select
                    value={typeLinkbannerLeft}
                    label="Abrir link em:"
                    onChange={(e) => setTypeLinkbannerLeft(e.target.value)}
                  >
                    <MenuItem value={'new-guide'}>Nova guia</MenuItem>
                    <MenuItem value={'inside-frame'}>Conteúdo interno</MenuItem>
                    <MenuItem value={'full-frame'}>Conteúdo completo</MenuItem>
                  </Select>
                </FormControl>

                <D.TriiboTextField
                  style={{
                    marginRight: `${window.screen.width <= 1140 ? 0 : '1.5rem'}`,
                  }}
                  rows={1}
                  placeholder={'www.triibo.com.br'}
                  value={clubForm.linkBannerLeft}
                  onChange={(e) => handleLinkBannerLeft(e)}
                />
              </S.FormGroup>
              <S.FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                <D.ButtonsEditClub
                  style={{
                    width: '155px',
                    marginTop: `${window.screen.width <= 1140 ? '1rem' : 0}`,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick('right');
                  }}
                  blue={true}
                >
                  Banner direito
                </D.ButtonsEditClub>
                <S.FormLabel>Link banner direito:</S.FormLabel>

                <FormControl fullWidth style={{ maxWidth: '180px' }}>
                  <InputLabel>Abrir link em:</InputLabel>
                  <Select
                    value={typeLinkbannerRight}
                    label="Abrir link em:"
                    onChange={(e) => setTypeLinkbannerRight(e.target.value)}
                  >
                    <MenuItem value={'new-guide'}>Nova guia</MenuItem>
                    <MenuItem value={'inside-frame'}>Conteúdo interno</MenuItem>
                    <MenuItem value={'full-frame'}>Conteúdo completo</MenuItem>
                  </Select>
                </FormControl>

                <D.TriiboTextField
                  style={{ maxWidth: '180px' }}
                  rows={1}
                  placeholder={'www.triibo.com.br'}
                  value={clubForm.linkBannerRight}
                  onChange={(e) => handleLinkBannerRight(e)}
                />
              </S.FormGroup>
            </Display>
          </S.UploadButtonContainer>
          <span>
            <S.FormGroupRow>
              <Checkbox
                color="primary"
                checked={clubForm.showScore}
                onChange={handleChangeShowScore}
              />
              Habilitar sistema de pontuação do seu clube ?
            </S.FormGroupRow>
            <S.FormGroupRow>
              <Checkbox
                color="primary"
                checked={clubForm.whatsapp.active}
                onChange={activeWhatsapp}
              />
              Habilitar whatsapp para contato ?
            </S.FormGroupRow>
            {clubForm.whatsapp.active === true ? (
              <S.FormGroupRow>
                <img width="30px" src={whatsappIcon} alt="" />
                <S.inputMaskStyle
                  type="cellphone"
                  placeholder="(00) 0 0000 0000"
                  mask="+55 (99) 99999 9999"
                  value={clubForm.whatsapp.cellphone}
                  onChange={(event) => whatsappValue(event)}
                />
              </S.FormGroupRow>
            ) : null}
          </span>
          <D.ButtonsEditClub onClick={updateInfoClub}>Salvar</D.ButtonsEditClub>
        </S.FormCard>
        <PreviewData
          clubForm={clubForm}
          setClubForm={setClubForm}
          createDateField={createDateField}
          updateDateField={updateDateField}
          filesForUpload={filesForUpload}
          newNameClube={newNameClube}
        />
      </S.EditContainer>
      <input
        type="file"
        ref={hiddenFileInputLogo}
        accept="image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            alert('Arquivo excedeu 10MB');
            return;
          }
          setCropPrevImg('');
          handleChange(e, 'logo');
        }}
        style={{ visibility: 'hidden' }}
      />
      <input
        type="file"
        ref={hiddenFileInputFavicon}
        accept=".ico"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            alert('Arquivo excedeu 10MB');
            return;
          }
          handleChange(e, 'favicon');
        }}
        style={{ visibility: 'hidden' }}
      />
      <input
        type="file"
        ref={handleFileInputLeft}
        accept="image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            alert('Arquivo excedeu 10MB');
            return;
          }
          handleChange(e, 'left');
        }}
        style={{ visibility: 'hidden' }}
      />
      <input
        type="file"
        ref={handleFileInputRight}
        accept="image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            alert('Arquivo excedeu 10MB');
            return;
          }
          handleChange(e, 'right');
        }}
        style={{ visibility: 'hidden' }}
      />
    </S.CreateClubContainer>
  );
};

export default EditClub;

