/* exemplo de chamada do componente:

<CustomButtonsTableApiPaginated
data={searchTerm ? filteredCampaigns : listTable}
column={column}
perPage={10}
setData={setData}
itemsPerPage={itemsPerPage}
size={data.size}
componentReceiver={componentReceiver}
/>

*/

import React, { useEffect, useState } from 'react';
import * as D from 'js/components/DesignSystem/Table/styles';
import usePagination from '../usePagination';
import { CircularProgress, Pagination } from '@mui/material';

const TableHeadItem = ({ item }) => <D.TableTH>{item.heading}</D.TableTH>;

const TableRow = ({ item, column, render }) => {
  const extractTableItem = (item, value) => {
    if (value.includes('.')) {
      const valueSplit = value.split('.');
      return valueSplit.reduce((prev, next) => prev[next], item);
    }
    return item[value];
  };

  return (
    <D.TableTR>
      {column.map((columnItem, index) => (
        <D.TableTD key={index}>{extractTableItem(item, columnItem.value)}</D.TableTD>
      ))}
      {render}
    </D.TableTR>
  );
};

const CustomButtonsTableApiPaginated = ({
  data,
  column,
  perPage,
  componentReceiver,
  isPagination,
  selectedItem = false,
  setData,
  size,
  itemsPerPage,
  //parametros usados apenas LandingPageList
  loadingTableDisabled,
  searchTerm,
}) => {
  const [page, setPage] = useState(1);
  const [searchInputUsed, setSearchInputUsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (selectedItem) {
      setSearchInputUsed(true);
      setError(false);
    } else {
      setSearchInputUsed(false);
    }

    if (data.length === 0) {
      setError(true);
    }

    if (loadingTableDisabled) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [data]);

  const PER_PAGE = perPage;
  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  //para a LandingPageList
  useEffect(() => {
    if (searchTerm === '') {
      setPage(1);
    }
  }, [searchTerm === '']);

  const handleChangePage = (_, currentPage) => {
    const { maxPage } = _DATA;

    if (currentPage === maxPage && itemsPerPage === size && !searchTerm) {
      setLoading(true);
      setData((prevState) => ({
        ...prevState,
        from: prevState.from + prevState.size,
      }));
    }

    setPage(currentPage);
    _DATA.jump(currentPage);
  };

  const { columnComponent } = componentReceiver(data, null);

  if (!searchInputUsed && data.length === 0) {
    return null;
  }

  if (searchInputUsed && error) {
    return <p style={{ textAlign: 'center', padding: '0' }}>Nenhum item encontrado...</p>;
  }

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={30} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <D.TableContainer>
            <D.Table>
              <thead>
                <D.TableTR>
                  {column.map((item, index) => (
                    <TableHeadItem key={index} item={item} />
                  ))}
                  {columnComponent}
                </D.TableTR>
              </thead>
              <tbody>
                {_DATA.currentData().map((item, index) => (
                  <TableRow
                    key={index}
                    item={item}
                    column={column}
                    render={componentReceiver(null, item).childrenComponent}
                  />
                ))}
              </tbody>
            </D.Table>
          </D.TableContainer>
          {isPagination ? null : (
            <Pagination
              sx={{ margin: '0 auto' }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={handleChangePage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CustomButtonsTableApiPaginated;
