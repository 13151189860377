import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, NativeSelect } from '@mui/material';
import searchIcon from 'styles/assets/SearchUser/search.svg';
import accessKeyIcon from 'styles/assets/SearchUser/accessKey.svg';
import { maskCEP } from 'js/library/utils/helpers';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import * as S from './styles';

export default function RenderInputDynamic({
  fieldInfos,
  inputValue,
  setInputValue,
  handleSubmit,
  loadingSearch = false,
  customIcon,
}) {
  const [cellphoneInput, setCellphoneInput] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputValue('');
    setCurrentDDD(valuesArr[0]);
    setCurrentCountry(e.target.value);
  };

  const handleInputChange = (event, input) => {
    let value = event.target.value;

    if (input.fieldName === 'cellPhone') {
      return setCellphoneInput(value.replace(/[^\d]/g, ''));
    }

    if (input.fieldName === 'cpf') {
      return setInputValue(value.replace(/\D/g, ''));
    }

    if (input.fieldName === 'cep') {
      return setInputValue(maskCEP(value));
    }

    return setInputValue(value);
  };

  const maskMapping = {
    cellPhone: '(99) 99999-9999',
    cpf: '999.999.999-99',
    cnpj: '99.999.999/9999-99',
    data: '99/99/9999',
  };

  const renderInput = () => {
    const mask = (fieldInfos && maskMapping[fieldInfos.inputMask]) || fieldInfos.inputMask;
    // switch (fieldInfos.inputType) {
    //   case 'text':
    //   case 'email':
    //   case 'date':
    return (
      <S.BoxInput>
        {fieldInfos.fieldType === 'cellPhone' ? (
          <S.Adornment>
            <NativeSelect
              disableUnderline
              disabled={loadingSearch}
              sx={{
                fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                flex: '1',
                height: '100%',
                border: 'none',
                outline: 'none',
                fontSize: '17px',
                color: '#383838d6',
                borderRadius: '10px',
                cursor: 'pointer',
                '@media (max-width: 600px)': {
                  fontSize: '14px',
                },
              }}
              value={currentCountry}
              onChange={(e) => handleCountryChange(e)}
            >
              {phoneMaskArray.map((country, index) => (
                <option
                  style={{
                    fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  }}
                  key={country.id}
                  value={[country.ddd, country.mask, country.fullMask]}
                >
                  {country.emoji} {country.initials} {country.ddd}
                </option>
              ))}
            </NativeSelect>
          </S.Adornment>
        ) : (
          <S.KeyType>
            <img src={accessKeyIcon} alt="" />
            {fieldInfos.label}:
          </S.KeyType>
        )}

        <S.SearchInput
          disabled={loadingSearch}
          mask={mask}
          value={fieldInfos.fieldType === 'cellPhone' ? cellphoneInput : inputValue}
          placeholder={mask}
          onChange={(e) => handleInputChange(e, fieldInfos)}
          id={fieldInfos.fieldName}
          name={fieldInfos.fieldName}
        />
        <S.SearchIcon>
          <Button disabled={loadingSearch} type="submit">
            {loadingSearch ? (
              <CircularProgress size={35} />
            ) : (
              customIcon ?? <img src={searchIcon} alt="" />
            )}
          </Button>
        </S.SearchIcon>
      </S.BoxInput>
    );
    //   default:
    //     break;
    // }
  };

  useEffect(() => {
    if (fieldInfos.fieldType === 'cellPhone') {
      setInputValue(currentDDD + cellphoneInput.replace(/\D/g, ''));
    } else {
      setInputValue('');
      setCellphoneInput('');
    }

    if (inputValue === '') {
      setCellphoneInput('');
    }
  }, [fieldInfos, currentDDD, cellphoneInput]);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      {renderInput()}
    </form>
  );
}
