import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function createSegmentCustomization(payload) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        axios
          .post(
            `${cfac22('API_HOST_V2')}/segmentCustomization/create`,
            { ...payload },
            {
              headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
            }
          )
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
