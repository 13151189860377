import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import ModelTargetModal from './model';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const TargetModal = ({
  isActiveModals,
  closeModal,
  setPayload,
  selectedModalInfos,
  confirmRemoveTarget,
  setConfirmRemoveTarget,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const { form, handleChange, setForm, validateForm, clearForm } = useForm({
    textAlign: {
      value: '',
      required: false,
    },
    textColor: {
      value: '',
      required: false,
    },
    goBack: {
      value: false,
      required: false,
    },
    buttonLabel: {
      value: '',
      required: false,
    },
    path: {
      value: '',
      required: false,
    },
    label: {
      value: '',
      required: true,
    },
  });

  useEffect(() => {
    if (confirmRemoveTarget) {
      clearForm();
      setConfirmRemoveTarget(false);
    }
  }, [confirmRemoveTarget]);

  const currentModal = ModelLPSignUp.modalsList.target;

  const renderAlignOptions = ModelTargetModal.textAlignOptions?.map(({ option, align }) => (
    <MenuItem key={align} value={align}>
      {option}
    </MenuItem>
  ));

  const handleSave = () => {
    const { textAlign, textColor, goBack, buttonLabel, path, label } = form;

    if (validateForm()) {
      setPayload((prevState) => {
        const modules = [...prevState.campaignLandpage.modules];

        const updatedModule = {
          ...selectedModalInfos.modalInfos,
          textAlign: textAlign?.value,
          buttonLabel: buttonLabel?.value,
          textColor: textColor?.value,
          goBack: goBack?.value,
          label: label?.value,
          path: path?.value,
          type: 'target',
        };

        const cleanedModule = Object.fromEntries(
          Object.entries(updatedModule).filter(([_, value]) => value !== '')
        );

        // Verifica se é para editar ou adicionar
        if (
          selectedModalInfos?.currentIndex != null &&
          modules[selectedModalInfos.currentIndex]?.id === cleanedModule.id
        ) {
          // Substituir módulo existente
          modules[selectedModalInfos.currentIndex] = cleanedModule;
        } else {
          // Adicionar um novo módulo
          modules.push(cleanedModule);
        }

        return {
          campaignLandpage: {
            ...prevState.campaignLandpage,
            modules,
          },
        };
      });

      toast.success('Target salvo com sucesso!');
      clearForm();
      closeModal(currentModal);
    } else {
      toast.error('Preencha todos os campos obrigatórios!');
    }
  };

  const handleCloseModal = () => {
    clearForm();
    closeModal(currentModal);
  };

  useEffect(() => {
    if (!form.goBack.value) {
      setForm((prevState) => ({
        ...prevState,
        buttonLabel: { value: '', required: false },
        path: { value: '', required: false },
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        buttonLabel: { ...prevState.buttonLabel, required: true },
        path: { ...prevState.path, required: true },
      }));
    }
  }, [form.goBack.value]);

  useEffect(() => {
    if (isActiveModals) {
      const { textAlign, textColor, goBack, buttonLabel, path, label } =
        selectedModalInfos?.modalInfos || {};

      setForm((prevState) => ({
        ...prevState,
        textAlign: { value: textAlign || '', required: false },
        textColor: { value: textColor || '#000000', required: true },
        goBack: { value: goBack || false, required: true },
        buttonLabel: { value: buttonLabel || '', required: false },
        path: { value: path || '', required: false },
        label: { value: label || '', required: true },
      }));
    } else {
      clearForm();
    }
  }, [isActiveModals, selectedModalInfos]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="alignTextLabel">Selecione o alinhamento do texto</InputLabel>
          <Select
            inputProps={{
              name: 'textAlign',
            }}
            onChange={(event) => handleChange(event)}
            label="Selecione o alinhamento do texto"
            value={form?.textAlign?.value || ''}
            margin="dense"
            size="20"
          >
            {renderAlignOptions}
          </Select>
        </FormControl>

        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor"
              id="textColor"
              margin="normal"
              value={form?.textColor?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.textColor?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPicker((prevState) => !prevState)}
            >
              {displayColorPicker ? 'Salvar' : 'Selecione a cor do texto'}
            </Button>
          </Box>
          {displayColorPicker && (
            <ChromePicker
              color={form?.textColor?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  textColor: { ...prevState.textColor, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        <TextField
          fullWidth
          multiline
          minRows={2}
          label="Texto*"
          id="label"
          margin="normal"
          value={form.label?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="alignTextLabel">Habilitar botão?</InputLabel>
          <Select
            inputProps={{
              name: 'goBack',
            }}
            onChange={(event) => handleChange(event)}
            label="Habilitar botão?"
            value={form?.goBack?.value || false}
            margin="dense"
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </Select>
        </FormControl>

        {form?.goBack.value === true && (
          <>
            <TextField
              fullWidth
              label="Texto do botão*"
              id="buttonLabel"
              margin="normal"
              value={form.buttonLabel?.value || ''}
              onChange={(event) => handleChange(event)}
            />

            <TextField
              fullWidth
              label="Caminho de redirecionamento*"
              id="path"
              margin="normal"
              value={form.path?.value || ''}
              onChange={(event) => handleChange(event)}
            />
          </>
        )}

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TargetModal;
