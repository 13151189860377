import React, { useState, useEffect } from 'react';

import InputMask from 'react-input-mask';

// STYLES
import * as S from './styles';
import { Box, Grid, Button, CircularProgress, Typography } from '@mui/material';

// COMPONENTS
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';

// SERVICES
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import { randomizeNumbersString } from 'js/library/utils/helpers';

function Header({ title, action, searchInputText, setSearchInputText, loadingButton }) {
  const [inputSearchCell, setInputSearchCell] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputSearchCell('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setSearchInputText(currentDDD + inputSearchCell.replace(/\D/g, ''));
  }, [inputSearchCell]);

  return (
    <div
      style={{
        marginTop: '0',
        marginRight: '1rem',
      }}
    >
      <GoBackHeader />

      <Box pt={4} style={{ width: '100%' }}>
        <HeaderTitle pageInfo={title} />

        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'max-content',
            padding: { sm: '10px 0px', md: '20px' },
            margin: '1rem 0rem',
          }}
        >
          <Typography
            variant="span"
            sx={{
              width: { sm: '100%', md: '80%' },
              fontSize: { xs: '12px', sm: '14px', md: '16px' },
            }}
          >
            Insira o número de celular do cliente para visualizar os cupons disponíveis. Envie
            cupons para consumo imediato ou posterior em <b>"Enviar Cupom"</b> ou utilize os cupons
            da carteira do cliente na guia <b>"Cupons do Usuário"</b>.
          </Typography>
        </Box>

        <form onSubmit={(e) => action(e)}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Grid item lg={4} sm={6} xs={12}>
              <S.searchCellBox sx={{ display: 'flex', alignItems: 'stretch' }}>
                <select
                  style={{
                    fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    height: '100%',
                  }}
                  value={currentCountry}
                  onChange={handleCountryChange}
                >
                  {phoneMaskArray.map((country) => (
                    <option
                      style={{
                        fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      }}
                      key={country.id}
                      value={[country.ddd, country.mask, country.fullMask]}
                    >
                      {country.emoji} {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>

                <InputMask
                  mask={inputMask}
                  value={inputSearchCell}
                  placeholder={randomizeNumbersString(inputMask)}
                  onChange={(e) => setInputSearchCell(e.target.value)}
                  className="searchInput"
                  style={{
                    height: '100%',
                    flex: 1,
                  }}
                />
              </S.searchCellBox>
            </Grid>

            <Grid item lg={2} sm={3} xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={loadingButton || searchInputText.length < 14}
                type="submit"
                sx={{ height: '100%', marginBottom: '2px' }}
              >
                {loadingButton ? <CircularProgress color="default" size={25} /> : 'Pesquisar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

export default React.memo(Header);
