import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { separateBase64String } from 'js/library/utils/helpers';

// STYLES
import { Box, Button, CircularProgress } from '@mui/material';

// COMPONENTS
import { ColorInformation } from './ComponentsEditCustomization/ColorInfomation';
import { ImagesInformation } from './ComponentsEditCustomization/ImagesInformation';
import { ImportantInformation } from './ComponentsEditCustomization/ImportantInformation';
import { PageStructure } from 'js/components/TriiboComponents/PageStructure/PageStructure';

// APIS
import { createSegmentCustomization } from 'js/library/utils/API/createSegmentCustomization';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

export const AddCustomization = () => {
  const location = useLocation();
  const navigate = useHistory();
  const { segments, partnerId, clubeId, customizationsAlready } = location.state;

  const [dataCustomization, setDataCustomization] = useState({
    // IMPORTANT INFORMATION
    clientName: '',
    name: '',
    stripeUrl: '',
    text: '',

    // COLORS
    backgroundColor: '',
    textsColor: '',

    // IMGS
    featuredImageDesktop: '',
    featuredImageMobile: '',
    logo: '',
    pin: '',
    stamp: '',
    stripeDesktop: '',
    stripeMobile: '',

    // IDS
    partnerId: partnerId,
    segmentId: '',
  });
  const [loading, setLoading] = useState(false);

  const uploadImgs = async (image, field) => {
    const firstPart = image.split('/');
    const [typeImg] = firstPart[1].split(';');
    const newImage = separateBase64String(image);

    const response = await uploadFiles(`segment-customization/${dataCustomization.segmentId}`, {
      mimeType: newImage.mimeType,
      fileName: `${field}_${dataCustomization.segmentId}_${typeImg}`,
      buffer: newImage.buffer,
    });

    setDataCustomization((prevCustomization) => ({
      ...prevCustomization,
      [field]: response,
    }));
  };

  const createCustomization = async () => {
    setLoading(true);

    try {
      // Verifica se todos os campos foram preenchidos
      const isFullyPopulated = Object.values(dataCustomization).every(
        (value) => value !== '' && value !== null && value !== undefined
      );

      if (!isFullyPopulated) {
        toast.warning('Todos os campos e imagens devem ser preenchidos.');

        return;
      }
      toast.info('Aguarde, estamos processando suas imagens, isso pode levar algum tempo.', {
        autoClose: 20000,
      });
      // Identifica e faz o upload das imagens
      const uploadPromises = Object.entries(dataCustomization)
        .filter(([_, value]) => typeof value === 'string' && value.includes('data:image'))
        .map(([field, value]) => uploadImgs(value, field));

      await Promise.all(uploadPromises);

      // Atualiza as customizações
      await createSegmentCustomization(dataCustomization);

      toast.success('Customização criada com sucesso!');
      navigate.push({
        pathname: `/admin/seu-clube/customizacao-segmentos/${clubeId}`,
      });
    } catch (err) {
      console.error(err);
      toast.error('Erro ao criar customização. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageStructure title="Criar customização">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <ImportantInformation
          dataCustomization={dataCustomization}
          setDataCustomization={setDataCustomization}
          segments={segments}
        />

        <ColorInformation
          dataCustomization={dataCustomization}
          setDataCustomization={setDataCustomization}
        />

        <ImagesInformation
          dataCustomization={dataCustomization}
          setDataCustomization={setDataCustomization}
        />

        <Box sx={{ margin: '0 auto' }}>
          <Button
            color="primary"
            disabled={loading}
            onClick={createCustomization}
            variant="contained"
            sx={{ fontWeight: 700, letterSpacing: '2px', minWidth: '10rem' }}
          >
            {loading ? (
              <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Box>
      </div>
    </PageStructure>
  );
};
