import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export const findStoreByProximity = async (title, isPublished, from, size) => {
  const token = await authToken();
  const endpoint = `${cfac22('API_HOST_V2')}/stores/findStoreByProximity`;
  const headers = {
    headers: {
      authorization: `Bearer ${token}`,
      'Full-Url': window.location.href,
    },
  };
  const body = {
    title,
    isPublished,
    from,
    size,
  };

  try {
    const response = (await axios.post(endpoint, body, headers)).data;
    if (response?.success) return response;
  } catch (error) {
    return error.response.data.error;
  }
};
