import React from 'react';
import ReactDOM from 'react-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  TextField,
  Box,
  Badge,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export const DraggableAccordionList = ({
  form,
  setForm,
  expandedAccordion,
  setExpandedAccordion,
  handleDeleteQuestion,
  handleChangeQuestion,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(form.questions.value);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Atualiza o prioriry do accordion com a nova posição
    const updatedItems = items.map((item, index) => ({ ...item, priority: index }));

    setForm((prev) => ({
      ...prev,
      questions: { ...prev.questions, value: updatedItems },
    }));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="questions">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {form?.questions?.value?.map((question, index) => (
              <Draggable key={index} draggableId={String(index)} index={index}>
                {(provided, snapshot) => {
                  const child = (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      width="100%"
                      mt="20px"
                      sx={{
                        background: snapshot.isDragging ? '#f0f0f0' : 'transparent',
                      }}
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        badgeContent={index + 1}
                        color="secondary"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Accordion
                          expanded={expandedAccordion === index}
                          onChange={() =>
                            setExpandedAccordion(expandedAccordion === index ? -1 : index)
                          }
                        >
                          <AccordionSummary
                            sx={{
                              '& .MuiAccordionSummary-content': {
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              },
                            }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            {form.questions.value.length > 1 && (
                              <IconButton
                                {...provided.dragHandleProps}
                                aria-label="drag"
                                size="small"
                                color="primary"
                              >
                                <DragIndicatorIcon sx={{ fontSize: '35px', color: '#bbb' }} />
                              </IconButton>
                            )}

                            <Typography
                              sx={{
                                width: 'max-content',
                                paddingLeft: '0px !important',
                                height: '100%',
                              }}
                            >
                              {question.title || `Pergunta ${index + 1}`}
                            </Typography>

                            {form.questions.value.length > 1 && (
                              <IconButton
                                aria-label="delete"
                                color="error"
                                onClick={() => handleDeleteQuestion(index)}
                                sx={{ marginLeft: 'auto' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </AccordionSummary>
                          <AccordionDetails>
                            <TextField
                              fullWidth
                              label="Título*"
                              value={question.title}
                              onChange={(e) => handleChangeQuestion(index, 'title', e.target.value)}
                              margin="normal"
                            />
                            <TextField
                              fullWidth
                              multiline
                              minRows={3}
                              label="Resposta*"
                              value={question.answer}
                              onChange={(e) =>
                                handleChangeQuestion(index, 'answer', e.target.value)
                              }
                              margin="normal"
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Badge>
                    </Box>
                  );
                  return snapshot.isDragging
                    ? ReactDOM.createPortal(child, document.body) // Renderiza o item diretamente no body
                    : child;
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
