export const errorMessagesMap = {
  'voucherId é orbrigatório para o tipo: inactive.':
    'A ordem enviada está inativa e requer o envio de um voucherId para prosseguir. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp. ',
  'ORDER PENDING':
    'A ordem enviada ainda está pendente de processamento. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  ERROR_CONSUME:
    'Ocorreu um erro ao tentar consumir a ordem. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  NO_TEMPLATE_ID:
    'O template da promoção não foi enviado corretamente. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  NO_CLIENT_ID:
    'O cliente da promoção não foi enviado. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  TEMPLATE_DOESNT_EXIST:
    'Não foi possível localizar o template da promoção enviada. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  ESTAB_NOTFOUND:
    'Não foi possível encontrar o estabelecimento vinculado à promoção. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp. ',
  ERROR_WHEN_CREATING_CONTRACT:
    'Houve um erro ao criar um contrato para o estabelecimento. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  GROUP_DOESNT_EXIST:
    'O grupo do template enviado não pôde ser localizado. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp. ',
  OUT_OF_STOCK: 'O estoque da promoção esgotou.',
  BLOCK_UNIQUE:
    'Esta promoção já foi consumida por este cliente e está configurada para permitir apenas um resgate.',
  BLOCK_SIMULTANEOUS:
    'O número máximo de cupons não consumidos desta promoção para este usuário foi atingido.',
  BLOCK_COOLDOWN:
    'Tente novamente mais tarde, pois há um bloqueio temporário para essa operação. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
  ERROR_CLAIM_VOUCHER:
    'Houve um erro ao resgatar o voucher. Se o erro persistir, entre em contato com o time de suporte pelo WhatsApp.',
};
