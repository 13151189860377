import axios from 'axios';
import authToken from './authToken';
import cfac22 from '../cfac22';

export function getPartnerFiles(partnerId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/partnerFiles/getPartnerFiles?partnerId=${partnerId}`, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          return reject(error.data.result);
        });
    });
  });
}
