import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Snackbar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import { AddPhotoAlternate, Delete, Edit as EditIcon, Search } from '@mui/icons-material';
import Transition from 'js/containers/Transition.js';

import Loading from 'js/containers/Loading/Loading';
import {
  updateDatabase,
  removeDatabase,
  createDatabase,
} from 'js/library/services/DatabaseManager.js';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';

import { b64toBlob } from 'js/library/utils/helpers';

import { uploadImage, deleteImage, downloadImage } from 'js/library/services/StorageManager';
import placeholder from 'styles/assets/placeholder/placeholder_card.png';

import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

import deburr from 'lodash/deburr';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

export function KeywordsList() {
  const [value, setValue] = useState('');
  const [keywordsList, setKeywordsList] = useState(false);
  const [keywordsImage, setKeywordsImage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Keyword já existente!');
  const [inputSearch, setInputSearch] = useState('');
  const [loadedImageFile, setLoadedImageFile] = useState(null);
  const [loadedDarkImageFile, setLoadedDarkImageFile] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editType, setEditType] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [loadedEditImageFile, setLoadedEditImageFile] = useState(null);
  const [lightImageFile, setLightImageFile] = useState(null);
  const [darkImageFile, setDarkImageFile] = useState(null);
  const [editImageFile, setEditImageFile] = useState(null);

  useEffect(() => {
    window.pathname = window.location.pathname;

    getDataListRestApi('Keywords').then((dataReceived) => {
      const data = [];
      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map(function (item) {
          dataReceived[item].key = item;
          return data.push(dataReceived[item]);
        });
      } else {
        return null;
      }
      setKeywordsList(data);
    });

    // Imagens das keywords
    getDataListRestApi('logo-keyword').then((dataReceived) => {
      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map(function (item) {
          if (dataReceived[item].image !== undefined && dataReceived[item].image !== null) {
            let storageInfo = dataReceived[item].image.split('/');

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImage) => {
                dataReceived[item].downloadedThumb = downloadedImage;
              })
              .catch(() => {
                // console.log("Foto não encontrada:", dataReceived[item].image);
              });
          }

          if (
            dataReceived[item].imageLight !== undefined &&
            dataReceived[item].imageLight !== null
          ) {
            let storageInfo = dataReceived[item].imageLight.split('/');

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImageLight) => {
                dataReceived[item].downloadedThumbLight = downloadedImageLight;
              })
              .catch(() => {
                // console.log(
                //   "Foto não encontrada:",
                //   dataReceived[item].imageLight
                // );
              });
          }

          if (dataReceived[item].imageDark !== undefined && dataReceived[item].imageDark !== null) {
            let storageInfo = dataReceived[item].imageDark.split('/');

            downloadImage(storageInfo[0], storageInfo[1])
              .then((downloadedImageDark) => {
                dataReceived[item].downloadedThumbDark = downloadedImageDark;
              })
              .catch(() => {
                // console.log(
                //   "Foto não encontrada:",
                //   dataReceived[item].imageDark
                // );
              });
          }

          return null;
        });
        window.setTimeout(() => {
          setKeywordsImage(dataReceived);
        }, 500);
      } else {
        return null;
      }
    });
  }, []);

  const downloadImages = () => {
    getDataListRestApi('logo-keyword').then((dataReceived) => {
      if (dataReceived) {
        Object.keys(dataReceived).forEach((item) => {
          // Verificação de imagem
          if (dataReceived[item]?.image && typeof dataReceived[item].image === 'string') {
            let storageInfo = dataReceived[item].image.split('/');
            console.log(`Imagem encontrada para o item: ${item}`);

            downloadImage(storageInfo[0], storageInfo[1]).then((downloadedImage) => {
              dataReceived[item].downloadedThumb = downloadedImage;
            });
          }
          if (dataReceived[item]?.imageLight && typeof dataReceived[item].imageLight === 'string') {
            let storageInfoLight = dataReceived[item].imageLight.split('/');

            downloadImage(storageInfoLight[0], storageInfoLight[1]).then((downloadedImageLight) => {
              dataReceived[item].downloadedThumbLight = downloadedImageLight;
            });
          }

          if (dataReceived[item]?.imageDark && typeof dataReceived[item].imageDark === 'string') {
            let storageInfoDark = dataReceived[item].imageDark.split('/');

            downloadImage(storageInfoDark[0], storageInfoDark[1]).then((downloadedImageDark) => {
              dataReceived[item].downloadedThumbDark = downloadedImageDark;
            });
          }
        });
        window.setTimeout(() => {
          setKeywordsImage(dataReceived);
        }, 1500);
      }
    });
  };
  // Altera os estados dos campos, caso o usuário insira informações no input
  const handleChange = (name) => (event) => {
    const sanitizedValue = event.target.value
      .replace(/[&#,/+()$~%.'":*?<>{}\\]/g, '')
      .replace(' ', '_');

    if (name === 'inputSearch') {
      setInputSearch(sanitizedValue);
    } else if (name === 'value') {
      setValue(sanitizedValue);
    }
  };

  const handleImage = (name) => (event) => {
    if (event.target.files[0]) {
      const image = new FileReader();

      image.onloadend = function () {
        if (name === 'light') {
          setLoadedImageFile(image.result);
          document.getElementById('lightPreview').src = image.result;
        } else if (name === 'dark') {
          setLoadedDarkImageFile(image.result);
          document.getElementById('darkPreview').src = image.result;
        } else if (name === 'edit') {
          setLoadedEditImageFile(image.result);
          document.getElementById('editPreview').src = image.result;
        }
      };

      image.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCloseAlert = () => setOpenSnackbar(false);

  const openEditImage = (item, type) => {
    document.getElementById('editPreview').src = '';
    setOpenEditDialog(true);
    setLoadedEditImageFile(null);
    setEditItem(item);
    setEditType(type);
  };

  const updateImage = () => {
    let item = editItem;

    if (!loadedEditImageFile) {
      console.error('Imagem de edição não carregada.');
      return;
    }

    const imageBlob = b64toBlob(loadedEditImageFile);

    if (editType === 'light') {
      deleteImage('logo-keyword', item.value + '.png');
      uploadImage('logo-keyword', imageBlob, item.value + '.png');

      let logoKeyword = { image: 'logo-keyword/' + item.value + '.png' };
      updateDatabase('logo-keyword', logoKeyword, item.value, null);
    } else {
      deleteImage('logo-keyword', item.value + '_dark.png');
      uploadImage('logo-keyword', imageBlob, item.value + '_dark.png');

      let logoKeyword = { imageDark: 'logo-keyword/' + item.value + '_dark.png' };
      updateDatabase('logo-keyword', logoKeyword, item.value, null);
    }

    window.location.reload();
  };

  const closeEditImage = () => {
    setOpenEditDialog(false);
    setEditItem(null);
    setEditType(null);
    setLoadedEditImageFile(null);
    setEditImageFile(null);
  };

  const addKeyword = (value) => {
    const exists = keywordsList?.some((item) => item.value === value && item.type === 'limitação');

    if (exists) {
      setSnackbarMessage('Keyword já existente!');
      setOpenSnackbar(true);
      return;
    }

    if ((loadedImageFile && !loadedDarkImageFile) || (!loadedImageFile && loadedDarkImageFile)) {
      setSnackbarMessage('Necessário adicionar ambas as imagens');
      setOpenSnackbar(true);
      return;
    }

    setSnackbarMessage('Criando Keyword...');
    setOpenSnackbar(true);
    const keywordInfo = { value, status: 0, createDate: Date.now() };

    createDatabase('Keywords', keywordInfo, null).then(() => {
      setValue('');
      setKeywordsList(null);

      getDataListRestApi('Keywords').then((dataReceived) => {
        const data = Object.keys(dataReceived).map((item) => {
          dataReceived[item].key = item;
          return dataReceived[item];
        });
        downloadImages();
        setKeywordsList(data);
      });
    });

    if (loadedImageFile && loadedDarkImageFile) {
      const logoKeyword = {
        image: `logo-keyword/${value}.png`,
        imageDark: `logo-keyword/${value}_dark.png`,
      };
      deleteImage('logo-keyword', logoKeyword.image);
      deleteImage('logo-keyword', logoKeyword.imageDark);

      uploadImage('logo-keyword', b64toBlob(loadedImageFile), `${value}.png`);
      uploadImage('logo-keyword', b64toBlob(loadedDarkImageFile), `${value}_dark.png`);

      updateDatabase('logo-keyword', logoKeyword, value, null);
      setLoadedImageFile(null);
      setLoadedDarkImageFile(null);
    } else {
      setSnackbarMessage('Criando Keyword sem imagens');
      setOpenSnackbar(true);
    }
  };

  const removeKeyword = (key, value, type) => {
    removeDatabase('Keywords', key).then(() => {
      if (type !== 'limitação') {
        deleteImage('logo-keyword', `${value}.png`);
        deleteImage('logo-keyword', `${value}_dark.png`);
        removeDatabase('logo-keyword', value);
      }

      setValue('');
      setKeywordsList(null);

      getDataListRestApi('Keywords').then((dataReceived) => {
        const data = Object.keys(dataReceived).map((item) => {
          dataReceived[item].key = item;
          return dataReceived[item];
        });
        setKeywordsList(data);
      });
    });
  };

  const getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? keywordsList
      : keywordsList.filter((suggestion) => {
          const keep =
            count < 5 && suggestion.value.slice(0, inputLength).toLowerCase() === inputValue;
          if (keep) count += 1;
          return keep;
        });
  };

  return (
    <div style={{ maxHeight: '100%', overflowY: 'auto', padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cadastro de Keywords" />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          addKeyword(value);
        }}
      >
        <TextField
          id="value"
          label="Keyword"
          value={value}
          onChange={handleChange('value')}
          margin="normal"
          required
        />

        <Button
          type="submit"
          disabled={value.length === 0}
          style={{
            fontWeight: '550',
            color: 'white',
            marginLeft: '10px',
            marginTop: '25px',
            textTransform: 'capitalize',
          }}
          variant="contained"
          color="primary"
        >
          Adicionar
        </Button>
      </form>

      <Grid container spacing={5}>
        {/* imagem */}

        <Grid item md={2} sm={12}>
          {loadedImageFile !== null ? (
            <img
              id="lightPreview"
              alt="Imagem"
              style={{
                width: '100%',
                height: 'auto',
                margin: '12px 0px',
              }}
              src={lightImageFile}
            />
          ) : null}

          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="lightImage"
            type="file"
            onChange={handleImage('light')}
          />
          <br />
          <label htmlFor="lightImage" style={{ width: '100%' }}>
            <Button
              style={{
                fontWeight: '550',
                color: 'white',
                width: '100%',
                textTransform: 'capitalize',
                textAlign: 'center',
              }}
              variant="contained"
              component="span"
              color="primary"
              startIcon={<AddPhotoAlternate />}
            >
              Imagem para fundo claro
            </Button>
          </label>
        </Grid>

        <Grid item md={2} sm={12}>
          {loadedDarkImageFile !== null ? (
            <img
              id="darkPreview"
              alt="Fundo claro"
              style={{
                width: '100%',
                height: 'auto',
                margin: '12px 0px',
              }}
              src={darkImageFile}
            />
          ) : null}

          <input
            accept="image/*"
            style={{ display: 'none', marginLeft: '24px' }}
            id="darkImage"
            type="file"
            onChange={handleImage('dark')}
          />
          <br />
          <label htmlFor="darkImage" style={{ width: '100%' }}>
            <Button
              style={{
                fontWeight: '550',
                color: 'white',
                width: '100%',
                textTransform: 'capitalize',
                textAlign: 'center',
              }}
              variant="contained"
              component="span"
              color="primary"
              startIcon={<AddPhotoAlternate />}
            >
              Imagem para fundo escuro
            </Button>
          </label>
        </Grid>

        <Grid item md={8} sm={false}></Grid>

        <Grid item md={8} sm={12}>
          <Divider />

          <TextField
            id="inputSearch"
            value={inputSearch}
            onChange={handleChange('inputSearch')}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <br />
          {keywordsList === false ? (
            <div align="center">
              <CircularProgress style={{ padding: '20px' }} />
            </div>
          ) : keywordsList === null ? (
            <div>
              <br />
              <br />
              <Typography variant="body2">Nenhuma keyword cadastrada.</Typography>
            </div>
          ) : (
            <List
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  fontWeight: 'bolder',
                  width: '128px',
                  marginLeft: '48px',
                  marginRight: '48px',
                }}
              >
                Logo
              </span>
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  fontWeight: 'bolder',
                  width: '128px',
                  marginLeft: '24px',
                  marginRight: '48px',
                }}
              >
                Dark
              </span>
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'center',
                  fontWeight: 'bolder',
                  width: 'auto',
                  marginLeft: '24px',
                }}
              >
                Keyword
              </span>
              {getSuggestions(inputSearch).map((item) => (
                <ListItem key={item.key} role={undefined} dense button>
                  {keywordsImage && keywordsImage[item.value] ? (
                    <div style={{ display: 'inline-flex' }}>
                      <img
                        width="128px"
                        style={{ marginLeft: '24px' }}
                        alt={item.value}
                        src={
                          keywordsImage[item.value].downloadedThumb
                            ? keywordsImage[item.value].downloadedThumb
                            : placeholder
                        }
                      />
                      <IconButton
                        style={{ width: '48px', height: '48px' }}
                        onClick={() => openEditImage(item, 'light')}
                        aria-label="Comments"
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div style={{ display: 'inline-flex' }}>
                      <div
                        style={{
                          width: '128px',
                          height: '46px',
                          marginLeft: '24px',
                        }}
                      ></div>
                      <IconButton
                        style={{ width: '48px', height: '48px' }}
                        onClick={() => openEditImage(item, 'light')}
                        aria-label="Comments"
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  )}

                  {keywordsImage && keywordsImage[item.value] ? (
                    <div style={{ display: 'inline-flex' }}>
                      <img
                        width="128px"
                        style={{ marginLeft: '24px' }}
                        alt={item.value}
                        src={
                          keywordsImage[item.value].downloadedThumbDark
                            ? keywordsImage[item.value].downloadedThumbDark
                            : placeholder
                        }
                      />
                      <IconButton
                        style={{ width: '48px', height: '48px' }}
                        onClick={() => openEditImage(item, 'dark')}
                        aria-label="Comments"
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div style={{ display: 'inline-flex' }}>
                      <div
                        style={{
                          width: '128px',
                          height: '46px',
                          marginLeft: '24px',
                        }}
                      />
                      <IconButton
                        style={{ width: '48px', height: '48px' }}
                        onClick={() => openEditImage(item, 'dark')}
                        aria-label="Comments"
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  )}

                  <ListItemText style={{ marginLeft: '24px' }} primary={`${item.value}`} />

                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => removeKeyword(item.key, item.value, item.type)}
                      edge="end"
                      aria-label="Comments"
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={openEditDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeEditImage}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {`Atualizar imagem ${editType ? editType : ''} da keyword ${
            editItem ? editItem.value : ''
          }?`}
        </DialogTitle>

        <DialogContent>
          <img
            id="editPreview"
            alt="imagem"
            style={{ width: '100%', height: 'auto', margin: '12px 0px' }}
            src={editImageFile}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="editImage"
            type="file"
            onChange={handleImage('edit')}
          />
          <br />
          <label htmlFor="editImage" style={{ width: '100%' }}>
            <Button
              style={{
                fontWeight: '550',
                color: 'white',
                width: '100%',
                textTransform: 'capitalize',
                textAlign: 'center',
              }}
              variant="contained"
              component="span"
              color="primary"
              startIcon={<AddPhotoAlternate />}
            >
              Nova Imagem
            </Button>
          </label>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeEditImage} color="primary">
            Cancelar
          </Button>
          <Button onClick={updateImage} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        onClose={handleCloseAlert}
        ContentProps={{ 'aria-describedby': 'message-id' }}
        message={<span id="message-id">{snackbarMessage}</span>}
      />
    </div>
  );
}

export default KeywordsList;
