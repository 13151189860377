import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function InputSearchTab({
  data,
  setFilteredPromotions,
  setFilteredCouponsList,
  type,
  setSearchTerm,
}) {
  const [localSearchTerm, setLocalSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value;
    setLocalSearchTerm(term);
    setSearchTerm(term);

    if (type === 'promotion') {
      const filtered = data.filter((promotion) =>
        promotion.title?.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredPromotions(filtered);
    } else if (type === 'coupon') {
      const filtered = data.filter(
        (coupon) =>
          coupon.title?.toLowerCase().includes(term.toLowerCase()) ||
          (coupon.voucherId?.toLowerCase().includes(term.toLowerCase()) ?? false)
      );
      setFilteredCouponsList(filtered);
    }
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={`Pesquise ${type === 'promotion' ? 'promoção' : 'cupom'}`}
      value={localSearchTerm}
      onChange={handleSearch}
      sx={{ marginBottom: '20px' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
