import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function getSegmentCustomization(data) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(`${cfac22('API_HOST_V2')}/segmentCustomization/get`, {
          params: data,
          headers: {
            authorization: `Bearer ${token}`,
            'Full-Url': window.location.href,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error.response?.data || { error: 'Erro desconhecido na API' });
        });
    });
  });
}
