import axios from 'axios';

import authTokenWithScAndUid from '../authTokenWithScAndUid';
import cfac22 from '../../cfac22';

export default async function apiChangeCellphone(uId, oldCellphone, newCellphone) {
  const token = await authTokenWithScAndUid(uId);

  var config = {
    method: 'PUT',
    url: `${cfac22('API_HOST_V2')}/users/phone`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Full-Url': window.location.href,
    },
    data: {
      oldCellphone,
      newCellphone,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error.response);
      });
  });
}
