import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Tooltip,
  Button,
  FormLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Snackbar,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
} from '@mui/material';

import { Save, Help } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';

import { downloadImage } from 'js/library/services/StorageManager';

import ImportImage from 'js/components/TriiboComponents/ImportImage';
import { separateBase64String, normalizeFileName } from 'js/library/utils/helpers';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

import { organizationInformationAction } from '../../core/actions/organizationInformationAction';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { toast } from 'react-toastify';

function OrganizationInformation(props) {
  const { location } = props;
  const { state: childState } = location;

  const [accessMethod, setAccessMethod] = useState(childState.accessMethod || 'private');
  const [administratorsList, setAdministratorsList] = useState(childState.administratorsList || []);
  const [description, setDescription] = useState(childState.description || '');
  const [lockdown, setLockdown] = useState(childState.lockdown || false);
  const [name, setName] = useState(childState.name || '');
  const [orgID, setOrgID] = useState(childState.orgID || null);
  const [owner, setOwner] = useState(childState.owner || '');
  const [tags, setTags] = useState(childState.tags || []);
  const [type, setType] = useState(childState.type || 'triibo');
  const [visible, setVisible] = useState(childState.visible || false);

  const [errorMessage, setErrorMessage] = useState('');

  // Imagens

  const oldThumbnail = childState.thumbnail || '';
  const [thumbnailFile, setThumbnailFile] = useState(childState.downloadedThumb || '');

  const oldCoverImage = childState.coverImage || '';
  const [coverImageFile, setCoverImageFile] = useState('');

  const oldLogoImage = childState.logoImage || '';
  const [logoImageFile, setLogoImageFile] = useState('');

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchOrganizationFiles();
  }, []);

  const fetchOrganizationFiles = () => {
    if (oldCoverImage) {
      const splitedCoverImageData = oldCoverImage.split('/');

      downloadImage(splitedCoverImageData[0], splitedCoverImageData[1]).then((downloadedImage) => {
        setCoverImageFile(downloadedImage);
      });
    }
    if (oldThumbnail) {
      const splitedThumbnailData = oldThumbnail.split('/');

      downloadImage(splitedThumbnailData[0], splitedThumbnailData[1]).then((downloadedImage) => {
        setThumbnailFile(downloadedImage);
      });
    }

    if (oldLogoImage) {
      const splitedLogoImageData = oldLogoImage.split('/');

      downloadImage(splitedLogoImageData[0], splitedLogoImageData[1]).then((downloadedImage) => {
        setLogoImageFile(downloadedImage);
      });
    }
  };

  const handleChange = (name) => (event) => {
    if (name === 'quantity' && event.target.value < 0) {
      setOpenCheck(true);
    } else {
      if (name === 'accessMethod') setAccessMethod(event.target.value);
      else if (name === 'administratorsList') setAdministratorsList(event.target.value);
      else if (name === 'description') setDescription(event.target.value);
      else if (name === 'lockdown') setLockdown(event.target.checked);
      else if (name === 'name') setName(event.target.value);
      else if (name === 'orgID') setOrgID(event.target.value);
      else if (name === 'owner') setOwner(event.target.value);
      else if (name === 'tags') setTags(event.target.value);
      else if (name === 'type') setType(event.target.value);
      else if (name === 'visible') setVisible(event.target.checked);
    }
  };

  const handleChangeSwitch = (name) => (event) => {
    if (name === 'lockdown') setLockdown(event.target.checked);
    else if (name === 'visible') setVisible(event.target.checked);
  };

  const isChangeImage = async (data) => {
    if (data.includes('data:image')) {
      const docImage = separateBase64String(data);

      const uploadedImage = await uploadFiles('organizations', {
        mimeType: docImage.mimeType,
        fileName: normalizeFileName(name),
        buffer: docImage.buffer,
      });
      return uploadedImage;
    } else {
      return null;
    }
  };

  const handleSubmit = async () => {
    if (!coverImageFile || !thumbnailFile || !logoImageFile) {
      setErrorMessage('As imagens são obrigatórias.');
      setOpenCheck(true);
    } else {
      setLoading(true);
      setSubmitLoading(true);

      try {
        const newThumbnailFile = await isChangeImage(thumbnailFile);

        const newCoverImageFile = await isChangeImage(coverImageFile);

        const newLogoImageFile = await isChangeImage(logoImageFile);

        const organizationData = {
          accessMethod,
          bannerImage: 'image',
          administratorsList,
          coverImageFile: newCoverImageFile,
          oldCoverImage,
          oldThumbnail,
          thumbnailFile: newThumbnailFile,
          oldLogoImage,
          logoImageFile: newLogoImageFile,
          description,
          lockdown,
          name,
          owner,
          tags,
          type,
          visible,
        };

        if (orgID) {
          organizationData.orgID = orgID;
        } else {
          organizationData.orgID = null;
        }

        await dispatch(organizationInformationAction(dispatch, organizationData));
        toast.success('Organização salva com sucesso!');
      } catch (error) {
        setErrorMessage('Não foi possível enviar os dados.');
        setOpenCheck(true);
      } finally {
        setLoading(false);
        setSubmitLoading(false);
      }
    }
  };

  const handleCloseAlert = () => {
    setOpenCheck(false);
  };

  return loading && submitLoading ? (
    <div className="loading">
      <Loading />
    </div>
  ) : (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cadastrar Organização" />
      <br />
      <br />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <br />
        <br />
        <FormLabel component="legend">Método de Acesso</FormLabel>
        <FormControl className="input-field">
          <Select
            style={{ minWidth: '500px' }}
            value={accessMethod}
            onChange={handleChange('accessMethod')}
            inputProps={{ id: 'accessMethod' }}
          >
            <MenuItem value="private">Privado</MenuItem>
            <MenuItem value="public">Público</MenuItem>
          </Select>

          <TextField
            value={name}
            required
            onChange={handleChange('name')}
            className="input-field"
            type="text"
            id="name"
            label="Nome da Organização"
          />

          <TextField
            value={description}
            required
            multiline
            onChange={handleChange('description')}
            className="input-field"
            type="text"
            id="description"
            label="Descrição"
          />

          <br />
          <br />

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  color="secondary"
                  style={{ fontWeight: '550' }}
                >
                  Foto Thumbnail*
                </Typography>
                <Tooltip title="Evite imagens com fundo branco.">
                  <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                </Tooltip>
              </Box>

              <label htmlFor="fotoThumb">
                <Box sx={{ width: 400, height: 'auto', marginBottom: '30px' }}>
                  <ImportImage
                    imageDefault={false}
                    autoWidth={true}
                    image={thumbnailFile}
                    setImage={setThumbnailFile}
                    ratio={4 / 4}
                  />
                </Box>
              </label>

              <br />
              <br />

              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  color="secondary"
                  style={{ fontWeight: '550' }}
                >
                  Foto de Capa*
                </Typography>
                <Tooltip title="Evite imagens com fundo branco.">
                  <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                </Tooltip>
              </Box>

              <label htmlFor="coverImage">
                <Box sx={{ width: 400, height: 'auto', marginBottom: '30px' }}>
                  <ImportImage
                    imageDefault={false}
                    autoWidth={true}
                    image={coverImageFile}
                    setImage={setCoverImageFile}
                    ratio={4 / 3}
                  />
                </Box>
              </label>

              <br />
              <br />

              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  color="secondary"
                  style={{ fontWeight: '550' }}
                >
                  Foto de Logo*
                </Typography>
                <Tooltip title="Evite imagens com fundo.">
                  <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                </Tooltip>
              </Box>

              <label htmlFor="logoImage">
                <Box sx={{ width: 400, height: 'auto', marginBottom: '30px' }}>
                  <ImportImage
                    imageDefault={false}
                    autoWidth={true}
                    image={logoImageFile}
                    setImage={setLogoImageFile}
                    ratio={95 / 12}
                  />
                </Box>
              </label>
            </Grid>
          </Grid>

          <FormControlLabel
            style={{ marginTop: '10px' }}
            control={
              <Switch
                checked={lockdown}
                onChange={handleChangeSwitch('lockdown')}
                color="primary"
              />
            }
            label={'Lockdown?'}
          />

          <FormControlLabel
            style={{ marginTop: '10px' }}
            control={
              <Switch checked={visible} onChange={handleChangeSwitch('visible')} color="primary" />
            }
            label={'Visível?'}
          />
        </FormControl>
        <Button
          style={{
            fontWeight: '550',
            marginTop: '20px',
            color: 'white',
            textTransform: 'capitalize',
          }}
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<Save />}
          disabled={submitLoading}
        >
          {submitLoading ? <CircularProgress size="23px" style={{ color: 'white' }} /> : 'Salvar'}
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openCheck}
        onClose={handleCloseAlert}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{errorMessage || 'Erro desconhecido!'}</span>}
      />
    </div>
  );
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.paymentInformationComponent.loading,
    success: state.paymentInformationComponent.success,
    error: state.paymentInformationComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  organizationInformationComponent: (data) => organizationInformationAction(dispatch, data),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationInformation);
