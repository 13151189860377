import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../../cfac22';

export function confirmStoreChanges(uId, storeId, status, comment, data) {
  return new Promise(async (resolve, reject) => {
    authToken(uId).then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/storeChanges/confirmStoreChanges`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: {
          storeId: storeId,
          status: status,
          comment: comment ? comment : '', // opcional
          changedFields: data, // opcional
        },
      };

      axios(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
