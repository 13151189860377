import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

export function voucherSend_v1(templateId, batchId, clientList, consumed, callback = null) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((token) => {
        // Mapeia todas as requisições em um array de Promises
        const promises = clientList.map((clientID) =>
          axios.post(
            cfac22('API_HOST_V1') + 'voucherSend_v1',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                uId: null,
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                channelTokenId: token,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              clientID,
              templateID: templateId,
              consumed,
            },
            {
              headers: { access_token: cfac22('ACCESS_TOKEN'), 'Full-Url': window.location.href },
            }
          )
        );

        // Aguarda todas as requisições serem concluídas
        Promise.all(promises)
          .then((results) => {
            const successResponses = results.filter((result) => result.data.success);
            if (callback) callback(successResponses);
            resolve(successResponses);
          })
          .catch((error) => {
            if (callback) callback({ error: true, message: error });
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
