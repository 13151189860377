import styled from 'styled-components';

export const searchCellBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: ${({ height }) => height || '40px'};
  border: 1px solid #ccc;
  border-radius: 5px;

  select {
    border: none;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }

  .searchInput {
    flex: 1;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }
`;
