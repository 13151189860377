import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';

import { updateCampaingTriiboAction } from 'js/core/actions/campaingTriiboActions';
import { convertToDate } from 'js/library/utils/helpers';
import { downloadImage } from 'js/library/services/StorageManager';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';

import {
  TextField,
  Typography,
  Grid,
  Button,
  IconButton,
  FormControl,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormGroup,
  Modal,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { Save, Delete, Help, AddPhotoAlternate, CropFree, ArrowBack } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';

import Cropper from 'react-image-cropper/lib/Cropper';
import 'styles/css/App.css';

import Loading from 'js/containers/Loading/Loading';
import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import thumbnail from 'styles/assets/promocao/thumbnail-campanhaTriibo.png';
import detailPhoto from 'styles/assets/promocao/photoDetails-campanhaTriibo.png';
import { debounce } from 'lodash';
import { getAllEvents } from 'js/library/utils/API/apiGetAllEvents';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },

  containerAddEvent: {
    display: 'flex',
    marginBottom: '2rem',
    gap: '3rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },

  inputAddEvent: {
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  buttonAddEvent: {
    width: '20%',
    height: '50px',
    fontWeight: '550',
    color: 'white',
    marginLeft: '10px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
});

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

const eventList = [
  'Store',
  'Chat',
  'Compra_Cashback',
  'Compra_Marketplace',
  'Access',
  'Pesquisa',
  'Compra_Marketplace_Gestor',
  'Assinatura_Estabelecimento',
  'Store_Gestor',
  'Pesquisa_Gestor',
  'Invitation',
  'Compartilhar_Promo_5_Cliques',
  'Compartilhar_Promo_10_Cliques',
  'Quiz',
];

const LotteryEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const cropperRef = useRef(null);

  const [form, setForm] = useState({
    //id da notificação
    key: props.location.state.key === undefined ? null : props.location.state.key,

    isNewCampaing: props.location.state.key === undefined ? true : false,

    walletTitle:
      props.location.state.walletTitle === undefined ? '' : props.location.state.walletTitle,

    walletDetailsTitle:
      props.location.state.walletDetailsTitle === undefined
        ? ''
        : props.location.state.walletDetailsTitle,

    walletDescription:
      props.location.state.walletDescription === undefined
        ? ''
        : props.location.state.walletDescription,

    walletDetailsDescription:
      props.location.state.walletDetailsDescription === undefined
        ? ''
        : props.location.state.walletDetailsDescription,

    downloadedThumb: props.location.state.downloadedThumb ? placeholder : null,

    downloadedPhoto: props.location.state.downloadedPhoto ? placeholder : null,

    walletThumbnail:
      props.location.state.walletThumbnail === undefined
        ? null
        : props.location.state.walletThumbnail,

    walletPhoto:
      props.location.state.walletPhoto === undefined ? null : props.location.state.walletPhoto,

    numbersLucky:
      props.location.state.numbersLucky === undefined ? null : props.location.state.numbersLucky,

    serieStart:
      props.location.state.serieStart === undefined
        ? null
        : Number(props.location.state.serieStart),
    serieEnd:
      props.location.state.serieEnd === undefined ? null : Number(props.location.state.serieEnd),

    lotterySerie:
      props.location.state.lotterySerie === undefined ? '' : props.location.state.lotterySerie,

    notificationMessage:
      props.location.state.notificationMessage === undefined
        ? {}
        : props.location.state.notificationMessage,

    quizzes: props.location.state.quizzes === undefined ? [] : props.location.state.quizzes,

    events: props.location.state.events === undefined ? [] : props.location.state.events,

    voucherQuantity:
      props.location.state.voucherQuantity === undefined
        ? {}
        : props.location.state.voucherQuantity,

    activationDate:
      props.location.state.activationDate === undefined
        ? convertToDate(Date.now())
        : convertToDate(props.location.state.activationDate),

    dueDate:
      props.location.state.dueDate === undefined
        ? convertToDate(Date.now() + 31536000000)
        : convertToDate(props.location.state.dueDate),

    establishmentId:
      props.location.state.establishmentId === undefined
        ? ''
        : props.location.state.establishmentId,

    establishmentName: !props.location.state.establishmentName
      ? ''
      : props.location.state.establishmentName,

    campaingId:
      props.location.state.campaingId === undefined ? '' : props.location.state.campaingId,

    campaingName:
      props.location.state.campaingName === undefined ? '' : props.location.state.campaingName,

    data: props.location.state.data === undefined ? '' : props.location.state.data,

    eventMergeList: [],
    eventListMerge: [],
    establishmentList: [],

    each: 1,
    voucherEach:
      props.location.state.voucherEach === undefined ? null : props.location.state.voucherEach,

    quizKey: '',
    quizValue: '',

    eventName: '',
    eventValue: 1,

    openModal: false,
    indexCrop: 0,

    oldThumbnail: null,
    oldPhoto: null,

    openCheck: false,
    messageAlert: '',
    errorVerification: false,

    openSelect: false,
    nameValue: '',

    loading: false,
    textOpen: '',

    saveButtonIsDisabled: false,

    currentEvents: eventList,

    isUpdateCampaign:
      props.location.state.numbersLucky !== undefined ||
      props.location.state.serieStart !== undefined ||
      props.location.state.serieEnd !== undefined
        ? true
        : false,
  });

  window.pathname = null;

  const history = useHistory();

  useEffect(() => {
    if (form.downloadedThumb !== null || form.downloadedThumb === placeholder) {
      downloadImage('voucher', form.walletThumbnail).then((downloadedImage) => {
        setForm((prevForm) => ({ ...prevForm, downloadedThumb: downloadedImage }));
      });
    }

    if (form.downloadedPhoto !== null || form.downloadedPhoto === placeholder) {
      downloadImage('voucher', form.walletPhoto).then((downloadedImage) => {
        setForm((prevForm) => ({ ...prevForm, downloadedPhoto: downloadedImage }));
      });
    }
  }, []);

  useEffect(() => {
    if (form.establishmentName) {
      setForm((prevForm) => ({
        ...prevForm,
        nameValue: {
          nome: prevForm.establishmentName,
          id: prevForm.establishmentId,
        },
      }));
    }
  }, [form.establishmentName]);

  const checkInformation = () => {
    if (!form.establishmentName) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'O estabelecimento é obrigatório.',
      }));
      return false;
    }

    if (!form.walletThumbnail && !form.thumbnailFile) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail.',
      }));
      return false;
    }

    if (!form.walletPhoto && !form.photoFile) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'É necessário adicionar uma imagem de foto de detalhe.',
      }));
      return false;
    }

    if (Object.keys(form.events).length < 1) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'É necessário adicionar um valor na lista de Eventos.',
      }));
      return false;
    }

    if (
      form.lotterySerie.toString().length === 0 ||
      form.walletTitle.toString().length === 0 ||
      form.walletDetailsTitle.toString().length === 0 ||
      form.walletDescription.toString().length === 0 ||
      form.walletDetailsDescription.toString().length === 0 ||
      form.activationDate.toString().length === 0 ||
      form.dueDate.toString().length === 0 ||
      form.campaingId.toString().length === 0 ||
      form.campaingName.toString().length === 0 ||
      form.serieStart.toString().length === 0 ||
      form.serieEnd.toString().length === 0
    ) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'Todas as informações obrigatórias devem estar preenchidas.',
      }));
      return false;
    }

    if (form.serieStart > form.serieEnd) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'A série inicial não pode ser maior que a final.',
      }));
      return false;
    }

    for (const event in form.voucherQuantity) {
      if (form.voucherQuantity[event] < 1) {
        setForm((prevForm) => ({
          ...prevForm,
          openCheck: true,
          messageAlert: 'Eventos: O "valor do evento" não pode ser menor que 1.',
        }));
        return false;
      }
    }

    for (const event in form.voucherEach) {
      if (form.voucherEach[event] < 1) {
        setForm((prevForm) => ({
          ...prevForm,
          openCheck: true,
          messageAlert: 'Eventos: O "a cada" não pode ser menor que 1.',
        }));
        return false;
      }
    }

    return true;
  };

  const handleOpenSelect = () => {
    setForm((prevForm) => ({
      ...prevForm,
      openSelect: true,
      textOpen: 'Pesquise por um estabelecimento',
    }));
  };

  const handleCloseSelect = () => {
    setForm((prevForm) => ({
      ...prevForm,
      openSelect: false,
    }));
  };

  const handleSearch = debounce((event) => {
    setForm((prevForm) => ({
      ...prevForm,
      textOpen: 'Pesquisando...',
    }));

    apiListEstablishment(event.target.value.trim(), 0, 10).then((dataEstablishments) => {
      // apenas os estabelecimentos ativos
      const activeEstablishments = dataEstablishments.filter(
        (establishment) =>
          establishment.isPublished === true &&
          establishment.enableDate <= Date.now() &&
          establishment.disableDate >= Date.now()
      );

      if (activeEstablishments.length === 0) {
        setForm((prevForm) => ({
          ...prevForm,
          textOpen: 'Nenhum estabelecimento encontrado',
        }));
      }

      setForm((prevForm) => ({
        ...prevForm,
        establishmentList: activeEstablishments,
      }));
    });
  }, 500);

  const handleSelect = (value) => {
    if (value) {
      setForm((prevForm) => ({
        ...prevForm,
        nameValue: { id: value.id, nome: value.nome },
        establishmentName: value.nome,
        establishmentId: value.id,
      }));
    }
  };

  // Trata o campo série impedindo o uso de espaços e caracteres especiais
  const validateSpecialCharacterSerie = (serieName) => {
    let serieChecked = '';

    serieChecked = serieName.replace(/\s/g, '').replace(/[^a-zA-Z0-9]+/g, '');
    return serieChecked;
  };

  // Verifica se o valor do campo série já existe.
  const validateSerieName = (serieName) => {
    if (form.isNewCampaing) {
      const dataLottery = form.data
        .filter((item) => {
          return item.lotterySerie === serieName;
        })
        .map((item) => item.lotterySerie)
        .includes(serieName);

      if (dataLottery) {
        setForm((prevForm) => ({
          ...prevForm,
          openCheck: true,
          messageAlert: 'O número de série já existe!',
          errorVerification: true,
          saveButtonIsDisabled: true,
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          saveButtonIsDisabled: false,
        }));
      }
    }
  };

  const formatSerie = (serie) => {
    if (serie === undefined || serie === null || serie === '') return '';
    const strSerie = String(serie);
    return strSerie.length > 5 ? strSerie.slice(0, -5) : strSerie; // Remove os últimos 5 números apenas se houver mais de 5
  };

  //Altera os states dos campos, caso o usuário insira informações nos input
  const handleChange = (name) => (event) => {
    if (name === 'lotterySerie') {
      const cleanName = validateSpecialCharacterSerie(event.target.value);
      setForm((prevForm) => ({
        ...prevForm,
        [name]: cleanName,
        errorVerification: false,
      }));
      return;
    }

    if (name === 'numbersLucky') {
      const regex = event.target.value.replace(/[^0-9]/g, '');
      setForm((prevForm) => ({
        ...prevForm,
        [name]: regex,
        errorVerification: false,
      }));
      return;
    }

    if (name === 'serieStart' || name === 'serieEnd') {
      if (form.isUpdateCampaign) return; // Impede a alteração se o campo estiver desabilitado

      const regex = event.target.value.replace(/[^0-9]/g, ''); // Permite apenas números
      setForm((prevForm) => ({
        ...prevForm,
        [name]: Number(regex), // Converte para número
        errorVerification: false,
      }));
      return;
    }

    if (name === 'title' || name === 'message' || name === 'url') {
      setForm((prevForm) => ({
        ...prevForm,
        notificationMessage: {
          ...form.notificationMessage,
          [name]: event.target.value,
        },
      }));
    }

    if (name === 'eventValue' || name === 'each') {
      const value = Math.max(1, parseInt(event.target.value) || 1); // Garante que o valor mínimo seja 1
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [name]: event.target.value,
      errorVerification: false,
    }));
  };

  const handleOpenModal = () => {
    setForm((prevForm) => ({
      ...prevForm,
      openModal: true,
    }));
  };

  const handleCloseModal = () => {
    setForm((prevForm) => ({
      ...prevForm,
      openModal: false,
    }));
  };

  const addQuiz = () => {
    let oldQuiz = form.quizzes;
    let newQuiz = {};
    newQuiz = { ...oldQuiz, [form.quizKey]: form.quizValue };
    setForm((prevForm) => ({
      ...prevForm,
      quizzes: newQuiz,
      quizKey: '',
      quizValue: '',
    }));
  };

  const removeQuiz = (item) => {
    let aux = form.quizzes;
    delete aux[item];
    setForm((prevForm) => ({
      ...prevForm,
      quizzes: aux,
    }));
  };

  const addEvent = () => {
    let newEvent = form.events;

    let oldVoucherQuantity = form.voucherQuantity;
    let newVoucherQuantity = {};

    let oldListEvent = form.eventListMerge;
    let newListEvent = [];

    let newVoucherEach = {};

    if (form.events.includes(form.eventName)) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
        messageAlert: 'Evento selecionado já contém na lista',
      }));
      return;
    }

    // Adiciona o evento à lista de eventos
    newEvent.push(form.eventName);

    // Atualiza voucherQuantity
    newVoucherQuantity = {
      ...oldVoucherQuantity,
      [form.eventName]: parseInt(form.eventValue) || 1,
    };

    // Atualiza voucherEach
    newVoucherEach = {
      ...form.voucherEach,
      [form.eventName]: parseInt(form.each) || 1,
    };

    // Atualiza a lista de eventos
    newListEvent = [
      ...oldListEvent,
      {
        eventName: form.eventName,
        eventValue: parseInt(form.eventValue) || 1,
        voucherEach: parseInt(form.each) || 1,
      },
    ];

    // Atualiza o estado do formulário
    setForm((prevForm) => ({
      ...prevForm,
      events: newEvent,
      voucherQuantity: newVoucherQuantity,
      eventListMerge: newListEvent,
      voucherEach: newVoucherEach,
      eventName: '',
      eventValue: 1,
      each: 1,
    }));
  };

  const removeEvent = (item) => {
    const newEvents = form.events.filter((event) => event !== item);
    const newEventList = form.eventListMerge.filter((event) => event.eventName !== item);
    let auxVoucherEach = { ...form.voucherEach };
    let auxVoucherQuantity = { ...form.voucherQuantity };

    delete auxVoucherEach[item];
    delete auxVoucherQuantity[item];

    setForm((prevForm) => ({
      ...prevForm,
      events: newEvents,
      eventListMerge: newEventList,
      voucherEach: auxVoucherEach,
      voucherQuantity: auxVoucherQuantity,
    }));
  };

  const fileChangedHandler = (name) => (event) => {
    if (name === 'fotoThumb') {
      setForm((prevForm) => ({
        ...prevForm,
        oldThumbnail: form.walletThumbnail,
      }));

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        setForm((prevForm) => ({
          ...prevForm,
          imageToCrop: data,
          imageCroped: 'thumbnailFile',
        }));
        handleOpenModal();
      }
    }

    if (name === 'detailPhoto') {
      setForm((prevForm) => ({
        ...prevForm,
        oldPhoto: form.walletPhoto,
      }));

      if (event.target.files[0] !== undefined) {
        const data = [URL.createObjectURL(event.target.files[0])];
        setForm((prevForm) => ({
          ...prevForm,
          imageToCrop: data,
          imageCroped: 'photoFile',
        }));
        handleOpenModal();
      }
    }
  };

  const cropImage = () => {
    //campo onde a image está sendo inserido
    const cropper = cropperRef.current;

    if (!cropper) {
      console.error('Cropper não é definido');
      return;
    }

    //imagem recebida do crop
    const node = cropper.crop();
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const newCrop = form.imageCroped;
    const endArray = form.imageToCrop ? form.imageToCrop[form.indexCrop + 1] : undefined;

    setForm((prevForm) => ({
      ...prevForm,
      [newCrop]: form.imageCroped === undefined ? null : node,
      indexCrop: endArray === undefined ? 0 : form.indexCrop + 1,
      imageToCrop: endArray === undefined ? null : form.imageToCrop,
    }));

    if (endArray === undefined) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (form.events && form.voucherQuantity) {
        const eventsFormated = form.events.map((event) => {
          let eventValue = 1;
          let voucherEach = 1;

          if (form.voucherQuantity[event]) {
            eventValue = Math.max(1, form.voucherQuantity[event]); // Garante que o valor mínimo seja 1
          }

          if (form.voucherEach && form.voucherEach[event]) {
            voucherEach = Math.max(1, form.voucherEach[event]);
          }

          return {
            eventName: event,
            eventValue,
            voucherEach,
          };
        });

        setForm((prevForm) => {
          if (JSON.stringify(prevForm.eventListMerge) === JSON.stringify(eventsFormated)) {
            return prevForm;
          }
          return {
            ...prevForm,
            eventListMerge: eventsFormated,
          };
        });

        try {
          const res = await getAllEvents();
          const newArr = res.map((element) => element.eventName);

          setForm((prevForm) => {
            if (JSON.stringify(prevForm.currentEvents) === JSON.stringify(newArr)) {
              return prevForm;
            }
            return {
              ...prevForm,
              currentEvents: newArr,
            };
          });
        } catch {
          setForm((prevForm) => {
            if (JSON.stringify(prevForm.currentEvents) === JSON.stringify(eventList)) {
              return prevForm;
            }
            return {
              ...prevForm,
              currentEvents: eventList,
            };
          });
        }
      }
    };

    fetchData();
  }, [form.events, form.currentEvents]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    const isValid = checkInformation();
    if (!isValid) return;

    try {
      toast.success('Sorteio enviado com sucesso', { autoClose: 2000 });

      setLoading(true);
      props.updateCampaingTriiboComponent(
        form.walletTitle,
        form.walletDetailsTitle,
        form.walletDescription,
        form.walletDetailsDescription,
        form.walletThumbnail,
        form.walletPhoto,
        form.thumbnailFile,
        form.photoFile,
        form.lotterySerie,
        form.notificationMessage,
        form.quizzes,
        form.events,
        form.voucherEach,
        form.voucherQuantity,
        form.activationDate,
        form.dueDate,
        form.establishmentId,
        form.establishmentName,
        form.campaingId,
        form.campaingName,
        form.numbersLucky,
        form.serieStart,
        form.serieEnd,
        form.isUpdateCampaign
      );

      setTimeout(() => {
        history.goBack();
      }, 2000);
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
    }
  };

  const selectEstablishiment = form.establishmentList;
  const { classes } = props;
  const loadingSelect = form.openSelect && selectEstablishiment.length === 0;

  if (props.loading && loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  } else if (window.pathname !== null) {
    return (
      <Redirect
        to={{
          pathname: window.pathname,
        }}
      />
    );
  } else {
    return (
      <div style={{ padding: '4rem 32px' }}>
        <GoBackHeader />
        <HeaderTitle pageInfo="Sorteios" />
        <Typography variant="h6" color="primary" style={{ fontWeight: '600', marginTop: '2rem' }}>
          {' '}
          Informações do Sorteio
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl className="input-field">
            <TextField
              required
              className="input-field"
              type="text"
              value={form.walletTitle === null ? '' : form.walletTitle}
              id="walletTitle"
              label="Título:"
              onChange={handleChange('walletTitle')}
            />

            <TextField
              required
              className="input-field"
              type="text"
              value={form.walletDetailsTitle === null ? '' : form.walletDetailsTitle}
              id="walletDetailsTitle"
              label="Detalhe do Título:"
              onChange={handleChange('walletDetailsTitle')}
            />

            <TextField
              required
              className="input-field"
              type="text"
              value={form.walletDescription === null ? '' : form.walletDescription}
              id="walletDescription"
              label="Descrição:"
              onChange={handleChange('walletDescription')}
            />

            <TextField
              required
              className="input-field"
              type="text"
              value={form.walletDetailsDescription === null ? '' : form.walletDetailsDescription}
              id="walletDetailsDescription"
              label="Detalhe da Descrição:"
              onChange={handleChange('walletDetailsDescription')}
            />

            <TextField
              required
              error={form.errorVerification}
              className="input-field"
              type="text"
              disabled={props.location.state.lotterySerie && true}
              value={form.lotterySerie === null ? '' : form.lotterySerie}
              id="lotterySerie"
              label="Nome interno:" // Série
              onChange={handleChange('lotterySerie')}
              onBlur={() => validateSerieName(form.lotterySerie === null ? '' : form.lotterySerie)}
            />

            <Autocomplete
              id="establishmentName"
              value={form.nameValue || null}
              onChange={(_, newValue) => {
                if (newValue !== null) {
                  handleSelect(newValue);
                }
              }}
              open={form.openSelect}
              onOpen={handleOpenSelect}
              onClose={handleCloseSelect}
              disableClearable
              getOptionLabel={(option) => option?.nome}
              options={selectEstablishiment}
              noOptionsText="Nenhum estabelecimento encontrado"
              loadingText={form.textOpen}
              loading={loadingSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nome do Estabelecimento"
                  className="input-field"
                  value={form.establishmentName}
                  onChange={handleSearch}
                  onBlur={handleSearch}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />

            <TextField
              required
              className="input-field"
              type="text"
              value={form.campaingId === null ? '' : form.campaingId}
              id="campaingId"
              label="Id da Campanha"
              onChange={handleChange('campaingId')}
            />

            <TextField
              required
              className="input-field"
              type="text"
              value={form.campaingName === null ? '' : form.campaingName}
              id="campaingName"
              label="Nome da Campanha"
              onChange={handleChange('campaingName')}
            />

            {form.isUpdateCampaign && form.numbersLucky && (
              <TextField
                required
                className="input-field"
                type="text"
                disabled={props.location.state.numbersLucky && true}
                value={form.numbersLucky === null ? '' : form.numbersLucky}
                id="numbersLucky"
                label="Quantidade de cupons da sorte"
                onChange={handleChange('numbersLucky')}
              />
            )}

            {!form.numbersLucky && (
              <Box display="flex" gap="10px">
                <TextField
                  required
                  className="input-field"
                  type="text"
                  value={
                    form.serieStart !== null && form.serieStart !== ''
                      ? formatSerie(form.serieStart)
                      : ''
                  }
                  id="serieStart"
                  label="Série Inicial"
                  disabled={form.isUpdateCampaign}
                  onChange={handleChange('serieStart')}
                />

                <TextField
                  required
                  className="input-field"
                  type="text"
                  value={
                    form.serieEnd !== null && form.serieEnd !== '' ? formatSerie(form.serieEnd) : ''
                  }
                  id="serieEnd"
                  label="Série Final"
                  disabled={form.isUpdateCampaign}
                  onChange={handleChange('serieEnd')}
                />
              </Box>
            )}

            <FormGroup>
              {/* IMAGENS */}
              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: '600', marginTop: '2rem' }}
              >
                {' '}
                Imagens do Sorteio
              </Typography>
              <div className="flex-external">
                {/* Fotos thumbnail */}
                <div
                  className="img-thumb"
                  style={{
                    maxWidth: '400px',
                    position: 'relative',
                    top: '0',
                    left: '0',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="secondary"
                    className="style-text"
                    style={{ fontWeight: '550', textAlign: 'center' }}
                  >
                    Thumbnail do Sorteio (Visualização na Carteira)
                  </Typography>

                  {/* Placeholder do thumbnail */}
                  <img
                    alt="Placeholder thumbnail"
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      maxHeight: '447px',
                      position: 'relative',
                      top: '0px',
                      left: '0px',
                    }}
                    src={thumbnail}
                  />
                  {/* Thumbnail miniatura */}
                  <img
                    alt="Thumbnail miniatura"
                    className="img-position-triibo"
                    src={
                      form.thumbnailFile
                        ? form.thumbnailFile
                        : form.downloadedThumb
                        ? form.downloadedThumb
                        : placeholder
                    }
                  />

                  {/* Botão add thumbnail */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                      value=""
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      onChange={fileChangedHandler('fotoThumb')}
                      id="thumbnail"
                    />

                    <label htmlFor="thumbnail">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{
                          marginTop: '15px',
                          fontWeight: '550',
                          marginLeft: '20px',
                          color: '#fff',
                          textTransform: 'capitalize',
                        }}
                        startIcon={<AddPhotoAlternate />}
                      >
                        Adicionar Foto
                      </Button>

                      <Tooltip title="As imagens devem ser 4x3.">
                        <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                      </Tooltip>
                    </label>
                  </div>
                </div>

                {/* Fotos Detalhes */}
                <div
                  className="Foto-detalhe"
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    position: 'relative',
                    top: '0',
                    left: '0',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="secondary"
                    className="style-text"
                    style={{ fontWeight: '550', textAlign: 'center' }}
                  >
                    Foto de Detalhe da Campanha (Visualização na Carteira)
                  </Typography>

                  {/* Placeholder Fotos Detalhe */}
                  <img
                    alt="Placeholder foto de detalhe "
                    style={{
                      width: '100%',
                      maxWidth: '392px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                    src={detailPhoto}
                  />
                  {/* Fotos Detalhe */}
                  <img
                    alt="Foto de Detalhe"
                    src={
                      form.photoFile
                        ? form.photoFile
                        : form.downloadedPhoto
                        ? form.downloadedPhoto
                        : placeholder
                    }
                    width="100%"
                    style={{
                      maxWidth: '393px',
                      position: 'absolute',
                      left: '0',
                      maxHeight: '295px',
                      objectFit: 'cover',
                    }}
                  />

                  {/* Botão add foto detalhe */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                      value=""
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      onChange={fileChangedHandler('detailPhoto')}
                      id="detailPhoto"
                    />

                    <label htmlFor="detailPhoto">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{
                          marginTop: '15px',
                          fontWeight: '550',
                          marginLeft: '20px',
                          color: '#fff',
                          textTransform: 'capitalize',
                        }}
                        startIcon={<AddPhotoAlternate />}
                      >
                        Adicionar Foto de Detalhe
                      </Button>

                      <Tooltip title="As imagens devem ser 4x3.">
                        <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                      </Tooltip>
                    </label>
                  </div>
                </div>
                {/* Fim das imgs */}
              </div>
            </FormGroup>

            {/* INICIO EVENTOS */}
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: '600', marginTop: '2rem' }}
            >
              Eventos
            </Typography>

            <div className={classes.containerAddEvent}>
              <FormControl className={classes.inputAddEvent}>
                <InputLabel id="eventName-select-label">Nome do Evento</InputLabel>
                <Select
                  labelId="eventName-select"
                  id="eventName-select"
                  value={form.eventName}
                  onChange={handleChange('eventName')}
                >
                  {form.currentEvents.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="number"
                value={form.eventValue === null ? '' : form.eventValue}
                id="eventValue"
                label="Valor do Evento:"
                onChange={handleChange('eventValue')}
                className={classes.inputAddEvent}
                disabled={!form.eventName}
              />

              <TextField
                type="number"
                value={form.each}
                id="each"
                label="A cada"
                onChange={handleChange('each')}
                // className={classes.inputAddEvent}
                disabled={!form.eventName}
              />

              <Button
                onClick={() => addEvent()}
                disabled={form.eventName.length === 0 || form.eventValue.length === 0}
                className={classes.buttonAddEvent}
                variant="contained"
                color="primary"
              >
                Adicionar evento
              </Button>
            </div>

            <Typography
              variant="subtitle2"
              gutterBottom
              color="secondary"
              className="style-text"
              style={{ fontWeight: '550' }}
            >
              Lista de Eventos
            </Typography>

            <Grid
              item
              md={12}
              sm={12}
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                maxHeight: '125px',
                overflowY: 'scroll',
              }}
            >
              {form.eventListMerge.length === 0 ? (
                <List>
                  <ListItem dense button>
                    <ListItemText primary={`Nenhum valor encontrado`} />
                  </ListItem>
                </List>
              ) : (
                <List>
                  {form.eventListMerge.map((item, index) => (
                    <ListItem key={index} role={undefined} dense button>
                      <ListItemText
                        primary={`${item.eventName} | Valor: ${item.eventValue} | A cada: ${item.voucherEach}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeEvent(item.eventName)}
                          edge="end"
                          aria-label="Comments"
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>

            {/* INICIO DA NOTIFICACAO */}
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: '600', marginTop: '2rem' }}
            >
              {' '}
              Notificação
            </Typography>
            <div className={classes.containerAddEvent}>
              <TextField
                type="text"
                value={
                  form.notificationMessage.title === null ? '' : form.notificationMessage.title
                }
                id="titleNotification"
                label="Título da notificação:"
                onChange={handleChange('title')}
                className={classes.inputAddEvent}
              />
              <TextField
                type="text"
                value={
                  form.notificationMessage.message === null ? '' : form.notificationMessage.message
                }
                id="messageNotification"
                label="Mensagem da notificação:"
                onChange={handleChange('message')}
                className={classes.inputAddEvent}
              />
              <TextField
                type="text"
                value={form.notificationMessage.url === null ? '' : form.notificationMessage.url}
                id="urlNotification"
                label="URL da notificação:"
                onChange={handleChange('url')}
                className={classes.inputAddEvent}
              />
            </div>

            {/* INICIO QUIZ */}
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: '600', marginTop: '2rem' }}
            >
              {' '}
              Informações do Quiz
            </Typography>

            <div className={classes.containerAddEvent}>
              <TextField
                type="text"
                value={form.quizKey === null ? '' : form.quizKey}
                id="quizKey"
                label="ID do Typeform:"
                onChange={handleChange('quizKey')}
                className={classes.inputAddEvent}
              />

              <TextField
                type="text"
                value={form.quizValue === null ? '' : form.quizValue}
                id="quizValue"
                label="ID da promoção:"
                onChange={handleChange('quizValue')}
                className={classes.inputAddEvent}
                disabled={!form.quizKey}
              />

              <Button
                onClick={() => addQuiz()}
                disabled={form.quizKey.length === 0 || form.quizValue.length === 0}
                className={classes.buttonAddEvent}
                variant="contained"
                color="primary"
              >
                Adicionar quiz
              </Button>
            </div>
            <Typography
              variant="subtitle2"
              gutterBottom
              color="secondary"
              className="style-text"
              style={{ fontWeight: '550' }}
            >
              Lista de Quizzes
            </Typography>

            <Grid
              item
              md={12}
              sm={12}
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '2rem',
                maxHeight: '125px',
                overflowY: 'scroll',
              }}
            >
              {Object.keys(form.quizzes).length < 1 ? (
                <List>
                  <ListItem dense button>
                    <ListItemText primary={`Nenhum valor encontrado`} />
                  </ListItem>
                </List>
              ) : (
                <List>
                  {Object.keys(form.quizzes).map((item) => (
                    <ListItem key={item} role={undefined} dense button>
                      <ListItemText primary={`${item} : ${form.quizzes[item]}`} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeQuiz(item)}
                          edge="end"
                          aria-label="Comments"
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>

            <TextField
              id="datetime-local"
              label="Data de ativação"
              type="datetime-local"
              value={form.activationDate}
              onChange={handleChange('activationDate')}
              InputLabelProps={{
                shrink: true,
              }}
              color="primary"
              required={true}
            />

            <br />

            <TextField
              id="datetime-local"
              label="Data de desativação"
              type="datetime-local"
              value={form.dueDate}
              onChange={handleChange('dueDate')}
              InputLabelProps={{
                shrink: true,
              }}
              color="primary"
              required={true}
            />
          </FormControl>

          <Button
            style={{
              fontWeight: '550',
              marginTop: '20px',
              color: 'white',
              textTransform: 'capitalize',
            }}
            variant="contained"
            color="secondary"
            onClick={() => history.goBack()}
            startIcon={<ArrowBack />}
          >
            Voltar
          </Button>

          <Button
            style={{
              fontWeight: '550',
              marginTop: '20px',
              marginLeft: '10px',
              color: 'white',
              textTransform: 'capitalize',
            }}
            variant="contained"
            color="primary"
            type="submit"
            id="buttonSave"
            disabled={form.saveButtonIsDisabled}
            startIcon={<Save />}
          >
            Salvar
          </Button>
        </form>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={form.openCheck}
          autoHideDuration={4000}
          onClose={() => setForm((prevForm) => ({ ...prevForm, openCheck: false }))}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{form.messageAlert}</span>}
        />

        {/* MODAL PARA CROPAR IMAGEM */}
        <Modal open={form.openModal}>
          <div style={getModalStyle()}>
            <Typography
              variant="h5"
              gutterBottom
              color="primary"
              style={{ fontWeight: '600', textAlign: 'center' }}
            >
              Editar Imagem
            </Typography>

            <div className="style-modalCrop">
              {form.imageToCrop === undefined || form.imageToCrop === null ? null : (
                <Cropper
                  style={{ overflow: 'hidden' }}
                  src={form.imageToCrop[form.indexCrop]}
                  ref={cropperRef}
                  ratio={4 / 3}
                />
              )}
            </div>

            <div
              style={{
                position: 'fixed',
                bottom: 10,
                display: 'flex',
                flexDirection: 'row',
                marginRight: '18px',
                marginLeft: '-6',
              }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => handleCloseModal()}
                startIcon={<ArrowBack />}
              >
                Cancelar
              </Button>

              <Button
                style={{
                  fontWeight: '550',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                onClick={() => cropImage('image')}
                startIcon={<CropFree />}
              >
                Cortar Imagem
              </Button>
            </div>
          </div>
        </Modal>
        {/* FIM MODAL PARA CROPAR IMAGEM */}
      </div>
    );
  }
};

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.campaingTriiboComponent.loading,
    success: state.campaingTriiboComponent.success,
    error: state.campaingTriiboComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateCampaingTriiboComponent: (
    walletTitle,
    walletDetailsTitle,
    walletDescription,
    walletDetailsDescription,
    walletThumbnail,
    walletPhoto,
    thumbnailFile,
    photoFile,
    lotterySerie,
    notificationMessage,
    quizzes,
    events,
    voucherEach,
    voucherQuantity,
    activationDate,
    dueDate,
    establishmentId,
    establishmentName,
    campaingId,
    campaingName,
    numbersLucky,
    serieStart,
    serieEnd,
    isUpdateCampaign
  ) =>
    updateCampaingTriiboAction(
      dispatch,
      walletTitle,
      walletDetailsTitle,
      walletDescription,
      walletDetailsDescription,
      walletThumbnail,
      walletPhoto,
      thumbnailFile,
      photoFile,
      lotterySerie,
      notificationMessage,
      quizzes,
      events,
      voucherEach,
      voucherQuantity,
      activationDate,
      dueDate,
      establishmentId,
      establishmentName,
      campaingId,
      campaingName,
      numbersLucky,
      serieStart,
      serieEnd,
      isUpdateCampaign
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(LotteryEdit);
