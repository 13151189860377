import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import cfac22 from '../cfac22';

export function getDataRedshift(sqlQuery) {
  return new Promise(function (resolve, reject) {
    getChannelTokenUidNull()
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_V1') + 'getDataRedshift_v1',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                channelTokenId: token,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              query: sqlQuery,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then((result) => {
            if (result.data.error === null && result.data.success.result !== null) {
              resolve(result.data.success.result);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR GET CHANNEL TOKEN ===>', error);
      });
  });
}
