import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, InputLabel } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';
import DataTableLottery from 'js/containers/DataTable/DataTableLottery';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { DebounceInput } from 'react-debounce-input';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const useStyles = makeStyles((theme) => ({
  inputSearch: {
    fontSize: '16px',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    width: '100%',
    marginBottom: '10px',
    boxShadow: '0 0 0 0',
    outline: '0',
    paddingLeft: '26px',
    paddingBottom: '5px',
    borderBottom: '1px solid #6b6b6b',
  },
  buttonAddCampaing: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
}));

const LotteryList = () => {
  const classes = useStyles();
  const [rows] = useState([
    { id: 'thumbnail', numeric: false, disablePadding: false, label: 'Thumbnail' },
    { id: 'titulo', numeric: false, disablePadding: false, label: 'Título' },
    { id: 'lotterySerie', numeric: false, disablePadding: false, label: 'Série' },
    { id: 'activationDate', numeric: false, disablePadding: false, label: 'Data de ativação' },
    { id: 'dueDate', numeric: false, disablePadding: false, label: 'Data de desativação' },
  ]);

  const [campaingList, setCampaingList] = useState([]);
  const [copyCampaingList, setCopyCampaingList] = useState([]);
  const [pesquisa, setPesquisa] = useState('');

  useEffect(() => {
    getDataListRestApi('lottery-serie')
      .then((dataReceived) => {
        const data = Object.keys(dataReceived).map((item) => ({
          ...dataReceived[item],
          key: item,
          downloadedThumb: placeholder,
          data: dataReceived,
        }));
        setCampaingList(data);
        setCopyCampaingList(data);
      })
      .catch((error) => {
        console.error('Erro na data:', error);
      });
  }, []);

  const handleChange = (event) => {
    const value = event.target.value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    setPesquisa(value);

    if (value.length > 0) {
      const filteredList = campaingList.filter((campaing) =>
        campaing.walletTitle
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(value)
      );
      setCopyCampaingList(filteredList);
    } else {
      setCopyCampaingList(campaingList);
    }
  };

  return (
    <div style={{ maxHeight: '100%', padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Sorteios" />
      <br />
      <Grid container spacing={5}>
        <Grid item md={6} xs={12} />
        <Grid item md={6} xs={12}>
          <div className={classes.buttonAddCampaing}>
            <Link
              to={{
                pathname: '/admin/configuracoes/sorteio/adicionar',
                state: { data: campaingList },
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{
                  fontWeight: '550',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                title="Adicionar Sorteio"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Add />}
              >
                Adicionar Sorteio
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <div style={{ position: 'relative', marginTop: '1rem' }}>
        <InputLabel style={{ fontSize: '13px', marginBottom: '17px' }}>
          Pesquise por um sorteio:
        </InputLabel>
        <Search style={{ position: 'absolute', bottom: '14px', left: '-1px' }} />
        <DebounceInput
          className={classes.inputSearch}
          minLength={3}
          debounceTimeout={0}
          value={pesquisa}
          onChange={handleChange}
        />
      </div>

      <DataTableLottery
        rows={rows}
        tableNode="lottery-serie"
        campaingList={campaingList}
        copyCampaingList={copyCampaingList}
      />
    </div>
  );
};

export default LotteryList;
