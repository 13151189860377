import { getByOwner } from 'js/library/utils/API/Campaigns/apiGetByOwner';
import { toast } from 'react-toastify';
import blog from '../../../../../../styles/assets/LPModules/placeholder.blog.png';
import banner from '../../../../../../styles/assets/LPModules/placeholder.herobanner.png';
import highlights from '../../../../../../styles/assets/LPModules/placeholder.destaque.png';
import text from '../../../../../../styles/assets/LPModules/imagem-texto-blog.png';
import target from '../../../../../../styles/assets/LPModules/imagem-texto-blog.png';
import faq from '../../../../../../styles/assets/LPModules/imagem-texto-blog.png';
import unitVoucherRedemption from '../../../../../../styles/assets/LPModules/imagem-texto-blog.png';
import campaignEnrollment from '../../../../../../styles/assets/LPModules/imagem-texto-blog.png';
import cardsGrid from '../../../../../../styles/assets/LPModules/placeholder.carousel.png';
import carousel from '../../../../../../styles/assets/LPModules/placeholder.carousel.png';
import { getLpById } from 'js/library/utils/API/CampaignLandinPage/apiGetLpById';
import { createLandingPage } from 'js/library/utils/API/CampaignLandinPage/apiCreateLandingPage';
import { updateLandingPage } from 'js/library/utils/API/CampaignLandinPage/apiUpdateLandingPage';

const getCampaignsByOwner = async (owner) => {
  const response = await getByOwner(owner);
  if (response?.success) return response?.result;
  else {
    toast.error(response);
  }
};

const getLandingPageById = async (lpId) => {
  const response = await getLpById(lpId);
  if (response?.success) return response?.result;
  else {
    toast.error(response);
  }
};

const createLP = async (body) => {
  const response = await createLandingPage(body);

  if (response.success) {
    return response;
  }
};

const updateLP = async (id, body) => {
  const response = await updateLandingPage(id, body);

  if (response.success) {
    return response;
  }
};

const modulesImages = {
  blog,
  banner,
  highlights,
  text,
  carousel,
  target,
  faq,
  unitVoucherRedemption,
  campaignEnrollment,
  cardsGrid,
};

const modulesList = [
  { id: 2, option: 'banner' },
  { id: 1, option: 'blog' },
  { id: 4, option: 'carousel' },
  { id: 3, option: 'highlights' },
  { id: 5, option: 'text' },
  { id: 6, option: 'target' },
  { id: 7, option: 'unitVoucherRedemption' },
  { id: 8, option: 'faq' },
  { id: 9, option: 'cardsGrid' },
  { id: 10, option: 'campaignEnrollment' },
];

const modalsList = {
  background: 'bgModal',
  banner: 'bannerModal',
  blog: 'blogModal',
  text: 'textModal',
  carousel: 'carouselModal',
  highlights: 'highlightsModal',
  target: 'targetModal',
  faq: 'faqModal',
  unitVoucherRedemption: 'redeemVoucherModal',
  campaignEnrollment: 'campaignEnrollmentModal',
  cardsGrid: 'cardsGridModal',
};

export default {
  getLandingPageById,
  getCampaignsByOwner,
  createLP,
  updateLP,
  modulesImages,
  modulesList,
  modalsList,
};
