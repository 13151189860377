import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function getEstablishmentSegment(id) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/resourceSegment/getEstablishmentSegment?segmentId=${id}`, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          return reject(error.data.response);
        });
    });
  });
}
