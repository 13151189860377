import React from 'react';
// STYLES
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { TriiboH1 } from 'js/components/DesignSystem/styles';
import { Container } from 'js/components/Establishment/ListEstablishment/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

export const PageStructure = ({ children, title }) => {
  return (
    <>
      <Container>
        <AreaHeader
          style={{
            color: '#08bad0',
            justifyContent: 'end',
            padding: '1rem',
          }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>

        <TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3px',
          }}
        >
          {title}
        </TriiboH1>
        {children}
      </Container>
    </>
  );
};
