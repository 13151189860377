import {
  Table,
  TableContainer,
  TableHead,
  InputLabel,
  Box,
  FormGroup,
  Accordion,
} from '@mui/material';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';
import InputMask from 'react-input-mask';

export const TbContainer = styled(TableContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TbContent = styled(Table)`
  min-width: 300px;
  max-width: 1550px;
`;

export const FormGroupBox = styled(FormGroup)`
  > div {
    display: flex;
    align-items: center;
  }
`;

export const AccordionStyle = styled(Accordion)`
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 600px) {
    max-width: 58%;
  }
`;

export const TableHeaderMui5 = styled(TableHead)`
  background-color: #fafafa;
  font-weight: bold;
`;

export const DeleteButtonContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const DeleteUserButton = styled.button`
  display: block;
  width: 2rem;
  height: 2rem;
  background: red;
  border-radius: 4px;
  margin-right: 0.3rem;
  border: 0;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50vh;
  width: 100%;
`;

export const ContainerSearch = styled.div`
  margin: 2rem;
  width: 50%;
`;
