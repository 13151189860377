import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../cfac22';

export function EditPartnerUserForms(body) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'put',
        url: `${cfac22('API_HOST_V2')}/partnerUserForms/editPartnerUserForms`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: body,
      };

      axios(config)
        .then((response) => {
          resolve(response.data.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
