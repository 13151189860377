import React from 'react';
import { cloneDeep } from 'lodash';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { TextField } from '@mui/material';

export default function ButtonText({ form, setForm }) {
  const handleCurrentTextChange = (e) => {
    e.preventDefault();
    const newObj = cloneDeep(form);
    newObj.action.label = e.target.value;
    setForm(newObj);
  };

  const errorLabel = form.action.label.length <= 0;

  return form?.action ? (
    <S.Container>
      <div>
        <D.TriiboH4>
          <b>Texto do botão*</b>
        </D.TriiboH4>
        <D.TriiboH5>Defina o texto que será inserido no Botão de Ação.</D.TriiboH5>
      </div>

      <S.CustomInputArea>
        <TextField
          fullWidth
          value={form.action.label}
          maxLength="20"
          onChange={(e) => handleCurrentTextChange(e)}
          error={errorLabel}
          helperText={errorLabel && 'Preencha o campo'}
        />
      </S.CustomInputArea>
    </S.Container>
  ) : null;
}
