import axios from 'axios';
import authToken from './authToken';

import cfac22 from '../cfac22';

export function GetUserPartner(partnerId, uid) {
  return new Promise((resolve, reject) => {
    authToken(uid).then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/userPartner?partnerId=${partnerId}&uid=${uid}`, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  });
}
