import axios from 'axios';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import { getUserInfo } from 'js/library/utils/helpers.js';

import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function getOrgById(orgID) {
  //OBTER INFORMAÇÕES DO USUÁRIO

  return new Promise((resolve, reject) => {
    const loggedUid = getUserInfo().uId;

    getChannelToken_v1(loggedUid)
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);
        axios
          .post(
            cfac22('API_HOST_V1') + 'getOrgByID',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId: loggedUid,
                channelTokenId: result,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              orgID: orgID,
              platform: 'web',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInactiveCardByOrg(orgID, from, size) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/cards/getInactiveCardByOrg?orgs=${orgID}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          payload: {
            from: from,
            size: size,
          },
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          !error.success ? resolve(error) : reject(error);
        });
    });
  });
}

export function getActiveCardByOrg(orgID, from, size) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/cards/getActiveCardByOrg?orgs=${orgID}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          payload: {
            from: from,
            size: size,
          },
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          !error.success ? resolve(error) : reject(error);
        });
    });
  });
}
