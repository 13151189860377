import React, { useEffect, useState } from 'react';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  List,
  ListItemText,
  Pagination,
  Typography,
} from '@mui/material';

// ICONES
import { MdArrowBackIosNew } from 'react-icons/md';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// COMPONENTS
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

// APIS
import { getLogsByGroupId } from 'js/library/utils/API/Points/getLogsByGroupId';
import CollapsibleTable from 'js/components/TriiboComponents/Tables/CollapsibleTable';
import { FileArrowDown } from 'phosphor-react';

// traduzindo os dados da mother
function translateMotherData(motherData) {
  const translationMap = {
    id: 'Identificação',
    debitId: 'ID de débito',
    status: 'Status',
    creationDate: 'Data de criação',
    updateDate: 'Data de atualização',
    isUnitary: 'É unitário',
    filePath: 'Caminho do arquivo',
    value: 'Pontos',
    transactionTotalPoints: 'Pontos totais da transação',
    type: 'Tipo',
    establishmentId: 'ID do estabelecimento',
    clubId: 'ID do clube',
    description: 'Descrição',
  };

  const translatedData = {};
  Object.keys(motherData).forEach((key) => {
    const value = motherData[key];
    if (value != null && key !== 'points' && key !== 'resumedDescription') {
      translatedData[translationMap[key] || key] = value;
    }
  });
  return translatedData;
}

// traduzindo os dados do filho
function translateChildData(childDataArray) {
  const translationMap = {
    id: 'Identificação',
    movementGroupId: 'ID do grupo de movimento',
    processDate: 'Data do processo',
    eventName: 'Nome do evento',
    value: 'Pontos',
    typeOfReceiver: 'Tipo do destinatário',
    receiverAccount: 'Conta do destinatário',
    receiverUid: 'UID do destinatário',
    receiverEstablishmentId: 'ID do estabelecimento do destinatário',
    receiverClubeId: 'ID do clube do destinatário',
    typeOfSender: 'Tipo do remetente',
    senderUserAccount: 'Conta do usuário remetente',
    senderUid: 'UID do remetente',
    senderEstablishmentId: 'ID do estabelecimento do remetente',
    senderClubeId: 'ID do clube do remetente',
  };

  return childDataArray.map((childData) => {
    const translatedData = {};
    Object.keys(childData).forEach((key) => {
      const value = childData[key];
      if (value != null) {
        translatedData[translationMap[key] || key] = value;
      }
    });
    return translatedData;
  });
}

export default function PointsDetails(props) {
  const motherData = props.location.state;
  const motherId = motherData.id;

  const [childData, setChildData] = useState([]);
  const [errorChildData, setErrorChildData] = useState([]);
  const [successChildData, setSuccessChildData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const translatedData = translateMotherData(motherData);
  const translatedSuccessChildData = translateChildData(successChildData);
  const translatedErrorChildData = translateChildData(errorChildData);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(childData.length / PER_PAGE);
  const _DATA_S = usePagination(successChildData, PER_PAGE);
  const _DATA_E = usePagination(errorChildData, PER_PAGE);

  const handleSuccessChangePage = (e, p) => {
    _DATA_S.jump(p);
    setPage(p);
  };

  const handleErrorChangePage = (e, p) => {
    _DATA_E.jump(p);
    setPage(p);
  };

  const successColumns = [
    { heading: 'ID', value: 'id' },
    { heading: 'Nome do evento', value: 'eventName' },
    { heading: 'Data de processamento', value: 'processDate' },
    { heading: 'Pontos', value: 'value' },
    { heading: 'UID Recebedor', value: 'receiverUid' },
  ];

  const errorColumns = [
    { heading: 'ID', value: 'id' },
    { heading: 'Data de processamento', value: 'processDate' },
    { heading: 'UID Recebedor', value: 'receiverUid' },
    { heading: 'Pontos', value: 'value' },
    { heading: 'Motivo', value: 'errorMessage' },
  ];

  const tranformData = (data) => {
    const filteredData = data.filter((item) => {
      return item;
    });

    return filteredData.map((item) => {
      return {
        ...item,
        processDate: new Date(item.processDate).toLocaleDateString('pt-BR'),
        typeOfReceiver:
          item.typeOfReceiver === 'user'
            ? 'Usuário'
            : item.typeOfReceiver === 'establishment'
            ? 'Estabelecimento'
            : 'Seu clube',
        typeOfSender:
          item.typeOfSender === 'establishment'
            ? 'Estabelecimento'
            : item.typeOfSender === 'club'
            ? 'Seu clube'
            : 'Usuário',
      };
    });
  };

  // trás os primeiros 20 registros
  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          movementGroupId: motherId,
          from: 0,
          size: 20,
        };

        if (motherData.usersProcessedWithSuccess > 0) {
          const responseSuccess = await getLogsByGroupId({ ...payload, status: 'success' });
          setSuccessChildData(tranformData(responseSuccess));
        }

        if (motherData.usersProcessedWithError > 0) {
          const responseErrors = await getLogsByGroupId({ ...payload, status: 'error' });
          setErrorChildData(tranformData(responseErrors));
        }
      } catch (error) {
        console.error('Ocorreu um erro na requisição:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // paginação: trás mais 20 quando o usuário clica na ultima página da tabela
  useEffect(() => {
    if (count === page && childData.length >= 20) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const payload = {
            movementGroupId: motherId,
            from: childData.length,
            size: 20,
            status: 'all',
          };
          const response = await getLogsByGroupId(payload);

          setChildData((prev) => [...prev, ...tranformData(response)]);
          setLoading(false);
        } catch (error) {
          console.error('Ocorreu um erro na requisição:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [count === page]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <S.Container style={{ paddingBottom: '200px' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
          marginBottom: '40px',
        }}
      >
        Detalhes do Evento
      </D.TriiboH1>
      <Box sx={{ width: '100%' }}>
        <Accordion elevation={1} expanded={expanded} onChange={handleAccordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontWeight: 'bold' }}
          >
            Informações do evento
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {Object.entries(translatedData).map(([key, value]) => (
                <ListItemText
                  key={key}
                  primary={
                    <>
                      <strong>{key}:</strong> {value}
                    </>
                  }
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
      {loading && <CircularProgress size={30} />}
      {!loading && translatedSuccessChildData.length > 0 && (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <Typography variant="h5">Sucessos:</Typography>
            <a href={motherData.successRelatoryUrl} download>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<FileArrowDown size={25} color="#f7f7f7" />}
              >
                {'   exportar tabela'}
              </Button>
            </a>
          </span>
          <CollapsibleTable
            columns={successColumns}
            data={_DATA_S.currentData()}
            translatedChildData={translatedSuccessChildData}
            perPage={10}
            isPagination
          />
          <Pagination
            sx={{ margin: '10px auto' }}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={handleSuccessChangePage}
          />
        </Box>
      )}
      {!loading && translatedErrorChildData.length > 0 && (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <Typography variant="h5">Erros:</Typography>
            <a href={motherData.errorRelatoryUrl} download>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<FileArrowDown size={25} color="#f7f7f7" />}
              >
                {'   exportar tabela'}
              </Button>
            </a>
          </span>
          <CollapsibleTable
            columns={errorColumns}
            data={_DATA_E.currentData()}
            translatedChildData={translatedErrorChildData}
            perPage={10}
            isPagination
          />
          <Pagination
            sx={{ margin: '0 auto' }}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={handleErrorChangePage}
          />
        </Box>
      )}
      {!loading && errorChildData.length === 0 && successChildData.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '40px 0px' }}>
          <strong>Nenhum resultado encontrado.</strong>
        </Box>
      )}
    </S.Container>
  );
}
