import React from 'react';
import './style.scss';
import { CircularProgress } from '@mui/material';

export const Loading = () => {
  return (
    <div className="flex-center">
      {/* <img alt="" src={ loading} /> */}
      <CircularProgress />
    </div>
  );
};

export default Loading;

