import React, { useCallback, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, CircularProgress, Modal, TextField } from '@mui/material';
import ModelVoucherModal from './model';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const RedeemVoucherModal = ({ isActiveModals, closeModal, setPayload, selectedModalInfos }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [promotionSelected, setPromotionSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const { form, handleChange, setForm, validateForm, clearForm } = useForm({
    moduleColor: {
      value: '',
      required: false,
    },
    redeemButtonLabel: {
      value: '',
      required: false,
    },
    comunicationId: {
      value: '',
      required: false,
    },
    campaign: {
      templateId: {
        value: '',
        required: false,
      },
      buttonLabel: {
        value: '',
        required: false,
      },
      url: {
        value: '',
        required: false,
      },
    },
  });

  const currentModal = ModelLPSignUp.modalsList.unitVoucherRedemption;

  const handleSave = () => {
    const { moduleColor, redeemButtonLabel, comunicationId, campaign } = form;

    if (validateForm()) {
      setPayload((prevState) => {
        const modules = prevState.campaignLandpage.modules;
        modules[selectedModalInfos.currentIndex] = {
          ...selectedModalInfos.modalInfos,
          moduleColor: moduleColor?.value,
          redeemButtonLabel: redeemButtonLabel?.value,
          comunicationId: comunicationId?.value,
          campaign: {
            templateId: campaign?.templateId.value,
            buttonLabel: campaign?.buttonLabel.value,
            url: campaign?.url.value,
          },
        };
        return {
          campaignLandpage: {
            ...prevState?.campaignLandpage,
            modules,
          },
        };
      });

      toast.success('Voucher salvo com sucesso!');
      setPromotionSelected(null);
      clearForm();
      closeModal(currentModal);
    } else toast.error('Preencha todos os campos obrigatórios!');
  };

  const handleCloseModal = () => {
    setPromotionSelected(null);

    clearForm();
    closeModal(currentModal);
  };

  useEffect(() => {
    if (isActiveModals) {
      const { moduleColor, redeemButtonLabel, campaign } = selectedModalInfos?.modalInfos;

      setForm((prevState) => ({
        ...prevState,

        moduleColor: {
          value: moduleColor || '#000000',
          required: true,
        },
        redeemButtonLabel: {
          value: redeemButtonLabel || '',
          required: true,
        },
        comunicationId: {
          value: '-O3nLVQCPfTyQYX6T--e',
          required: true,
        },
        campaign: {
          templateId: {
            value: campaign?.templateId || '',
            required: true,
          },
          buttonLabel: {
            value: campaign?.buttonLabel || '',
            required: true,
          },
          url: {
            value: campaign?.url || '',
            required: true,
          },
        },
      }));
    }
  }, [isActiveModals, selectedModalInfos]);

  const dataStore = async (input) => {
    setLoading(true);
    try {
      const response = await ModelVoucherModal.getListPromotions(input, true, 0, 10);

      // Filtra as promoções que possuem templateId
      const filteredPromotions = response.filter((promotion) => promotion.templateId);
      setPromotions(filteredPromotions);
    } catch (error) {
      console.error('Erro ao obter a lista de promoções:', error);
    } finally {
      setLoading(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce((input) => {
      dataStore(input);
    }, 500),
    []
  );

  useEffect(() => {
    if (inputSearch.length > 2) debouncedSearch(inputSearch);
  }, [inputSearch]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor do módulo"
              id="moduleColor"
              margin="normal"
              value={form?.moduleColor?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.moduleColor?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPicker((prevState) => !prevState)}
            >
              {displayColorPicker ? 'Salvar' : 'Selecione a cor do módulo'}
            </Button>
          </Box>
          {displayColorPicker && (
            <ChromePicker
              color={form?.moduleColor?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  moduleColor: { ...prevState.moduleColor, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        <TextField
          fullWidth
          label="Texto do botão de resgate*"
          id="redeemButtonLabel"
          margin="normal"
          value={form?.redeemButtonLabel?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <Autocomplete
          fullWidth
          disablePortal
          sx={{ mt: '15px', mb: '5px' }}
          id="combo-box-demo"
          options={promotions}
          value={promotionSelected ?? null}
          getOptionLabel={(option) =>
            `Nome: ${option.title} | ID: ${option.id} | TemplateId: ${option.templateId}`
          }
          blurOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                loading ? (
                  <>
                    <CircularProgress size={20} color="inherit" />
                    &nbsp;Pesquisando...
                  </>
                ) : (
                  'Selecione uma promoção'
                )
              }
              placeholder="Pesquise"
              disabled={loading}
              onChange={(event) => setInputSearch(event.target.value)}
              onClick={() => setOptionsOpen(true)}
              onFocus={() => setOptionsOpen(true)}
              onBlur={() => setOptionsOpen(false)}
            />
          )}
          ListboxProps={{ style: { maxHeight: '200px' } }}
          open={optionsOpen}
          onClose={() => setOptionsOpen(false)}
          onChange={(_, newValue) => {
            if (newValue) {
              setPromotionSelected(newValue);
              setForm((form) => ({
                ...form,
                campaign: {
                  ...form.campaign,
                  templateId: {
                    value: newValue.templateId,
                  },
                },
              }));
            }
          }}
        />

        {form?.campaign?.templateId?.value !== '' && (
          <TextField
            fullWidth
            label="TemplateId"
            id="campaign.templateId"
            margin="normal"
            value={form.campaign.templateId.value}
            inputProps={{ readOnly: true }}
            disabled
          />
        )}

        <TextField
          fullWidth
          label="ComunicationId"
          id="campaign.comunicationId"
          margin="normal"
          value={form.comunicationId.value}
          inputProps={{ readOnly: true }}
          disabled
        />

        <TextField
          fullWidth
          label="Texto do botão de redirecionamento*"
          id="campaign.buttonLabel"
          margin="normal"
          value={form?.campaign?.buttonLabel?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <TextField
          fullWidth
          label="Caminho de redirecionamento*"
          id="campaign.url"
          margin="normal"
          value={form?.campaign?.url?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RedeemVoucherModal;
