import axios from 'axios';

import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function RegisterUsers(cellphone, userInformation, establishmentId, establishmentName) {
  return new Promise(function async(resolve, reject) {
    getChannelTokenUidNull().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V1') + 'createUserBatch',
          {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            establishmentId,
            establishmentName,
            bunch: [{ cellphone, ...userInformation }],
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Full-Url': window.location.href,
            },
          }
        )
        .then(async (result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function RegisterUsersBatch(bunch, establishmentId, establishmentName) {
  return new Promise(function async(resolve, reject) {
    getChannelTokenUidNull().then((token) => {
      axios
        .post(cfac22('API_HOST_V1') + 'createUserBatch', {
          triiboHeader: {
            apiToken: cfac22('keyapiv1triibowebadmin'),
            channelGroup: cfac22('channelGroup'),
            channelName: cfac22('channelName'),
            channelTokenId: token,
            channelId: cfac22('channelId'),
            sessionId: '001',
            transactionId: '002',
          },
          establishmentId,
          establishmentName,
          bunch,
        })
        .then(async (result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function RegisterUsersBatch_v2(bunch) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/preRegisters`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          bunch: [...bunch],
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
