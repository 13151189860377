import React, { useState } from 'react';
import { cloneDeep } from 'lodash';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

export default function ButtonText({ formCommunication, setFormCommunication }) {
  const [textValue, setTextValue] = useState('');

  const handleCurrentTextChange = (e) => {
    const newValue = e.target.value;
    setTextValue(newValue);
    setFormCommunication(
      cloneDeep({
        ...formCommunication,
        action: {
          ...formCommunication.action,
          label: textValue ? textValue : formCommunication.action.label,
        },
      })
    );
  };

  return (
    <S.Container>
      <div>
        <D.TriiboH4>
          <b>Texto do botão*</b>
        </D.TriiboH4>
        <D.TriiboH5>Defina a ação que será realizada ao clicar no Botão de Ação.</D.TriiboH5>
      </div>
      <S.CustomInputArea>
        <D.TriiboTextField
          rows={1}
          placeholder="Ver mais"
          maxLength="20"
          value={textValue}
          onChange={(e) => handleCurrentTextChange(e)}
        />
      </S.CustomInputArea>
    </S.Container>
  );
}
