import axios from 'axios';
import authToken from '../authToken';

import cfac22 from '../../cfac22';

export async function getByOwner(ownerId) {
  const token = await authToken();
  const headers = {
    headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
  };
  const endpoint = `${cfac22('API_HOST_V2')}/campaign/getByOwner/${ownerId}`;

  try {
    const response = await axios.get(endpoint, headers);

    if (response?.data?.success) {
      return response?.data;
    }
  } catch (err) {
    const error = err;
    return error?.response?.data?.error;
  }
}
