import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

// STYLES
import * as S from '../Establishment/ListEstablishment/styles';
import {
  Paper,
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  CircularProgress,
  Typography,
  useMediaQuery,
  IconButton,
  Grid,
  FormHelperText,
  Popover,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

// ICONES
import { AddPhotoAlternate, Send } from '@mui/icons-material';
import { MagnifyingGlass, Question, Trash } from 'phosphor-react';

// ASSETS
import csvModel from '../../../styles/assets/sendingPoints/modelo-envio-pontos.csv';
import xlsModel from '../../../styles/assets/sendingPoints/modelo-envio-pontos.xls';

// APIS
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { getSeuClubList } from 'js/library/utils/API/seuClube';
import {
  getUserInfo,
  separateBase64String,
  blobToBase64,
  cellPhoneMask2,
} from 'js/library/utils/helpers';
import { pointsExChangeInBatch } from 'js/library/utils/API/Points/pointsExChangeInBatch';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { getBalance_v1 } from 'js/library/utils/API/Points/getBalance_v1';
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import RenderInputDynamic from './SearchUser/RenderInputDynamic';
import { pointsExchangePartnerFiles } from 'js/library/utils/API/Points/pointsExchangePartnerFiles';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';
import { pointsExchangeUsersList } from 'js/library/utils/API/Points/pointsExchangeUsersList';

export default function SendingPoints(props) {
  const clubId = props?.location?.state?.clubId;
  const contractIdClub = props?.location?.state?.contractIdClub;
  const partnerId = props?.location?.state?.clubeData.partnerId;
  const establishmentId = props?.location?.state?.establishmentId;
  const contractIdEstablishment = props?.location?.state?.contractIdEstablishment;

  const history = useHistory();

  const [value, setValue] = useState(0);
  const [eventDescription, setEventDescription] = useState('');
  const [clientList, setClientList] = useState(null);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [receiverType, setReceiverType] = useState('');
  const [fromWhere, setFromWhere] = useState('');
  const [sendMode, setSendMode] = useState(1);

  const [userSearch, setUserSearch] = useState('');
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const [fieldKey, setFieldKey] = useState({});

  const [balancePoints, setBalancePoints] = useState(0);
  const [loadingBalancePoints, setLoadingBalancePoints] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [usersList, setUsersList] = useState([]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(600));

  const uId = getUserInfo().uId;

  const previousUrl = localStorage.getItem('previousUrlPoints');

  const openHelper = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const searchUnitary = async () => {
    if (!userSearch) {
      return toast.error('Preencha o campo de chave do usuário.', { autoClose: 2000 });
    }
    setLoadingSearchUser(true);

    if (fromWhere === 'adminConfig') {
      const payload = {
        contactList: [
          {
            type: 'cellPhone',
            value: userSearch,
          },
        ],
      };

      const response = await getUserInfo_v1(['getUserInfo'], payload);
      setLoadingSearchUser(false);
      const userInfo = response.data.success.userInfo;

      const isDuplicate = userInfo && usersList.some((obj) => obj.uId === userInfo.uId);

      if (!userInfo) {
        setUserSearch('');
        return toast.error('Usuário não encontrado.', { autoClose: 2000 });
      } else if (isDuplicate) {
        setUserSearch('');
        return toast.error('Usuário já adicionado.', { autoClose: 2000 });
      } else {
        setUsersList((prevUser) => {
          return [...prevUser, { ...userInfo, cellPhone: userSearch, key: userSearch }];
        });
        setUserSearch('');
      }
    } else {
      const user = await ValidateUserPartner(userSearch, partnerId);
      const isDuplicate = usersList.some((obj) => obj.uId === user.uId);
      setLoadingSearchUser(false);

      if (!user.user) {
        setUserSearch('');
        return toast.error('Usuário não encontrado.', { autoClose: 2000 });
      } else if (isDuplicate) {
        setUserSearch('');
        return toast.error('Usuário já adicionado.', { autoClose: 2000 });
      } else {
        setUsersList((prevUser) => {
          return [...prevUser, { ...user, key: userSearch }];
        });
        setUserSearch('');
      }
    }
  };

  useEffect(() => {
    if (!previousUrl) {
      setFromWhere('error');
    } else if (previousUrl.includes('estabelecimento')) {
      setFromWhere('estabelecimento');
    } else if (previousUrl.includes('seu-clube')) {
      setFromWhere('seuClube');
    } else if (previousUrl.includes('configuracoes')) {
      setFromWhere('adminConfig');
    }
  }, [previousUrl]);

  useEffect(() => {
    // é o contractId do estabelecimento triiboWifi + @contrato-triibo,com,br
    let triiboId = '';

    if (fromWhere === 'adminConfig') {
      triiboId = '-LEWO0lc274ebMRbuFnG@contrato-triibo,com,br';
      setFieldKey({
        fieldName: 'cellPhone',
        indexKey: true,
        allowEdition: false,
        inputType: 'text',
        label: 'Insira um celular',
        type: 'contactList',
        inputMask: 'cellPhone',
        fieldType: 'cellPhone',
        required: true,
      });
    } else if (fromWhere === 'estabelecimento' && contractIdEstablishment !== '') {
      triiboId = `${contractIdEstablishment}@contrato-triibo.com.br`;
    } else if (fromWhere === 'seuClube' && contractIdClub !== '') {
      triiboId = `${contractIdClub}@contrato-triibo.com.br`;
    }

    if (fromWhere === 'seuClube') setLoadingBalancePoints(true);

    if (fromWhere === 'seuClube') {
      GetPartnerUserForms(partnerId).then((response) => {
        const field = response.formFields.filter((item) => item.indexKey);
        setFieldKey(field[0]);
      });
    }

    if (triiboId !== '') {
      getBalance_v1(uId, triiboId)
        .then((res) => {
          setBalancePoints(res.balance.consolidateBalance?.total);
        })
        .catch((e) => {
          console.error('Erro getBalance: ', e);
          setLoadingBalancePoints(false);
        })
        .finally(() => {
          setLoadingBalancePoints(false);
        });
    }
  }, [fromWhere, contractIdClub, contractIdEstablishment]);

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const fileChangedHandler = (event) => {
    if (event.target.files.length !== 0) {
      setClientList(event.target.files[0]);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById('thumbnailStore').click();
  };

  // sobe o arquivo com a lista de usuários
  const uploadingFile = async (file) => {
    return new Promise(async (resolve, reject) => {
      const newFile = separateBase64String(await blobToBase64(file));

      uploadFiles('points-exchanges', {
        mimeType: newFile.mimeType,
        fileName: file.type === 'text/plain' ? 'usuarios.txt' : 'usuarios.csv',
        buffer: newFile.buffer,
      })
        .then((res) => {
          const response = res.split('/');
          const path = 'points-exchanges/' + response[response.length - 1].split('?')[0];
          resolve(path);
        })
        .catch(() => {
          reject('');
        });
    });
  };

  const addEventByFile = async (event) => {
    event.preventDefault();

    const getIdAndType = () => {
      if (clubId) return { type: 'clubId', id: clubId };
      if (establishmentId) return { type: 'establishmentId', id: establishmentId };
      return {};
    };

    const { type, id } = getIdAndType();

    const validateForm = () => {
      if (!eventDescription) return 'A descrição é obrigatória!';
      if (!clientList) return 'Insira o arquivo com os uId dos usuários.';
      return null;
    };

    const validationError = validateForm();
    if (validationError) {
      toast.error(validationError, { autoClose: 2500 });
      return;
    }

    const handleUserPoints = async (path, additionalPayload = {}) => {
      const payload = {
        filePath: path,
        description: eventDescription,
        ...additionalPayload,
        [type]: id,
      };
      await pointsExchangePartnerFiles(payload);
    };

    try {
      setLoadingAdd(true);

      const path = await uploadingFile(clientList);
      const additionalPayload =
        fromWhere === 'adminConfig' ? { establishmentId: '-TriiboWifi' } : {};
      await handleUserPoints(path, additionalPayload);

      toast.success('Pontos enviados com sucesso!');
      setTimeout(() => {
        setLoadingAdd(false);
        history.goBack();
      }, 2000);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Ocorreu um erro inesperado.';
      toast.error(errorMessage, { autoClose: 2500 });
      setLoadingAdd(false);
    }
  };

  const addEventByList = async (event) => {
    event.preventDefault();

    const getIdAndType = () => {
      if (clubId) return { type: 'clubId', id: clubId };
      if (establishmentId) return { type: 'establishmentId', id: establishmentId };
      return {};
    };

    const { type, id } = getIdAndType();

    const validateForm = () => {
      if (!eventDescription) return 'A descrição é obrigatória!';
      if (!usersList) return 'Adicione usuários na lista.';
      if (!value || value === 0) return 'Digite um valor maior que 0.';
      return null;
    };

    const validationError = validateForm();
    if (validationError) {
      toast.error(validationError, { autoClose: 2500 });
      return;
    }

    const handleUserPoints = async (additionalPayload = {}) => {
      const list = usersList.map((item) => item.uId);

      const payload = {
        [type]: id,
        description: eventDescription,
        usersList: [...list],
        value: value,
        ...additionalPayload,
      };

      await pointsExchangeUsersList(payload);
    };

    try {
      setLoadingAdd(true);

      const additionalPayload =
        fromWhere === 'adminConfig' ? { establishmentId: '-TriiboWifi' } : {};
      await handleUserPoints(additionalPayload);

      toast.success('Pontos enviados com sucesso!');
      setTimeout(() => {
        setLoadingAdd(false);
        history.goBack();
      }, 2000);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Ocorreu um erro inesperado.';
      toast.error(errorMessage, { autoClose: 2500 });
      setLoadingAdd(false);
    }
  };

  if (!previousUrl) {
    return <Redirect to={'/admin'} />;
  } else
    return (
      <div style={{ paddingBottom: '10rem' }}>
        <S.Container>
          <GoBackHeader />
          <HeaderTitle pageInfo="Gestão de Pontos" />
          <ToastContainer autoClose={2000} />
        </S.Container>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', marginBottom: '20px' }}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>Saldo disponível:</Typography>
          <Box sx={{ marginLeft: '10px' }}>
            {loadingBalancePoints ? (
              <Box sx={{ marginTop: '5px' }}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Typography variant={isMobile ? 'h6' : 'h5'}>
                <strong>{balancePoints ?? 0}</strong>
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', margin: '20px 0px', gap: '25px' }}
        >
          <Button
            variant="contained"
            onClick={() => setSendMode(1)}
            color={sendMode === 1 ? 'primary' : 'secondary'}
            sx={{
              transition: '1000ms',
              transform: sendMode === 1 ? 'scale(1.2)' : 'scale(0.9)',
              opacity: sendMode === 1 ? '1' : '0.25',
            }}
          >
            Envio por arquivo
          </Button>
          <Button
            variant="contained"
            onClick={() => setSendMode(2)}
            color={sendMode === 2 ? 'primary' : 'secondary'}
            sx={{
              transition: '1000ms',
              transform: sendMode === 2 ? 'scale(1.2)' : 'scale(0.9)',
              opacity: sendMode === 2 ? '1' : '0.25',
            }}
          >
            Envio unitário
          </Button>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper
            elevation={2}
            sx={{
              padding: '2rem 32px',
              margin: '0px 40px',
              width: '800px',
              height: 'max-content',
            }}
          >
            {sendMode === 1 && (
              <div>
                <FormControl fullWidth margin="normal">
                  <Box
                    sx={{
                      marginTop: '10px',
                    }}
                  >
                    <Typography variant="subtitle1">
                      Carregue um arquivo nos formatos .xls ou .csv para enviar pontos aos membros
                      do seu clube. Para simplificar, baixe o template disponível, edite-o conforme
                      necessário seguindo as orientações e faça o upload do arquivo no sistema.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <span>
                      <Typography variant="subtitle1">
                        <a href={xlsModel} download>
                          baixar template .xls
                        </a>{' '}
                        |{' '}
                        <a href={csvModel} download>
                          baixar template .csv
                        </a>
                      </Typography>
                    </span>
                    <span>
                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        color="secondary"
                        onClick={openHelper}
                      >
                        <Question size={30} weight="fill" />
                      </IconButton>
                    </span>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <input
                      id="thumbnailStore"
                      accept=".txt, .csv, .xls"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={fileChangedHandler}
                    />
                    <label
                      htmlFor="thumbnailStore"
                      style={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Button
                        sx={{ textTransform: 'capitalize', fontWeight: '550' }}
                        variant="contained"
                        color="secondary"
                        startIcon={<AddPhotoAlternate />}
                        onClick={handleFileButtonClick}
                      >
                        Adicionar Arquivo
                      </Button>
                      {clientList && (
                        <Box sx={{ fontSize: '14px' }}>
                          <b>Arquivo selecionado: </b>
                          {clientList.name}
                        </Box>
                      )}
                    </label>
                  </Box>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <div style={{ padding: '10px' }}>
                      <Typography
                        variant="body1"
                        style={{ paddingLeft: '0px', marginBottom: '10px' }}
                      >
                        • O arquivo deve ser enviado sem cabeçalho.
                      </Typography>
                      <Typography variant="body1" style={{ paddingLeft: '0px' }}>
                        • As colunas devem estar na seguinte ordem:
                      </Typography>
                      <Typography variant="body2">1. Chave de Identificação do Usuário</Typography>
                      <Typography variant="body2">
                        2. Motivo do Envio: Texto que será exibido na carteira do usuário no "Seu
                        Clube"(max de 80 caracteres).
                      </Typography>
                      <Typography variant="body2">
                        3. Data do Evento: Data do evento relacionado aos pontos (formato
                        AAAA-MM-DD).
                      </Typography>
                      <Typography variant="body2">
                        4. Valor: Quantidade de pontos a serem creditados (apenas números inteiros)
                      </Typography>
                    </div>
                  </Popover>

                  <TextField
                    fullWidth
                    required
                    multiline
                    type="text"
                    value={eventDescription}
                    id="eventDescription"
                    label="Nome do envio"
                    onChange={handleChange(setEventDescription)}
                    margin="normal"
                    aria-describedby="eventDescription-helper"
                  />
                  <FormHelperText id="eventDescription-helper" sx={{ pl: '0px' }}>
                    *O texto acima será exibido no relatório de envios. Utilize um texto único e
                    identificável para facilitar a organização e o acompanhamento.
                  </FormHelperText>
                </FormControl>

                <Button
                  sx={{
                    marginTop: '20px',
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: '550',
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={!loadingAdd && <Send />}
                  disabled={loadingAdd}
                  onClick={addEventByFile}
                >
                  {loadingAdd ? (
                    <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            )}
            {sendMode === 2 && (
              <div>
                <FormControl fullWidth margin="normal">
                  <TextField
                    fullWidth
                    onChange={handleChange(setValue)}
                    value={value}
                    type="number"
                    id="value"
                    required
                    label="Quantidade de Pontos"
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    required
                    multiline
                    type="text"
                    value={eventDescription}
                    id="eventDescription"
                    label="Descrição"
                    onChange={handleChange(setEventDescription)}
                    margin="normal"
                  />
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '17px',
                      }}
                    >
                      <FormControl disabled={loadingSearch} component="fieldset">
                        <RadioGroup
                          row
                          value={receiverType}
                          onChange={(event) => setReceiverType(event.target.value)}
                        >
                          <FormControlLabel
                            title="Usuário"
                            value="user"
                            label={
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                              >
                                Usuário
                              </Typography>
                            }
                            control={<Radio />}
                          />
                          <FormControlLabel
                            title="Clube"
                            value="club"
                            label={
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                              >
                                Clube
                              </Typography>
                            }
                            control={<Radio />}
                          />
                          <FormControlLabel
                            title="Estabelecimento"
                            value="establishment"
                            label={
                              <Typography
                                variant="subtitle1"
                                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
                              >
                                Estabelecimento
                              </Typography>
                            }
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        marginTop: '17px',
                      }}
                    >
                      {loadingSearchUser && <CircularProgress />}
                      {receiverType === 'user' && (
                        <RenderInputDynamic
                          fieldInfos={fieldKey}
                          inputValue={userSearch}
                          setInputValue={setUserSearch}
                          handleSubmit={searchUnitary}
                        />
                      )}
                      {receiverType === 'club' && <>rendewr de clube</>}
                      {receiverType === 'stablishment' && <>rendewr de establecimento</>}
                    </Grid>
                    {usersList.length > 0 && (
                      <Grid item xs={12} sx={{ marginTop: '25px' }}>
                        {usersList.map((item, index) => (
                          <Box
                            key={item.uId}
                            component="span"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                const tempUL = [...usersList];
                                tempUL.splice(index, 1);
                                setUsersList([...tempUL]);
                              }}
                            >
                              <Trash color="#da0000" />
                            </IconButton>
                            <Typography variant="body1" sx={{ pl: '0px' }}>
                              {item.cellPhone !== item.key
                                ? 'Chave: ' +
                                  item.key +
                                  ` || Telefone do usuário: ${cellPhoneMask2(item.cellPhone)}`
                                : `Telefone do usuário: ${cellPhoneMask2(item.cellPhone)}`}
                            </Typography>
                          </Box>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </FormControl>

                <Button
                  sx={{
                    marginTop: '20px',
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: '550',
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={!loadingAdd && <Send />}
                  disabled={loadingAdd || usersList.length === 0}
                  onClick={addEventByList}
                >
                  {loadingAdd ? (
                    <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            )}
          </Paper>
        </Box>
      </div>
    );
}
