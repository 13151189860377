import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../../cfac22';

export function apiListEstablishment(query, from, size) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/establishments/paginatedSearch`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: { payload: { query: query, from: from, size: size } },
      };

      axios(config)
        .then((response) => {
          resolve(response.data.establishments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
