import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export function getTrialStatus(uId) {
  return new Promise(function (resolve, reject) {
    authToken().then((token) => {
      const config = {
        method: 'get',
        url: `${cfac22('API_HOST_V2')}/users/stepsOnboarding/${uId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
