import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 2rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  @media (max-width: 780px) {
    padding: 1rem;
  }
`;

export const SearchBox = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
