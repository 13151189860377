import React, { useState, useEffect } from 'react';

import { downloadImage } from 'js/library/services/StorageManager';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

const EditPreview = ({ title, data }) => {
  const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    if (data.type === 'banner') {
      for (const key in data.images) {
        let storageInfo = data.images[key].split('/');

        downloadImage(storageInfo[0], storageInfo[1])
          .then((res) => {
            setCurrentImages((prevImages) => [...prevImages, res]);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (data.type === 'carousels') {
      let storageInfo = data.frameIcon.split('/');

      downloadImage(storageInfo[0], storageInfo[1])
        .then((res) => {
          setCurrentImages((prevImages) => [...prevImages, res]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (data.type === 'stripeBanner') {
      if (typeof data.images === 'object') {
        Object.keys(data.images).forEach((item) => {
          let storageInfo = data.images[item].split('/');

          downloadImage(storageInfo[0], storageInfo[1])
            .then((res) => {
              setCurrentImages((prevImages) => [...prevImages, res]);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    } else {
      let storageInfo = data.image.split('/');

      downloadImage(storageInfo[0], storageInfo[1])
        .then((res) => {
          setCurrentImages((prevImages) => [...prevImages, res]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  return (
    <S.Container>
      <D.TriiboH3>
        <strong>{title}</strong>
      </D.TriiboH3>

      <S.ListContainer>
        {data ? (
          <>
            <D.TriiboH4>
              {data.type === 'banner' || data.type === 'stripeBanner'
                ? 'Preview de imagens:'
                : 'Preview de imagem:'}
            </D.TriiboH4>
            {currentImages.map((element, index) => (
              <S.PreviewImgContainer key={index} isIcon={data.type === 'carousels'}>
                <S.PreviewImg key={index} src={element} alt={element} />
              </S.PreviewImgContainer>
            ))}
          </>
        ) : (
          <D.TriiboH4>Nenhuma imagem encontrada</D.TriiboH4>
        )}
      </S.ListContainer>
    </S.Container>
  );
};

export default EditPreview;
