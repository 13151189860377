import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import { getUserInfo } from '../../helpers';
import cfac22 from '../../cfac22';

export function updateEstablishmentApprovals(uId, establishmentId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (establishmentId !== null) {
      authToken(uId)
        .then((token) => {
          axios
            .patch(
              `${cfac22('API_HOST_V2')}/establishments/pending/${establishmentId}`,
              { payload },
              {
                headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
              }
            )
            .then((result) => {
              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.establishmentInfos.id = establishmentId;

              resolve(result.data.establishmentInfos);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

export function updateEstablishment(establishmentId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (establishmentId !== null) {
      const userInfo = getUserInfo();

      authToken(userInfo.uId)
        .then((token) => {
          axios
            .patch(
              `${cfac22('API_HOST_V2')}/establishments/${establishmentId}`,
              { payload },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            )
            .then((result) => {
              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.establishmentInfo = {
                id: establishmentId,
                ...result.data.establishmentInfo,
              };

              resolve(result.data.establishmentInfo);
            })
            .catch((error) => {
              console.log('***error', error);
              reject(error);
            });
        })
        .catch((error) => {
          console.log('***error', error);
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

export function createEstablishmentRequest(uid, data) {
  return new Promise((resolve, reject) => {
    authToken(uid).then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/establishmentRequest/createEstablishmentRequest`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          payload: data,
        },
      };

      axios(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
