import styled from 'styled-components';
import { Box, Button } from '@mui/material';

export const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  box-shadow: 24;
  padding: 40px;
  border: 1px solid;
  max-height: 90vh;
  overflow-y: auto;
`;

export const ButtonStyled = styled(Button)`
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
  padding: 10px;
`;
