import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { separateBase64String } from 'js/library/utils/helpers';

// STYLES
import { Box, Button, CircularProgress } from '@mui/material';

// COMPONENTS
import { ColorInformation } from './ComponentsEditCustomization/ColorInfomation';
import { ImagesInformation } from './ComponentsEditCustomization/ImagesInformation';
import { ImportantInformation } from './ComponentsEditCustomization/ImportantInformation';
import { PageStructure } from 'js/components/TriiboComponents/PageStructure/PageStructure';

// APIS
import { updateSegmentCustomization } from 'js/library/utils/API/updateSegmentCustomization';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

export const EditCustomization = () => {
  const location = useLocation();
  const navigate = useHistory();
  const { info, segments, clubeId, customizationsAlready } = location.state;

  // Estado para armazenar as imagens originais
  const [originalImages, setOriginalImages] = useState({
    featuredImageDesktop: info.featuredImageDesktop,
    featuredImageMobile: info.featuredImageMobile,
    logo: info.logo,
    pin: info.pin,
    stamp: info.stamp,
    stripeDesktop: info.stripeDesktop,
    stripeMobile: info.stripeMobile,
  });

  const [dataCustomization, setDataCustomization] = useState(info);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Atualiza as imagens originais sempre que o `dataCustomization` mudar
    setOriginalImages({
      featuredImageDesktop: dataCustomization.featuredImageDesktop,
      featuredImageMobile: dataCustomization.featuredImageMobile,
      logo: dataCustomization.logo,
      pin: dataCustomization.pin,
      stamp: dataCustomization.stamp,
      stripeDesktop: dataCustomization.stripeDesktop,
      stripeMobile: dataCustomization.stripeMobile,
    });
  }, [dataCustomization]);

  // Função para fazer o upload das imagens
  const uploadImgs = async (image, field) => {
    const firstPart = image.split('/');
    const [typeImg] = firstPart[1].split(';');
    const newImage = separateBase64String(image);

    const response = await uploadFiles(`segment-customization/${dataCustomization.segmentId}`, {
      mimeType: newImage.mimeType,
      fileName: `${field}_${dataCustomization.segmentId}_${typeImg}`,
      buffer: newImage.buffer,
    });

    return response;

    /*   setDataCustomization((prevCustomization) => ({
      ...prevCustomization,
      [field]: response,
    })); */
  };

  const saveCustomization = async () => {
    setLoading(true);
    try {
      // Verifica se todos os campos foram preenchidos
      const isFullyPopulated = Object.values(dataCustomization).every(
        (value) => value !== '' && value !== null && value !== undefined
      );

      if (!isFullyPopulated) {
        toast.warning('Todos os campos e imagens devem ser preenchidos.');
        return;
      }

      toast.info('Aguarde, estamos processando suas imagens, isso pode levar algum tempo.');

      // Armazena o resultado das customizações antes de modificá-las
      const originalDataCustomization = { ...dataCustomization };

      // Faz o upload das imagens e atualiza os campos correspondentes
      const uploadPromises = Object.entries(dataCustomization)
        .filter(([field, value]) => typeof value === 'string' && value.includes('base64'))
        .map(([field, value]) =>
          uploadImgs(value, field).then((result) => {
            // Coloca o resultado do upload no campo correspondente
            originalDataCustomization[field] = result;
          })
        );

      // Espera todas as promessas de upload de imagens serem resolvidas
      await Promise.all(uploadPromises);
      console.log(originalDataCustomization);

      // Atualiza a customização com os novos valores
      await updateSegmentCustomization(originalDataCustomization);

      toast.success('Customização salva com sucesso!');
      navigate.push({
        pathname: `/admin/seu-clube/customizacao-segmentos/${clubeId}`,
      });
    } catch (err) {
      console.error(err);
      toast.error('Erro ao salvar customização. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageStructure title={`Editar customização (${dataCustomization.name})`}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <ImportantInformation
          segments={segments}
          dataCustomization={dataCustomization}
          setDataCustomization={setDataCustomization}
        />

        <ColorInformation
          dataCustomization={dataCustomization}
          setDataCustomization={setDataCustomization}
        />

        <ImagesInformation
          dataCustomization={dataCustomization}
          setDataCustomization={setDataCustomization}
        />

        <Box sx={{ margin: '0 auto' }}>
          <Button
            color="primary"
            disabled={loading}
            onClick={saveCustomization}
            variant="contained"
            sx={{ fontWeight: 700, letterSpacing: '2px', minWidth: '10rem' }}
          >
            {loading ? (
              <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Box>
      </div>
    </PageStructure>
  );
};
