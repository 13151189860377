import React from 'react';

import * as S from './styles';

import CropperTool from './CropperTool';

const CropperModal = (props) => {
  const { open, setOpen, img, setImg, ratio, setIsActiveOverflow, allowFreeCrop } = props;

  return (
    <S.ModalContainer show={open}>
      <S.ModalBody>
        <S.ModalLabelContainer>
          <S.ModalTitle>Editar imagem</S.ModalTitle>
          <S.ModalSubtitle>
            Uma foto de qualidade ajuda as pessoas a reconhecerem o seu negócio
          </S.ModalSubtitle>
        </S.ModalLabelContainer>
        <CropperTool
          setIsActiveOverflow={setIsActiveOverflow}
          setOpen={setOpen}
          img={img}
          setImg={setImg}
          ratio={ratio}
          allowFreeCrop={allowFreeCrop}
        />
      </S.ModalBody>
      <S.ModalBackground
        onClick={(e) => {
          e.preventDefault();
          setImg('');
          setOpen(false);
        }}
      />
    </S.ModalContainer>
  );
};

export default CropperModal;
