import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../cfac22';

export function getFeedContent(query) {
  const queryContent = query ? `?find=${query}` : '';
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'get',
        url: `${cfac22('API_HOST_V2')}/mapfre/feed/` + queryContent,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
