import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export const getLpById = async (lpId) => {
  const token = await authToken();
  const endpoint = `${cfac22('API_HOST_V2')}/campaignLandpage/getLp/?id=${lpId}`;
  const headers = {
    headers: {
      authorization: `Bearer ${token}`,
      'Full-Url': window.location.href,
    },
  };

  try {
    const response = (await axios.get(endpoint, headers)).data;
    if (response?.success) return response;
  } catch (error) {
    return error.response.data.error;
  }
};
