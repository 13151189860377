import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { useHistory } from 'react-router-dom';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { ContainerButtons, ContainerFormSegment, ContentSwitchSegment } from './segmentStyles';
import { MdArrowBackIosNew } from 'react-icons/md';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

// APIS
import { createUserSegment } from 'js/library/utils/API/Segmentation/createUserSegment';
import { createResourceSegment } from 'js/library/utils/API/Segmentation/createResourceSegment';
import { getResourceSegment } from 'js/library/utils/API/Segmentation/getResourceSegment';
import { getSegmentById } from 'js/library/utils/API/Segmentation/getSegmentById';
import { updateUserSegment } from 'js/library/utils/API/Segmentation/updateUserSegment';
import { updateResourceSegment } from 'js/library/utils/API/Segmentation/updateResourceSegment';

export function AddSegmentation() {
  const history = useHistory();
  const partnerId = localStorage.getItem('partnerIdClube');
  const isEdit = document.location.pathname.split('/')[4];
  const id = document.location.pathname.split('/')[5];

  const [loadingFormEdit, setLoadingFormEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [segmentationType, setSegmentationType] = useState(null);
  const [data, setData] = useState({
    active: true,
    name: '',
    query: '',
    partnerId: id,
  });

  useEffect(() => {
    switch (isEdit) {
      case 'editar-segmento-pessoa':
        getSegmentById(id)
          .then((res) => {
            setData({
              ...data,
              active: res.active,
              name: res.name,
              query: res.query,
              partnerId: res.partnerId,
            });
            setSegmentationType('pessoa');
            setLoadingFormEdit(false);
          })
          .catch((err) => {
            console.error(err);
            toast.error('Algo não funcionou corretamente.');
          });
        break;
      case 'editar-segmento-recurso':
        getResourceSegment(id).then((res) => {
          setData({
            ...data,
            active: res.active,
            name: res.name,
            query: res.query,
            partnerId: res.partnerId,
          });
        });
        setSegmentationType('recurso');
        setLoadingFormEdit(false);
        break;
      default:
        setLoadingFormEdit(false);
        break;
    }
  }, [isEdit]);

  const handleChangeSegmentation = (e) => {
    setSegmentationType(e.target.value);
  };

  const createSegmentation = async () => {
    setLoading(true);
    try {
      switch (segmentationType) {
        case 'pessoa':
          await createUserSegment(data);
          toast.success('Segmento criado com sucesso.', { autoClose: 2000 });
          history.goBack();
          break;
        case 'recurso':
          const payload = {
            active: data.active,
            name: data.name,
            query: data.query,
            partnerId: {
              [data.partnerId]: true,
            },
          };
          await createResourceSegment(payload);
          toast.success('Segmento criado com sucesso.', { autoClose: 2000 });
          history.goBack();
          break;
        default:
          break;
      }
    } catch (err) {
      toast.error('Algo não funcionou corretamente.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateSegmentation = async () => {
    setLoading(true);
    try {
      switch (segmentationType) {
        case 'pessoa':
          const payload = {
            ...data,
            partnerId: partnerId,
          };
          await updateUserSegment(id, payload);
          toast.success('Segmento editado com sucesso.', { autoClose: 2000 });
          history.goBack();
          break;
        case 'recurso':
          const payloadResource = {
            ...data,
            partnerId: {
              [partnerId]: true,
            },
          };

          await updateResourceSegment(id, payloadResource);
          toast.success('Segmento editado com sucesso.', { autoClose: 2000 });
          history.goBack();
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
      toast.error(err.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Container>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        {isEdit === 'adicionar-segmento' ? 'Criar segmento' : 'Editar segmento'}
      </D.TriiboH1>
      <ContainerFormSegment>
        {loadingFormEdit ? (
          <span style={{ margin: '0 auto' }}>
            <CircularProgress size={100} thickness={5} />
          </span>
        ) : (
          <>
            <ContainerButtons style={{ gap: 0, marginBottom: '1rem' }}>
              <FormLabel id="demo-radio-buttons-group-label">Tipo de segmentação</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={handleChangeSegmentation}
                value={segmentationType}
              >
                {isEdit === 'adicionar-segmento' ? (
                  <>
                    <FormControlLabel value="pessoa" control={<Radio />} label="Pessoa" />
                    <FormControlLabel value="recurso" control={<Radio />} label="Recurso" />
                  </>
                ) : segmentationType === 'pessoa' ? (
                  <FormControlLabel value="pessoa" control={<Radio />} label="Pessoa" />
                ) : (
                  <FormControlLabel value="recurso" control={<Radio />} label="Recurso" />
                )}
              </RadioGroup>
            </ContainerButtons>
            <TextField
              id="outlined-basic"
              label="Nome"
              required
              onChange={(e) => setData({ ...data, name: e.target.value })}
              variant="outlined"
              value={data.name}
            />

            <TextField
              id="outlined-basic"
              label="Query (Opcional)"
              onChange={(e) => setData({ ...data, query: e.target.value })}
              variant="outlined"
              value={data.query}
            />

            <ContentSwitchSegment style={{ justifyContent: 'center', margin: '1rem' }}>
              Inativo
              <Switch
                checked={data.active}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#08BAD0"
                onChange={() => setData({ ...data, active: !data.active })}
              />
              Ativo
            </ContentSwitchSegment>
            <Button
              disabled={segmentationType === null || data.name === '' || data.partnerId === ''}
              onClick={isEdit === 'adicionar-segmento' ? createSegmentation : updateSegmentation}
              variant="contained"
            >
              {loading ? (
                <CircularProgress size={25} thickness={5} />
              ) : isEdit === 'adicionar-segmento' ? (
                'Criar segmento'
              ) : (
                'Editar segmento'
              )}
            </Button>
          </>
        )}
      </ContainerFormSegment>
    </S.Container>
  );
}
