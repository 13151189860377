import React, { useState } from 'react';

import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';

import * as S from './styles';

import {
  cellPhoneMaskwithout55,
  maskCEP,
  maskCPF2,
  maskCnpj,
  randomizeNumbersString,
} from 'js/library/utils/helpers';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import InputMask from 'react-input-mask';

import { Checkbox, Button, CircularProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { addAccessList } from 'js/library/utils/API/apiAddAccessList';

const RegisterUserModalForm = (props) => {
  const { formFields, clubForms, clubInfo, formNotFound, modalClose } = props;
  const [clientForm, setClientForm] = useState({});
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [cellphone, setCellPhone] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [currentCountry, setCurrentCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const formatUserList = (formValues) => {
    let userInfoObj = {};
    let contactList = [];
    let documentList = [];
    let addressList = {};

    clubForms.formFields.forEach((field, index) => {
      switch (field.type) {
        case 'contactList':
          if (formValues[field.fieldName]) {
            contactList.push({
              type: field.fieldName,
              value: formValues[field.fieldName].toString(),
            });
          }
          userInfoObj.contactList = contactList;
          break;

        case 'documentList':
          if (formValues[field.fieldName]) {
            if (field.fieldType === 'cpf') {
              documentList.push({
                type: field.fieldName,
                value: formValues[field.fieldName].toString().replace(/\D+/g, ''),
              });
            } else {
              documentList.push({
                type: field.fieldName,
                value: formValues[field.fieldName].toString().replace(/\D+/g, ''),
              });
            }
            userInfoObj.documentList = documentList;
          }

          break;

        case 'addressList':
          if (formValues[field.fieldName]) {
            addressList[field.fieldName] = formValues[field.fieldName];

            userInfoObj.addressList = addressList;
          }
          break;

        case 'others':
          if (formValues[field.fieldName]) {
            if (field.inputType === 'date') {
              userInfoObj[field.fieldName] = new Date(formValues[field.fieldName]).getTime();
            } else {
              userInfoObj[field.fieldName] = formValues[field.fieldName];
            }
          }
          break;
        case 'root':
          if (formValues[field.fieldName]) {
            userInfoObj[field.fieldName] = formValues[field.fieldName];
          }
          break;
        default:
          break;
      }
    });

    userInfoObj.partnerId = clubInfo.partnerId;
    userInfoObj.partnerName = clubInfo.clubeId;

    const data = {
      partnerInfo: { ...userInfoObj },
      clubeId: clubInfo.clubeId,
      token:
        clubForms.tokenType === 'cpf'
          ? formValues[clubForms.tokenType].replace(/\D+/g, '')
          : formValues[clubForms.tokenType],
      cellphone: formValues.cellPhone,
      name: formValues.name,
    };
    return data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let clientList = [clientForm];

    const filteredUserInfo = clientList.filter((row) => {
      if (row[clubForms.tokenType]) {
        return row;
      }
    }); // Filter para que venha apenas os usuários que possuem tokenType informados.

    if (filteredUserInfo.length === 0) {
      setLoading(false);
      return toast.error(
        `O ${
          clubForms.tokenType === 'cellPhone' ? 'número de celular' : clubForms.tokenType
        } é obrigatório`
      );
    }

    if (
      (!filteredUserInfo[0].cellPhone || filteredUserInfo[0].cellPhone.length < 14) &&
      formFields.filter((item) => item.fieldType === 'cellPhone').length === 1
    ) {
      setLoading(false);
      return toast.error(`O número de celular é obrigatório`);
    }

    if (clientForm.cpf && clientForm.cpf.length !== 14) {
      setLoading(false);
      return toast.error('CPF inválido!');
    }

    const formattedUsers = filteredUserInfo.map((client, index) => {
      return formatUserList(client);
    });

    const userTokenVal = filteredUserInfo[0];

    const token =
      typeof userTokenVal === 'object'
        ? userTokenVal[Object.keys(filteredUserInfo[0])]
        : userTokenVal;

    const removeMask = (value) => {
      if (value.includes('@')) {
        // Se for email, aceita os caracteres
        return value.replace(/[^a-zA-Z0-9@()_.]/g, '');
      } else {
        return value.replace(/[^0-9+]/g, '');
      }
    };

    if (location.pathname.includes('relacionamento/cadastro-usuarios')) {
      try {
        RegisterUsersBatch_v2(formattedUsers)
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              toast.success(
                modalClose
                  ? 'Usuários cadastrados com sucesso! Em breve você poderá consultá-los na aba "Editar lista de permissões".'
                  : 'Usuários cadastrados com sucesso! Em breve você poderá consultá-los na aba "Ver meus clientes".'
              );
              setClientForm({});
              modalClose && modalClose();
            }
          })
          .catch((error) => {
            toast.error('Erro ao cadastrar usuários.');
            setLoading(false);
          });
      } catch (error) {
        toast.error('Ops, usuários não foram cadastrados.');
        setLoading(false);
      }
    } else {
      try {
        const payload = {
          usersPartnersData: [
            {
              partnerInfo: {
                partnerId: clubInfo.partnerId,
              },
              userPartnerId: removeMask(token),
            },
          ],
        };
        const response = await addAccessList(payload);

        if (response.message === 'Usuários adicionados com sucesso') {
          setLoading(false);
          toast.success('Usuário adicionado com sucesso à lista de acesso!');
          setClientForm({});
          modalClose && modalClose();
        } else {
          setLoading(false);
          toast.error('Erro ao adicionar usuário à lista de acesso.');
        }
      } catch (error) {
        setLoading(false);
        console.error('Error during API call:', error);
        toast.error('Erro ao adicionar usuário à lista de acesso.');
      }
    }
  };

  function handleChange(e, key, mask) {
    let cloneClientForm = cloneDeep(clientForm);

    if (mask === 'cellPhone') {
      cloneClientForm[key] = cellPhoneMaskwithout55(e.target.value);
    } else if (mask === 'cep') {
      cloneClientForm[key] = maskCEP(e.target.value);
    } else if (mask === 'cpf') {
      cloneClientForm[key] = maskCPF2(e.target.value);
    } else if (mask === 'cnpj') {
      cloneClientForm[key] = maskCnpj(e.target.value);
    } else {
      cloneClientForm[key] = e.target.value;
    }
    setClientForm(cloneClientForm);
  }

  const handleChangeCellphone = (event, key) => {
    let cloneClientForm = cloneDeep(clientForm);

    setCellPhone(event.target.value);

    if (event.target.value.length) {
      cloneClientForm[key] = '+' + (currentDDD + event.target.value).replace(/\D/g, '');
    } else if (!event.target.value.length) {
      delete cloneClientForm[key];
    }
    setClientForm(cloneClientForm);
  };

  function handleChangeCheck(key) {
    let cloneClientForm = cloneDeep(clientForm);

    if (!cloneClientForm[key]) {
      cloneClientForm[key] = true;
    } else if (cloneClientForm[key]) {
      cloneClientForm[key] = false;
    }
    setClientForm(cloneClientForm);
  }

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setCellPhone('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setCurrentCountry(e.target.value);
  };

  function fieldGenerator(field, index) {
    if (field.inputType === 'text') {
      if (field.fieldType === 'cellPhone') {
        return (
          <React.Fragment key={`fragment-${index}`}>
            {field.label}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
                border: ' 1px solid #afafaf',
                borderRadius: '10px',
                height: '42px',
                width: '100%',
              }}
            >
              <select
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  height: '100%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '17px',
                  color: '#383838d6',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
                value={currentCountry}
                onChange={handleCountryChange}
              >
                {phoneMaskArray.map((country, index) => (
                  <option
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.emoji} {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
              <InputMask
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  alignContent: 'center',
                  height: '100%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '17px',
                  color: '#383838d6',
                  borderRadius: '0px',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '117px',
                }}
                mask={inputMask}
                value={cellphone}
                placeholder={randomizeNumbersString(inputMask)}
                onChange={(e) => handleChangeCellphone(e, field.fieldName)}
              />
            </div>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={`fragment-${index}`}>
          {field.label}
          <S.ModalTextArea
            placeholder={field.label}
            value={clientForm[field.fieldName]}
            rows={1}
            type="text"
            onChange={(e) => {
              handleChange(e, field.fieldName, field.fieldType);
            }}
          />
        </React.Fragment>
      );
    } else if (field.inputType === 'email') {
      return (
        <React.Fragment key={`fragment-${index}`}>
          {field.label}
          <S.ModalTextArea
            placeholder={field.label}
            value={clientForm[field.fieldName]}
            rows={1}
            type="email"
            onChange={(e) => {
              handleChange(e, field.fieldName, field.fieldType);
            }}
          />
        </React.Fragment>
      );
    } else if (field.inputType === 'checkbox') {
      return (
        <React.Fragment key={`fragment-${index}`}>
          {field.label}
          <Checkbox
            checked={clientForm[field.fieldName]}
            onChange={() => handleChangeCheck(field.fieldName)}
          />
        </React.Fragment>
      );
    }
  }

  if (formNotFound) {
    return (
      <S.ModalMessageContainer>
        {'Este clube ainda não está disponível para cadastro.'}
      </S.ModalMessageContainer>
    );
  }

  if (!formFields) {
    return (
      <S.ModalMessageContainer>
        {'Buscando formulário...'}
        <br />
        <CircularProgress />
      </S.ModalMessageContainer>
    );
  }

  return (
    <S.RegisterUnitForm
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      {formFields.map((form, index) => fieldGenerator(form, index))}{' '}
      {/* map para renderizar os campos do formulário */}
      <Button type="submit" variant="contained" color="primary" style={{ width: '6rem' }}>
        {loading ? <CircularProgress size="1.6rem" style={{ color: '#fff' }} /> : 'Cadastrar'}
      </Button>
    </S.RegisterUnitForm>
  );
};

export default RegisterUserModalForm;
