import dispatcher from 'js/core/dispatcher';

import { updateOrg } from '../../library/utils/API/updateOrg';
import { getFileNameFromStorageURL, getUserInfo } from '../../library/utils/helpers';

export const organizationInformationAction = (dispatch, stateData) => {
  const {
    coverImageFile,
    oldCoverImage,
    oldThumbnail,
    thumbnailFile,
    oldLogoImage,
    logoImageFile,
  } = stateData;

  const orgID = stateData.orgID;

  const { uId } = getUserInfo();

  const newInfo = {
    orgID, // required - const mode - check if has
    accessMethod: stateData.accessMethod,
    bannerImage: stateData.bannerImage,
    coverImage: stateData.coverImageFile
      ? 'organizations/' + getFileNameFromStorageURL(coverImageFile, 'organizations')
      : oldCoverImage,
    description: stateData.description,
    lockdown: stateData.lockdown,
    thumbnail: stateData.thumbnailFile
      ? 'organizations/' + getFileNameFromStorageURL(thumbnailFile, 'organizations')
      : oldThumbnail,
    logoImage: stateData.logoImageFile
      ? 'organizations/' + getFileNameFromStorageURL(logoImageFile, 'organizations')
      : oldLogoImage,
    name: stateData.name,
    owner: stateData.owner || uId,
    tags: stateData.tags.length > 0 ? stateData.tags : ['org'],
    type: stateData.type,
    visible: stateData.visible,
  };

  return dispatcher(
    dispatch,
    'ORGANIZATION_INFORMATION',
    updateOrg(
      uId, // required
      orgID, // required - const mode - check if has
      newInfo, // org info
      stateData.coverImage,
      coverImageFile,
      oldCoverImage,
      stateData.thumbnail,
      thumbnailFile,
      oldThumbnail,
      logoImageFile,
      stateData.logoImage,
      oldLogoImage
    )
  );
};
