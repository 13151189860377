import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import * as E from 'js/components/DesignSystem/Table/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ButtonDetails } from './styles';

// ICONES
import { MdArrowBackIosNew } from 'react-icons/md';
import AddIcon from '@mui/icons-material/Add';

// COMPONENTES
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

// APIS
import { getMovementGroupsBySender } from 'js/library/utils/API/Points/getMovementGroupsBySender';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { getUserInfo } from 'js/library/utils/helpers';
import { getBalance_v1 } from 'js/library/utils/API/Points/getBalance_v1';

export default function DataTablePoints({ clubId, establishmentId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [fromWhere, setFromWhere] = useState('');
  const [contractIdClub, setContractIdClub] = useState('');
  const [clubeData, setClubeData] = useState({});
  const [contractIdEstablishment, setContractIdEstablishment] = useState('');
  const [balancePoints, setBalancePoints] = useState(0);
  const [loadingBalancePoints, setLoadingBalancePoints] = useState(false);

  const uId = getUserInfo().uId;

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  const handleChangePage = (e, p) => {
    _DATA.jump(p);
    setPage(p);
  };

  const history = useHistory();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(600));

  const column = [
    { heading: 'Data da solicitação', value: 'creationDate' },
    { heading: 'Pontos solicitados', value: 'requestedPoints' },
    { heading: 'Pontos enviados', value: 'totalSended' },
    { heading: 'Total de usuários', value: 'totalUserQuantity' },
    { heading: 'Usuários com sucesso', value: 'usersProcessedWithSuccess' },
    { heading: 'Usuários com erro', value: 'usersProcessedWithError' },
    { heading: 'Nome do Arquivo', value: 'isUnitary' },
  ];

  const tranformData = (data) => {
    const filteredData = data.filter((item) => {
      return item;
    });

    return filteredData.map((item) => {
      const hasFilePath = !!item.filePath;
      return {
        ...item,
        creationDate: new Date(item.creationDate).toLocaleDateString('pt-BR'),
        updateDate: new Date(item?.updateDate).toLocaleDateString('pt-BR'),
        isUnitary: hasFilePath
          ? item.filePath.split('/')[1] ?? item.filePath
          : item?.isUnitary
          ? 'envio unitário'
          : 'envio multiplo',
        requestedPoints: item.transactionTotalPoints ?? item.value,
        totalSended: item.totalSended,
        resumedDescription:
          item.description.length > 50
            ? `${item.description.substring(0, 50)}...`
            : item.description,
        status:
          item.status === 'pending'
            ? 'Pendente'
            : item.status === 'processing'
            ? 'Processando'
            : item.status === 'processed'
            ? 'Processado'
            : 'Processado com erro',
      };
    });
  };

  let type;
  let id;

  if (clubId) {
    type = 'clubId';
    id = clubId;
  } else if (establishmentId) {
    type = 'establishmentId';
    id = establishmentId;
  } else {
    type = 'establishmentId';
    id = '-TriiboWifi';
  }

  const url = window.location.href;

  useEffect(() => {
    if (url.includes('estabelecimento')) {
      setFromWhere('estabelecimento');
    } else if (url.includes('seu-clube')) {
      setFromWhere('seuClube');
    } else {
      setFromWhere('adminConfig');
    }
  }, [url]);

  useEffect(() => {
    if (clubId) {
      getSeuClube(clubId).then((res) => {
        setContractIdClub(res.contractId);
        setClubeData(res);
      });
    }

    if (establishmentId) {
      getEstablishmentId(establishmentId).then((res) => {
        setContractIdEstablishment(res.contractId);
      });
    }
  }, [clubId, establishmentId]);

  useEffect(() => {
    // é o contractId do estabelecimento triiboWifi + @contrato-triibo,com,br
    let triiboId = '';

    if (fromWhere === 'adminConfig') {
      triiboId = '-LEWO0lc274ebMRbuFnG@contrato-triibo,com,br';
    } else if (fromWhere === 'estabelecimento' && contractIdEstablishment !== '') {
      triiboId = `${contractIdEstablishment}@contrato-triibo.com.br`;
    } else if (fromWhere === 'seuClube' && contractIdClub !== '') {
      triiboId = `${contractIdClub}@contrato-triibo.com.br`;
    }

    setLoadingBalancePoints(true);

    if (triiboId !== '') {
      getBalance_v1(uId, triiboId)
        .then((res) => {
          setBalancePoints(res.balance.consolidateBalance?.total);
        })
        .catch((e) => {
          console.error('Erro getBalance: ', e);
          setLoadingBalancePoints(false);
        })
        .finally(() => {
          setLoadingBalancePoints(false);
        });
    }
  }, [fromWhere, contractIdClub, contractIdEstablishment]);

  // trás os primeiros 20 registros
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMovementGroupsBySender(type, id, 0, 20);
        console.log('response', response);

        setData(tranformData(response));
      } catch (error) {
        console.error('Ocorreu um erro na requisição:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // paginação: trás mais 20 quando o usuário clica na ultima página da tabela
  useEffect(() => {
    if (count === page && data.length >= 20) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await getMovementGroupsBySender(type, id, data.length, 20);

          setData((prev) => [...prev, ...tranformData(response)]);
          setLoading(false);
        } catch (error) {
          console.error('Ocorreu um erro na requisição:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [count === page]);

  const handleDetails = (selected) => {
    history.push({
      pathname: `/admin/configuracoes/gestao-pontos/detalhes/${selected.id}`,
      state: selected,
    });
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <E.TableTH>Detalhes</E.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <E.TableTD>
              <div style={{ marginLeft: '15px' }}>
                <Tooltip
                  title={childrens.status === 'Pendente' ? 'Pendente' : 'Ver detalhes'}
                  placement="top"
                >
                  <span
                    style={{ cursor: childrens.status === 'Pendente' ? 'not-allowed' : 'pointer' }}
                  >
                    <ButtonDetails
                      variant="contained"
                      disabled={childrens.status === 'Pendente'}
                      onClick={() => handleDetails(childrens)}
                    >
                      <AddIcon size={10} />
                    </ButtonDetails>
                  </span>
                </Tooltip>
              </div>
            </E.TableTD>
          </>
        ),
    };
  };

  const handleRedirect = () => {
    localStorage.setItem('previousUrlPoints', window.location.pathname);
    history.push({
      pathname: `/admin/configuracoes/gestao-pontos/adicionar-pontos`,
      state: {
        clubId: clubId,
        contractIdClub: contractIdClub,
        clubeData: clubeData,
        establishmentId: establishmentId,
        contractIdEstablishment: contractIdEstablishment,
      },
    });
  };

  return (
    <S.Container style={{ paddingBottom: '200px' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        Gestão de Pontos
      </D.TriiboH1>

      <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
        <Typography variant={isMobile ? 'subtitle1' : 'h6'}>Saldo disponível:</Typography>
        <Box sx={{ marginLeft: '10px' }}>
          {loadingBalancePoints ? (
            <Box sx={{ marginTop: '5px' }}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
              <strong>{balancePoints ?? 0}</strong>
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ textTransform: 'capitalize', fontWeight: '550' }}
          startIcon={<AddIcon size={10} />}
          variant="contained"
          onClick={handleRedirect}
        >
          Adicionar evento
        </Button>
      </Box>

      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <CustomButtonsTable
                data={_DATA.currentData()}
                column={column}
                perPage={10}
                componentReceiver={componentReceiver}
                isPagination
              />
              <Pagination
                sx={{ margin: '0 auto' }}
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                color="secondary"
                onChange={handleChangePage}
              />
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '40px 0px' }}>
              <strong>Nenhum resultado encontrado.</strong>
            </Box>
          )}
        </>
      )}
    </S.Container>
  );
}
