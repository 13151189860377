import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import * as D from 'js/components/DesignSystem/styles';
import * as S from 'js/components/Establishment/EstablishmentSteps/styles';

import currentEstActionsAction from 'js/core/actions/currentEstActions';
import setEst from 'js/library/services/currentEstSetter';
import registerEstToLocal from 'js/library/services/registerEst';
import registerEstablishmentActionsAction from 'js/core/actions/authActionsRegisterEstab';

import {
  getBase64FromUrl,
  separateBase64String,
  compareDatesFromStr,
  getUserInfo,
} from 'js/library/utils/helpers';

import { Box, CircularProgress } from '@mui/material';

import { downloadImage } from 'js/library/services/StorageManager';

import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

import EstablishmentEditMenu from './EstablishmentSteps/EstablishmentEditMenu';

import StepOne from './EstablishmentSteps/StepOne';
import StepTwo from './EstablishmentSteps/StepTwo';
import StepThree from './EstablishmentSteps/StepThree';
import StepFour from './EstablishmentSteps/StepFour';
import { GoBackHeader } from '../Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';
import {
  createEstablishmentRequest,
  updateEstablishment,
} from 'js/library/utils/API/Establishment/apiUpdateEstablishment';
import localStorage from 'redux-persist/es/storage';
import { getEstablishmentChangesRequest } from 'js/library/utils/API/Curation/getEstablishmentChangesRequest';

const EstablishmentEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let cellphone = useSelector((state) => state.phoneInputModel);

  const [formEdit, setFormEdit] = useState({
    id: props.match.params.id ? props.match.params.id : '',
    idGestor: props.location.state.idGestor ? props.location.state.idGestor : '',
    nome: props.location.state.nome ? props.location.state.nome : '',
    // keywords: [],
    descricao: props.location.state.descricao ? props.location.state.descricao : '',
    tags: props.location.state.tags ? props.location.state.tags : [],
    dataInclusao: props.location.state.dataInclusao
      ? props.location.state.dataInclusao
      : Date.now(), // + 31536000000,
    dataAlteracao: Date.now(), // + 31536000000,
    endereco: props.location.state.endereco ? props.location.state.endereco : 'Brasil',
    lat: props.location.state.lat ? props.location.state.lat : 0,
    long: props.location.state.long ? props.location.state.long : 0,
    complemento: props.location.state.complemento ? props.location.state.complemento : '',
    site: props.location.state.site ? props.location.state.site : '',
    email: props.location.state.email ? props.location.state.email : '',
    contatos: props.location.state.contatos ? props.location.state.contatos : [],
    fotoThumb: props.location.state.fotoThumb ? props.location.state.fotoThumb : '',
    listaFotos: props.location.state.listaFotos ? props.location.state.listaFotos : [],
    funcionamento: props.location.state.funcionamento
      ? props.location.state.funcionamento
      : [
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: true,
            horarioFim: '18:00',
            horarioInicio: '09:00',
          },
          {
            ativo: false,
          },
          {
            ativo: false,
          },
        ],
    isPublished: props.location.state.isPublished ? props.location.state.isPublished : false,
    enableDate: props.location.state.enableDate ? props.location.state.enableDate : Date.now(), // + 31536000000,
    disableDate: props.location.state.disableDate
      ? props.location.state.disableDate
      : Date.now() + 31556926 * 1000 * 20, //TODO TRATAR DAS DATAS DEPOIS
    contatoComercial: props.location.state.contatoComercial
      ? props.location.state.contatoComercial
      : [
          {
            type: 'telefone',
            value: '',
          },
        ],
    contatoTriibo: props.location.state.contatoTriibo ? props.location.state.contatoTriibo : [],
    horarios: props.location.state.horarios
      ? props.location.state.horarios
      : [
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'aberto',
            turnos: [
              {
                fim: '18:00',
                inicio: '09:00',
              },
            ],
          },
          {
            tipo: 'fechado',
          },
          {
            tipo: 'fechado',
          },
        ],
    redesSociais: props.location.state.redesSociais ? props.location.state.redesSociais : [],
    cep: props.location.state.cep ? props.location.state.cep : '',
    logradouro: props.location.state.logradouro ? props.location.state.logradouro : '',
    cidade: props.location.state.cidade ? props.location.state.cidade : '',
    estado: props.location.state.estado ? props.location.state.estado : '',
    bairro: props.location.state.bairro ? props.location.state.bairro : '',
    numero: props.location.state.numero ? props.location.state.numero : '',
  });
  let [estThumb, setEstThumb] = useState('');
  let [galleryArr, setGalleryArr] = useState([]);

  const [origin] = useState({ ...props.location.state });
  const [currentScreen, setCurrentScreen] = useState(0);
  const [progressOne, setProgressOne] = useState(100);
  const [progressTwo, setProgressTwo] = useState(100);
  const [progressThree, setProgressThree] = useState(100);
  const [adminForm, setAdminForm] = useState({
    isPublished: props.location.state.isPublished ? props.location.state.isPublished : false,
    signatureId: props.location.state.signatureId ? props.location.state.signatureId : '',
    keyWordsSet: props?.location?.state?.keyWordsSet ? props?.location?.state?.keyWordsSet : [],
  });
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [dataEstApprovals, setDataEstApprovals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getEstablishmentChangesRequest(formEdit.id);
        const data = result.response;

        const dataApprovals = data.establishmentApprovals;
        let estApprovals;

        // Verifica se dataApprovals está definido e não vazio
        if (dataApprovals && Object.keys(dataApprovals).length > 0) {
          // Pega o primeiro elemento do objeto dataApprovals
          const firstApprovalKey = Object.keys(dataApprovals)[0];
          estApprovals = dataApprovals[firstApprovalKey];
        }

        setDataEstApprovals(estApprovals);
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
    };

    fetchData();
  }, [formEdit.id]);

  useEffect(() => {
    downloadImage('estabelecimento', props.location.state.fotoThumb)
      .then((downloadedImage) => {
        setEstThumb(downloadedImage);
      })
      .catch(() => {
        setEstThumb('');
      });

    let loadedPhotoList = [];

    if (props.location.state.listaFotos) {
      props.location.state.listaFotos.map((item, i) =>
        downloadImage('estabelecimento', item)
          .then((downloadedImage) => {
            loadedPhotoList.push(downloadedImage);
          })
          .catch(() => {
            loadedPhotoList.push('');
          })
      );
    }

    setGalleryArr(loadedPhotoList);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let edittedEst = { ...formEdit };
    edittedEst.listaFotos = [];

    let payloadState = { ...props.location.state };

    if (estThumb && estThumb.indexOf('https://') < 0) {
      const newThumbnailFile = separateBase64String(await getBase64FromUrl(estThumb));
      let fotoThumbWithTimestamp;

      await uploadFiles('estabelecimento', {
        mimeType: newThumbnailFile.mimeType,
        fileName: 'thumb_' + props.match.params.id,
        buffer: newThumbnailFile.buffer,
      })
        .then((res) => {
          const response = res.split('/');
          fotoThumbWithTimestamp = response[response.length - 1].split('?')[0];
          edittedEst.fotoThumb = fotoThumbWithTimestamp;
        })
        .catch(() => {
          delete edittedEst.fotoThumb;
        });
    }

    const promisesImg = galleryArr.map(async (item, i) => {
      if (
        galleryArr[i] &&
        galleryArr[i].indexOf('https://') >= 0 &&
        props?.location?.state?.listaFotos &&
        props?.location?.state?.listaFotos[i]
      ) {
        edittedEst.listaFotos[i] = props?.location?.state?.listaFotos[i];
      } else {
        const newGalleryFile = separateBase64String(await getBase64FromUrl(galleryArr[i]));
        let galleryImgWithTimestamp;

        await uploadFiles('estabelecimento', {
          mimeType: newGalleryFile.mimeType,
          fileName: 'gal' + i + '_' + props.match.params.id,
          buffer: newGalleryFile.buffer,
        })
          .then((res) => {
            const response = res.split('/');
            galleryImgWithTimestamp = response[response.length - 1].split('?')[0];
            edittedEst.listaFotos[i] = galleryImgWithTimestamp;
          })
          .catch((error) => {
            console.log(error);
            //removendo do array em caso de algum erro
            edittedEst.listaFotos[i] = '';
          });
      }
    });

    await Promise.allSettled(promisesImg);

    if (adminForm.isPublished !== origin.isPublished) {
      edittedEst.isPublished = adminForm.isPublished;
    }
    if (JSON.stringify(adminForm.keyWordsSet) !== JSON.stringify(origin.keyWordsSet)) {
      edittedEst.keyWordsSet = adminForm.keyWordsSet;
    }
    if (adminForm.signatureId !== origin.signatureId) {
      edittedEst.signatureId = adminForm.signatureId;
    }

    if (edittedEst.redesSociais && Array.isArray(edittedEst.redesSociais)) {
      edittedEst.redesSociais.map((redeSocial, index) => {
        if (Array.isArray(redeSocial.value) && redeSocial.value.length < 1) {
          edittedEst.redesSociais.splice(index, 1);
        }
      });
    }

    if (payloadState.redesSociais) {
      payloadState.redesSociais.map((redeSocial, index) => {
        if (Array.isArray(redeSocial.value) && redeSocial.value.length < 1) {
          payloadState.redesSociais.splice(index, 1);
        }
      });
    }

    for (const propertyOne in edittedEst) {
      for (const propertyTwo in payloadState) {
        if (propertyOne === propertyTwo) {
          payloadState[propertyTwo] = edittedEst[propertyOne];
        }
      }
    }

    setEst(payloadState).then((result) => {
      dispatch(currentEstActionsAction(result));
    });
    registerEstToLocal(payloadState).then((result) => {
      dispatch(registerEstablishmentActionsAction(result));
    });

    const loggedUid = getUserInfo().uId;

    delete edittedEst.contato;

    let payload = {
      establishmentId: props.match.params.id,
      establishmentName: origin.nome,
      changedFields: edittedEst,
    };

    // caso o estabelecimento ainda não tenha sido avaliado na curadoria, usa a api que atualiza
    if (dataEstApprovals.status === 'aberto') {
      updateEstablishment(props.match.params.id, edittedEst, null)
        .then(() => {
          toast.success('Estabelecimento atualizado!');
          history.push({
            pathname: `/estabelecimento/${props.match.params.id}`,
            state: edittedEst,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error('Erro ao salvar as informações do estabelecimento.');
        });
    } else {
      // se não usa a api que cria uma nova curadoria para o estabelecimento
      createEstablishmentRequest(loggedUid, payload)
        .then(() => {
          localStorage.setItem('isChangedEstablishment', true);
          toast.success('Estabelecimento atualizado!');
          history.push({
            pathname: `/estabelecimento/${props.match.params.id}`,
            state: edittedEst,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error('Erro ao salvar as informações do estabelecimento.');
        });
    }
  };

  const AllSteps = [
    <StepOne
      setDisabledButton={setDisabledButton}
      formState={formEdit}
      setFormState={setFormEdit}
      setCurrentScreen={setCurrentScreen}
      cellphone={cellphone}
      progress={progressOne}
      setProgress={setProgressOne}
      editPage={true}
    />,
    <StepTwo
      setDisabledButton={setDisabledButton}
      formState={formEdit}
      setFormState={setFormEdit}
      setCurrentScreen={setCurrentScreen}
      progress={progressTwo}
      setProgress={setProgressTwo}
      editPage={true}
    />,
    <StepThree
      setDisabledButton={setDisabledButton}
      formState={formEdit}
      setCurrentScreen={setCurrentScreen}
      progress={progressThree}
      setProgress={setProgressThree}
      estThumb={estThumb}
      setEstThumb={setEstThumb}
      galleryArr={galleryArr}
      setGalleryArr={setGalleryArr}
      editPage={true}
    />,
    <StepFour
      adminForm={adminForm}
      setAdminForm={setAdminForm}
      preState={origin}
      setDisabledButton={setDisabledButton}
    />,
  ];

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <Box sx={{ py: '3.6rem', px: { xs: '1.4rem', md: '6rem' } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <HeaderTitle pageInfo="Editar informações do negócio" />
          <D.TriiboH5>Edite as informações do seu negócio</D.TriiboH5>
        </Box>
        <Box sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <EstablishmentEditMenu setCurrentScreen={setCurrentScreen} />
        </Box>
        {AllSteps[currentScreen]}
        <Box sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <S.ButtonContainer>
            <S.ButtonSaveContainer>
              {progressOne < 100 || progressTwo < 100 || progressThree < 100 ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                  }}
                  onClick={() => {
                    let hasValidDates = 5;
                    for (let i = 0; formEdit.funcionamento.length > i; i++) {
                      if (
                        compareDatesFromStr(
                          formEdit.funcionamento[i].horarioFim,
                          formEdit.funcionamento[i].horarioInicio
                        )
                      ) {
                        hasValidDates = 0;
                      }
                    }

                    toast.error(`Os seguintes campos ainda precisam ser preenchidos: 
                    ${
                      formEdit.nome.length < 2
                        ? 'NOME DO NEGÓCIO PRECISA TER NO MÍNIMO 2 CARACTERES,'
                        : ''
                    }
                    ${
                      formEdit.descricao.length < 5
                        ? 'DESCRIÇÃO DO NEGÓCIO PRECISA TER NO MÍNIMO 5 CARACTERES,'
                        : ''
                    }
                    ${!formEdit.tags[0] ? 'TAGS DO SEU NEGÓCIO,' : ''}
                    ${!formEdit.email || formEdit.email.length < 3 ? 'EMAIL DO ATENDIMENTO,' : ''}
                    ${!formEdit.contatos[0] ? 'TELEFONE PRIVADO,' : ''}
                    ${!formEdit.contatoTriibo[0] ? 'CONTATOS PARA OS CLIENTES,' : ''}
                    ${!estThumb ? 'FOTO PRINCIPAL,' : ''}
                    ${galleryArr.length === 0 ? 'LISTA DE FOTOS' : ''}
                    ${hasValidDates === 0 ? 'HORÁRIO DE FUNCIONAMENTO' : ''}
                    ${formEdit.cep.length < 8 ? 'ENDEREÇO DO ESTABELECIMENTO' : ''}

                    `);
                  }}
                />
              ) : (
                <></>
              )}
              <D.TriiboFilledButton
                disabled={
                  progressOne < 100 ||
                  progressTwo < 100 ||
                  progressThree < 100 ||
                  loading ||
                  disabledButton
                }
                onClick={(e) => handleSubmit(e)}
                blue={true}
              >
                {loading ? <CircularProgress size={15} /> : 'Salvar'}
              </D.TriiboFilledButton>
            </S.ButtonSaveContainer>
          </S.ButtonContainer>
          <S.WarningContainer>
            <D.TriiboH6>
              Os campos marcados com asterisco (*) são de preenchimento obrigatório.
            </D.TriiboH6>
          </S.WarningContainer>
        </Box>
      </Box>
    </div>
  );
};

export default EstablishmentEdit;

