import axios from 'axios';

import authToken from './authToken';
import cfac22 from '../cfac22';

export function CreateOrder(uId, sender, itemId, latitude, longitude, type, receiver, status) {
  return new Promise((resolve, reject) => {
    const currentDate = new Date().getTime();

    authToken(uId).then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/order',
          {
            itemId,
            receiver,
            sender,
            type,
            requestDate: currentDate,
            status,
            requestLat: latitude,
            requestLon: longitude,
          },
          { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

////////////////////////////////////////////////////////

// exemplo de payload processOrder:

// sender: triiboId do usuário logado,
// itemId: se o botão for "Uso imediato" usar: '_CupomParaEnvio' se não templateId,
// requestDate: currentDate,
// requestLat: promo.lat,
// requestLon: promo.long,
// type: 'promotion' (enviar para a carteira do usuário), ou 'validation' (consumir da carteira do usuário)
// receiver: triiboId,

export function processOrder(uId, data) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/order/processOrder',
          {
            ...data,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
