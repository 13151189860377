import axios from 'axios';

import { loginUser } from 'js/library/services/AuthenticationManager.js';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import cfac22 from '../cfac22';

export function setUserInfo_v1(uId, queryPartnerAPI, userInfo) {
  return new Promise(function (resolve, reject) {
    getChannelToken_v1(uId)
      .then((channelTokenId) => {
        axios
          .post(
            cfac22('API_HOST_V1') + 'setUserInfo_v1',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId: uId,
                channelTokenId: channelTokenId,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              queryPartnerAPI,
              userInfo,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then((result) => {
            resolve(result.data.success.newUserInfo);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR SET INFO ===>', error);
      });
  });
}

export function setUserInfo_v1_creation(uId, queryPartnerAPI, userInfo, isTriibo) {
  return new Promise(function (resolve, reject) {
    getChannelToken_v1(uId)
      .then((channelTokenId) => {
        axios
          .post(cfac22('API_HOST_V1') + 'setUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: uId,
              channelTokenId: channelTokenId,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            await loginUser(
              result.data.success.newUserInfo.triiboId.replace(/[,]/gi, '.'),
              result.data.success.newUserInfo.passPhrase
            );

            window.pathname = '/admin';

            result.data.success.newUserInfo.admin = true;

            resolve({ userInfo: result.data.success.newUserInfo });
          })
          .catch((error) => {
            // console.log('ERROR SET INFO ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET TOKEN ===>', error);
        reject(error);
      });
  });
}
