import axios from 'axios';

import authToken from '../authToken';
import cfac22 from '../../cfac22';

export function deleteBrasilCapPartner(partnerId, uid) {
  return new Promise((resolve, reject) => {
    authToken(uid).then((token) => {
      let data = {
        partnerId: partnerId,
        uid: uid,
      };

      let config = {
        method: 'delete',
        url: `${cfac22('API_HOST_V2')}/users/deleteBrasilCapPartner`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Full-Url': window.location.href,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
