import React from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import { PreviewHeader } from '../styles';

import { BiImage } from 'react-icons/bi';
import logoTriiboImg from '../../../../../../styles/assets/communications/logo_triibo.svg';
import heartImg from '../../../../../../styles/assets/communications/heart.svg';
import cfac22 from 'js/library/utils/cfac22';
import { Avatar } from '@mui/material';

export default function PostPreview({ formCommunication, image }) {
  return (
    <S.Container>
      <PreviewHeader>
        <Avatar
          alt="logo Organization"
          src={
            cfac22('STORAGE_URL') + encodeURIComponent(formCommunication.sender.logo) + '?alt=media'
          }
        />
        <D.TriiboH5>{formCommunication.sender.name}</D.TriiboH5>
      </PreviewHeader>
      <S.ImagePreview>
        {image ? (
          <img alt="Imagem" src={image} />
        ) : (
          <BiImage size={150} color="#999999" className="imagePlaceholder" />
        )}
      </S.ImagePreview>
      {formCommunication.action.type && (
        <S.Interactions>
          <S.BuyButton>
            <D.TriiboH5>
              {formCommunication.action ? formCommunication.action.label : 'Comprar'}
            </D.TriiboH5>
          </S.BuyButton>
        </S.Interactions>
      )}
      <S.MessageAndTitlePreview>
        <D.TriiboH4 className="title">
          <b>{formCommunication.title ? formCommunication.title : 'Título da mensagem'}</b>
        </D.TriiboH4>
        <div className="boxMessage">
          <D.TriiboH4>
            {formCommunication.message ? formCommunication.message : 'Mensagem'}
          </D.TriiboH4>
        </div>
      </S.MessageAndTitlePreview>
    </S.Container>
  );
}
