import React, { useState } from 'react';
import RenderInputDynamic from '../../SearchUser/RenderInputDynamic';

import { Grid, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { UserCirclePlus } from 'phosphor-react';
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';
import { toast } from 'react-toastify';
import { updateSeuClube } from 'js/library/utils/API/seuClube';

export default function AddAdmins({
  clubeData,
  fieldKey,
  adminsList,
  setAdminsList,
  userRole,
  roleOrder,
}) {
  const hasGestor =
    clubeData.hasOwnProperty('admins') &&
    Object.values(clubeData.admins).some((user) => user.role === 'gestor');

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [role, setRole] = useState(hasGestor ? 'administrador' : 'gestor');
  const [userSearch, setUserSearch] = useState('');

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const setUserClubRole = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);

    const admins = {
      ...adminsList,
    };

    // API call
    const basicValidateUser = await ValidateUserPartner(userSearch, clubeData.partnerId);

    if (basicValidateUser.user) {
      if (basicValidateUser.uId in admins) {
        setLoadingSearch(false);
        setUserSearch('');
        return toast.error('Usuário já possui cargo no clube.');
      }

      admins[basicValidateUser.uId] = {
        role,
      };

      const payload = {
        clubeId: clubeData.clubeId,
        admins: {
          [basicValidateUser.uId]: {
            role,
          },
        },
      };

      const updateClube = await updateSeuClube(payload);

      if (updateClube.status === 200) {
        toast.success('Inserido com sucesso!');
        setAdminsList(admins);
      } else {
        toast.error('Erro ao inserir usuário: ' + updateClube.message);
      }

      setUserSearch('');
      setLoadingSearch(false);
    } else {
      toast.error('Usuário não encontrado. Verifique se o mesmo está cadastrado no clube.');
      setUserSearch('');
      setLoadingSearch(false);
    }
  };

  const getTitleAndDisabled = (key) => {
    if (key === 'gestor') {
      return {
        disabled: hasGestor || userRole !== 'superAdmin',
        title: hasGestor
          ? 'Apenas um gestor por clube.'
          : userRole !== 'superAdmin'
          ? 'Apenas superAdmin pode adicionar gestor.'
          : '',
      };
    }

    const isDisabled = !hasGestor || (!userRole && roleOrder[userRole] > roleOrder[key]);
    const title = !hasGestor
      ? 'Adicione pelo menos um gestor.'
      : !userRole && roleOrder[userRole] > roleOrder[key]
      ? 'Você não tem permissão de criar um cargo superior ao seu.'
      : '';

    return { disabled: isDisabled, title };
  };

  return (
    <Grid item xs={12} md={6}>
      <RenderInputDynamic
        fieldInfos={fieldKey}
        inputValue={userSearch}
        setInputValue={setUserSearch}
        handleSubmit={setUserClubRole}
        loadingSearch={loadingSearch}
        customIcon={<UserCirclePlus weight="fill" size={35} color="#6E3296" />}
      />

      <FormControl disabled={loadingSearch} component="fieldset">
        <RadioGroup row value={role} onChange={handleRoleChange}>
          {Object.keys(roleOrder).map((key) => {
            const { disabled, title } = getTitleAndDisabled(key);

            return (
              <FormControlLabel
                key={key}
                disabled={disabled}
                title={title}
                value={key}
                label={
                  <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Typography>
                }
                control={<Radio />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
