import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// STYLES
import {
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Menu,
  MenuItem,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

// ASSETS
import placeholder from 'styles/assets/placeholder/placeholder-4x3.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// SERVICES
import { downloadImage } from 'js/library/services/StorageManager';
import { processOrder } from 'js/library/utils/API/apiCreateOrder';
import { getUserInfo } from 'js/library/utils/helpers';
import { errorMessagesMap } from './messagesApiProcessOrder';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';
import { getVoucherGroup } from 'js/library/utils/API/getVoucherGroup';

// para o submenu do botão
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 145,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
  '@media (max-width: 394px)': {
    fontSize: '12px',
  },
}));

export const PromotionCard = ({
  classes,
  promotion,
  tabValue,
  userData,
  setFilteredCouponsList,
  setCouponList,
}) => {
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(placeholder);

  // para o botao com dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(600));
  const isExtraSmall = useMediaQuery('(max-width:394px)');

  useEffect(() => {
    if (promotion.thumbnail !== '') {
      downloadImage('promocao', promotion.thumbnail)
        .then((downloadedImage) => {
          setThumbnail(downloadedImage);
        })
        .catch(() => {});
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // remove o foco do item ativo dentro do menu
    if (document.activeElement) {
      document.activeElement.blur();
    }

    setAnchorEl(null);
  };

  const handleSubmit = async (promo, type, value) => {
    const currentDate = new Date().getTime();

    // triiboId do usuário logado
    const loggedUserTriiboId = getUserInfo().triiboId;

    // fecha o submenu do botão
    handleClose();

    let award = '';

    if (value === 'premio') {
      const response = await getVoucherTemplate(promo.templateId);

      if (response.result.groupId) {
        const group = await getVoucherGroup(response.result.groupId);

        if (!group.counterMatch) {
          toast.error('Este grupo não possui prêmio cadastrado.', { autoClose: 6000 });
        } else {
          award = group.counterMatch;
        }
      }
    }

    const typeItemId =
      value === 'premio' && award !== ''
        ? award
        : value === 'carteira'
        ? promo.voucherId
        : promo.templateId;

    const payload = {
      orderObject: {
        sender: loggedUserTriiboId,
        itemId: typeItemId,
        requestDate: currentDate,
        requestLat: promo.lat ?? 0,
        requestLon: promo.long ?? 0,
        type,
        receiver: userData.triiboId,
      },
      consumed: value === 'imediato' ? true : false,
    };

    if (
      promo.keyWordsSetConsumption &&
      !userData.keywords.some((keyword) => promo.keyWordsSetConsumption.includes(keyword))
    ) {
      const messageKeywords = promo.message || 'Você não tem permissão para usar essa oferta!';
      toast.error(`${messageKeywords}`, { autoClose: 4000 });
    } else {
      try {
        setLoading(true);
        await processOrder(userData.uId, payload);

        if (tabValue === 0) {
          toast.success('Cupom enviado com sucesso.', { autoClose: 3000 });
        } else {
          toast.success('Cupom consumido com sucesso.', { autoClose: 3000 });
        }

        if (promo.voucherId) {
          setFilteredCouponsList((prevCoupons) =>
            prevCoupons.filter((coupon) => coupon.voucherId !== promo.voucherId)
          );
          setCouponList((prevCoupons) =>
            prevCoupons.filter((coupon) => coupon.voucherId !== promo.voucherId)
          );
        }
      } catch (e) {
        const apiError = e.response?.data?.error || 'Erro desconhecido';
        const errorMessage = errorMessagesMap[apiError] || apiError;
        toast.error(errorMessage, { autoClose: 6000 });
        console.error('Erro na processOrder:', errorMessage);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleButtonClick = (event) => {
    if (tabValue === 0) {
      handleClick(event);
    } else {
      handleSubmit(promotion, 'validation', 'carteira');
    }
  };

  return (
    <Card className={classes.card} elevation={0} sx={{ border: '1px solid #e0e0e0' }}>
      <CardMedia
        component="img"
        alt="Thumbnail da promoção"
        image={thumbnail}
        title="Thumbnail da promoção"
        className={classes.media}
      />
      <Box className={classes.contentContainer}>
        <CardContent className={classes.content}>
          <Typography variant="body2" component={isMobile ? 'h6' : 'h3'}>
            {isMobile
              ? promotion.title && promotion.title.length > 70
                ? `${promotion.title.substring(0, 70)}...`
                : promotion.title || 'Título da promoção'
              : promotion.title || 'Título da promoção'}
          </Typography>
          {promotion.voucherId && (
            <Typography variant="body2" component={isMobile ? 'h6' : 'h3'}>
              Cupom: {promotion.voucherId}
            </Typography>
          )}
        </CardContent>

        <Box className={classes.buttonBox}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            disabled={loading}
            onClick={handleButtonClick}
            endIcon={tabValue === 0 && <KeyboardArrowDownIcon />}
            sx={{
              fontSize: isExtraSmall ? '12px' : isMobile ? '14px' : '16px',
              padding: isExtraSmall ? '5px 8px' : '8px 16px',
              minHeight: 'auto',
              background: '#6E3296',
              '&:hover': { background: '#6E3296' },
            }}
          >
            {loading ? (
              <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <CircularProgress color="default" size={20} />
                {isExtraSmall ? null : 'Processando...'}
              </span>
            ) : tabValue === 0 ? (
              'Enviar cupom'
            ) : (
              'Consumir cupom'
            )}
          </Button>

          {/* uso posterior === dar cupom */}
          {/*uso imediato === consumir cupom */}
          {tabValue === 0 && (
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {promotion?.hasGroup ? (
                <div>
                  <StyledMenuItem
                    onClick={() => handleSubmit(promotion, 'promotion', 'imediato')}
                    disableRipple
                  >
                    Acumular cupom
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => handleSubmit(promotion, 'promotion', 'premio')}
                    disableRipple
                  >
                    Enviar prêmio
                  </StyledMenuItem>
                </div>
              ) : (
                <div>
                  <StyledMenuItem
                    onClick={() => handleSubmit(promotion, 'promotion', 'imediato')}
                    disableRipple
                  >
                    Uso imediato
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => handleSubmit(promotion, 'promotion', 'posterior')}
                    disableRipple
                  >
                    Uso posterior
                  </StyledMenuItem>
                </div>
              )}
            </StyledMenu>
          )}
        </Box>
      </Box>
    </Card>
  );
};
