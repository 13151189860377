import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

// 'xs' (0px), 'sm' (600px), 'md' (900px), 'lg' (1200px), 'xl' (1536px).
export default makeStyles((theme) => ({
  root: {
    maxHeight: '100%',
    padding: '0px 40px 100px 40px',
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '5px',
    },
  },

  media: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: '100%',
    },
  },

  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
    gap: '5px',
  },

  content: {
    textAlign: 'center',
    flex: 1,
    padding: '10px 0px !important',

    [theme.breakpoints.down('sm')]: {
      padding: '5px 0px !important',
    },
  },

  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '15px',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '5px',
      marginBottom: '0px',
    },
  },
}));

export const cellPhoneBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: ${({ height }) => height || '40px'};
  border: 1px solid #ccc;
  border-radius: 5px;

  select {
    border: none;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }

  .inputCellphone {
    flex: 1;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }
`;
