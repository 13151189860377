import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22';

export function updatePartnerFilesActive(id, active) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        axios
          .patch(
            `${cfac22(
              'API_HOST_V2'
            )}/partnerFilesType/updatePartnerFilesTypeActive?id=${id}&active=${active}`,
            null,
            {
              headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
            }
          )
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
