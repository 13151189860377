import axios from 'axios';

import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import { getUserInfo } from 'js/library/utils/helpers.js';
import cfac22 from '../cfac22';

export function getUserOrgs(uId) {
  return new Promise(function async(resolve, reject) {
    const loggedUid = getUserInfo().uId;

    getChannelToken_v1(loggedUid).then((channelTokenId) => {
      axios
        .post(
          cfac22('API_HOST_V1') + 'getUserOrgs',
          {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: loggedUid,
              channelTokenId: channelTokenId,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            uId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Full-Url': window.location.href,
            },
          }
        )
        .then(async (result) => {
          // console.log("RETORNOU A API", result)
          return resolve(result.data);
        })
        .catch((error) => {
          // console.log("ERROR-> ", error);
          return resolve(false);
        });
    });
  });
}
