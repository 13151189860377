import axios from 'axios';
import authToken from './authToken';

import { decrypt, getUserInfo } from '../helpers';
import cfac22 from '../cfac22';

export function ValidateUserPartner(accessToken, partnerId) {
  const userInfo = getUserInfo();
  return new Promise((resolve, reject) => {
    authToken(userInfo.uId).then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/userPartner/validateUserPartner',
          {
            token: accessToken,
            partnerId: partnerId,
          },
          {
            headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
          }
        )
        .then(async (result) => {
          resolve(JSON.parse(decrypt(result.data.response, cfac22('CRYPTO_KEY'))));
        })
        .catch((error) => {
          console.log(error);
          reject(error.response);
        });
    });
  });
}
