import React, { useState } from 'react';
import { Save as SaveIcon } from '@mui/icons-material';
import {
  Typography,
  Grid,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  FormControlLabel,
  Button,
} from '@mui/material';
import { pushDatabase } from 'js/library/services/DatabaseManager';

import Loading from 'js/containers/Loading/Loading';

import QRCode from 'qrcode.react';
import crachaCabecalho from 'styles/assets/cracha/crachaCabecalho.png';
import { toast } from 'react-toastify';
import ImportImage from '../TriiboComponents/ImportImage';

function ValidatorsInformation(props) {
  const nome = props.state.nome;
  const [email, setEmail] = useState(props.state.email);
  const [crachaCabecalhoImg, setCrachaCabecalhoImg] = useState(crachaCabecalho)
  const [isValidator, setIsValidator] = useState(true);
  const idEstabelecimento = props.state.id ?? props.state.idEstabelecimento;
  const idGestor = props.state.idGestor;
  const [identificacao1, setIdentificacao1] = useState(props.state.identificacao1);
  const [textoApresentacao, setTextoApresentacao] = useState('Escaneie o QR Code abaixo:');
  const [identificacao2, setIdentificacao2] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = () => (event) => {
    if (event.target.value.length > 50) {
      toast.error('Número máximo de caracteres atingido!');
    } else {
      setTextoApresentacao(event.target.value);
    }
  };

  const downloadQRCode = () => {
    var element = document.createElement('a');

    element.setAttribute('href', document.getElementById('QRCode').toDataURL('image/png'));
    element.setAttribute('download', email + '.png');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const print = () => {
    var win = window.open('Crachá', 'PRINT', 'height=700,width=700');
    win.document.write('<html>');
    win.document.write('<head>');
    win.document.write(
      '<style>@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:900");</style>'
    );
    win.document.write('</head');
    win.document.write('<body>');
    win.document.write('<div style="width:110%; margin-left: -5%">');
    win.document.write(
      '<div style="-webkit-print-color-adjust:exact; background-color: white; height: 10px"></div>'
    );
    win.document.write('<div>');
    win.document.write('<img src="' + crachaCabecalhoImg + '" width="100%" />');
    win.document.write('</div>');

    win.document.write(
      '<div style="-webkit-print-color-adjust:exact; background-color: orange; color: white; text-align: center; margin-top: -16px; margin-bottom: -10px;">'
    );
    win.document.write(
      '<p style="padding-top:10px; padding-bottom : 10px; font-size: 12px;font-family:Source Sans Pro,sans-serif;">' +
        identificacao1.toUpperCase() +
        '</p>'
    );
    win.document.write('</div>');

    textoApresentacao.length === 0
      ? win.document.write('<br/><br/>')
      : textoApresentacao.length > 32
      ? win.document.write(
          '<div style="text-align: center; width: 70%; margin: 0 auto;"><p style="font-size: 10px; font-family:Source Sans Pro,sans-serif; color: #363636;">' +
            textoApresentacao +
            '</p></div>'
        )
      : win.document.write(
          '<div style="text-align: center; width: 70%; margin: 0 auto;"><p style="font-size: 10px; font-family:Source Sans Pro,sans-serif; color: #363636;">' +
            textoApresentacao +
            '</p></div><br/>'
        );

    win.document.write('<div>');
    win.document.write(
      '<img width="45%" style=" display: block; margin-left: auto; margin-right: auto;" src="' +
        document.getElementById('QRCode').toDataURL('image/png') +
        '" />'
    );
    win.document.write('</div>');

    win.document.write('<div style="text-align: center">');
    win.document.write(
      '<p style="font-size: 10px; font-family:Source Sans Pro,sans-serif; color: #363636;">' +
        identificacao2.toUpperCase() +
        '</p>'
    );
    win.document.write('</div>');

    win.document.write('</div>');
    win.document.write('</body');
    win.document.write('</html>');

    //win.focus();
    win.onfocus = function () {
      setTimeout(function () {
        win.print();
        win.close();
      }, 1000);
    };
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (isValidator === false) {
      setEmail(null);
    }

    try {
      const data = { idEstabelecimento: idEstabelecimento, idGestor: idGestor };
      await pushDatabase('Estabelecimento-Validador', email, data, null);
      setLoading(false);
      toast.success('Salvo com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um arro ao salvar o validador.');
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  else {
    return (
      <div style={{ padding: '0' }}>
        {nome !== '' ? (
          nome !== false ? (
            <div>
              <Grid container>

                {isValidator ? (
                  <Grid item md={6} sm={6} xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="subtitle2" color="secondary" style={{ fontWeight: '500' }}>
                      {' '}
                      QRCode do Validador{' '}
                    </Typography>

                    <QRCode
                      style={{ height: '128px', width: '128px' }}
                      id="QRCode"
                      value={email.replace(/[.]/gi, ',')}
                      size={1000}
                      bgColor={'#ffffff'}
                      fgColor={'#000000'}
                      level={'M'}
                    />
                    <br />
                    <Button
                      onClick={() => downloadQRCode()}
                      style={{ width: '128px' }}
                      variant="outlined"
                    >
                      Baixar
                    </Button>
                  </Grid>
                ) : null}
              </Grid>

              <Grid container>
                <Grid item md={6} sm={6} xs={12}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="secondary"
                    style={{ fontWeight: '550' }}
                  >
                    Cabeçalho do Crachá
                  </Typography>

                  <FormControl className="input-field">

                    <ImportImage
                      imageDefault={true}
                      autoWidth={true}
                      image={crachaCabecalhoImg}
                      setImage={setCrachaCabecalhoImg}
                      ratio={4 / 2}
                    />

                    <FormLabel style={{ marginTop: '20px' }}>1º campo de identificação</FormLabel>
                    <RadioGroup
                      aria-label="identificacao1"
                      name="identificacao1"
                      value={identificacao1}
                      onChange={(e) => setIdentificacao1(e.target.value)}
                    >
                      <FormControlLabel
                        value={nome}
                        control={<Radio color="primary" />}
                        label="Nome do Usuário"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value={props.state.nomeEstabelecimento}
                        control={<Radio color="primary" />}
                        label="Nome do Estabelecimento"
                        labelPlacement="end"
                      />
                    </RadioGroup>

                    <TextField
                      style={{ width: '90%' }}
                      className="input-field"
                      value={textoApresentacao}
                      onChange={handleChange('textoApresentacao')}
                      type="text"
                      id="textoApresentacao"
                      label="Texto de Apresentação"
                    />

                    <FormLabel style={{ marginTop: '20px' }}>2º campo de identificação</FormLabel>
                    <RadioGroup
                      aria-label="identificacao2"
                      name="identificacao2"
                      value={identificacao2}
                      onChange={(e) => setIdentificacao2(e.target.value)}
                    >
                      <FormControlLabel
                        value={nome}
                        control={<Radio color="primary" />}
                        label="Usuário"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value=""
                        control={<Radio color="primary" />}
                        label="Vazio"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid style={{ padding: '3px' }} item md={3} sm={3} xs={12}>
                  <Typography variant="h6" gutterBottom color="secondary">
                    Frente{' '}
                  </Typography>

                  <div
                    style={{
                      position: 'relative',
                      border: '1px solid #000',
                      borderRadius: '7px',
                    }}
                    align="center"
                  >
                    <div
                      style={{
                        backgroundColor: 'white',
                        height: '10px',
                      }}
                    ></div>
                    <img
                      id="crachaCabecalhoImg"
                      width="100%"
                      src={crachaCabecalhoImg}
                      alt="Frente do Cracha"
                    />

                    <div
                      style={{
                        width: '100%',
                        backgroundColor: 'orange',
                        textAlign: 'center',
                        marginTop: '-16px',
                      }}
                    >
                      <Typography
                        id="identificacao1"
                        style={{
                          fontWeight: 'bold',
                          paddingTop: '20px',
                          paddingBottom: '10px',
                        }}
                        color="secondary"
                        variant="subtitle2"
                        gutterBottom
                      >
                        <span style={{ color: 'white' }}>{identificacao1.toUpperCase()}</span>
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        style={{ fontWeight: '550', width: '60%' }}
                        color="secondary"
                        variant="subtitle2"
                        gutterBottom
                      >
                        <span style={{ color: '#363636' }}>{textoApresentacao}&nbsp;</span>
                      </Typography>
                    </div>
                    <div style={{ width: '100%', height: '100%' }}>
                      <QRCode
                        style={{ width: '45%', height: '45%' }}
                        id="QRCode"
                        value={email.replace(/[.]/gi, ',')}
                        size={1000}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        level={'M'}
                      />
                    </div>
                    <br />
                    <div
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold' }}
                        color="secondary"
                        variant="subtitle2"
                        gutterBottom
                      >
                        <span style={{ color: '#363636' }}>
                          {identificacao2.toUpperCase()}&nbsp;
                        </span>
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : null
        ) : null}

        <Button
          disabled={nome === '' || nome === false ? true : false}
          style={{
            fontWeight: '550',
            marginTop: '20px',
            color: 'white',
            textTransform: 'capitalize',
          }}
          variant="contained"
          color="secondary"
          onClick={() => print()}
          startIcon={<SaveIcon color="white" />}
        >
          Imprimir
        </Button>

        <Button
          disabled={nome === '' || nome === false ? true : false}
          style={{
            fontWeight: '550',
            marginTop: '20px',
            marginLeft: '20px',
            color: 'white',
            textTransform: 'capitalize',
          }}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          startIcon={<SaveIcon color="white" />}
        >
          Salvar
        </Button>
      </div>
    );
  }
}

export default ValidatorsInformation;