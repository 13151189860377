import React, { useEffect, useState } from 'react';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import { DraggableAccordionList } from './DraggableAccordionList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const FaqModal = ({ isActiveModals, closeModal, setPayload, selectedModalInfos }) => {
  const [displayColorPickerSummary, setDisplayColorPickerSummary] = useState(false);
  const [displayColorPickerDetails, setDisplayColorPickerDetails] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(0);
  const { form, setForm, validateForm, clearForm } = useForm({
    backgroundDetails: {
      value: '',
      required: false,
    },
    backgroundSummary: {
      value: '',
      required: false,
    },
    questions: {
      value: [],
      required: false,
    },
  });

  const currentModal = ModelLPSignUp.modalsList.faq;

  const onAddQuestion = () => {
    setForm((prevForm) => {
      const newQuestions = [
        ...(prevForm.questions.value || []),
        {
          title: '',
          answer: '',
          priority: prevForm.questions.value?.length ?? 0,
        },
      ];

      setExpandedAccordion(newQuestions.length - 1); // Expande o último adicionado

      return {
        ...prevForm,
        questions: {
          ...prevForm.questions,
          value: newQuestions,
        },
      };
    });
  };

  const handleChangeQuestion = (index, field, value) => {
    setForm((prevForm) => {
      const updatedQuestions = [...prevForm.questions.value];
      updatedQuestions[index][field] = value;
      return {
        ...prevForm,
        questions: {
          ...prevForm.questions,
          value: updatedQuestions,
        },
      };
    });
  };

  const handleDeleteQuestion = (indexToRemove) => {
    setForm((prevForm) => {
      // Verifica se há mais de uma pergunta antes de permitir a exclusão
      if (prevForm.questions.value.length <= 1) {
        return prevForm;
      }

      const updatedQuestions = prevForm.questions.value.filter(
        (_, index) => index !== indexToRemove
      );

      return {
        ...prevForm,
        questions: {
          ...prevForm.questions,
          value: updatedQuestions,
        },
      };
    });

    setExpandedAccordion(null);
  };

  const handleSave = () => {
    const { backgroundDetails, backgroundSummary, questions } = form;

    if (validateForm()) {
      setPayload((prevState) => {
        const modules = prevState.campaignLandpage.modules;
        modules[selectedModalInfos.currentIndex] = {
          ...selectedModalInfos.modalInfos,
          backgroundDetails: backgroundDetails?.value,
          backgroundSummary: backgroundSummary?.value,
          questions: questions?.value,
        };
        return {
          campaignLandpage: {
            ...prevState?.campaignLandpage,
            modules,
          },
        };
      });
      toast.success('FAQ salvo com sucesso!');
      clearForm();
      setExpandedAccordion(null);
      closeModal(currentModal);
    } else {
      toast.error('Preencha todos os campos obrigatórios!');
    }
  };

  const handleCloseModal = () => {
    clearForm();
    closeModal(currentModal);
  };

  useEffect(() => {
    if (isActiveModals) {
      const { backgroundDetails, backgroundSummary, questions } =
        selectedModalInfos?.modalInfos || {};

      setForm((prevState) => ({
        ...prevState,
        backgroundDetails: {
          value: backgroundDetails || '#000000',
          required: true,
        },
        backgroundSummary: {
          value: backgroundSummary || '#000000',
          required: true,
        },
        questions: {
          value: questions?.length
            ? questions
            : [
                {
                  title: '',
                  answer: '',
                  priority: 0,
                },
              ],
          required: true,
        },
      }));
    }
  }, [isActiveModals, selectedModalInfos, setForm]);

  useEffect(() => {
    if (form.questions.value.length >= 0) {
      setExpandedAccordion(null);
    }
  }, []);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Fundo do título"
              id="backgroundSummary"
              margin="normal"
              value={form?.backgroundSummary?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.backgroundSummary?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPickerSummary((prevState) => !prevState)}
            >
              {displayColorPickerSummary ? 'Salvar' : 'Selecione a cor'}
            </Button>
          </Box>
          {displayColorPickerSummary && (
            <ChromePicker
              color={form?.backgroundSummary?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  backgroundSummary: { ...prevState.backgroundSummary, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Fundo dos detalhes"
              id="backgroundDetails"
              margin="normal"
              value={form?.backgroundDetails?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.backgroundDetails?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPickerDetails((prevState) => !prevState)}
            >
              {displayColorPickerDetails ? 'Salvar' : 'Selecione a cor'}
            </Button>
          </Box>
          {displayColorPickerDetails && (
            <ChromePicker
              color={form?.backgroundDetails?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  backgroundDetails: { ...prevState.backgroundDetails, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', mt: '20px' }}
        >
          <Typography variant="body" sx={{ paddingLeft: '0px !important' }}>
            Lista de perguntas:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={onAddQuestion}
            disabled={form.questions.value.length > 49}
          >
            Adicionar
          </Button>
        </Box>

        <Box width="100%">
          <DraggableAccordionList
            form={form}
            setForm={setForm}
            expandedAccordion={expandedAccordion}
            setExpandedAccordion={setExpandedAccordion}
            handleDeleteQuestion={handleDeleteQuestion}
            handleChangeQuestion={handleChangeQuestion}
          />
        </Box>

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FaqModal;
