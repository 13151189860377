import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { store } from 'js/core/configureStore';

import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';
import cuponsValidos from 'styles/assets/promocao/cuponsValidos.png';
import nomeCupom from 'styles/assets/promocao/nomeCupom.png';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  MenuItem,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Badge,
  colors,
  Alert,
  Box,
  CircularProgress,
} from '@mui/material';
import { ArrowBack, Help, AddPhotoAlternate, DoneAll, Close, FileCopy } from '@mui/icons-material';

import CropFreeIcon from '@mui/icons-material/CropFree';
import './storeStyle.css';
import { Cropper } from 'react-image-cropper';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { verificaPerfil } from 'js/library/services/AuthenticationManager';

import Loading from 'js/containers/Loading/Loading';
import { updateStorePromotionInformationAction } from 'js/core/actions/storePromotionInformationActions';
import {
  downloadImage,
  downloadImageAsBlob,
  uploadImage,
} from 'js/library/services/StorageManager';

import {
  msToTime,
  findValueInArray,
  getUserInfo,
  formatDate,
  isAdminPage,
  toDataURL,
  b64toBlob,
} from 'js/library/utils/helpers.js';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';

import * as S from 'js/components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { BsXLg } from 'react-icons/bs';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

import readEstablishment from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { getStoreChangeRequest } from 'js/library/utils/API/Store/apiGetStoreChangeRequest';
import { confirmStoreChanges } from 'js/library/utils/API/Store/apiConfirmStoreChanges';

import { updateStore, updateStoreApprovals } from 'js/library/utils/API/Store/apiUpdateStore';
import { toast } from 'react-toastify';
import { updateVoucherTemplate } from 'js/library/utils/API/apiUpdateVoucherTemplate';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

// Estilo da notificação - Badge
const StyledBadge = styled(Badge)({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',

    '&:hover': {
      backgroundColor: colors['red'][700],
      color: 'white',
      cursor: 'pointer',
      border: 'none',
    },
  },
});

const StyledBadge2 = styled(Badge)({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',
  },
});

// Estilo da mensagem de aprovado/rejeitado - Alert
const StyledAlert = styled(Alert)({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: '6px 50px',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '25px',
    textAlignLast: 'center',
  },
});

function convertIsoToDate(date) {
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const dateDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const dateHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const dateMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  return (date = dateYear + '-' + dateMonth + '-' + dateDay + 'T' + dateHour + ':' + dateMinute);
}
class StorePromotionExternal extends Component {
  constructor(props, context) {
    super(props, context);

    window.pathname = null;

    const urlParts = window.location.pathname.split('/');
    const establishmentIdIndex = urlParts.indexOf('estabelecimento');
    const establishmentId = urlParts[establishmentIdIndex + 1];

    const urlEdit = window.location.href;
    const hasEditWord = urlEdit.includes('/editar');

    // trás os dados do estabelecimento
    (async () => {
      try {
        const result = await readEstablishment(establishmentId);
        const dataEstablishment = result;

        this.setState({
          idEstabelecimento: dataEstablishment.id ?? establishmentId,
          idGestor: dataEstablishment.idGestor,
          nomeEstabelecimento: dataEstablishment.nome,
          lat: dataEstablishment.lat,
          long: dataEstablishment.long,
          address:
            dataEstablishment.endereco === 'Brasil'
              ? dataEstablishment.endereco
              : `${dataEstablishment.logradouro}, ${dataEstablishment.numero} - ${dataEstablishment.bairro}, ${dataEstablishment.cidade} - ${dataEstablishment.estado}, `,
          neighborhood: dataEstablishment.bairro,
        });
      } catch (error) {
        console.error('Erro ao obter dados do estabelecimento', error);
      }
    })();

    //extrai o id da promoção da url
    function extractIdPromotionUrl() {
      const url = window.location.href;
      const regex = /\/promocao\/([^/]+)\/editar\//;
      const match = url.match(regex);

      if (match && match.length > 1) {
        const promoId = match[1];
        return promoId;
      } else {
        return null;
      }
    }

    const establishmentListInfos = store.getState().authModel.establishmentList;

    this.dayMs = 86400000;
    this.responseDays = 14; //Tempo de espera para aprovação em dias

    const today = new Date();
    const nextYear = new Date(today);
    nextYear.setFullYear(nextYear.getFullYear() + 1);

    // Verificando se o próximo ano é bissexto
    if (
      (nextYear.getFullYear() % 4 === 0 && nextYear.getFullYear() % 100 !== 0) ||
      nextYear.getFullYear() % 400 === 0
    ) {
    } else {
      nextYear.setMonth(1);
      nextYear.setDate(28);
    }

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hour}:${minutes}`;

    const disableYear = nextYear.getFullYear();
    const disableMonth = String(today.getMonth() + 1).padStart(2, '0');
    const disableDay = String(today.getDate()).padStart(2, '0');
    const disableHour = String(today.getHours()).padStart(2, '0');
    const disableMinutes = String(today.getMinutes()).padStart(2, '0');

    const disableFormattedDate = `${disableYear}-${disableMonth}-${disableDay}T${disableHour}:${disableMinutes}`;

    this.state = {
      //dados do estabelecimento
      idEstabelecimento: null,
      idGestor: null,
      nomeEstabelecimento: null,
      lat: null,
      long: null,
      address: null,
      neighborhood: '',
      dataEstabelecimento: null,

      //dados do template'
      id: null,
      consumoUnico: false,
      cooldown: 0,
      cooldownDays: 0,
      cooldownHours: 0,
      cooldownMinutes: 0,
      dataGeracao: null,
      descricao: null,
      detailsDescription: null,
      credit: 0,
      detailsTitle: ' ',
      generator: true,
      nomeTemplate: null,
      singular: null,
      plural: null,
      qtdDisponivel: null,
      hasSimultaneo: false,
      qtdSimultaneo: 1,
      region: null,
      titulo: null,
      campanhaTriibo: false,
      copyTemplateMessage: 'Copiar ID do template',

      equalImageThumnailFile: null,
      equalImagePhotoFile: null,

      type: null,
      //se consumo for posterior ==> showQRCode: null
      //se consumo for imediato showQRCode: true; qntSimultanea:1
      consumo: null,
      foto: null,
      thumbnail: null,

      loadedFoto: null,
      loadedThumbnail: null,

      oldFoto: null,
      oldThumbnail: null,

      fileFoto: null,
      fileThumbnail: null,

      //dados da store
      action: null,
      idStore: null,
      level: null,
      description: null,
      showQRCode: null,
      title: null,
      storeType: 'promotion',
      autoValidate: null,
      deliverType: null,
      cost: 0,
      isPublished: false,
      dueDate: null,
      enableDate: formattedDate,
      disableDate: disableFormattedDate,
      buttonLabel: null,
      buttonDescription: null,
      showButtonDescription: null,
      goToPlaceLabel: null,
      showGoToPlace: null,
      consumed: false,
      keywords: '',
      keywordsDownload: null,
      keywordsList: [],
      tagCapture: '',
      tags: [],
      storeThumbnail: null,
      storePhoto: null,
      loadedStoreThumbnail: null,
      loadedStorePhoto: null,
      oldStoreThumbnail: null,
      oldStorePhoto: null,
      fileStoreThumbnail: null,
      fileStorePhoto: null,

      //states de validação local
      equalImageThumnail: false,
      equalImagePhoto: false,
      open: false,
      openModal: false,
      goBack: false,
      indexCrop: 0,
      openAlert: false,
      messageAlert: '',
      loading: false,

      //mensagem de aprovação
      newStatus: null,
      messageApproval: null,
      comment: '',

      oldPhotoList: [],
      photoListFile: null,
      loadedPhotoList: null,

      sameInformation: false,

      //Botões de adicionar imagem
      disableButtonImage: false,
      disableButtonUpdate: true,

      //controle template
      // couponGetMode: props.location.state.couponGetMode || 'simpleGetMode',
      couponGetMode: ' ',

      //controle do botão de avaliação da curadoria
      isApproveButtonDisabled: false,
      isDisapproveButtonDisabled: false,

      modalStepIsOpen: false,
      establishmentListInfos: establishmentListInfos,
      unlimitedDisableDate: null,

      superUser: null,
      wherePromo: null,
      editPage: urlEdit.includes('/editar'),
      onSendExpiration: null,
      sendSMS: false,
    };

    if (hasEditWord) {
      const promoId = extractIdPromotionUrl();

      getStoreChangeRequest(promoId).then((dataReceived) => {
        const promoData = dataReceived.response;

        //storeInfo
        const storeInfo = promoData.storeInfo;

        // storeApprovals
        const dataApprovals = promoData.storeApprovals;
        let storeApprovals;

        // Verifica se dataApprovals está definido e não vazio
        if (dataApprovals && Object.keys(dataApprovals).length > 0) {
          // Verifica se há algum objeto com o campo "action" igual a "edit"
          const editApprovalKey = Object.keys(dataApprovals).find(
            (key) => dataApprovals[key].action === 'edit'
          );

          // Se houver um objeto com "action" === "edit", define storeApprovals como esse objeto
          // Caso contrário, define como o primeiro objeto
          storeApprovals = editApprovalKey
            ? dataApprovals[editApprovalKey]
            : dataApprovals[Object.keys(dataApprovals)[0]];
        }

        //storeEdits
        const dataStoreEdits = promoData.storeEdits;
        let storeEdits = '';

        if (dataStoreEdits) {
          Object.values(dataStoreEdits).forEach((edits) => {
            storeEdits = edits;
          });
        }

        const mergeFields = (info, edits) => {
          return {
            ...info,
            ...edits,
          };
        };

        // Finalizando o estado com os valores atualizados de storeEdits
        const finalState = mergeFields(storeInfo, storeEdits.changedFields || {});

        this.setState({
          action: storeApprovals?.action,
          status: storeApprovals?.status,
          comment: storeApprovals?.comment,
          newStatus: null,

          idStore: finalState.id === undefined ? null : finalState.id,
          level: finalState.level === undefined ? 99 : finalState.level,
          description: finalState.description === undefined ? null : finalState.description,
          showQRCode:
            finalState.id === undefined
              ? true
              : finalState.showQRCode === undefined
              ? null
              : finalState.showQRCode,
          title: finalState.title === undefined ? null : finalState.title,
          storeType: finalState.type === null ? 'promotion' : finalState.type,
          autoValidate:
            finalState.id === undefined
              ? 'cliente'
              : finalState.autoValidate === true
              ? 'cliente'
              : 'estabelecimento',

          deliverType: null,

          isPublished: finalState.isPublished,
          dueDate: finalState.dueDate ?? null,

          buttonLabel: '',
          buttonDescription: '',

          showButtonDescription:
            finalState.showButtonDescription === undefined
              ? false
              : finalState.showButtonDescription,
          goToPlaceLabel: '',
          showGoToPlace: null,
          consumed: null,
          keywords: '',
          keywordsDownload: null,
          keywordsList: [],
          tagCapture: '',

          tags: finalState.tags === undefined ? [] : finalState.tags,

          oldPhotoList: finalState.photoList ?? [],

          storeThumbnail: finalState.thumbnail === undefined ? null : finalState.thumbnail,
          storePhoto: finalState.photo === undefined ? null : finalState.photo,

          loadedStoreThumbnail:
            finalState.downloadedThumb === undefined ? null : finalState.downloadedThumb,
          loadedStorePhoto: finalState.photo === undefined ? null : placeholder,

          oldStoreThumbnail: finalState.thumbnail === undefined ? null : finalState.thumbnail,
          oldStorePhoto: finalState.photo === undefined ? null : finalState.photo,

          fileStoreThumbnail: null,
          fileStorePhoto: null,

          // dados do template
          id: finalState.templateId,
          wherePromo: localStorage.getItem('fromWherePromotion'),
        });

        // Atualizando photoList
        if (finalState.photoList !== undefined) {
          let photoListFile = [];

          Object.keys(finalState.photoList).map((item, i) =>
            downloadImage('promocao', finalState.photoList[i]).then((downloadedImage) => {
              photoListFile[i] = downloadedImage;
              this.setState({ photoListFile });
            })
          );
        }

        // Atualizando thumbnail
        if (finalState.thumbnail !== undefined) {
          const folderThumb =
            finalState.type === 'product' && finalState.thumbnail !== undefined
              ? finalState.thumbnail.split('/')[0]
              : 'promocao';

          const thumbnailName =
            finalState.type === 'product' && finalState.thumbnail !== undefined
              ? finalState.thumbnail.split('/')[1]
              : finalState.thumbnail;

          downloadImage(folderThumb, thumbnailName)
            .then((downloadedImage) => {
              this.setState({
                loadedStoreThumbnail: downloadedImage,
              });
            })
            .catch(() => {
              // console.log("Foto não encontrada:", storeInfo.thumbnail);
            });
        }

        if (finalState.photo !== undefined) {
          const folderPhoto =
            finalState.type === 'product' && finalState.photo !== undefined
              ? finalState.photo.split('/')[0]
              : 'promocao';

          downloadImage(folderPhoto, finalState.photo).then((loaded) => {
            this.setState({
              loadedStorePhoto: loaded,
            });
          });
        }

        // Atualizando oldPhotoList
        if (this.state.oldPhotoList.length > 0) {
          let photoListFile = [];

          this.state.oldPhotoList.map((item, i) =>
            downloadImage('promocao', this.state.oldPhotoList[i]).then((downloadedImage) => {
              photoListFile[i] = downloadedImage;
              this.setState({ photoListFile });
            })
          );
        }

        //baixando dados da Voucher Template, caso seja edição
        if (storeInfo.templateId) {
          getVoucherTemplate(storeInfo.templateId)
            .then((dataReceived) => {
              const dataVoucherTemplate = dataReceived.result;

              let cooldown = msToTime(dataVoucherTemplate.cooldown);

              if (dataVoucherTemplate.thumbnail !== undefined) {
                dataVoucherTemplate.loadedThumbnail = placeholderThumbnail;
                downloadImage('voucher', dataVoucherTemplate.thumbnail)
                  .then((downloadedImage) => {
                    this.setState({
                      oldThumbnail: dataVoucherTemplate.thumbnail,
                      thumbnail: dataVoucherTemplate.thumbnail,
                      loadedThumbnail: downloadedImage,
                    });
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }

              if (dataVoucherTemplate.foto !== undefined) {
                dataVoucherTemplate.loadedFoto = placeholder;
                downloadImage('voucher', dataVoucherTemplate.foto)
                  .then((downloadedImage) => {
                    this.setState({
                      oldFoto: dataVoucherTemplate.foto,
                      foto: dataVoucherTemplate.foto,
                      loadedFoto: downloadedImage,
                    });
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }

              dataReceived.region =
                this.props.location.state.region === undefined
                  ? 'local'
                  : this.props.location.state.region;
              dataReceived.nomeEstabelecimento = this.state.nomeEstabelecimento;

              this.setState({
                ...dataReceived,
                consumoUnico: dataVoucherTemplate.consumoUnico,
                dataGeracao: dataVoucherTemplate.dataGeracao,
                descricao: dataVoucherTemplate.descricao,
                detailsDescription: dataVoucherTemplate.detailsDescription,
                detailsTitle: dataVoucherTemplate.detailsTitle,
                generator: dataVoucherTemplate.generator,
                nomeTemplate: dataVoucherTemplate.nomeTemplate,
                singular: dataVoucherTemplate.singular,
                plural: dataVoucherTemplate.plural,
                // qtdDisponivel: null,
                qtdSimultaneo: dataVoucherTemplate.qtdSimultaneo,
                region:
                  props.location.state.region === undefined ? 'local' : props.location.state.region,
                titulo: dataVoucherTemplate.titulo,
                campanhaTriibo: dataVoucherTemplate.campanhaTriibo,
                credit: dataVoucherTemplate.credit,

                type: dataVoucherTemplate.type,
                //se consumo for posterior ==> showQRCode: null
                //se consumo for imediato showQRCode: true; qntSimultanea:1
                consumo: dataVoucherTemplate.couponGetMode === ' ' ? 'posterior' : 'imediato',
                couponGetMode: dataVoucherTemplate.couponGetMode,
                foto: dataVoucherTemplate.foto,
                thumbnail: dataVoucherTemplate.thumbnail,

                loadedFoto: dataVoucherTemplate.loadedFoto,

                cooldownDays: cooldown.days,
                cooldownHours: cooldown.hours,
                cooldownMinutes: cooldown.minutes,
              });
            })
            .catch((error) => {
              // console.log("Erro na data:", error);
            });
        }
      });

      //convertendo enable date
      let enableDate = new Date(Date.now());
      if (this.state.enableDate !== undefined) {
        enableDate = new Date(this.state.enableDate);
      }

      const enableYear = enableDate.getFullYear();
      const enableMonth =
        enableDate.getMonth() + 1 < 10
          ? '0' + (enableDate.getMonth() + 1)
          : enableDate.getMonth() + 1;
      const enableDay =
        enableDate.getDate() < 10 ? '0' + enableDate.getDate() : enableDate.getDate();
      const enableHour =
        enableDate.getHours() < 10 ? '0' + enableDate.getHours() : enableDate.getHours();
      const enableMinute =
        enableDate.getMinutes() < 10 ? '0' + enableDate.getMinutes() : enableDate.getMinutes();

      enableDate =
        enableYear + '-' + enableMonth + '-' + enableDay + 'T' + enableHour + ':' + enableMinute;

      //convertendo disable date
      let disableDate = new Date(Date.now() + 86400000 * 365);
      if (this.state.disableDate !== undefined) {
        disableDate = new Date(this.state.disableDate);
      }

      const disableYear = disableDate.getFullYear();
      const disableMonth =
        disableDate.getMonth() + 1 < 10
          ? '0' + (disableDate.getMonth() + 1)
          : disableDate.getMonth() + 1;
      const disableDay =
        disableDate.getDate() < 10 ? '0' + disableDate.getDate() : disableDate.getDate();
      const disableHour =
        disableDate.getHours() < 10 ? '0' + disableDate.getHours() : disableDate.getHours();
      const disableMinute =
        disableDate.getMinutes() < 10 ? '0' + disableDate.getMinutes() : disableDate.getMinutes();

      disableDate =
        disableYear +
        '-' +
        disableMonth +
        '-' +
        disableDay +
        'T' +
        disableHour +
        ':' +
        disableMinute;

      this.setState({
        enableDate,
        disableDate,
        unlimitedDisableDate:
          Math.abs(Date.parse(disableDate) - Date.parse(enableDate)) <=
          1000 * 60 * 60 * 24 * 365 * 5
            ? false
            : true,
      });
    }

    verificaPerfil(findValueInArray(getUserInfo().contactList, 'type', 'cellPhone').value).then(
      (result) => {
        this.setState({ superUser: result });
      }
    );

    window.pathname = null;
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    //tratando casos especiais
    //não permitir que qtdSimultaneo seja menor que 1
    if (name === 'qtdSimultaneo') {
      return this.setState({
        [name]: event.target.value < 1 ? 1 : event.target.value,
        goBack: true,
        openAlert: event.target.value < 1 ? true : false,
        messageAlert: event.target.value < 1 ? 'Este valor não pode ser menor que 1.' : '',
      });
    }

    if (name === 'level')
      return this.setState({
        [name]:
          event.target.value > 99 || event.target.value < 1 ? this.state.level : event.target.value,
        openAlert: event.target.value > 99 || event.target.value < 1 ? true : false,
        messageAlert:
          event.target.value > 99 || event.target.value < 1
            ? 'O nível da hierarquia deve ser entre 1 e 99.'
            : null,
      });

    if (
      name === 'cooldownDays' ||
      name === 'cooldownHours' ||
      name === 'cooldownMinutes' ||
      name === 'cost'
    ) {
      if (name === 'cooldownMinutes' && event.target.value > 59)
        return this.setState({
          [name]: 59,
          goBack: true,
        });

      if (name === 'cooldownHours' && event.target.value > 23)
        return this.setState({
          [name]: 23,
          goBack: true,
        });

      return this.setState({
        [name]: event.target.value < 0 ? 0 : event.target.value,
        goBack: true,
        openAlert: event.target.value < 0 ? true : false,
        messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
      });
    }

    if (event.target.value === 'imediato') {
      // Se a opção selecionada for 'imediato', ajusta automaticamente couponGetMode para 'simpleGetMode'
      this.setState({
        [name]: event.target.value,
        showQRCode: true,
        autoValidate: 'cliente',
        goBack: true,
        qtdSimultaneo: 1,
        couponGetMode: 'simpleGetMode',
      });
    } else {
      this.setState({
        [name]: event.target.value,
        goBack: true,
      });
    }

    // coupon get mode check
    if (name === 'couponGetMode')
      return this.setState({
        [name]: event.target.value,
      });

    if (event.target.value === 'promocao')
      return this.setState({
        [name]: event.target.value,
        showQRCode: true,
        goBack: true,
      });

    //ADICIONAL
    if (event.target.value === 'posterior')
      return this.setState({
        [name]: event.target.value,
        goBack: true,
        showQRCode: null,
        autoValidate: 'estabelecimento',
        couponGetMode: null,
      });

    // //casos normais
    return this.setState({
      [name]: event.target.value,
      goBack: true,
    });
  };

  //handle downshift
  handleChangeDownshift = (item) => {
    let { keywordsList } = this.state;

    if (keywordsList.indexOf(item) === -1) {
      keywordsList = [...keywordsList, item];
    }

    this.setState({
      keywords: '',
      keywordsList,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keywordsList = [...state.keywordsList];
      keywordsList.splice(keywordsList.indexOf(item), 1);
      return { keywordsList };
    });
  };
  //fim handle downshift

  handleChangeSwitch = (name) => (event) => {
    let disableButtonImage = this.state.disableButtonImage;

    if (name === 'disableButtonImage') {
      if (disableButtonImage) {
        disableButtonImage = false;
        this.setState({ disableButtonImage });
      } else {
        disableButtonImage = true;
        this.setState({ disableButtonImage });
      }
    } else if (name === 'isPublished') {
      this.setState({
        [name]: !event.target.checked,
        goBack: true,
      });
    } else if (name === 'hasSimultaneo') {
      this.setState({
        [name]: event.target.checked,
        consumoUnico: event.target.checked,
        goBack: true,
      });

      if (!this.state.hasSimultaneo) {
        this.setState({
          qtdSimultaneo: 1,
        });
      }
    } else {
      this.setState({
        [name]: event.target.checked,
        goBack: true,
      });
    }

    if (name === 'unlimitedDisableDate') {
      this.setState({
        disableDate: convertIsoToDate(new Date(Date.now() + 86400000 * 365 * 60)),
      });
    }
  };

  handleTagCapture = (e) => {
    this.setState({ tagCapture: e.target.value });
  };

  handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handlePushTag(e);
    }
  };

  handlePushTag = (e) => {
    e.preventDefault();
    let tagHolder = this.state.tags;
    if (
      tagHolder.indexOf(this.state.tagCapture.toLocaleLowerCase()) === -1 &&
      this.state.tagCapture.length !== 0
    ) {
      tagHolder.push(this.state.tagCapture.toLowerCase());
    }
    this.setState({
      tags: [...tagHolder],
      tagCapture: '',
      disableButtonUpdate: false,
    });
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  //handle Modal
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  fileChangedHandler = (name, oldName) => (event) => {
    this.setState({ [oldName]: this.state[name] });

    if (event.target.files[0] !== undefined) {
      const data = [URL.createObjectURL(event.target.files[0])];
      this.setState({ imageToCrop: data, imageCroped: name }, this.handleOpenModal);
    }
  };

  photoListChangedHandler = (event) => {
    let listImages = [];

    for (let index = 0; index < event.target.files.length; index++) {
      listImages[index] = URL.createObjectURL(event.target.files[index]);
    }

    if (event.target.files !== undefined) {
      this.setState(
        {
          imageToCrop: listImages,
          imageCroped: 'photoListFile',
        },
        this.handleOpenModal
      );
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;

    //imagem recebida do crop
    let node = this[state].crop();

    this.setState(
      {
        [newCrop]: node,
        imageToCrop: null,
        goBack: true,
      },
      this.handleCloseModal
    );
  }

  photoListCropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    //verificando se é um array de imagens
    let data = [];
    if (this.state[newCrop] !== null) {
      // const a = this.state.indexCrop === 0 ? [] : this.state[newCrop];
      let a = this.state[newCrop];
      data = a.concat(node);
    } else {
      data = [node];
    }

    this.setState(
      {
        [newCrop]: this.state.imageCroped === 'photoListFile' ? data.slice(0, 4) : node,
        indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
        goBack: true,
        imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
      },
      () => {
        if (endArray === undefined) {
          this.handleCloseModal();
          // }else {
          // 	this.handleOpenModal()
        }
      }
    );
  }

  handleEqualImages = (name) => (event) => {
    if (event.target.checked === true) {
      this.setState({
        equalImageThumnailFile:
          this.state.fileStoreThumbnail === null
            ? this.state.loadedStoreThumbnail
            : this.state.fileStoreThumbnail,
        equalImagePhotoFile:
          this.state.fileStorePhoto === null
            ? this.state.loadedStorePhoto
            : this.state.fileStorePhoto,
        equalImageThumnail: true,
        equalImagePhoto: true,
      });
    } else {
      this.setState({
        equalImageThumnailFile: null,
        equalImagePhotoFile: null,
        equalImageThumnail: false,
        equalImagePhoto: false,
      });
    }
  };

  //HANDLE ALERT
  goBack = () => {
    if (this.state.goBack === false) {
      window.history.back();
    } else {
      this.handleClickOpen();
    }
  };

  checkInformation = () => {
    this.setState({ loading: true });

    let finalHours =
      parseInt(this.state.cooldownHours, 10) +
      parseInt(24 * parseInt(this.state.cooldownDays, 10), 10);

    let couponGetModeValue = this.state.couponGetMode;
    let showQRCodeValue = this.state.showQRCode;
    let autoValidateValue;
    if (this.state.consumo === 'imediato') {
      showQRCodeValue = true;
      autoValidateValue = couponGetModeValue === 'scannerMode' ? 'cliente' : 'estabelecimento';
    } else {
      couponGetModeValue = null;
    }

    const uid = getUserInfo().uId;
    getTrialStatus(uid).then((result) => {
      updateTrialStatus({
        establishmentStep: result.establishmentStep,
        storeStep: true,
        relationshipStep: result.relationshipStep,
        congrats: result.congrats,
      }).then((result) => {
        if (
          window.pathname !== null &&
          JSON.parse(JSON.stringify(window.sessionStorage)).secondStepNew === 'incomplete'
        ) {
          window.sessionStorage.setItem('secondStepNew', 'complete');
          this.setState({ modalStepIsOpen: true });
        }
      });
    });
    this.props.updateStorePromotionInformationComponent(
      this.state.id,
      this.state.consumoUnico,
      finalHours,
      this.state.cooldownMinutes,
      this.state.dataGeracao,
      this.state.sameInformation ? this.state.description : this.state.descricao,
      this.state.detailsDescription,
      this.state.credit,
      this.state.keywordsSetConsumption,
      this.state.messageBlockedKw,
      this.state.detailsTitle,
      this.state.generator,
      this.state.neighborhood,
      this.state.nomeTemplate || ' ',
      this.state.plural,
      this.state.qtdDisponivel,
      this.state.qtdSimultaneo,
      this.state.singular,
      this.state.sameInformation ? this.state.title : this.state.titulo,
      this.state.type,
      this.state.fileThumbnail,
      this.state.oldThumbnail,
      this.state.thumbnail,
      this.state.fileFoto,
      this.state.oldFoto,
      this.state.foto,

      this.state.campanhaTriibo,
      this.state.buttonLabel,
      this.state.buttonDescription,
      this.state.showButtonDescription,
      this.state.goToPlaceLabel,
      this.state.showGoToPlace,
      this.state.consumed,
      this.state.idStore,
      this.state.active,
      this.state.level,
      this.state.region,
      this.state.description,
      showQRCodeValue,
      this.state.title,
      this.state.storeType,
      autoValidateValue,
      null,
      this.state.deliverType,
      this.state.cost,
      this.state.enableDate,
      this.state.disableDate,
      this.state.isPublished,
      this.state.keywordsList,
      this.state.tags,
      this.state.fileStoreThumbnail,
      this.state.oldStoreThumbnail,
      this.state.storeThumbnail,
      this.state.fileStorePhoto,
      this.state.oldStorePhoto,
      this.state.storePhoto,

      this.state.idEstabelecimento,
      this.state.idGestor,
      this.state.nomeEstabelecimento,
      this.state.lat,
      this.state.long,
      this.state.address,

      this.state.hasSimultaneo,
      this.state.equalImageThumnailFile,
      this.state.equalImagePhotoFile,
      this.state.oldPhotoList,
      this.state.photoListFile,
      this.state.messageApproval,
      this.state.newStatus,
      couponGetModeValue
    );
  };

  handleSubmit = () => {
    const checkInformation =
      (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) ||
      (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) ||
      (this.state.equalImageThumnailFile === null &&
        this.state.fileThumbnail === null &&
        this.state.loadedThumbnail === null) ||
      (this.state.equalImagePhotoFile === null &&
        this.state.fileFoto === null &&
        this.state.loadedFoto === null);

    if (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail.',
      });
    } else if (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar ao menos uma imagem na lista de fotos.',
      });
    } else if (
      this.state.equalImageThumnailFile === null &&
      this.state.fileThumbnail === null &&
      this.state.loadedThumbnail === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail para o cupom.',
      });
    } else if (
      this.state.equalImagePhotoFile === null &&
      this.state.fileFoto === null &&
      this.state.loadedFoto === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de detalhe para o cupom.',
      });
    } else if (this.state.tags.length < 1) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar ao menos uma tag',
      });
    } else if (checkInformation === true) {
      this.setState({
        openAlert: true,
        messageAlert: 'Todas as informações devem ser preenchidas.',
      });
    } else {
      this.checkInformation();

      setTimeout(() => {
        delete window.promoListOrganized;
        window.history.back();
      }, 2000);
    }
  };

  //HANDLE DIALOGS
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  //handle alerts
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  removeImage = (idx) => {
    if (this.state.photoListFile && idx < this.state.photoListFile.length) {
      let newPhotoList = this.state.photoListFile;
      newPhotoList.splice(idx, 1);
      this.setState({
        photoListFile: newPhotoList,
        disableButtonUpdate: false,
      });
    } else {
      // console.log("Imagem não existe");
    }
  };

  mainImage = (idx) => {
    let currentFoto = this.state.fileStorePhoto
      ? this.state.fileStorePhoto
      : this.state.loadedStorePhoto;
    let newPhotoListFile = this.state.photoListFile;
    let newFoto = this.state.photoListFile[idx];

    newPhotoListFile.splice(idx, 1);
    newPhotoListFile.unshift(currentFoto);

    this.setState({ photoListFile: newPhotoListFile, fileStorePhoto: newFoto });
  };

  //Função que verifica se é a mesma informação
  sameInformation = () => (event) => {
    this.setState({
      titulo: this.state.title,
      descricao: this.state.description,
    });
  };

  handleStateButton = () => {
    this.setState({ disableButtonUpdate: false });
  };

  //Função que aprova as promoções, dos tipos: edição e criação
  approvePromotion = async (e) => {
    e.preventDefault();
    this.setState({ isApproveButtonDisabled: true });
    let currentDate = Date.now();

    //imagens voucherTemplate
    const equalImageThumnailFile = await toDataURL(this.state.equalImageThumnailFile);
    const equalImagePhotoFile = await toDataURL(this.state.equalImagePhotoFile);

    if (this.state.fileThumbnail !== null || equalImageThumnailFile !== null) {
      this.state.thumbnail = 'storage:' + this.state.idStore + ':thumbnail:' + currentDate;
      this.state.fileThumbnail =
        equalImageThumnailFile === null
          ? b64toBlob(this.state.fileThumbnail)
          : b64toBlob(equalImageThumnailFile);
    }

    if (this.state.fileFoto !== null || equalImagePhotoFile !== null) {
      this.state.foto = 'storage:' + this.state.idStore + ':foto:' + currentDate;
      this.state.fileFoto =
        equalImagePhotoFile === null
          ? b64toBlob(this.state.fileFoto)
          : b64toBlob(equalImagePhotoFile);
    }

    //imagens da promoção
    if (this.state.fileStoreThumbnail !== null) {
      this.state.storeThumbnail =
        this.state.storeType === 'promotion'
          ? 'storage:' + this.state.idStore + ':thumbnail:' + currentDate
          : 'triibomania-premios/storage:' + this.state.idStore + ':thumbnail:' + currentDate;

      if (this.state.fileStoreThumbnail.indexOf('https') === 0) {
        this.state.fileStoreThumbnail = await downloadImageAsBlob(this.state.fileStoreThumbnail);
      } else {
        this.state.fileStoreThumbnail = b64toBlob(this.state.fileStoreThumbnail);
      }
    }

    if (this.state.fileStorePhoto !== null) {
      this.state.storePhoto =
        this.state.storeType === 'promotion'
          ? 'storage:' + this.state.idStore + ':foto:' + currentDate
          : 'triibomania-premios/storage:' + this.state.idStore + ':foto:' + currentDate;

      if (this.state.fileStorePhoto.indexOf('https') === 0) {
        this.state.fileStorePhoto = await downloadImageAsBlob(this.state.fileStorePhoto);
      } else {
        this.state.fileStorePhoto = b64toBlob(this.state.fileStorePhoto);
      }
    }

    let listaFotos = [];
    if (this.state.photoListFile !== null) {
      for (const key in this.state.photoListFile) {
        listaFotos[key] = 'gal' + key + '_' + this.state.id + ':' + currentDate;

        if (this.state.photoListFile[key].indexOf('https') === 0) {
          this.state.photoListFile[key] = await downloadImageAsBlob(this.state.photoListFile[key]);
        } else {
          this.state.photoListFile[key] = b64toBlob(this.state.photoListFile[key]);
        }
      }
    } else {
      listaFotos = this.state.oldPhotoList;
    }

    let pathnameArray = window.location.pathname.split('/');

    let pathname = '/';

    if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
      pathname = '/admin/estabelecimento/' + this.state.idEstabelecimento + '/ofertas';
    } else if (
      pathnameArray[1] === 'admin' &&
      pathnameArray[2] === 'aprovacao' &&
      pathnameArray[3] === 'promocao'
    ) {
      pathname = '/admin/aprovar/promocoes';
    } else {
      pathname = '/estabelecimento/' + this.state.idEstabelecimento + '/promocoes';
    }

    const userId = getUserInfo().uId;

    const numberToMillis = (number) => {
      return number * 86400000;
    };

    //dados da voucherTemplate que vão ser atualizados
    const dataVoucher = {
      campanhaTriibo: this.state.campanhaTriibo,
      sendSMS: this.state.sendSMS,
      consumoUnico: this.state.consumoUnico,
      consumed: this.state.consumed ?? false,
      cooldown: this.state.cooldown,
      dataGeracao: this.state.dataGeracao,
      descricao: this.state.descricao,
      detailsDescription: this.state.detailsDescription,
      credit: this.state.credit,
      detailsTitle: this.state.detailsTitle === '' ? ' ' : this.state.detailsTitle,
      generator: this.state.generator,
      neighborhood: this.state.neighborhood ?? ' ',
      foto: this.state.foto,
      thumbnail: this.state.thumbnail,
      nomeTemplate: this.state.nomeTemplate ?? ' ',
      plural: this.state.plural ?? ' ',
      qtdSimultaneo: this.state.qtdSimultaneo,
      singular: this.state.singular ?? ' ',
      titulo: this.state.titulo,
      type: this.state.type,
      idEstabelecimento: this.state.idEstabelecimento,
      idGestor: this.state.idGestor,
      nomeEstabelecimento: this.state.nomeEstabelecimento,
      lat: this.state.lat,
      long: this.state.long,
      address: this.state.address,
      cost: this.state.cost,
      tags: this.state.tags,
      expirationType: this.state.expirationType,
      onSendExpiration:
        this.state.expirationType === 'onSend' ? numberToMillis(this.state.onSendExpiration) : 0,
      couponGetMode: !this.state.couponGetMode ? ' ' : this.state.couponGetMode.toString(),
    };

    //dados da promoção
    const data = {
      status: 1,
      level: this.state.level,
      description: this.state.description,
      dueDate: this.state.dueDate,
      showQRCode: this.state.showQRCode,
      campanhaTriibo: this.state.campanhaTriibo,
      thumbnail: this.state.storeThumbnail,
      title: this.state.title,
      type: this.state.storeType,
      establishmentId: this.state.idEstabelecimento,
      establishmentName: this.state.nomeEstabelecimento,
      region: this.state.region,
      lat: this.state.lat,
      long: this.state.long,
      photo: this.state.storePhoto,
      templateId: this.state.id,
      cost: this.state.cost,
      deliverType: this.state.deliverType,
      enableDate: Date.parse(this.state.enableDate),
      disableDate: Date.parse(this.state.disableDate),
      isPublished: true,
      keyWordsSet: this.state.keywordsList,
      autoValidate: this.state.autoValidate === 'cliente' ? true : false,
      photoList: listaFotos,
      tags: this.state.tags,
      buttonLabel: this.state.buttonLabel,
      buttonDescription: this.state.buttonDescription,
      showButtonDescription: this.state.showButtonDescription,
      goToPlaceLabel: this.state.goToPlaceLabel,
      couponGetMode: this.state.couponGetMode,
    };

    const emptyFields = [];

    if (!data.tags[0]) {
      emptyFields.push('Tags');
    }

    if (!data.title) {
      emptyFields.push('Título');
    }

    if (!data.description) {
      emptyFields.push('Descrição');
    }

    if (!data.enableDate) {
      emptyFields.push('Data de ativação');
    }

    if (!data.disableDate) {
      emptyFields.push('Data de desativação');
    }

    if (!dataVoucher.titulo) {
      emptyFields.push('Título do cupom na carteira');
    }

    if (!dataVoucher.descricao) {
      emptyFields.push('Descrição resumida na carteira');
    }

    if (!dataVoucher.detailsDescription) {
      emptyFields.push('Descrição detalhada na carteira');
    }

    const storageFolderStore =
      this.state.storeType === 'promotion' ? 'promocao' : 'triibomania-premios';

    if (emptyFields.length === 1) {
      const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
      toast.error(errorMessage);
      this.setState({ isApproveButtonDisabled: false });
    } else if (emptyFields.length > 1) {
      const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
        ', '
      )}`;
      toast.error(errorMessage);
      this.setState({ isApproveButtonDisabled: false });
    } else {
      if (this.state.action !== 'create') {
        // api para aprovar promoções do tipo de edição
        confirmStoreChanges(userId, this.state.idStore, true, this.state.comment, data).then(() => {
          this.props.history.push('/admin/aprovado/promocoes');
          this.setState({ isApproveButtonDisabled: true });
          toast.success('Promoção aprovada com sucesso');
        });

        //atualiza os dados do voucherTemplate
        updateVoucherTemplate(userId, this.state.id, dataVoucher);
        uploadImage('voucher', this.state.fileThumbnail, this.state.thumbnail);
        uploadImage('voucher', this.state.fileFoto, this.state.foto);

        //imagens da promoção
        uploadImage(storageFolderStore, this.state.fileStoreThumbnail, this.state.storeThumbnail);
        uploadImage(storageFolderStore, this.state.fileStorePhoto, this.state.storePhoto);
        uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
      } else {
        // api para aprovar promoções do tipo de criação
        const payload = {
          status: 'aprovado',
          comment: this.state.comment ?? '',
        };

        if (emptyFields.length === 1) {
          const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
          this.setState({ isApproveButtonDisabled: false });
          toast.error(errorMessage);
        } else if (emptyFields.length > 1) {
          const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
            ', '
          )}`;
          toast.error(errorMessage);
          this.setState({ isApproveButtonDisabled: false });
        } else {
          //atualiza os dados da promoção
          updateStore(this.state.idStore, data, pathname).then(() => {
            //atualiza a aprovação da promoção
            updateStoreApprovals(userId, this.state.idStore, payload, null);
            this.setState({ isApproveButtonDisabled: true });
            toast.success('Promoção aprovada com sucesso!');
            this.props.history.push('/admin/aprovado/promocoes');
          });

          //atualiza os dados do voucherTemplate
          updateVoucherTemplate(userId, this.state.id, dataVoucher);
          uploadImage('voucher', this.state.fileThumbnail, this.state.thumbnail);
          uploadImage('voucher', this.state.fileFoto, this.state.foto);

          //imagens da promoção
          uploadImage(storageFolderStore, this.state.fileStoreThumbnail, this.state.storeThumbnail);
          uploadImage(storageFolderStore, this.state.fileStorePhoto, this.state.storePhoto);
          uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
        }
      }
    }
  };

  //Função que reprova as promoções, dos tipos: edição e criação
  disapprovePromotion = async (e) => {
    this.setState({ isDisapproveButtonDisabled: true });

    e.preventDefault();
    if (this.state.comment === undefined || this.state.comment.trim() === '') {
      toast.error('A mensagem de avaliação é obrigatória!');
      return;
    }

    let currentDate = Date.now();

    //imagens voucherTemplate
    const equalImageThumnailFile = await toDataURL(this.state.equalImageThumnailFile);
    const equalImagePhotoFile = await toDataURL(this.state.equalImagePhotoFile);

    if (this.state.fileThumbnail !== null || equalImageThumnailFile !== null) {
      this.state.thumbnail = 'storage:' + this.state.idStore + ':thumbnail:' + currentDate;
      this.state.fileThumbnail =
        equalImageThumnailFile === null
          ? b64toBlob(this.state.fileThumbnail)
          : b64toBlob(equalImageThumnailFile);
    }

    if (this.state.fileFoto !== null || equalImagePhotoFile !== null) {
      this.state.foto = 'storage:' + this.state.idStore + ':foto:' + currentDate;
      this.state.fileFoto =
        equalImagePhotoFile === null
          ? b64toBlob(this.state.fileFoto)
          : b64toBlob(equalImagePhotoFile);
    }

    //imagens da promoção
    if (this.state.fileStoreThumbnail !== null) {
      this.state.storeThumbnail =
        this.state.storeType === 'promotion'
          ? 'storage:' + this.state.idStore + ':thumbnail:' + currentDate
          : 'triibomania-premios/storage:' + this.state.idStore + ':thumbnail:' + currentDate;

      if (this.state.fileStoreThumbnail.indexOf('https') === 0) {
        this.state.fileStoreThumbnail = await downloadImageAsBlob(this.state.fileStoreThumbnail);
      } else {
        this.state.fileStoreThumbnail = b64toBlob(this.state.fileStoreThumbnail);
      }
    }

    if (this.state.fileStorePhoto !== null) {
      this.state.storePhoto =
        this.state.storeType === 'promotion'
          ? 'storage:' + this.state.idStore + ':foto:' + currentDate
          : 'triibomania-premios/storage:' + this.state.idStore + ':foto:' + currentDate;

      if (this.state.fileStorePhoto.indexOf('https') === 0) {
        this.state.fileStorePhoto = await downloadImageAsBlob(this.state.fileStorePhoto);
      } else {
        this.state.fileStorePhoto = b64toBlob(this.state.fileStorePhoto);
      }
    }

    let listaFotos = [];
    if (this.state.photoListFile !== null) {
      for (const key in this.state.photoListFile) {
        listaFotos[key] = 'gal' + key + '_' + this.state.id + ':' + currentDate;

        if (this.state.photoListFile[key].indexOf('https') === 0) {
          this.state.photoListFile[key] = await downloadImageAsBlob(this.state.photoListFile[key]);
        } else {
          this.state.photoListFile[key] = b64toBlob(this.state.photoListFile[key]);
        }
      }
    } else {
      listaFotos = this.state.oldPhotoList;
    }

    let pathnameArray = window.location.pathname.split('/');

    let pathname = '/';

    if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
      pathname = '/admin/estabelecimento/' + this.state.idEstabelecimento + '/ofertas';
    } else if (
      pathnameArray[1] === 'admin' &&
      pathnameArray[2] === 'aprovacao' &&
      pathnameArray[3] === 'promocao'
    ) {
      pathname = '/admin/aprovar/promocoes';
    } else {
      pathname = '/estabelecimento/' + this.state.idEstabelecimento + '/promocoes';
    }

    const userId = getUserInfo().uId;

    const numberToMillis = (number) => {
      return number * 86400000;
    };

    //dados da voucherTemplate que vão ser atualizados
    const dataVoucher = {
      campanhaTriibo: this.state.campanhaTriibo,
      sendSMS: this.state.sendSMS,
      consumoUnico: this.state.consumoUnico,
      consumed: this.state.consumed ?? false,
      cooldown: this.state.cooldown,
      dataGeracao: this.state.dataGeracao,
      descricao: this.state.descricao,
      detailsDescription: this.state.detailsDescription,
      credit: this.state.credit,
      detailsTitle: this.state.detailsTitle === '' ? ' ' : this.state.detailsTitle,
      generator: this.state.generator,
      neighborhood: this.state.neighborhood ?? ' ',
      foto: this.state.foto,
      thumbnail: this.state.thumbnail,
      nomeTemplate: this.state.nomeTemplate ?? ' ',
      plural: this.state.plural ?? ' ',
      qtdSimultaneo: this.state.qtdSimultaneo,
      singular: this.state.singular ?? ' ',
      titulo: this.state.titulo,
      type: this.state.type,
      idEstabelecimento: this.state.idEstabelecimento,
      idGestor: this.state.idGestor,
      nomeEstabelecimento: this.state.nomeEstabelecimento,
      lat: this.state.lat,
      long: this.state.long,
      address: this.state.address,
      cost: this.state.cost,
      tags: this.state.tags,
      expirationType: this.state.expirationType,
      onSendExpiration:
        this.state.expirationType === 'onSend' ? numberToMillis(this.state.onSendExpiration) : 0,
      couponGetMode: !this.state.couponGetMode ? ' ' : this.state.couponGetMode.toString(),
    };

    //dados da promoção
    const data = {
      status: 1,
      level: this.state.level,
      description: this.state.description,
      dueDate: this.state.dueDate,
      showQRCode: this.state.showQRCode,
      campanhaTriibo: this.state.campanhaTriibo,
      thumbnail: this.state.storeThumbnail,
      title: this.state.title,
      type: this.state.storeType,
      establishmentId: this.state.idEstabelecimento,
      establishmentName: this.state.nomeEstabelecimento,
      region: this.state.region,
      lat: this.state.lat,
      long: this.state.long,
      photo: this.state.storePhoto,
      templateId: this.state.id,
      cost: this.state.cost,
      deliverType: this.state.deliverType,
      enableDate: Date.parse(this.state.enableDate),
      disableDate: Date.parse(this.state.disableDate),
      isPublished: this.state.isPublished,
      keyWordsSet: this.state.keywordsList,
      autoValidate: this.state.autoValidate === 'cliente' ? true : false,
      photoList: listaFotos,
      tags: this.state.tags,
      buttonLabel: this.state.buttonLabel,
      buttonDescription: this.state.buttonDescription,
      showButtonDescription: this.state.showButtonDescription,
      goToPlaceLabel: this.state.goToPlaceLabel,
      couponGetMode: this.state.couponGetMode,
    };

    const emptyFields = [];

    if (!data.tags[0]) {
      emptyFields.push('Tags');
    }

    if (!data.title) {
      emptyFields.push('Título');
    }

    if (!data.description) {
      emptyFields.push('Descrição');
    }

    if (!data.enableDate) {
      emptyFields.push('Data de ativação');
    }

    if (!data.disableDate) {
      emptyFields.push('Data de desativação');
    }

    if (!dataVoucher.titulo) {
      emptyFields.push('Título do cupom na carteira');
    }

    if (!dataVoucher.descricao) {
      emptyFields.push('Descrição resumida na carteira');
    }

    if (!dataVoucher.detailsDescription) {
      emptyFields.push('Descrição detalhada na carteira');
    }

    const storageFolderStore =
      this.state.storeType === 'promotion' ? 'promocao' : 'triibomania-premios';

    if (emptyFields.length === 1) {
      const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
      toast.error(errorMessage);
      this.setState({ isDisapproveButtonDisabled: false });
    } else if (emptyFields.length > 1) {
      const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
        ', '
      )}`;
      toast.error(errorMessage);
      this.setState({ isDisapproveButtonDisabled: false });
    } else {
      if (this.state.action !== 'create') {
        // api para reprovar promoções do tipo de edição
        confirmStoreChanges(userId, this.state.idStore, false, this.state.comment, data).then(
          () => {
            this.props.history.push('/admin/aprovado/promocoes');
          }
        );

        //atualiza os dados do voucherTemplate
        updateVoucherTemplate(userId, this.state.id, dataVoucher);
        uploadImage('voucher', this.state.fileThumbnail, this.state.thumbnail);
        uploadImage('voucher', this.state.fileFoto, this.state.foto);

        //imagens da promoção
        uploadImage(storageFolderStore, this.state.fileStoreThumbnail, this.state.storeThumbnail);
        uploadImage(storageFolderStore, this.state.fileStorePhoto, this.state.storePhoto);
        uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
      } else {
        // api para reprovar promoções do tipo de criação
        const payload = {
          status: 'negado',
          comment: this.state.comment,
        };
        if (emptyFields.length === 1) {
          const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
          this.setState({ isDisapproveButtonDisabled: false });
          toast.error(errorMessage);
        } else if (emptyFields.length > 1) {
          const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
            ', '
          )}`;
          this.setState({ isDisapproveButtonDisabled: false });
          toast.error(errorMessage);
        } else {
          //atualiza os dados da promoção
          updateStore(this.state.idStore, data, pathname).then(() => {
            //atualiza a aprovação da promoção
            updateStoreApprovals(userId, this.state.idStore, payload, null);
            toast.success('Promoção reprovada com sucesso!');
            this.setState({ isDisapproveButtonDisabled: true });
            this.props.history.push('/admin/aprovado/promocoes');
          });

          //atualiza os dados do voucherTemplate
          updateVoucherTemplate(userId, this.state.id, dataVoucher);
          uploadImage('voucher', this.state.fileThumbnail, this.state.thumbnail);
          uploadImage('voucher', this.state.fileFoto, this.state.foto);

          //imagens da promoção
          uploadImage(storageFolderStore, this.state.fileStoreThumbnail, this.state.storeThumbnail);
          uploadImage(storageFolderStore, this.state.fileStorePhoto, this.state.storePhoto);
          uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
        }
      }
    }
  };

  copyTemplateIdToClipboard = () => {
    navigator.clipboard.writeText(this.state.id);
    this.setState({ copyTemplateMessage: 'ID copiado' });
  };

  render = () => {
    let fotos = [];
    for (let i = 0; i < 4; i++) {
      let exists =
        (this.state.photoListFile && this.state.photoListFile[i]) ||
        (this.state.loadedPhotoList && this.state.loadedPhotoList[i]);
      fotos.push(
        <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
          <StyledBadge
            badgeContent={
              exists ? (
                <Tooltip title="Excluir">
                  <Close
                    style={{ width: '100%', height: '100%' }}
                    onClick={() => this.removeImage(i)}
                  />
                </Tooltip>
              ) : (
                ''
              )
            }
            invisible={!exists}
          >
            <Tooltip title={exists ? 'Tornar principal' : 'Utilize o botão "Adicionar fotos"'}>
              <img
                style={{ cursor: exists ? 'pointer' : 'default' }}
                onClick={exists ? () => this.mainImage(i) : null}
                src={
                  this.state.photoListFile && this.state.photoListFile[i]
                    ? this.state.photoListFile[i]
                    : this.state.loadedPhotoList && this.state.loadedPhotoList[i]
                    ? this.state.loadedPhotoList[i]
                    : placeholder
                }
                width="100%"
                height="100%"
                alt="Lista de Fotos"
              />
            </Tooltip>
          </StyledBadge>
        </Grid>
      );
    }

    if (this.props.loading && this.state.loading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    } else {
      if (
        window.pathname !== null &&
        JSON.parse(JSON.stringify(window.sessionStorage)).secondStepNew === 'incomplete'
      ) {
        window.sessionStorage.setItem('secondStepNew', 'complete');
        return (
          <Redirect
            to={{
              pathname: `/estabelecimento/${this.state.establishmentListInfos[0].id}/secondStep`,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      }
      if (this.state.modalStepIsOpen === true) {
        return (
          <Redirect
            to={{
              pathname: `/estabelecimento/${this.state.establishmentListInfos[0].id}/secondStep`,
              state: this.establishmentListInfos[0],
            }}
          />
        );
      } else if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      } else {
        return (
          <div
            style={{
              maxHeight: '100%',
              overflowY: 'auto',
              padding: '35px 22px',
              maxWidth: '100%',
            }}
          >
            <Grid item sm={12} xs={12}>
              {/* alert com msg aprovação */}
              {this.state.aprovacao && this.state.aprovacao.status !== 'aprovado' && (
                <StyledAlert
                  variant="filled"
                  severity={this.state.aprovacao.status === 'negado' ? 'error' : 'info'}
                >
                  {this.state.aprovacao.status === 'negado'
                    ? this.state.aprovacao.comment
                    : `Aguarde aprovação até ${formatDate(
                        this.state.aprovacao.requestDate + this.dayMs * this.responseDays
                      )}`}
                </StyledAlert>
              )}
            </Grid>

            {this.state.editPage === true && this.state.title === null ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100vh',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size="100px" />
              </div>
            ) : (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  color="primary"
                  style={{
                    fontWeight: '700',
                    fontSize: '25px',
                    marginBottom: '10px',
                  }}
                >
                  {this.state.nomeEstabelecimento}
                </Typography>
                {this.state.idStore === null ? (
                  <Typography
                    variant="h5"
                    gutterBottom
                    color="primary"
                    style={{ fontWeight: '700', fontSize: '25px', marginBottom: '10px' }}
                  >
                    Cadastro da Promoção
                  </Typography>
                ) : (
                  <EditShowId text="Editar Promoção" id={this.state.idStore} />
                )}

                <form
                  onChange={() => this.handleStateButton()}
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                >
                  <div>
                    <S.TagsLabelContainer>
                      <S.TagsBigLabel>Tags do seu negócio</S.TagsBigLabel>
                      <S.TagsSmallLabel>
                        As tags auxiliam na classificação e busca do seu negócio dentro do App.
                        Adicione uma palavra por vez.
                      </S.TagsSmallLabel>
                    </S.TagsLabelContainer>

                    <S.TagsContainer>
                      <S.TagsAddContainer>
                        <D.TriiboTextField
                          rows={1}
                          placeholder={'Ex.pizza*'}
                          value={this.state.tagCapture}
                          onChange={(e) => {
                            this.handleTagCapture(e);
                          }}
                          onKeyDown={(e) => {
                            this.handlePressEnter(e);
                          }}
                        />
                        <S.TagsAddButton
                          blue={true}
                          onClick={(e) => {
                            this.handlePushTag(e);
                          }}
                        >
                          Adicionar tag
                        </S.TagsAddButton>
                      </S.TagsAddContainer>
                      <D.TriiboTagsField>
                        <D.TriiboTagsPlaceholder
                          visibility={this.state.tags.length === 0 ? 'hidden' : 'visible'}
                        >
                          Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                        </D.TriiboTagsPlaceholder>
                        {this.state.tags.length > 0 &&
                          this.state.tags.map((body, index) => (
                            <div key={index}>
                              <D.UserTags>
                                <D.TagText>{body}</D.TagText>
                                <D.TagDelete
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let newArr = this.state.tags;
                                    newArr.splice(index, 1);
                                    this.setState({
                                      tags: [...newArr],
                                      disableButtonUpdate: false,
                                    });
                                  }}
                                >
                                  {<BsXLg style={{ color: 'white' }} />}
                                </D.TagDelete>
                              </D.UserTags>
                            </div>
                          ))}
                      </D.TriiboTagsField>
                    </S.TagsContainer>
                  </div>
                  <FormGroup>
                    <TextField
                      placeholder="Ex: Leve 2, pague 1!!!"
                      className="input-field"
                      type="text"
                      value={this.state.title === null ? '' : this.state.title}
                      id="title"
                      label="Título da promoção"
                      onChange={this.handleChange('title')}
                      required={true}
                    />
                    <TextField
                      style={{ width: '100%' }}
                      placeholder="Ex: Ao comprar 2 produtos você paga somente por 1."
                      className="input-field"
                      multiline
                      type="text"
                      value={this.state.description === null ? '' : this.state.description}
                      id="description"
                      required={true}
                      label="Descrição da promoção"
                      onChange={this.handleChange('description')}
                    />
                    <br />
                    <Typography
                      style={{
                        marginTop: '10px',
                        marginBottom: '-15px',
                        fontWeight: '700',
                        fontSize: '20px',
                      }}
                      variant="h5"
                      gutterBottom
                      color="primary"
                    >
                      Fotos da promoção
                    </Typography>
                    <br />
                    <br />
                    <div className="flex-external">
                      <div
                        className="img-thumb"
                        style={{
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="secondary"
                          style={{ fontWeight: '550', textAlign: 'center' }}
                        >
                          Thumbnail (aparecerá em "Promoções Próximas")
                        </Typography>

                        <img
                          src={thumbnailPromocao}
                          alt="Thumbnail"
                          style={{
                            width: '100%',
                            maxWidth: '400px',
                            maxHeight: '447px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <img
                          className="img-position-simples"
                          alt="thumbnail"
                          src={
                            this.state.fileStoreThumbnail ??
                            this.state.loadedStoreThumbnail ??
                            placeholder
                          }
                        />

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <input
                            value=""
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/*"
                            onChange={this.fileChangedHandler(
                              'fileStoreThumbnail',
                              'storeThumbnail'
                            )}
                            id="thumbnail"
                          />

                          <label htmlFor="thumbnail">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{
                                marginTop: '15px',
                                fontWeight: '550',
                                marginLeft: '20px',
                                color: '#fff',
                                textTransform: 'capitalize',
                              }}
                              startIcon={<AddPhotoAlternate />}
                            >
                              Adicionar Foto
                            </Button>

                            <Tooltip title="As imagens devem ser 4x3.">
                              <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                            </Tooltip>
                          </label>
                        </div>
                      </div>

                      {/* Lista de fotos */}
                      <div>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          color="secondary"
                          className="style-text"
                          style={{ fontWeight: '550' }}
                        >
                          Lista de Fotos (aparecerá em "Detalhe do Estabelecimento")
                        </Typography>

                        <section className="grid-style">
                          <div
                            className="Foto-detalhe"
                            style={{
                              width: '100%',
                              maxWidth: '400px',
                              position: 'relative',
                              top: '0',
                              left: '0',
                            }}
                          >
                            <img
                              alt="Lista de Fotos"
                              src={listaFotos}
                              style={{
                                width: '100%',
                                maxWidth: '392px',
                                position: 'relative',
                                top: '0',
                                left: '0',
                              }}
                            />

                            <StyledBadge2
                              style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                height: '100%',
                                width: '100%',
                              }}
                              invisible={
                                (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                                (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                                  ? false
                                  : true
                              }
                              badgeContent={
                                <Tooltip
                                  title={
                                    (this.state.loadedStorePhoto &&
                                      this.state.loadedStorePhoto[0]) ||
                                    (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                                      ? 'Para trocar clique em uma imagem ao lado'
                                      : 'Utilize o botão "Adicionar fotos"'
                                  }
                                  style={{ height: '100%', width: '100%' }}
                                >
                                  <Help color="secondary" style={{ fontSize: 18 }} />
                                </Tooltip>
                              }
                              color="primary"
                            >
                              <img
                                src={
                                  this.state.fileStorePhoto
                                    ? this.state.fileStorePhoto
                                    : this.state.loadedStorePhoto
                                    ? this.state.loadedStorePhoto
                                    : placeholder
                                }
                                alt="lista de fotos"
                                width="100%"
                                style={{
                                  maxWidth: '393px',
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                }}
                              />
                            </StyledBadge2>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {this.state.loadedStorePhoto || this.state.fileStorePhoto ? (
                                <div>
                                  <input
                                    value=""
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*"
                                    onChange={this.photoListChangedHandler}
                                    id="listaFotos"
                                  />
                                  <label htmlFor="listaFotos">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      style={{
                                        marginTop: '15px',
                                        fontWeight: '550',
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                      }}
                                      startIcon={<AddPhotoAlternate />}
                                    >
                                      Adicionar Foto Na Lista
                                    </Button>

                                    <Tooltip title="As imagens devem ser 4x3.">
                                      <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                                    </Tooltip>
                                  </label>
                                </div>
                              ) : (
                                <div>
                                  <input
                                    value=""
                                    id="photoStore"
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*"
                                    onChange={this.fileChangedHandler(
                                      'fileStorePhoto',
                                      'storePhoto'
                                    )}
                                  />
                                  <label htmlFor="photoStore">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      style={{
                                        marginTop: '15px',
                                        fontWeight: '550',
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                      }}
                                      startIcon={<AddPhotoAlternate />}
                                    >
                                      Adicionar Foto De Detalhe
                                    </Button>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="lista-detalhes" id="photoList">
                            {fotos}
                          </div>
                        </section>
                      </div>
                    </div>
                    <br />
                    <Typography
                      style={{
                        marginTop: '50px',
                        marginBottom: '-15px',
                        fontWeight: '700',
                        fontSize: '20px',
                      }}
                      placeholder="Placeholder"
                      variant="h5"
                      gutterBottom
                      color="primary"
                    >
                      Informações do Cupom (para uso da promoção)
                    </Typography>
                    <br />
                    {this.state.idStore !== null ? (
                      <Box display="flex">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            fontWeight: '550',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          startIcon={<FileCopy />}
                          onClick={() => this.copyTemplateIdToClipboard()}
                        >
                          {this.state.copyTemplateMessage}
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <br />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.sameInformation}
                          onChange={this.handleChangeSwitch('sameInformation')}
                          color="primary"
                          onClick={this.sameInformation()}
                        />
                      }
                      label={'Utilizar as mesmas informações da promoção'}
                    />
                    <TextField
                      disabled={this.state.sameInformation}
                      style={{
                        width: '100%',
                      }}
                      placeholder="Ex: Leve 2 e pague 1"
                      className="input-field"
                      value={
                        this.state.sameInformation
                          ? this.state.title
                          : this.state.titulo === null
                          ? ''
                          : this.state.titulo
                      }
                      type="text"
                      id="titulo"
                      required={true}
                      label="Título do cupom na carteira"
                      onChange={this.handleChange('titulo')}
                    />
                    <TextField
                      disabled={this.state.sameInformation}
                      style={{ width: '100%' }}
                      placeholder="Ex: Leve 2 produtos e pague 1. (Limite de 60 caracteres)"
                      className="input-field"
                      value={
                        this.state.sameInformation
                          ? this.state.description
                          : this.state.descricao === null
                          ? ''
                          : this.state.descricao
                      }
                      multiline
                      onChange={this.handleChange('descricao')}
                      type="text"
                      id="descricao"
                      required={true}
                      label="Descrição resumida na carteira"
                    />
                    <TextField
                      style={{ width: '100%' }}
                      placeholder="Ex: Parabéns, você ganhou um desconto. Apresente este cupom no local e aproveite!"
                      className="input-field"
                      value={
                        this.state.detailsDescription === null ? '' : this.state.detailsDescription
                      }
                      multiline
                      onChange={this.handleChange('detailsDescription')}
                      type="text"
                      id="detailsDescription"
                      required={true}
                      label="Descrição detalhada na carteira"
                    />
                    <br />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.disableButtonImage}
                          onChange={this.handleChangeSwitch('disableButtonImage')}
                          color="primary"
                          onClick={this.handleEqualImages('both')}
                        />
                      }
                      label={'Utilizar as mesmas imagens da promoção'}
                    />
                    {!this.state.disableButtonImage && (
                      <Typography
                        style={{
                          marginTop: '10px',
                          marginBottom: '15px',
                          fontWeight: '700',
                          fontSize: '20px',
                        }}
                        variant="h5"
                        gutterBottom
                        color="primary"
                      >
                        Fotos do cupom na carteira
                      </Typography>
                    )}
                    {!this.state.disableButtonImage && (
                      <div className="flex-external">
                        <div
                          className="img-thumb"
                          style={{
                            maxWidth: '400px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            color="secondary"
                            style={{ fontWeight: '550', textAlign: 'center' }}
                          >
                            Thumbnail (aparecerá na "Carteira")
                          </Typography>

                          <img
                            alt="Thumbnail"
                            src={cuponsValidos}
                            style={{
                              width: '100%',
                              maxWidth: '400px',
                              position: 'relative',
                              top: '0',
                              left: '0',
                            }}
                          />

                          <img
                            alt="thumbnail"
                            className="img-position-carteira"
                            src={
                              this.state.equalImageThumnailFile !== null
                                ? this.state.equalImageThumnailFile
                                : this.state.fileThumbnail !== null
                                ? this.state.fileThumbnail
                                : this.state.loadedThumbnail !== null
                                ? this.state.loadedThumbnail
                                : placeholder
                            }
                          />

                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <input
                              value=""
                              disabled={this.state.equalImageThumnail === true ? true : false}
                              id="thumbailTemplate"
                              accept="image/*"
                              style={{ display: 'none' }}
                              type="file"
                              onChange={this.fileChangedHandler('fileThumbnail', 'thumbnail')}
                            />

                            <label htmlFor="thumbailTemplate">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{
                                  marginTop: '15px',
                                  fontWeight: '550',
                                  marginLeft: '20px',
                                  color: '#fff',
                                  textTransform: 'capitalize',
                                }}
                                disabled={this.state.disableButtonImage}
                                startIcon={<AddPhotoAlternate />}
                              >
                                Adicionar Foto
                              </Button>

                              <Tooltip title="As imagens devem ser 4x3.">
                                <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                              </Tooltip>
                            </label>
                          </div>
                        </div>

                        <div
                          className="img-thumb"
                          style={{
                            maxWidth: '400px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            color="secondary"
                            style={{ fontWeight: '550', textAlign: 'center' }}
                          >
                            Foto detalhada (aparecerá em "Detalhe do Cupom")
                          </Typography>

                          <img
                            alt="Placeholder lista img"
                            src={nomeCupom}
                            style={{
                              maxWidth: '100%',
                              position: 'relative',
                              top: '0',
                              left: '0',
                            }}
                          />

                          <img
                            alt="Foto detalhada"
                            width="100%"
                            style={{ position: 'absolute', left: '0' }}
                            src={
                              this.state.equalImagePhotoFile !== null
                                ? this.state.equalImagePhotoFile
                                : this.state.fileFoto !== null
                                ? this.state.fileFoto
                                : this.state.loadedFoto !== null
                                ? this.state.loadedFoto
                                : placeholder
                            }
                          />

                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <input
                              value=""
                              disabled={this.state.equalImagePhoto === true ? true : false}
                              id="photoTemplate"
                              style={{ display: 'none' }}
                              type="file"
                              onChange={this.fileChangedHandler('fileFoto', 'foto')}
                            />

                            <label htmlFor="photoTemplate">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{
                                  marginTop: '15px',
                                  fontWeight: '550',
                                  marginLeft: '20px',
                                  color: '#fff',
                                  textTransform: 'capitalize',
                                }}
                                disabled={this.state.disableButtonImage}
                                startIcon={<AddPhotoAlternate />}
                              >
                                Adicionar Fotos
                              </Button>
                              <Tooltip title="As imagens devem ser 4x3.">
                                <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                              </Tooltip>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <Typography
                      style={{
                        marginTop: '60px',
                        marginBottom: '-10px',
                        fontWeight: '700',
                        fontSize: '20px',
                      }}
                      variant="h5"
                      gutterBottom
                      color="primary"
                    >
                      Informação do Cupom (para uso da promoção)
                    </Typography>
                    <br />
                    <br />
                    <FormControl className="input-field">
                      <RadioGroup
                        aria-label="consumo"
                        name="consumo"
                        value={this.state.consumo}
                        onChange={this.handleChange('consumo')}
                      >
                        <FormControlLabel
                          value="posterior"
                          control={<Radio color="primary" />}
                          label="Na carteira (Cupom enviado à carteira para consumo posterior)"
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          value="imediato"
                          control={<Radio color="primary" />}
                          label="Imediato (Cupom consumido imediatamente)"
                          labelPlacement="end"
                        />
                      </RadioGroup>

                      {this.state.consumo === 'imediato' && (
                        <RadioGroup
                          aria-label="couponGetMode"
                          name="couponGetMode"
                          value={this.state.couponGetMode}
                          onChange={this.handleChange('couponGetMode')}
                          style={{ marginLeft: 50 }}
                        >
                          <FormControlLabel
                            value="simpleGetMode"
                            control={<Radio color="primary" />}
                            label="Mostrar QR code para validação do Estabelecimento."
                            labelPlacement="end"
                          />

                          <FormControlLabel
                            value="scannerMode"
                            control={<Radio color="primary" />}
                            label="Escanear QR code (Necessita escanear um QR code)"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      )}

                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.consumoUnico}
                            onChange={this.handleChangeSwitch('hasSimultaneo')}
                            color="primary"
                          />
                        }
                        label={'Promoção de consumo único por usuário'}
                      />

                      <Typography
                        style={{
                          fontWeight: '400',
                          fontSize: '1rem',
                          marginTop: '30px',
                          marginLeft: '-45px',
                        }}
                        gutterBottom
                      >
                        Quantidade máxima de cupons não consumidos na carteira do usuário
                      </Typography>
                      <TextField
                        style={{
                          width: '80px',
                          padding: '8px',
                          marginLeft: '-6px',
                        }}
                        className="input-field"
                        disabled={
                          this.state.consumo === 'imediato' || this.state.hasSimultaneo === true
                        }
                        onChange={this.handleChange('qtdSimultaneo')}
                        value={this.state.qtdSimultaneo === null ? '' : this.state.qtdSimultaneo}
                        type="number"
                        id="qtdSimultaneo"
                        required={this.state.isPublished}
                      />

                      <Typography
                        style={{
                          fontWeight: '400',
                          fontSize: '1rem',
                          marginTop: '30px',
                          marginLeft: '-45px',
                        }}
                        gutterBottom
                      >
                        Tempo de espera para pegar outro cupom
                      </Typography>
                      <Grid container item lg={8}>
                        <Grid item lg={1}>
                          <TextField
                            style={{ width: '60px' }}
                            className="input-field"
                            value={this.state.cooldownDays === null ? '' : this.state.cooldownDays}
                            onChange={this.handleChange('cooldownDays')}
                            type="number"
                            id="cooldownDays"
                            required={true}
                            label="Dias"
                          />
                        </Grid>
                        <Grid item lg={1}>
                          <TextField
                            style={{ width: '60px' }}
                            className="input-field"
                            value={
                              this.state.cooldownHours === null ? '' : this.state.cooldownHours
                            }
                            onChange={this.handleChange('cooldownHours')}
                            type="number"
                            id="cooldownHours"
                            required={true}
                            label="Horas"
                          />
                        </Grid>
                        <Grid item lg={1}>
                          <TextField
                            style={{ width: '70px' }}
                            className="input-field"
                            value={
                              this.state.cooldownMinutes === null ? '' : this.state.cooldownMinutes
                            }
                            onChange={this.handleChange('cooldownMinutes')}
                            type="number"
                            id="cooldownMinutes"
                            required={true}
                            label="Minutos"
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl className="input-field">
                      <br />
                      <section>
                        <Grid container spacing={2} alignItems="stretch">
                          <Grid item sm={12} md={12}>
                            <Typography
                              style={{
                                fontWeight: '400',
                                fontSize: '1rem',
                                marginTop: '30px',
                                marginLeft: '-45px',
                              }}
                              gutterBottom
                            >
                              Data de ativação
                            </Typography>

                            <TextField
                              id="enableDate"
                              type="datetime-local"
                              value={this.state.enableDate === null ? '' : this.state.enableDate}
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleChange('enableDate')}
                              required={true}
                            />
                          </Grid>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel
                              style={{ marginTop: '10px' }}
                              control={
                                <Switch
                                  checked={this.state.unlimitedDisableDate}
                                  onChange={this.handleChangeSwitch('unlimitedDisableDate')}
                                  color="primary"
                                />
                              }
                              label={'Desabilitar data de desativação programada'}
                            />
                            <div
                              style={{
                                color: 'red',
                                fontSize: '0.7rem',
                                fontWeight: 400,
                                padding: 0,
                              }}
                            >
                              *Se a data de desativação for maior que 5 anos esse botão será
                              habilitado automaticamente.
                            </div>
                          </div>

                          <Grid
                            item
                            sm={12}
                            md={12}
                            style={{
                              visibility: this.state.unlimitedDisableDate ? 'hidden' : 'visible',
                              width: '100%',
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: '400',
                                fontSize: '1rem',
                                marginLeft: '-45px',
                              }}
                              gutterBottom
                            >
                              Data de desativação
                            </Typography>

                            <TextField
                              id="disableDate"
                              name="disableDate"
                              type="datetime-local"
                              value={this.state.disableDate === null ? '' : this.state.disableDate}
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleChange('disableDate')}
                              required={true}
                            />
                          </Grid>
                        </Grid>
                      </section>

                      <br />
                      <br />
                    </FormControl>
                  </FormGroup>
                  {/* para promoções novas */}
                  {this.state.wherePromo === null && (
                    <>
                      <Button
                        style={{
                          fontWeight: '550',
                          marginTop: '50px',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.goBack()}
                        startIcon={<ArrowBack />}
                      >
                        Voltar
                      </Button>

                      <Button
                        disabled={this.state.disableButtonUpdate}
                        style={{
                          fontWeight: '550',
                          marginTop: '50px',
                          marginLeft: '10px',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={<DoneAll />}
                      >
                        {isAdminPage()
                          ? 'Salvar'
                          : !this.state.idStore
                          ? 'Solicitar Aprovação'
                          : this.state.aprovacao && this.state.aprovacao.status === 'negado'
                          ? 'Solicitar Revisão'
                          : 'Atualizar Informação'}
                      </Button>
                    </>
                  )}

                  {/* para promoções existentes */}
                  {this.state.wherePromo === 'estabelecimento-promo' && (
                    <>
                      <Button
                        style={{
                          fontWeight: '550',
                          marginTop: '50px',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          localStorage.removeItem('statusPromotion');
                          this.goBack();
                        }}
                        startIcon={<ArrowBack />}
                      >
                        Voltar
                      </Button>

                      <Button
                        disabled={this.state.disableButtonUpdate}
                        style={{
                          fontWeight: '550',
                          marginTop: '50px',
                          marginLeft: '10px',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={<DoneAll />}
                      >
                        {isAdminPage()
                          ? 'Salvar'
                          : !this.state.idStore
                          ? 'Solicitar Aprovação'
                          : this.state.aprovacao && this.state.aprovacao.status === 'negado'
                          ? 'Solicitar Revisão'
                          : 'Atualizar Informação'}
                      </Button>
                    </>
                  )}
                </form>
              </>
            )}

            {this.state.wherePromo === 'curadoria-promo' && (
              <S.CardContainer>
                <S.SideContainer>
                  <S.SectionLabelContainer style={{ padding: '0rem 2rem 2rem 2rem' }}>
                    <Box sx={{ paddingBottom: '4rem' }}>
                      <S.TitleH2>Campo de Mensagem</S.TitleH2>
                    </Box>

                    <Box>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <S.TagsLabelContainer style={{ marginTop: '0rem' }}>
                          Mensagem de Avaliação !
                        </S.TagsLabelContainer>

                        <br />
                        <D.TriiboTextField
                          disabled={this.state.status !== 'aberto'}
                          rows={1}
                          placeholder={'Mensagem...'}
                          maxLength="40"
                          onChange={(e) => this.setState({ comment: e.target.value })}
                          value={this.state.comment === '' ? '' : this.state.comment}
                        />
                        <div>
                          <S.TagsAddButton
                            disabled={
                              this.state.status !== 'aberto'
                                ? true
                                : false || this.state.isApproveButtonDisabled
                            }
                            onClick={(e) => {
                              this.approvePromotion(e);
                            }}
                            style={{
                              marginTop: '20px',
                            }}
                          >
                            {this.state.isApproveButtonDisabled ? (
                              <CircularProgress size={13} color={'inherit'} />
                            ) : (
                              'Aprovar'
                            )}
                          </S.TagsAddButton>

                          <S.FailButton
                            disabled={
                              this.state.status !== 'aberto'
                                ? true
                                : false || this.state.isDisapproveButtonDisabled
                            }
                            onClick={(e) => {
                              this.disapprovePromotion(e);
                            }}
                            style={{
                              marginLeft: '10px',
                              marginTop: '20px',
                            }}
                          >
                            {this.state.isDisapproveButtonDisabled ? (
                              <CircularProgress size={13} color={'inherit'} />
                            ) : (
                              'Reprovar'
                            )}
                          </S.FailButton>
                        </div>
                      </form>
                    </Box>
                  </S.SectionLabelContainer>
                </S.SideContainer>
              </S.CardContainer>
            )}

            {/* ALERTS */}
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {'Deseja voltar a página anterior?'}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  As alterações realizadas não serão salvas.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  cancelar
                </Button>
                <Button onClick={() => window.history.back()} color="primary">
                  voltar
                </Button>
              </DialogActions>
            </Dialog>
            {/* FIM ALERTS */}
            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openModal}
              onClose={this.handleCloseModal}
            >
              <div style={getModalStyle()}>
                <Typography
                  variant="h5"
                  gutterBottom
                  color="primary"
                  style={{ fontWeight: '600', textAlign: 'center' }}
                >
                  Editar Imagem
                </Typography>

                <div className="style-modalCrop">
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop[this.state.indexCrop]}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={4 / 3}
                    />
                  )}
                </div>

                <div
                  style={{
                    position: 'fixed',
                    bottom: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: '18px',
                    marginLeft: '-6',
                  }}
                >
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleCloseModal()}
                    startIcon={<ArrowBack />}
                  >
                    Cancelar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.state.imageCroped === 'photoListFile') {
                        this.photoListCropImage('image');
                      } else {
                        this.cropImage('image');
                      }
                    }}
                    startIcon={<CropFreeIcon />}
                  >
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}

            {/* ALERT */}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messageAlert}</span>}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.storePromotionComponent.loading,
    success: state.storePromotionComponent.success,
    error: state.storePromotionComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateStorePromotionInformationComponent: (
    id,
    consumoUnico,
    cooldownHours,
    cooldownMinutes,
    dataGeracao,
    descricao,
    detailsDescription,
    credit,
    keywordsSetConsumption,
    messageBlockedKw,
    detailsTitle,
    generator,
    neighborhood,
    nomeTemplate,
    plural,
    qtdDisponivel,
    qtdSimultaneo,
    singular,
    titulo,
    type,
    fileThumbnail,
    oldThumbnail,
    thumbnail,
    fileFoto,
    oldFoto,
    foto,
    campanhaTriibo,
    buttonLabel,
    buttonDescription,
    showButtonDescription,
    goToPlaceLabel,
    showGoToPlace,
    consumed,
    idStore,
    active,
    level,
    region,
    description,
    showQRCode,
    title,
    storeType,
    autoValidate,
    voucherPaymentTemplates,
    deliverType,
    cost,
    enableDate,
    disableDate,
    isPublished,
    keywordsList,
    tags,
    fileStoreThumbnail,
    oldStoreThumbnail,
    storeThumbnail,
    fileStorePhoto,
    oldStorePhoto,
    storePhoto,

    idEstabelecimento,
    idGestor,
    nomeEstabelecimento,
    lat,
    long,
    address,

    hasSimultaneo,
    equalImageThumnailFile,
    equalImagePhotoFile,
    oldPhotoList,
    photoListFile,
    messageApproval,
    newStatus,
    couponGetMode,
    expirationType,
    disableDateCoupon,
    OnSendExpiration
  ) =>
    updateStorePromotionInformationAction(
      dispatch,
      id,
      consumoUnico,
      cooldownHours,
      cooldownMinutes,
      dataGeracao,
      descricao,
      detailsDescription,
      credit,
      keywordsSetConsumption,
      messageBlockedKw,
      detailsTitle,
      generator,
      neighborhood,
      nomeTemplate,
      plural,
      qtdDisponivel,
      qtdSimultaneo,
      singular,
      titulo,
      type,
      fileThumbnail,
      oldThumbnail,
      thumbnail,
      fileFoto,
      oldFoto,
      foto,
      campanhaTriibo,
      buttonLabel,
      buttonDescription,
      showButtonDescription,
      goToPlaceLabel,
      showGoToPlace,
      consumed,
      idStore,
      active,
      level,
      region,
      description,
      showQRCode,
      title,
      storeType,
      autoValidate,
      voucherPaymentTemplates,
      deliverType,
      cost,
      enableDate,
      disableDate,
      isPublished,
      keywordsList,
      tags,
      fileStoreThumbnail,
      oldStoreThumbnail,
      storeThumbnail,
      fileStorePhoto,
      oldStorePhoto,
      storePhoto,

      idEstabelecimento,
      idGestor,
      nomeEstabelecimento,
      lat,
      long,
      address,

      hasSimultaneo,
      equalImageThumnailFile,
      equalImagePhotoFile,
      oldPhotoList,
      photoListFile,
      messageApproval,
      newStatus,
      couponGetMode,
      expirationType,
      disableDateCoupon,
      OnSendExpiration
    ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(StorePromotionExternal);
