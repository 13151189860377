import React from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { ChromePicker } from 'react-color';

export const EdgeSelector = ({
  form,
  handleChangeSlider,
  ModelCardsGridModal,
  displayColorPickerBorder,
  setDisplayColorPickerBorder,
  setForm,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: '1px solid #aaaaaa',
        padding: '1rem',
      }}
    >
      <span style={{ width: '100%', marginBottom: '0.8rem' }}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Bordas:
        </Typography>
      </span>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '40px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FormControl sx={{ width: '300px' }}>
            <InputLabel id="borderStyle-label">Tipo de Borda</InputLabel>
            <Select
              label="Tipo de Borda"
              labelId="borderStyle-label"
              id="borderStyle"
              value={form.border.style.value || ''}
              onChange={(event) =>
                handleChangeSlider({
                  target: { name: 'border.style', value: event.target.value },
                })
              }
            >
              {Object.entries(ModelCardsGridModal.borderStylesMap).map(([original, translated]) => (
                <MenuItem key={original} value={original}>
                  {translated}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '40%',
            }}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                align="left"
                sx={{ paddingLeft: '0px !important' }}
              >
                Largura da Borda
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: '10px' }}>
                {`${form.border.tickness.value || 1}px`}
              </Typography>
            </span>
            <Slider
              disabled={form.border.style.value === 'none'}
              value={form.border.tickness.value || 1}
              min={1}
              max={30}
              step={1}
              onChange={(event, newValue) =>
                handleChangeSlider({
                  target: { name: 'border.tickness', value: newValue },
                })
              }
              sx={{ width: '90%', marginLeft: '10px' }}
            />
          </Box>

          <Box display={'flex'} alignItems={'center'} gap={'10px'} sx={{ paddingBottom: '10px' }}>
            <TextField
              disabled={form.border.style.value === 'none'}
              width="100px"
              label="Cor da borda"
              id="border.color"
              margin="normal"
              value={form?.border?.color?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form?.border?.color?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              disabled={form?.border?.style.value === 'none'}
              variant="contained"
              onClick={() => setDisplayColorPickerBorder((prevState) => !prevState)}
            >
              {displayColorPickerBorder ? 'Salvar' : 'Selecione a cor'}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: '100px' }}>
        {displayColorPickerBorder && (
          <ChromePicker
            color={form?.border?.color?.value}
            onChange={(event) =>
              setForm((prevState) => ({
                ...prevState,
                border: {
                  ...prevState.border,
                  color: { ...prevState.border.color, value: event.hex },
                },
              }))
            }
          />
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="20px"
        >
          <Typography variant="body1" paddingLeft="0px !important">
            Visualização:
          </Typography>
          <Box
            sx={{
              border: `${form?.border.tickness.value}px ${form?.border.style.value} ${form?.border.color.value}`,
              height: '100px',
              width: '300px',
              backgroundColor: '#fff',
            }}
          ></Box>
        </Box>
      </Box>
    </div>
  );
};
