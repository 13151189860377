import authToken from 'js/library/utils/API/authToken';

import axios from 'axios';
import cfac22 from '../../cfac22';

export function confirmEstablishmentChangeRequest(uId, id, status, comment, data) {
  // console.log(data);
  return new Promise(async (resolve, reject) => {
    authToken(uId).then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/establishmentRequest/confirmEstablishmentRequest`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: {
          establishmentId: id,
          status: status,
          comment: comment ? comment : '', // opcional
          changedFields: data, // opcional
        },
      };

      axios(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
