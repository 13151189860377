import axios from 'axios';

import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import { uploadImage, deleteImage } from 'js/library/services/StorageManager.js';
import { b64toBlob } from 'js/library/utils/helpers';
import cfac22 from '../cfac22';

export function updateOrg(
  uId,
  orgID,
  newInfo,
  newCoverImage,
  coverImageFile,
  oldCoverImage,
  newThumbnail,
  thumbnailFile,
  oldThumbnail,
  logoImageFile,
  newLogo,
  oldLogoImage
) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1(uId)
      .then((channelTokenId) => {
        //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);
        axios
          .post(
            cfac22('API_HOST_V1') + 'insertOrUpdateOrg',
            {
              triiboHeader: {
                apiToken: cfac22('keyapiv1triibowebadmin'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId,
                channelId: cfac22('channelId'),
                channelTokenId,
              },
              orgInfo: {
                orgID,
                ...newInfo,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then(async (result) => {
            if (newCoverImage) {
              const splitedOldCoverImageData = oldCoverImage && oldCoverImage.split('/');

              oldCoverImage &&
                (await deleteImage(splitedOldCoverImageData[0], splitedOldCoverImageData[1]));
              await uploadImage(
                'organizations',
                b64toBlob(coverImageFile),
                newInfo.coverImage.split('/')[1]
              );
            }

            if (newThumbnail) {
              const splitedOldThumbnail = oldThumbnail && oldThumbnail.split('/');

              oldThumbnail && (await deleteImage(splitedOldThumbnail[0], splitedOldThumbnail[1]));
              await uploadImage(
                'organizations',
                b64toBlob(thumbnailFile),
                newInfo.thumbnail.split('/')[1]
              );
            }

            if (newLogo) {
              const splitedOldLogoImage = oldLogoImage && oldLogoImage.split('/');

              oldLogoImage && (await deleteImage(splitedOldLogoImage[0], splitedOldLogoImage[1]));
              await uploadImage(
                'organizations',
                b64toBlob(logoImageFile),
                newInfo.logoImage.split('/')[1]
              );
            }

            window.pathname = '/admin/configuracoes/organizacoes';

            resolve(result.data);
          })
          .catch((error) => {
            // console.log("ERROR UPDATE ORG ---->", error);
            reject(error);
          });
      })
      .catch((error) => {
        // console.log("ERROR GET CHANNEL TOKEN ---->", error);
        reject(error);
      });
  });
}
