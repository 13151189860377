import React, { useState, useEffect } from 'react';

// LIBS
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast, ToastContainer } from 'react-toastify';

// STYLES
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box, Tooltip } from '@mui/material';
import { ButtonStyled } from './styles';

// ICONES
import { PencilSimple, TrashSimple } from 'phosphor-react';

export default function DragDropModules({ modules, onModulesUpdate, setOpen, setModuleSelected }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const itemsArray = modules.map((module, index) => ({
      ...module,
      priority: index,
    }));
    setItems(itemsArray);
  }, [modules]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    // atualiza o priority
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      priority: index,
    }));

    setItems(updatedItems);
    onModulesUpdate(updatedItems);
  };

  const handleDelete = (priority) => {
    const updatedItems = items
      .filter((item) => item.priority !== priority)
      .map((item, index) => ({
        ...item,
        priority: index,
      }));

    setItems(updatedItems);
    onModulesUpdate(updatedItems);
    toast.success('Modulo removido!', { autoClose: 1500 });
  };

  const getTypeModule = (type) => {
    switch (type) {
      case 'date':
        return 'Data';
      case 'getUserFromClub':
        return 'Buscar usuário no clube';
      case 'insertCode':
        return 'Inserir código';
      case 'file':
        return 'Arquivo';
      default:
        return null;
    }
  };

  const handleEdit = (priority) => {
    const moduleToEdit = items.find((module) => module.priority === priority);
    if (moduleToEdit) {
      setModuleSelected(moduleToEdit);
      setOpen(true);
    }

    localStorage.setItem('Edição', true);
  };

  return (
    <>
      <ToastContainer />
      {items.length > 0 && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ marginTop: '40px' }}
              >
                {items.map(({ label, type, priority }, index) => (
                  <Draggable
                    key={`${index}-${label}-${priority}`}
                    draggableId={`${index}-${label}-${priority}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="draggable-item"
                      >
                        <Paper
                          elevation={1}
                          style={{
                            padding: '16px',
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="h6">{getTypeModule(type)}</Typography>
                          <Box display="flex" alignItems="center" gap="10px">
                            <Tooltip title="Editar" placement="top">
                              <ButtonStyled
                                variant="contained"
                                sx={{
                                  backgroundColor: '#3A63F1',
                                  '&:hover': { backgroundColor: '#3A63F1' },
                                }}
                                onClick={() => handleEdit(priority)}
                              >
                                <PencilSimple size={20} color="#ffffff" weight="fill" />
                              </ButtonStyled>
                            </Tooltip>
                            <Tooltip title="Apagar" placement="top">
                              <ButtonStyled
                                variant="contained"
                                color="error"
                                onClick={() => handleDelete(priority)}
                              >
                                <TrashSimple size={20} color="#ffffff" weight="fill" />
                              </ButtonStyled>
                            </Tooltip>
                          </Box>
                        </Paper>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
}
